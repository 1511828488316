import React, { useState, useEffect, useMemo, useCallback } from "react";
import * as Dfns from "date-fns/fp";
import AttributesChart from "./AttributesChart";
import TagsChart from "./TagsChart";
import CreativeShelf from "./CreativeShelf";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import { randomColorPart } from "../utils/colors";
import { useCreativeMap, CreativeMapItem, MediaType } from "../redux/creative";
import { Page, KpiPicker, FullPageSpinner } from "../Components";
import {
  awaitJSON,
  LinearPerformanceLambdaFetch,
  CreativeLambdaFetch,
  MiscLambdaFetch,
  pollS3,
} from "../utils/fetch-utils";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";
import {
  CreativeInsightsIsciData,
  CreativeInsightsImportanceData,
  Importance,
  CreativeInfoItem,
  B2BCreativeTags,
} from "@blisspointmedia/bpm-types/dist/Creative";

import "./CreativeInsights.scss";
import * as R from "ramda";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
import { BsEye } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";

interface ConceptMap {
  [concept: string]: Set<string>;
}

export const getFillColors = (
  attributeImportanceData: Importance[],
  suppressedAttributes: Set<string>
): Map<string, string> => {
  const seriesColors: string[] = [
    "#aa69e7",
    "#f8369a",
    "#fc8b08",
    "#4dd0e1",
    "#ff77a9",
    "#00a0af",
    "#c4001d",
    "#9e00c5",
    "#ffe330",
    "#efc700",
    "#ff4c64",
    "#d400f9",
    "#00a0df",
    "#691b9a",
    "#ff6f00",
    "#ffb032",
    "#e91e62",
    "#3d5afe",
    "#4c8bff",
    "#ff8e4c",
    "#ff4ca6",
    "#4cfff9",
  ];
  const overflowColors: Record<string, string> = {};
  const getSeriesColorGreenExcluded = (i: number): string => {
    if (i < seriesColors.length) {
      return seriesColors[i];
    }
    if (overflowColors[`${i}`]) {
      return overflowColors[`${i}`];
    }
    const newRandomColor = `#${randomColorPart()}${50}${randomColorPart()}`;
    overflowColors[`${i}`] = newRandomColor;
    return newRandomColor;
  };

  let colors: Map<string, string> = new Map();
  for (let i = 0; i < attributeImportanceData.length; i++) {
    let attributeName = attributeImportanceData[i].name;
    if (!suppressedAttributes.has(attributeName)) {
      colors.set(attributeName, getSeriesColorGreenExcluded(i));
    }
  }

  return colors;
};

const fakeCreative = (modelEntry, creativeIndex, concept, evBodyType, location, color) => {
  return {
    liveLinear: false,
    liveStreaming: false,
    name: `${concept} ${evBodyType} ${location} ${color}`,
    parent: "",
    variant: "",
    length: 30,
    language: "English" as "English" | "Spanish" | "French",
    avail: "N" as "N" | "L",
    file: `TINUITI${creativeIndex}30ENH`,
    clickthroughUrl: "https://www.tinuiti.com",
    media_types: ["linear"] as MediaType[],
    retired: false,
    creativeTags: {
      Concept: [concept],
      "EV Body Type": [evBodyType],
      Location: [location],
      Color: [color],
    },
    concept,
    modelEntry,
    isci: `TINUITI${creativeIndex}30ENH`,
    ranges: [
      {
        startDate: "2024-01-01",
        endDate: "2024-09-01",
      },
    ],
    mostRecentDateRange: { startDate: "2024-01-01", endDate: "2024-09-01" },
  };
};

const CreativeInsights = (): JSX.Element => {
  const { cid: company } = useCompanyInfo();
  const setError = useSetError();

  const [selectedAttribute, setSelectedAttribute] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<string>("");
  const [tagFillColor, setTagFillColor] = useState<string>("");

  const [kpiPickerMap, setKpiPickerMap] = useState<P.GetKpiPickerMapResponse>();
  const [viewableKpis, setViewableKpis] = useState<string[]>();
  const [internalKpis, setInternalKpis] = useState<Set<string>>();
  let isInternal = useSelector(UserRedux.isInternalSelector);

  const companyParam = company === "tinuititest" ? "homechef" : company;

  useEffect(() => {
    if (companyParam && !viewableKpis && !internalKpis) {
      (async () => {
        try {
          let res = await CreativeLambdaFetch("/getEnabledKpis", {
            params: {
              company: companyParam,
            },
          });
          const rows = await awaitJSON<{ cid: string; enabled: boolean }[]>(res);
          let kpis = rows
            .filter(kpi => kpi.enabled || (!kpi.enabled && isInternal))
            .map(kpi => kpi.cid);
          setViewableKpis(kpis);
          let internal = rows.filter(kpi => !kpi.enabled && isInternal).map(kpi => kpi.cid);
          setInternalKpis(new Set(internal));
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [viewableKpis, companyParam, setError, isInternal, internalKpis]);

  useEffect(() => {
    if (companyParam && viewableKpis) {
      (async () => {
        try {
          const res = await LinearPerformanceLambdaFetch<P.GetKpiMetaDataParams>(
            "/getKpiPickerMap",
            {
              params: {
                company: companyParam,
              },
            }
          );
          const kpiMap = await awaitJSON<P.GetKpiPickerMapResponse>(res);
          // Filter for only enabled KPIs for creeative insights
          const filteredKpiMap = viewableKpis
            ? Object.fromEntries(
                Object.entries(kpiMap).filter(([key, value]) => {
                  return viewableKpis.includes(key);
                })
              )
            : {};
          setKpiPickerMap(filteredKpiMap);
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [viewableKpis, companyParam, setError]);

  const [kpi, setKpi] = useState<string>("");
  useEffect(() => {
    if (companyParam) {
      (async () => {
        try {
          let res = await CreativeLambdaFetch("/getInitialKpi", {
            params: {
              company: companyParam,
            },
          });
          const kpi = await awaitJSON<string>(res);
          setKpi(kpi);
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [companyParam, setError]);

  const [
    creativeInsightsIsciData,
    setCreativeInsightsIsciData,
  ] = useState<CreativeInsightsIsciData>({});
  useEffect(() => {
    if (kpi) {
      (async () => {
        try {
          const params: { kpi: string } = {
            kpi,
          };
          const result = await MiscLambdaFetch("/kickOffLambda", {
            method: "POST",
            body: {
              fileType: "txt",
              lambdaArgs: params,
              lambdaName: "creative-getCreativeInsightsIsciData",
            },
          });
          const uuid = await awaitJSON(result);

          const content = await pollS3({
            autoDownload: false,
            bucket: "bpm-cache",
            filename: `${uuid}.txt`,
            mimeType: "text/plain",
          });
          const textContent = await content.text();
          let parsedRes = JSON.parse(textContent) as CreativeInsightsIsciData;

          if (company === "tinuititest") {
            parsedRes = {
              TINUITIA30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITIB30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITIC30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITID30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITIE30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITIF30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITIG30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITIH30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITII30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITIJ30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITIK30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITIL30ENH: { spend: 9075.599999999997, numSpots: 47 },
              TINUITIM30ENH: { spend: 9075.599999999997, numSpots: 47 },
            };
          }
          setCreativeInsightsIsciData(parsedRes);
        } catch (e) {
          setError({
            message: `Couldn't fetch creative map. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [kpi, setError, company]);

  const [attributeImportanceData, setAttributeImportanceData] = useState<Importance[]>([]);
  const [tagImportanceData, setTagImportanceData] = useState<{ [concept: string]: Importance[] }>(
    {}
  );
  const [attributeTypeBinomial, setAttributeTypeBinomial] = useState<{
    [attribute: string]: boolean;
  }>({});
  const [lastModified, setLastModified] = useState<string>();
  useEffect(() => {
    if (companyParam && kpi) {
      (async () => {
        try {
          let res = await CreativeLambdaFetch("/getCreativeInsightsImportanceData", {
            params: {
              company: companyParam,
              kpi,
            },
          });
          let parsedRes = await awaitJSON<{
            creativeInsights: CreativeInsightsImportanceData;
            attributeTypeBinomial: { [attribute: string]: boolean };
            lastModified: Date | null;
          }>(res);
          if (company === "tinuititest") {
            parsedRes = {
              attributeTypeBinomial: {
                Concept: false,
                "EV Body Type": false,
                Location: false,
                Color: false,
              },
              creativeInsights: {
                attributeImportanceData: [
                  { name: "Location", val: 32.57 },
                  { name: "EV Body Type", val: 23.3 },
                  { name: "Color", val: 22.39 },
                  { name: "Concept", val: 21.75 },
                ],
                tagImportanceData: {
                  Concept: [
                    { name: "Freedom", val: Math.round(1.259 * 1000 - 1000) / 10 },
                    { name: "Control", val: Math.round(0.741 * 1000 - 1000) / 10 },
                  ],
                  "EV Body Type": [
                    { name: "Hatchback", val: Math.round(1.168 * 1000 - 1000) / 10 },
                    { name: "Sedan", val: Math.round(1.014 * 1000 - 1000) / 10 },
                    { name: "SUV", val: Math.round(0.818 * 1000 - 1000) / 10 },
                  ],
                  Location: [
                    { name: "Coastal", val: Math.round(1.269 * 1000 - 1000) / 10 },
                    { name: "City", val: Math.round(1.164 * 1000 - 1000) / 10 },
                    { name: "Forest", val: Math.round(0.566 * 1000 - 1000) / 10 },
                  ],
                  Color: [
                    { name: "Jupiter", val: Math.round(1.346 * 1000 - 1000) / 10 },
                    { name: "White", val: Math.round(1.165 * 1000 - 1000) / 10 },
                    { name: "Black", val: Math.round(0.835 * 1000 - 1000) / 10 },
                    { name: "Gold", val: Math.round(0.655 * 1000 - 1000) / 10 },
                  ],
                },
              },
              lastModified: parsedRes.lastModified,
            };
          }
          setAttributeImportanceData(parsedRes.creativeInsights.attributeImportanceData);
          setTagImportanceData(parsedRes.creativeInsights.tagImportanceData);
          setAttributeTypeBinomial(parsedRes.attributeTypeBinomial);
          if (parsedRes.lastModified) {
            setLastModified(
              `${R.pipe(Dfns.parseISO, Dfns.format("M/d/yyyy"))(`${parsedRes.lastModified}`)}`
            );
          } else {
            setLastModified("Info not available");
          }
        } catch (e) {
          setError({
            message: `Couldn't fetch creative insights data. Error: ${e.message}.`,
            reportError: e,
          });
        }
      })();
    }
  }, [companyParam, kpi, setError, company]);

  // In the future we will likely allow users to select other attributes to suppress but for now need to pull default suppressed attributes
  const [suppressedAttributes, setSuppressedAttributes] = useState<Set<string>>(new Set());
  useEffect(() => {
    (async () => {
      try {
        let res = await CreativeLambdaFetch("/getSuppressedAttributes", {
          params: { company: companyParam },
        });

        let attributes = await awaitJSON<string>(res);
        let set = attributes !== null ? new Set<string>(attributes.split(",")) : new Set<string>();
        setSuppressedAttributes(set);
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [companyParam, setError]);

  const fillColors: Map<string, string> = useMemo(() => {
    return getFillColors(attributeImportanceData, suppressedAttributes);
  }, [attributeImportanceData, suppressedAttributes]);

  const [selectedCreative, setSelectedCreative] = useState<CreativeInfoItem>();

  const { creativeMap } = useCreativeMap({
    company: companyParam,
    streaming: false,
    mediaTypes: ["linear"],
  });

  // Maps concepts to a set of disabled attributes
  const [conceptMap, setConceptMap] = useState<ConceptMap>({});
  useEffect(() => {
    (async () => {
      try {
        let res = await CreativeLambdaFetch("/get_creative_options", {
          params: { cid: companyParam },
        });

        let formattedRes = await awaitJSON(res);
        let conceptMap = {};
        for (let entry of formattedRes.conceptData) {
          let attributesSet = new Set<string>();
          let conceptCategories = entry.categories;
          for (let key in conceptCategories) {
            if (!conceptCategories[key]) {
              attributesSet.add(key);
            }
          }
          if (attributesSet.size > 0) {
            conceptMap[entry.concept] = attributesSet;
          }
        }
        if (company === "tinuititest") {
          conceptMap = {
            Concept: ["Control", "Freedom"],
            "EV Body Type": ["SUV", "Sedan", "Hatchback"],
            Location: ["City", "Coastal", "Forest"],
            Color: ["White", "Black", "Jupiter", "Gold"],
          };
        }
        setConceptMap(conceptMap);
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [companyParam, setError, company]);

  const [b2bCreativeTags, setB2BCreativeTags] = useState<B2BCreativeTags>({});
  useEffect(() => {
    (async () => {
      try {
        let res = await CreativeLambdaFetch("/getB2BCreativeTags", {
          params: { company: companyParam },
        });

        let parsedRes = await awaitJSON<B2BCreativeTags>(res);
        setB2BCreativeTags(parsedRes);
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [companyParam, setError]);

  const RECENT_RANGE_INDEX = 0;
  const sortedCreativeList: CreativeMapItem[] = useMemo(() => {
    if (creativeMap) {
      // Add mostRecentRange values to creativeMap
      let processedCreativeMap = Object.values(creativeMap).map(creative => {
        let { creativeTags, ranges } = creative;
        // Sort date ranges and order in descending order
        let sortedRanges = ranges
          .map(range => ({ startDate: range.startDate, endDate: range.endDate }))
          .sort((rangeA, rangeB) => {
            let endDateA = rangeA.endDate || "1950-01-01";
            let endDateB = rangeB.endDate || "1950-01-01";
            return new Date(endDateB).valueOf() - new Date(endDateA).valueOf();
          });

        let mostRecentDateRange;
        if (sortedRanges?.length) {
          let { startDate, endDate } = sortedRanges[RECENT_RANGE_INDEX];
          mostRecentDateRange = { startDate, endDate };
        } else {
          mostRecentDateRange = null;
        }
        if (creative.concept === "B2B") {
          return {
            ...creative,
            creativeTags: b2bCreativeTags[creative.isci] || {},
            mostRecentDateRange,
          };
        } else if (!creativeTags) {
          return { ...creative, creativeTags: {}, mostRecentDateRange };
        } else {
          return { ...creative, mostRecentDateRange };
        }
      });
      if (company === "tinuititest") {
        processedCreativeMap = [
          fakeCreative(1, "A", "Control", "SUV", "City", "White"),
          fakeCreative(2, "B", "Freedom", "Sedan", "City", "White"),
          fakeCreative(3, "C", "Freedom", "Hatchback", "City", "White"),
          fakeCreative(4, "D", "Freedom", "SUV", "Coastal", "Black"),
          fakeCreative(5, "E", "Freedom", "SUV", "Coastal", "Jupiter"),
          fakeCreative(6, "F", "Control", "SUV", "Coastal", "Black"),
          fakeCreative(7, "G", "Control", "SUV", "Coastal", "Jupiter"),
          fakeCreative(8, "H", "Control", "SUV", "Coastal", "White"),
          fakeCreative(9, "I", "Freedom", "Sedan", "Forest", "Black"),
          fakeCreative(10, "J", "Freedom", "Sedan", "Forest", "White"),
          fakeCreative(11, "K", "Freedom", "SUV", "Forest", "Jupiter"),
          fakeCreative(12, "L", "Freedom", "Sedan", "Coastal", "Gold"),
          fakeCreative(13, "M", "Control", "Hatchback", "Coastal", "Black"),
        ];
      }

      // Sort processedCreativeMap using mostRecentDateRange
      return processedCreativeMap.sort((creativeA, creativeB) => {
        let endDateA = creativeA.mostRecentDateRange?.endDate || "1950-01-01";
        let endDateB = creativeB.mostRecentDateRange?.endDate || "1950-01-01";

        return new Date(endDateB).valueOf() - new Date(endDateA).valueOf();
      });
    }

    return [];
  }, [b2bCreativeTags, creativeMap, company]);

  const [creativeInfoList, setCreativeInfoList] = useState<CreativeInfoItem[]>([]);

  const handleAttributeClick: (attributeName: string) => void = useCallback(
    attributeName => {
      if (attributeName === selectedAttribute) {
        setTagFillColor("");
        setCreativeInfoList([]);
        setSelectedAttribute("");
      } else {
        setTagFillColor(fillColors.get(attributeName) || "");

        let sortedCreativeListNAExcluded: CreativeMapItem[];
        if (attributeName === "Concept" || attributeName === "Language") {
          sortedCreativeListNAExcluded = sortedCreativeList.filter(creative => {
            return creative.modelEntry;
          });
        } else {
          // Exclude creatives that have concept with attributeName as disabled attribute
          sortedCreativeListNAExcluded = sortedCreativeList.filter(creative => {
            return !conceptMap[creative.concept]?.has(attributeName) && creative.modelEntry;
          });
        }
        // Process sortedCreativeListNAExcluded
        let map = new Map<number, CreativeMapItem[]>();
        sortedCreativeListNAExcluded.forEach(creative => {
          if (creative.modelEntry) {
            let arr: CreativeMapItem[] = map.get(creative.modelEntry) || [];
            arr.push(creative);
            map.set(creative.modelEntry, arr);
          }
        });

        let creativeInfoList: CreativeInfoItem[] = [];
        map.forEach(value => {
          let name = "";
          let isci = "";
          let file = "";
          let isciList: string[] = [];
          let concept = "";
          let creativeTags: Record<string, string[]> = {};
          let length = 0;
          let language = "";
          let spend = 0;
          let numSpots = 0;
          let mostRecentDateRanges: string[] = [];

          let dateSet = new Set<string>();
          value.forEach(creative => {
            if (creative.mostRecentDateRange && creativeInsightsIsciData[creative.isci]?.numSpots) {
              ({ name, isci, file, concept, creativeTags, length, language } = creative);

              isciList.push(creative.isci);
              let { startDate, endDate } = creative.mostRecentDateRange;
              if (endDate) {
                const formattedStartDate = Dfns.format("MM-dd-yyyy", new Date(startDate));
                const formattedEndDate = Dfns.format("MM-dd-yyyy", new Date(endDate));
                dateSet.add(formattedStartDate.concat(" to ", formattedEndDate));
              } else {
                const formattedStartDate = Dfns.format("MM-dd-yyyy", new Date(startDate));
                dateSet.add(formattedStartDate);
              }
              spend += creativeInsightsIsciData[creative.isci]?.spend;
              numSpots += creativeInsightsIsciData[creative.isci]?.numSpots;
            }
          });

          mostRecentDateRanges = Array.from(dateSet);

          if (mostRecentDateRanges.length > 0 && numSpots > 0) {
            creativeInfoList.push({
              name,
              isci,
              file,
              isciList,
              concept,
              creativeTags,
              length,
              language,
              spend,
              numSpots,
              mostRecentDateRanges,
            });
          }
        });

        setCreativeInfoList(creativeInfoList);
        setSelectedAttribute(attributeName);
      }
      setSelectedTag("");
    },
    [
      selectedAttribute,
      setSelectedAttribute,
      setSelectedTag,
      fillColors,
      setTagFillColor,
      sortedCreativeList,
      conceptMap,
      creativeInsightsIsciData,
      setCreativeInfoList,
    ]
  );

  return (
    <Page
      title="Linear Creative Insights"
      pageType="Linear Creative Insights"
      actions={
        <div className="creativeInsightsActions">
          {lastModified && (
            <div className="lastModified">
              <MdRefresh className="refreshIcon" />
              <div className="lastModifiedDate">{`Data Last Updated: ${lastModified}`}</div>
            </div>
          )}
          {internalKpis?.has(kpi) && (
            <div className="eye">
              <BsEye></BsEye>
            </div>
          )}
          <KpiPicker
            company={companyParam}
            kpi={kpi}
            onChange={value => {
              if (value !== kpi) {
                setKpi(value);
                setAttributeImportanceData([]);
                setTagImportanceData({});
                setSelectedAttribute("");
                setSelectedTag("");
                setSelectedCreative(undefined);
              }
            }}
            kpiMap={kpiPickerMap}
          />
        </div>
      }
    >
      {attributeImportanceData.length && Object.entries(creativeInsightsIsciData).length ? (
        <div className="creativeInsightsBody">
          <div className="creativeInsightsCharts">
            <AttributesChart
              selectedAttribute={selectedAttribute}
              handleAttributeClick={handleAttributeClick}
              setSelectedTag={setSelectedTag}
              fillColors={fillColors}
              attributeImportanceData={attributeImportanceData}
              suppressedAttributes={suppressedAttributes}
              kpi={kpi}
              showDownloadButton={true}
            />
            <TagsChart
              selectedAttribute={selectedAttribute}
              setSelectedAttribute={setSelectedAttribute}
              handleAttributeClick={handleAttributeClick}
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
              tagFillColor={tagFillColor}
              attributeImportanceData={attributeImportanceData}
              tagImportanceData={tagImportanceData}
              setSelectedCreative={setSelectedCreative}
              creativeInfoList={creativeInfoList}
              attributeTypeBinomial={attributeTypeBinomial}
              suppressedAttributes={suppressedAttributes}
              kpi={kpi}
            />
          </div>
          <CreativeShelf
            selectedAttribute={selectedAttribute}
            attributeTypeBinomial={attributeTypeBinomial}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            creativeInsightsIsciData={creativeInsightsIsciData}
            tagImportanceData={tagImportanceData}
            selectedCreative={selectedCreative}
            setSelectedCreative={setSelectedCreative}
            creativeInfoList={creativeInfoList}
          />
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

export default CreativeInsights;
