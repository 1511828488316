import "./CustomerInsightsChart.scss";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import { formatNumber, formatPercent } from "../utils/format-utils";
import {
  CUSTOMER_INSIGHTS_PRETTY_NAMES,
  CustomerInsightsFields,
} from "./customerInsightsConstants";
import { getChartType } from "./customerInsightsUtils";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { getChannelSeriesColor } from "../utils/colors";
import ChartContainer from "../Components/ChartContainer";
import { CustomerInsightsLegend } from "./CustomerInsightsGroup";

interface StackedBarProps {
  data: KnowYourCustomerData[];
  fieldPrettyName: string;
  setSelectedValue: Dispatch<SetStateAction<string | undefined>>;
}

const StackedBar: React.FC<StackedBarProps> = ({ data, fieldPrettyName, setSelectedValue }) => {
  const barFormattedData = useMemo(() => {
    return data.map(({ clientPopulation, tuPopulation, levels }) => {
      return {
        name: levels,
        val1: Math.round(clientPopulation),
        val2: Math.round(tuPopulation),
        val2Reduced: Math.round(tuPopulation - clientPopulation),
        computedValue: formatPercent(clientPopulation / tuPopulation, 1),
      };
    });
  }, [data]);

  const legendFormattedData = useMemo(() => {
    return barFormattedData.map(({ name, val1, val2, computedValue }) => {
      return {
        name: name,
        val1: formatNumber(val1),
        val2: formatNumber(val2),
        computedValue: computedValue,
      };
    });
  }, [barFormattedData]);

  return (
    <div className="customerInsightsBarChart penetrationBarChart">
      <ChartContainer title={fieldPrettyName}>
        {barFormattedData.map((entry, index) => {
          const fill = getChannelSeriesColor(index, true);
          return (
            <ResponsiveContainer width="100%" height={48} key={entry.name}>
              <BarChart
                data={[entry]}
                layout="vertical"
                onClick={() => setSelectedValue(entry.name)}
              >
                <XAxis type="number" hide={true} />
                <YAxis dataKey="name" type="category" hide={true} />
                <Legend
                  content={() => <CustomerInsightsLegend data={[legendFormattedData[index]]} />}
                  layout="vertical"
                  align="left"
                />
                <Bar dataKey="val1" stackId="a" fill={fill} isAnimationActive={false} />
                <Bar
                  dataKey="val2Reduced"
                  stackId="a"
                  fill={fill}
                  isAnimationActive={false}
                  style={{ opacity: 0.25 }}
                />
              </BarChart>
            </ResponsiveContainer>
          );
        })}
      </ChartContainer>
    </div>
  );
};

interface PenetrationPieProps {
  data: KnowYourCustomerData[];
  fieldPrettyName: string;
  setSelectedValue: Dispatch<SetStateAction<string | undefined>>;
}

const PenetrationPie: React.FC<PenetrationPieProps> = ({
  data,
  fieldPrettyName,
  setSelectedValue,
}) => {
  const getFillFromName = useCallback(
    (name: string): string => {
      const index = data.findIndex(entry => entry.levels === name);

      if (index !== -1) {
        return getChannelSeriesColor(index, true);
      }

      return "transparent";
    },
    [data]
  );

  const innerPieFormattedData = useMemo(() => {
    return data.map(({ levels, tuPopulation }) => {
      return {
        name: levels,
        value: Math.round(tuPopulation),
      };
    });
  }, [data]);

  const outerPieFormattedData = useMemo(() => {
    const formattedData: { name; value }[] = [];

    data.forEach(({ levels, clientPopulation, tuPopulation }) => {
      formattedData.push({
        name: levels,
        value: Math.round(clientPopulation),
      });
      formattedData.push({
        name: `non-${levels}`,
        value: Math.round(tuPopulation - clientPopulation),
      });
    });

    return formattedData;
  }, [data]);

  const legendFormattedData = useMemo(() => {
    return data.map(({ levels, clientPopulation, tuPopulation }) => {
      return {
        name: levels,
        val1: formatNumber(clientPopulation),
        val2: formatNumber(tuPopulation),
        computedValue: formatPercent(clientPopulation / tuPopulation, 1),
        color: getFillFromName(levels),
      };
    });
  }, [data, getFillFromName]);

  return (
    <div className="customerInsightsPieChart penetrationPieChart">
      <ChartContainer title={fieldPrettyName}>
        <ResponsiveContainer width="100%">
          <PieChart>
            <Legend
              content={() => (
                <CustomerInsightsLegend
                  data={legendFormattedData}
                  setSelectedValue={setSelectedValue}
                  selectBehavior="click"
                />
              )}
              layout="vertical"
              align="left"
              verticalAlign="top"
            />
            <Pie
              className="penetrationInnerPie"
              data={innerPieFormattedData}
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              innerRadius="15%"
              outerRadius="45%"
              dataKey="value"
            >
              {innerPieFormattedData.map(({ name }) => (
                <Cell
                  className="pieCell"
                  key={`cell-${name}`}
                  fill={getFillFromName(name)}
                  onClick={() => setSelectedValue(name)}
                />
              ))}
            </Pie>
            <Pie
              className="penetrationOuterPie"
              data={outerPieFormattedData}
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              innerRadius="15%"
              outerRadius="60%"
              dataKey="value"
            >
              {outerPieFormattedData.map(({ name }) => (
                <Cell
                  className="pieCell"
                  key={`cell-${name}`}
                  fill={getFillFromName(name)}
                  onClick={() =>
                    setSelectedValue(name.startsWith("non-") ? name.substring(4) : name)
                  }
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );
};

interface SelectedPieProps {
  data: KnowYourCustomerData[];
  fieldName: string;
  fieldPrettyName: string;
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string | undefined>>;
}

const SelectedPie: React.FC<SelectedPieProps> = ({
  data,
  fieldName,
  fieldPrettyName,
  selectedValue,
  setSelectedValue,
}) => {
  const pieRef: RefObject<HTMLDivElement> = useRef(null);
  const getFillFromName = useCallback(
    (name: string): string => {
      const index = data.findIndex(entry => entry.levels === name);

      if (index !== -1) {
        return getChannelSeriesColor(index, true);
      }

      return "transparent";
    },
    [data]
  );

  const innerPieFormattedData = useMemo(() => {
    const formattedData: { name; value }[] = [];

    const selectedData = data.find(({ levels }) => levels === selectedValue);
    if (selectedData) {
      formattedData.push({
        name: selectedData.levels,
        value: Math.round(selectedData.tuPopulation),
      });
    }

    return formattedData;
  }, [data, selectedValue]);

  const outerPieFormattedData = useMemo(() => {
    const formattedData: { name; value }[] = [];

    const selectedData = data.find(({ levels }) => levels === selectedValue);
    if (selectedData) {
      formattedData.push({
        name: selectedData.levels,
        value: Math.round(selectedData.clientPopulation),
      });
      formattedData.push({
        name: `non-${selectedData.levels}`,
        value: Math.round(selectedData.tuPopulation - selectedData.clientPopulation),
      });
    }

    return formattedData;
  }, [data, selectedValue]);

  const legendFormattedData = useMemo(() => {
    return data.map(({ levels, clientPopulation, tuPopulation }) => {
      return {
        name: levels,
        val1: formatNumber(clientPopulation),
        val2: formatNumber(tuPopulation),
        computedValue: formatPercent(clientPopulation / tuPopulation, 1),
        // TODO: If it used to be a bar chart, don't give it colors
        color:
          getChartType(fieldName) === "pie"
            ? levels === selectedValue
              ? getFillFromName(levels)
              : "#DFE2E6"
            : undefined,
      };
    });
  }, [data, fieldName, getFillFromName, selectedValue]);

  useEffect(() => {
    const handleOutsideClick = event => {
      if (pieRef && pieRef.current && !pieRef.current.contains(event.target)) {
        setSelectedValue(undefined);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setSelectedValue]);

  return (
    <div className="customerInsightsPieChart selectedPenetrationPieChart" ref={pieRef}>
      <ChartContainer title={fieldPrettyName}>
        <ResponsiveContainer width="100%">
          <PieChart>
            <Legend
              content={() => (
                <CustomerInsightsLegend
                  data={legendFormattedData}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  selectBehavior="click"
                />
              )}
              layout="vertical"
              align="left"
              verticalAlign="top"
            />
            <Pie
              className="penetrationInnerPie"
              data={innerPieFormattedData}
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              innerRadius="15%"
              outerRadius="45%"
              dataKey="value"
            >
              {innerPieFormattedData.map(({ name }) => (
                <Cell className="pieCell" key={`cell-${name}`} fill={getFillFromName(name)} />
              ))}
            </Pie>
            <Pie
              className="penetrationOuterPie"
              data={outerPieFormattedData}
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              innerRadius="15%"
              outerRadius="60%"
              dataKey="value"
            >
              {outerPieFormattedData.map(({ name }) => (
                <Cell className="pieCell" key={`cell-${name}`} fill={getFillFromName(name)} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );
};

interface PenetrationChartProps {
  fieldName: CustomerInsightsFields;
  data: KnowYourCustomerData[];
}

const PenetrationChart: React.FC<PenetrationChartProps> = ({ fieldName, data }) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const fieldPrettyName = CUSTOMER_INSIGHTS_PRETTY_NAMES[fieldName];

  if (selectedValue) {
    return (
      <SelectedPie
        data={data}
        fieldName={fieldName}
        fieldPrettyName={fieldPrettyName}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
    );
  }

  if (getChartType(fieldName) === "bar") {
    return (
      <StackedBar
        data={data}
        fieldPrettyName={fieldPrettyName}
        setSelectedValue={setSelectedValue}
      />
    );
  }

  if (getChartType(fieldName) === "pie") {
    return (
      <PenetrationPie
        data={data}
        fieldPrettyName={fieldPrettyName}
        setSelectedValue={setSelectedValue}
      />
    );
  }

  return null;
};

export default PenetrationChart;
