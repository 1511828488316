import { useCallback, useState } from "react";
import {
  Dropdown,
  DropdownToggleType,
  FullPageSpinner,
  Page,
  Input,
  DatePicker,
  Button,
  ButtonType,
  Spinner,
} from "../Components";
import { useCompanyInfo } from "../redux/company";
import { Form } from "react-bootstrap";
import { TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import Plan from "./Plan";
import "./YouTubePlanning.scss";
import "../Components/FormCheck.scss";

export interface YouTubePlanData {
  inputBudget: number;
  suggestedBudget: number;
  onTargetReach: number;
  averageFreq: number;
  cpm: number;
  youTubePopulation: number;
}

const GOAL_OPTIONS = [
  "Efficient Reach (Awareness)",
  "Efficient Completions (Awareness)",
  "Maximize Views (Considerations)",
  "Online Conversions (Action)",
];

const YouTubePlanning = (): JSX.Element => {
  const { cid } = useCompanyInfo();

  const [goal, setGoal] = useState<string>();
  const [googleAdsAccount, setGoogleAdsAccount] = useState<string>();
  const [budget, setBudget] = useState<number>();
  const [dates, setDates] = useState({ start: "", end: "" });
  const [audience, setAudience] = useState<string>();
  const [generating, setGenerating] = useState<boolean>(false);
  const [recommendationData, setRecommendationData] = useState<YouTubePlanData>();
  const [showRecommendation, setShowRecommendation] = useState<boolean>(true); // TODO: change to false when done developing rec

  // TODO: replace with real values from API calls
  const googleAdsAccounts = ["123", "456", "789"];
  const audiences = ["Fake Audience 1", "Fake Audience 2"];

  const generateRecommendation = useCallback(() => {
    setGenerating(true);
    console.log(
      `Generating recommendation for goal: ${goal}, account: ${googleAdsAccount}, budget: ${budget}, dates: ${dates.start} - ${dates.end}, audience: ${audience}.`
    );

    // TODO: generate a recommendation
    console.log(recommendationData);
    const fakeRecommendationData = {
      inputBudget: 1200000,
      suggestedBudget: 1000000,
      onTargetReach: 1243,
      averageFreq: 2.34,
      cpm: 3.23,
      youTubePopulation: 22000000,
    };

    setGenerating(false);
    setRecommendationData(fakeRecommendationData);
    setShowRecommendation(true);
  }, [audience, budget, dates.end, dates.start, goal, googleAdsAccount, recommendationData]);

  return (
    <Page
      app2Redesign
      title="YouTube Planning"
      pageType="YouTube Planning"
      actions={<div className="youTubePlanningActions"></div>}
    >
      {cid ? (
        <div className="youTubePlanningBody">
          {showRecommendation ? (
            // <Plan planData={recommendationData} />
            <Plan
              planData={{
                inputBudget: 1200000,
                suggestedBudget: 1000000,
                onTargetReach: 1243,
                averageFreq: 2.34,
                cpm: 3.23,
                youTubePopulation: 22000000,
              }}
              onClose={() => setShowRecommendation(false)}
            />
          ) : (
            <div className="planningForm">
              <div className="planningFormHeader">Build Recommendation</div>
              <div className="planningFormBody">
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Goal</div>
                  <div className="goalRadioChoice">
                    {GOAL_OPTIONS.map(goalName => {
                      return (
                        <Form.Check
                          className="goalRadioOption"
                          type="radio"
                          label={goalName}
                          checked={goal === goalName}
                          id={goalName}
                          onChange={() => setGoal(goalName)}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Google Ads Account</div>
                  <Dropdown
                    className="googleAdsAccountDropdown"
                    type={DropdownToggleType.OUTLINED}
                    design="primary"
                    value={googleAdsAccount || ""}
                    placeholder="Select Google Ads Account"
                    options={googleAdsAccounts}
                    onChange={setGoogleAdsAccount}
                  />
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Budget</div>
                  <Input
                    type="number"
                    className="budgetInput"
                    placeholder="Input Budget"
                    value={budget}
                    onChange={e => setBudget(e.target.value)}
                  />
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Date Range</div>
                  <DatePicker
                    range={dates}
                    isOutsideRange={date => date < TODAY}
                    onChange={({ start, end }) => {
                      setDates({ start, end });
                    }}
                  />
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Audience</div>
                  <Dropdown
                    className="audienceDropdown"
                    type={DropdownToggleType.OUTLINED}
                    design="primary"
                    disabled={!googleAdsAccount}
                    value={audience || ""}
                    placeholder="Select Audience"
                    options={audiences}
                    onChange={setAudience}
                  />
                </div>
              </div>
              <div className="planningFormFooter">
                <Button
                  className="generateButton"
                  type={ButtonType.FILLED}
                  disabled={
                    !goal || !googleAdsAccount || !budget || !dates.start || !dates.end || !audience
                  }
                  onClick={generateRecommendation}
                >
                  {generating ? <Spinner /> : "Generate Recommendation"}
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

export default YouTubePlanning;
