import "./Performance.scss";
import { awaitJSON, LinearPerformanceLambdaFetch } from "../utils/fetch-utils";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SharedStateFetcher,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  DATE_FORMAT,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import {
  COLUMN_METADATA_MAP as LINEAR_COLUMN_METADATA_MAP,
  constructTotalsMap,
  performanceRowToTableRow,
  RowWithTabularData,
} from "../Performance/LinearPerformance/linearPerformanceUtils";
import {
  CreativeData,
  DimensionData,
  DimensionValue,
  GetPresetsPreset,
  PerformanceTableData as LinearPerformanceTableData,
} from "@blisspointmedia/bpm-types/dist/PerformanceSlide";
import { DateRange } from "../utils/types";
import {
  DEFAULT_DATE_SETTING,
  emptyLinearPreset,
  getHeatMapColor,
  pixelsToInches,
  WHITE,
} from "./slideUtils";
import { CheckBox, OldDropdown, RelativeDatePicker } from "../Components";
import { Form } from "react-bootstrap";
import { getGlobalBrand } from "../Performance/performanceUtils";
import {
  GetKpiMetaDataParams,
  GetKpiMetaDataResponse,
} from "@blisspointmedia/bpm-types/dist/Performance";
import { Provider, useSelector } from "react-redux";
import { reduxStore } from "../redux";
import { SetError } from "../redux/modals";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { CompanyInfo, useCompanyInfo } from "../redux/company";
import { CreativeMap, useCreativeMap } from "../redux/creative";
import * as Dfns from "date-fns/fp";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as R from "ramda";
import * as UserRedux from "../redux/user";
import PerformanceGrid from "../Performance/PerformanceGrid";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";
import { getCreativeThumbnail } from "../SingleChannel/MetricsTable/metricsTableUtils";

export const linearPerformanceNewSharedStateKey = "linearPerformanceNew" as const;
export const makeFetchKey = (kpi: string, audience: string): string => `${kpi}_${audience}`;
const MAX_DATES = 4;
const MAX_FONT_SIZE = 20;
const EMPTY_ROW_DATA = {
  color: WHITE,
  content: "-",
  divider: false,
  value: -1,
};

export const makeDimKey = (dimensionData: DimensionData): string => {
  let dimKey = "";
  for (let key of R.keys(dimensionData)) {
    // Don't want to add type of dimensions just their values
    if (key !== "dimensions") {
      dimKey = `${dimKey}_${dimensionData[key]}`;
    }
  }
  return dimKey;
};

interface SlideLinearPerformanceNewChartResult {
  clear: boolean;
  linearPerformanceTableData: LinearPerformanceTableData | null;
}

interface SlideLinearPerformanceNewChartOnLoad {
  (results: SlideLinearPerformanceNewChartResult): void;
}

interface SlideLinearPerformanceNewChartProps {
  clear: boolean;
  company: string;
  creativeMap: CreativeMap;
  data: any;
  dataKey: string;
  defaultKPI: string;
  end: string;
  endLabel: string;
  fetchedPreset: L.PresetRow;
  kpiMetaData: GetKpiMetaDataResponse;
  presetID: number;
  prevKey: string;
  start: string;
  startLabel: string;
  onLoad: SlideLinearPerformanceNewChartOnLoad;
}

interface OnPathLoadInterface {
  bottomData: any;
  heatmapInfo: any;
  leftData: any;
  tableData: any;
  topData: any;
}

const SlideLinearPerformanceNewChart: React.FC<SlideLinearPerformanceNewChartProps> = ({
  clear,
  creativeMap,
  data,
  dataKey,
  defaultKPI,
  endLabel,
  fetchedPreset,
  kpiMetaData,
  prevKey,
  startLabel,
  onLoad,
}) => {
  const { preset } = fetchedPreset;
  const kpi = preset && preset.globalKpi ? preset.globalKpi : defaultKPI;
  const audience = preset && preset.globalAudience ? preset.globalAudience : "HH";
  const [pathLoaded, setPathLoaded] = useState<OnPathLoadInterface | null>(null);
  const [otherDates, setOtherDates] = useState<DateRange>();
  const [filterTerm, setFilterTerm] = useState("");
  const [dataMap, setDataMap] = useState<Record<string, LinearPerformanceTableData>>({});
  const wait =
    !R.isEmpty(dataKey) &&
    !R.isEmpty(prevKey) &&
    !R.isNil(dataMap[prevKey]) &&
    !R.isNil(dataMap[dataKey]) &&
    dataMap[dataKey] === dataMap[prevKey] &&
    prevKey !== dataKey;
  useLayoutEffect(() => {
    if (!dataKey || wait || (pathLoaded === null && clear)) {
      onLoad({
        linearPerformanceTableData: {
          headerData: [],
          rowData: [],
          aggregateData: [],
          dimensionData: [],
          start: "",
          end: "",
        },
        clear,
      });
    }
    if (pathLoaded !== null && data.length === 0) {
      onLoad({
        linearPerformanceTableData: null,
        clear: false,
      });
    } else if (!clear && pathLoaded !== null && !R.isNil(data)) {
      let { topData, tableData, bottomData, heatmapInfo, leftData } = pathLoaded;
      let leftDataCheck = !R.isEmpty(leftData);
      for (let row of leftData) {
        let dims = R.keys(row);
        // Check if the correct type of data has properly loaded
        for (let dimCol of preset.performanceDimensionColumns) {
          if (!dims.includes(dimCol.dimension)) {
            leftDataCheck = false;
            break;
          }
        }
      }
      if (
        !R.isEmpty(topData) &&
        !R.isEmpty(tableData) &&
        !R.isEmpty(bottomData) &&
        !R.isEmpty(heatmapInfo) &&
        leftDataCheck
      ) {
        let rowIndex = 0;
        for (let row of tableData) {
          let colIndex = 0;
          for (let col of heatmapInfo) {
            if (col) {
              let color = getHeatMapColor(
                colIndex,
                row[colIndex].value,
                heatmapInfo,
                topData,
                LINEAR_COLUMN_METADATA_MAP
              );
              tableData[rowIndex][colIndex].color = color;
            }
            colIndex++;
          }
          rowIndex++;
          for (let elem of leftData) {
            let dimensions: DimensionValue[] = [];
            for (let dimCol of preset.performanceDimensionColumns) {
              let dimensionValue: DimensionValue = {
                headerLabel: R.defaultTo(dimCol.type, dimCol.label),
                type: dimCol.type,
              };
              if (dimCol.dimension === "Network") {
                if (dimCol.type === "Network Name") {
                  dimensionValue.label = elem.Network;
                } else if (dimCol.type === "Network Logo") {
                  dimensionValue.url = `https://cdn.blisspointmedia.com/networks/${elem.Network}.png`;
                }
              } else if (dimCol.dimension === "Creative") {
                let creativeData =
                  creativeMap && creativeMap[elem.Creative]
                    ? ((creativeMap[elem.Creative] as any) as CreativeData)
                    : ({} as CreativeData);
                if (dimCol.type === "Creative") {
                  dimensionValue.label = creativeData.name;
                } else if (dimCol.type === "Creative Thumbnail") {
                  dimensionValue.url = creativeData
                    ? getCreativeThumbnail(
                        (creativeData as any).company,
                        (creativeData as any).isci
                      )
                    : "";
                }
              } else if (dimCol.dimension === "Avail") {
                dimensionValue.label = elem.Avail;
              } else if (dimCol.dimension === "Length") {
                dimensionValue.label = elem.Length;
              } else if (dimCol.dimension === "Daypart") {
                dimensionValue.label = elem.Daypart;
              } else if (dimCol.dimension === "Campaign") {
                dimensionValue.label = elem.Campaign;
              }
              dimensions.push(dimensionValue);
            }
            elem.dimensions = dimensions;
          }
        }

        let linearPerformanceTableData: LinearPerformanceTableData | null =
          !!topData && !!tableData && !!bottomData && !!leftData
            ? {
                headerData: topData,
                rowData: tableData,
                aggregateData: bottomData,
                dimensionData: leftData,
                start: startLabel,
                end: endLabel,
              }
            : null;
        if (
          (prevKey !== dataKey && dataMap[prevKey] !== linearPerformanceTableData) ||
          (prevKey === dataKey && dataMap[prevKey] === linearPerformanceTableData)
        ) {
          onLoad({
            linearPerformanceTableData,
            clear: false,
          });
          if (linearPerformanceTableData !== null) {
            setDataMap(map => {
              map[dataKey] = linearPerformanceTableData as LinearPerformanceTableData;
              return map;
            });
          }
        }
      }
    }
  }, [
    clear,
    creativeMap,
    data,
    dataKey,
    dataMap,
    endLabel,
    fetchedPreset,
    onLoad,
    pathLoaded,
    preset.performanceDimensionColumns,
    prevKey,
    startLabel,
    wait,
  ]);
  if (!data || !creativeMap || !fetchedPreset || !kpiMetaData || clear || wait) {
    return (
      <PerformanceGrid
        preset={emptyLinearPreset}
        data={[]}
        filterTerm={filterTerm}
        setFilterTerm={setFilterTerm}
        otherDataRowMap={undefined}
        otherDates={otherDates}
        setOtherDates={setOtherDates}
        creativeMap={{}}
        onPathLoad={setPathLoaded}
        isLinear={true}
        isSlides={true}
        slidesKpi={kpi}
        slidesKpiMetaData={kpiMetaData ? kpiMetaData : undefined}
        slidesAudience={audience}
        slidesCompany={kpi?.split("_")[0]}
      />
    );
  } else {
    return (
      <PerformanceGrid
        preset={preset}
        data={data}
        filterTerm={filterTerm}
        setFilterTerm={setFilterTerm}
        otherDataRowMap={undefined}
        otherDates={otherDates}
        setOtherDates={setOtherDates}
        creativeMap={creativeMap}
        onPathLoad={setPathLoaded}
        isLinear={true}
        isSlides={true}
        slidesKpi={kpi}
        slidesKpiMetaData={kpiMetaData}
        slidesAudience={audience}
        slidesCompany={kpi?.split("_")[0]}
      />
    );
  }
};

interface LinearPerformanceNewSlideData {
  companyColor: string | null;
  customSlideHeader: string;
  customTableColumnWidth: number;
  customTableFontSize: number;
  customTableRowHeight: number;
  footnote: string;
  linearPerformanceTableData: any[];
}

export interface LinearPerformanceNewSlideState {
  customSlideHeader: string;
  customTableColumnWidth: number;
  customTableFontSize: number;
  customTableRowHeight: number;
  dates: RelativeDateRange[];
  datesSort: boolean[];
  footnote: string;
  isInternal: boolean;
  numDates: number;
  numRows: number;
  selectedPreset: GetPresetsPreset | null;
}

export interface LinearPerformanceNewSharedState {
  companyInfo: CompanyInfo | undefined;
  creativeMap: CreativeMap | undefined;
  globalBrand: string | undefined;
  kpiMetaData: GetKpiMetaDataResponse | undefined;
  presets: GetPresetsPreset[];
  presetMap: Record<string, L.PresetRow>;
}

export interface LinearPerformanceSharedStateContext {
  presetID: number;
  state: LinearPerformanceNewSharedState;
}

export const linearPerformanceNewSharedFetcher: SharedStateFetcher<LinearPerformanceNewSharedState> = async (
  company: string,
  setError: SetError,
  context: LinearPerformanceSharedStateContext
) => {
  let res: LinearPerformanceNewSharedState = {
    companyInfo: undefined,
    creativeMap: undefined,
    globalBrand: undefined,
    kpiMetaData: undefined,
    presets: [],
    presetMap: {},
  };
  if (R.isNil(context)) {
    try {
      let presetsRes = await LinearPerformanceLambdaFetch("/getPresets", {
        params: { company, prefix: "linear" },
      });
      let presets: GetPresetsPreset[] = await awaitJSON(presetsRes);
      res.presets = R.filter(preset => !preset.temporary, presets);
    } catch (e) {
      let error: Error = e as Error;
      setError({
        message: `Could not get valid linear performance presets. Error: ${error.message}`,
        reportError: error,
      });
    }
    try {
      const kpiMetaDataRes = await LinearPerformanceLambdaFetch<GetKpiMetaDataParams>(
        "/getKpiMetaData",
        {
          params: {
            company,
          },
        }
      );
      const kpiMetaData = await awaitJSON<GetKpiMetaDataResponse>(kpiMetaDataRes);
      res.kpiMetaData = kpiMetaData;
    } catch (e) {
      let error = e as Error;
      setError({
        message: `Failed to get linear performance preset data: ${error.message}`,
        reportError: error,
      });
    }
  } else {
    if (!R.isNil(context.presetID) && R.isNil(context.state.presetMap[context.presetID])) {
      try {
        const fields = {
          company,
          id: context.presetID,
        };
        const res = await LinearPerformanceLambdaFetch("/getPreset", {
          params: { ...fields },
        });
        const fetchedPreset = await awaitJSON(res);
        context.state.presetMap[context.presetID] = fetchedPreset;
      } catch (e) {
        let error = e as Error;
        setError({
          message: `Failed to get linear performance preset data: ${error.message}`,
          reportError: error,
        });
      }
    }
    res = context.state;
  }

  return res;
};

class LinearPerformanceNewSlide extends SlideType {
  static typeKey = "linearPerformanceNew";
  static displayKey = "Linear Performance New";
  static defaultState: LinearPerformanceNewSlideState = {
    customSlideHeader: "",
    customTableColumnWidth: 55,
    customTableFontSize: 9,
    customTableRowHeight: 30,
    dates: [DEFAULT_DATE_SETTING],
    datesSort: [true, false, false, false],
    footnote: "",
    isInternal: false,
    numDates: 1,
    numRows: 20,
    selectedPreset: null,
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<LinearPerformanceNewSlideState>
  > = React.memo(({ state, setState, sharedFetch, sharedState, slideContext }) => {
    const {
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      dates,
      datesSort,
      footnote,
      isInternal,
      numDates,
      numRows,
      selectedPreset,
    } = state;

    const [isInternalReady, setIsInternalReady] = useState<boolean>();
    const fetchedIsInternal = useSelector(UserRedux.isInternalSelector);
    const { company } = slideContext;
    const { companyInfo, creativeMap, globalBrand, presetMap, presets } =
      R.isNil(sharedState) ||
      R.isNil(sharedState[linearPerformanceNewSharedStateKey]) ||
      R.isNil(sharedState[linearPerformanceNewSharedStateKey][company])
        ? {
            companyInfo: undefined,
            creativeMap: undefined,
            globalBrand: undefined,
            presetMap: {},
            presets: [],
          }
        : sharedState[linearPerformanceNewSharedStateKey][company];
    const fetchedCompanyInfo = useCompanyInfo();
    const fetchedGlobalBrand = companyInfo
      ? getGlobalBrand(companyInfo, companyInfo.initial_kpi)
      : undefined;
    const { creativeMap: fetchedCreativeMap } = useCreativeMap({
      company: globalBrand || company,
      mediaTypes: ["linear"],
    });

    useEffect(() => {
      if (
        sharedState &&
        sharedState[linearPerformanceNewSharedStateKey] &&
        sharedState[linearPerformanceNewSharedStateKey][company]
      ) {
        (async () => {
          if (
            (fetchedCompanyInfo && R.isNil(companyInfo)) ||
            (fetchedCreativeMap && R.isNil(creativeMap)) ||
            (fetchedGlobalBrand && R.isNil(globalBrand))
          ) {
            await sharedFetch(linearPerformanceNewSharedStateKey, company, {
              state: {
                ...sharedState[linearPerformanceNewSharedStateKey][company],
                companyInfo: fetchedCompanyInfo,
                creativeMap: fetchedCreativeMap,
                globalBrand: fetchedGlobalBrand,
              },
              updateState: true,
            });
          }
          if (!R.isNil(presets) && !R.isEmpty(presets) && !R.isNil(selectedPreset)) {
            if (!R.map(elem => elem.id, presets).includes(selectedPreset.id)) {
              setState({ selectedPreset: null });
            }
          }
          if (selectedPreset && R.isNil(presetMap[selectedPreset.id])) {
            try {
              await sharedFetch(linearPerformanceNewSharedStateKey, company, {
                state: {
                  ...sharedState[linearPerformanceNewSharedStateKey][company],
                  companyInfo: fetchedCompanyInfo,
                  creativeMap: fetchedCreativeMap,
                  globalBrand: fetchedGlobalBrand,
                },
                presetID: selectedPreset.id,
                updateState: true,
              });
            } catch (e) {
              setState({ selectedPreset: null });
            }
          }
        })();
      }
    }, [
      company,
      companyInfo,
      creativeMap,
      fetchedCompanyInfo,
      fetchedCreativeMap,
      fetchedGlobalBrand,
      globalBrand,
      presetMap,
      presets,
      selectedPreset,
      setState,
      sharedFetch,
      sharedState,
    ]);

    useEffect(() => {
      if (!isInternalReady && !R.isNil(fetchedIsInternal)) {
        setState({ isInternal: fetchedIsInternal });
        setIsInternalReady(true);
      }
    }, [isInternal, fetchedIsInternal, setState, isInternalReady]);

    useEffect(() => {
      if (!R.path([linearPerformanceNewSharedStateKey, company], sharedState)) {
        (async () => {
          await sharedFetch(linearPerformanceNewSharedStateKey, company);
        })();
      }
    }, [sharedState, sharedFetch, company]);

    return (
      <div className="settingsBox">
        <div className="wrappingColumn">
          <Form.Group className="options">
            <div className="presetSelector">
              <OldDropdown
                label={"Preset"}
                value={selectedPreset ? selectedPreset.id.toString() : "undefined"}
                options={R.map(preset => {
                  return { label: preset.name, value: preset.id.toString() };
                }, presets)}
                onChange={presetID => {
                  let selectedPreset: GetPresetsPreset | null = null;
                  for (let preset of presets) {
                    if (presetID === preset.id.toString()) {
                      selectedPreset = preset;
                    }
                  }
                  setState({ selectedPreset });
                }}
              />
            </div>
            <div>
              <Form.Label>Font Size</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                max={MAX_FONT_SIZE}
                value={customTableFontSize}
                onChange={e => {
                  const size = R.defaultTo(1, parseInt(e.target.value));
                  const sizeInPxs = Math.ceil(size * (1 + 1 / 3));
                  if (customTableColumnWidth < sizeInPxs) {
                    setState({ customTableColumnWidth: sizeInPxs });
                  }
                  if (customTableRowHeight < sizeInPxs) {
                    setState({ customTableRowHeight: sizeInPxs });
                  }
                  setState({ customTableFontSize: size });
                }}
              />
            </div>
            <div>
              <Form.Label>Number of Date Ranges in Table</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                max={MAX_DATES}
                value={numDates}
                onChange={e => {
                  let newDatesSort = [false, false, false, false];
                  let sortByIndex = 0;
                  const numDates = R.defaultTo(1, parseInt(e.target.value));
                  let newDates = Array(numDates);
                  for (let i = 0; i < numDates; i++) {
                    newDates[i] = dates[i] && i < dates.length ? dates[i] : DEFAULT_DATE_SETTING;
                    if (datesSort[i]) {
                      sortByIndex = i;
                    }
                  }
                  newDatesSort[sortByIndex] = true;
                  setState({ dates: newDates, datesSort: newDatesSort, numDates: numDates });
                }}
              />
            </div>
            <div>
              <Form.Label>Number of Rows in Table</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                value={numRows}
                onChange={e => {
                  setState({ numRows: R.defaultTo(1, parseInt(e.target.value)) });
                }}
              />
            </div>
            <Form.Label className="note">
              NOTE: Remember to sort the page by the relevant column if the number of rows exceeds
              20 and to specify the global kpi and audience being used in the table and if any
              columns have a custom kpi or audience set in the optional footnote!
            </Form.Label>
            <div>
              <Form.Label>
                Custom Table Column Width in Pixels(
                {pixelsToInches(customTableColumnWidth).toFixed(2)} in Inches)
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={32}
                value={customTableColumnWidth}
                onChange={e => {
                  let newWidth;
                  try {
                    newWidth = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
                  } catch {
                    newWidth = customTableColumnWidth;
                  }
                  setState({ customTableColumnWidth: newWidth });
                }}
              />
            </div>
            <div>
              <Form.Label>
                Custom Table Row Height in Pixels({pixelsToInches(customTableRowHeight).toFixed(2)}
                in Inches)
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={10}
                value={customTableRowHeight}
                onChange={e => {
                  let newHeight;
                  try {
                    newHeight = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
                  } catch {
                    newHeight = customTableRowHeight;
                  }
                  setState({ customTableRowHeight: newHeight });
                }}
              />
            </div>
            <div className="footnoteDiv">
              Optional Footnote:
              <Form.Control
                className="footnoteTextEntry"
                value={footnote}
                onChange={e => {
                  setState({ footnote: e.target.value });
                }}
              />
            </div>
            <div>
              {`${footnote.length ? "Optional Footnote on Linear Performance Slide:" : ""}`}
            </div>
            <Form.Label className="note">{`${
              footnote.length ? "Note: " : ""
            }${footnote}`}</Form.Label>
          </Form.Group>
          <Form.Group className="datePickers">
            <div className="customSlideHeaderText">
              Optional Custom Slide Header:
              <Form.Control
                className="customSlideHeader"
                value={customSlideHeader ? customSlideHeader : ""}
                onChange={e => {
                  setState({ customSlideHeader: e.target.value });
                }}
              />
            </div>
            <div className="dp">
              {dates.length > 0 &&
                dates.map((date: RelativeDateRange, index: number) => {
                  if (date && date.start && date.end) {
                    return (
                      <div key={`Dates Pickers ${index + 1}`}>
                        <div className="dateRangeLabelAndSortBy">
                          <Form.Label className="dateRangeText">{`Start Date ${
                            index + 1
                          }`}</Form.Label>
                          <div className="dateRangeSortBy">
                            <Form.Label className="dateRangeSortByText">
                              Sort By Date Range?
                            </Form.Label>
                            <CheckBox
                              checked={datesSort[index]}
                              onCheck={e => {
                                let newDatesSort = [false, false, false, false];
                                newDatesSort[index] = e;
                                setState({ datesSort: newDatesSort });
                              }}
                              size="md"
                            />
                          </div>
                        </div>
                        <RelativeDatePicker
                          state={date.start}
                          onChange={start => {
                            let datesUpdate = dates;
                            datesUpdate[index] = { start, end: datesUpdate[index].end };
                            setState({ dates: datesUpdate });
                          }}
                        />
                        <Form.Label className="dateRangeText">{`End Date ${index + 1}`}</Form.Label>
                        <RelativeDatePicker
                          state={date.end}
                          onChange={end => {
                            let datesUpdate = dates;
                            datesUpdate[index] = { start: datesUpdate[index].start, end };
                            setState({ dates: datesUpdate });
                          }}
                        />
                      </div>
                    );
                  } else {
                    return <div></div>;
                  }
                })}
            </div>
          </Form.Group>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<LinearPerformanceNewSlideData> => {
    let { company } = context;
    let {
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      dates,
      datesSort,
      footnote,
      isInternal,
      numDates,
      numRows,
      selectedPreset,
    } = state as LinearPerformanceNewSlideState;
    const { companyInfo, creativeMap, kpiMetaData, presetMap } = _.linearPerformanceNew[company];
    if (R.isNil(companyInfo)) {
      throw new Error(`No default kpi or lag found for: ${company}`);
    }
    if (R.isNil(selectedPreset)) {
      throw new Error(`No preset selected. (Linear Performance New, ${customSlideHeader}).`);
    }
    const fetchedPreset = presetMap[selectedPreset.id];
    if (R.isNil(fetchedPreset)) {
      throw new Error(
        `We are still loading your linear performance presets, please wait. (Linear Performance New, ${customSlideHeader}).`
      );
    }
    const { color: companyColor = null, initial_kpi: defaultKPI } = companyInfo;
    const linearPerformanceTableData: LinearPerformanceTableData[] = [];
    let prevKey = "";
    for (let i = 0; i < numDates; i++) {
      let sandbox = await claimSandbox();
      let data: LinearPerformanceTableData;
      const dateObj = dates[i];
      const start = computeResolvedDate(dateObj.start);
      const end = Dfns.format(
        DATE_FORMAT,
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.end)))
      );
      const startLabel = Dfns.format(
        "MMMM do",
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.start)))
      );
      const endLabel = Dfns.format(
        "MMMM do",
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.end)))
      );
      if (fetchedPreset === null || kpiMetaData === null) {
        throw new Error(
          `No preset data (Linear Performance New, ${customSlideHeader}, ${startLabel}-${endLabel}).`
        );
      }
      const dataKey = `${fetchedPreset.id}_${start}_${end}`;
      const slideName = `${customSlideHeader ? `${customSlideHeader}, ` : ""}Preset: ${
        fetchedPreset.name
      }`;
      const fields = {
        start,
        end,
        id: selectedPreset ? selectedPreset.id : 0,
        company,
        branch: "v2",
        build: "latest",
      };
      let fetchedPerformanceData;
      try {
        let res = await LinearPerformanceLambdaFetch("/", {
          params: { ...fields },
        });
        fetchedPerformanceData = await awaitJSON(res);
      } catch (e) {
        throw new Error(
          `No data available for this date range (Linear Performance New, ${slideName}, ${startLabel}-${endLabel}).
          If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
        );
      }
      if (
        fetchedPreset === null ||
        !fetchedPreset.preset ||
        !fetchedPreset.preset.performanceDimensionColumns ||
        R.isEmpty(fetchedPreset.preset.performanceDimensionColumns)
      ) {
        throw new Error(
          `No preset data (Linear Performance New, ${slideName}, ${startLabel}-${endLabel}).`
        );
      } else if (kpiMetaData === null) {
        throw new Error(
          `No kpi meta data (Linear Performance New, ${slideName}, ${startLabel}-${endLabel}).`
        );
      } else if (
        !fetchedPerformanceData ||
        !fetchedPerformanceData.data ||
        R.isEmpty(fetchedPerformanceData.data)
      ) {
        throw new Error(
          `No data available for this date range (Linear Performance New, ${slideName}, ${startLabel}-${endLabel}).
          If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
        );
      } else if (creativeMap === null) {
        throw new Error(
          `Error with Creative Map (Linear Performance New, ${slideName}, ${startLabel}-${endLabel}).`
        );
      } else {
        let rows: RowWithTabularData[] = [];
        const { preset } = fetchedPreset;
        try {
          if (fetchedPerformanceData && kpiMetaData) {
            let { data } = fetchedPerformanceData;
            let totalsMap = constructTotalsMap(
              preset.columns,
              data,
              preset.globalKpi ? preset.globalKpi : defaultKPI,
              preset.globalAudience ? preset.globalAudience : "HH"
            );
            let tabularData: RowWithTabularData[] = [];
            for (let row of data) {
              if (kpiMetaData) {
                tabularData.push({
                  ...row,
                  tabularRow: await performanceRowToTableRow(
                    row,
                    preset.columns,
                    totalsMap,
                    preset.globalKpi ? preset.globalKpi : defaultKPI,
                    kpiMetaData,
                    preset.globalAudience ? preset.globalAudience : "HH",
                    isInternal
                  ),
                });
              }
            }
            for (let row of tabularData) {
              for (let dimCol of preset.performanceDimensionColumns) {
                let rowContainsFilterTerm: (row: RowWithTabularData) => boolean;
                // In the data we are referring to creatives by their ISCI, so we need to get the name from
                // the creative map so that you can filter on creative name in the filter bar.
                if (dimCol.dimension === "Creative") {
                  rowContainsFilterTerm = row =>
                    (R.isNil(creativeMap) ? {} : creativeMap)[row.dimensions.Creative || ""]?.name
                      .toLowerCase()
                      .includes("");
                } else {
                  rowContainsFilterTerm = row =>
                    `${row.dimensions[dimCol.dimension] || ""}`.toLowerCase().includes("");
                }

                if (rowContainsFilterTerm(row)) {
                  rows.push(row);
                  break;
                }
              }
            }
          }
        } catch (e) {
          let error = e as Error;
          throw error;
        }
        if (R.isEmpty(rows)) {
          throw new Error(
            `No data available for this date range (Linear Performance New, ${slideName}, ${startLabel}-${endLabel}).
            If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
          );
        }
        let prevKeyChart = prevKey;
        data = await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              //At the start of each loop clear the performance grid
              const clear = true;
              const load = (clear: boolean) => {
                ReactDOM.render(
                  <Provider store={reduxStore}>
                    <SlideLinearPerformanceNewChart
                      clear={clear}
                      company={company}
                      creativeMap={R.defaultTo({}, creativeMap)}
                      data={rows}
                      defaultKPI={defaultKPI}
                      end={end}
                      endLabel={endLabel}
                      fetchedPreset={fetchedPreset as L.PresetRow}
                      dataKey={dataKey}
                      kpiMetaData={kpiMetaData as GetKpiMetaDataResponse}
                      presetID={selectedPreset ? selectedPreset.id : 0}
                      prevKey={prevKeyChart}
                      start={start}
                      startLabel={startLabel}
                      onLoad={({ linearPerformanceTableData, clear }) => {
                        if (clear) {
                          load(false);
                        }
                        if (
                          linearPerformanceTableData !== null &&
                          R.defaultTo([], linearPerformanceTableData.headerData).length > 0 &&
                          R.defaultTo([], linearPerformanceTableData.rowData).length > 0 &&
                          R.defaultTo([], linearPerformanceTableData.aggregateData).length > 0
                        ) {
                          resolve(linearPerformanceTableData);
                        } else if (linearPerformanceTableData === null) {
                          reject(
                            new Error(
                              `No data available for this date range (Linear Performance New, ${slideName})`
                            )
                          );
                        }
                      }}
                    />
                  </Provider>,
                  sandbox.element
                );
              };
              setTimeout(() => {
                load(clear);
              }, 500);
            } catch (e) {
              reject(e);
            }
          }, 100);
        });
      }
      linearPerformanceTableData.push(data);
      releaseSandbox(sandbox);
      prevKey = dataKey;
    }

    let sortIndex = 0;
    for (let i = 0; i < datesSort.length; i++) {
      if (datesSort[i]) {
        sortIndex = i;
      }
    }

    let dimensionKeys: Record<string, number> = {};
    let numRowsAdjusted = numRows;
    if (
      linearPerformanceTableData[sortIndex].dimensionData &&
      linearPerformanceTableData[sortIndex].dimensionData.length < numRowsAdjusted
    ) {
      numRowsAdjusted = linearPerformanceTableData[sortIndex].dimensionData.length;
    } else if (
      !linearPerformanceTableData[sortIndex].dimensionData ||
      !linearPerformanceTableData[sortIndex].rowData
    ) {
      throw new Error("Failed to get linear performance data (Linear Performance New)");
    }

    // Store indexes of dimension data in case subsequent date ranges are not in the same order
    for (let j = 0; j < numRowsAdjusted; j++) {
      let { dimensionData } = linearPerformanceTableData[sortIndex];
      let dimKey = makeDimKey(dimensionData[j]);
      dimensionKeys[dimKey] = j;
    }

    for (let i = 0; i < numDates; i++) {
      // If data is missing, enter zeros
      let { dimensionData, rowData, headerData } = linearPerformanceTableData[i];
      let sortedRowData = Array(numRowsAdjusted).fill(
        Array(R.defaultTo([], headerData).length).fill(EMPTY_ROW_DATA)
      );
      if (dimensionData && rowData) {
        if (i === sortIndex) {
          sortedRowData = rowData.slice(0, numRowsAdjusted);
        } else {
          for (let k = 0; k < R.defaultTo(0, rowData.length); k++) {
            let dimKey = makeDimKey(dimensionData[k]);
            // Must check for zero or else this index will be ignored
            if (dimensionKeys[dimKey] || dimensionKeys[dimKey] === 0) {
              sortedRowData[dimensionKeys[dimKey]] = rowData ? rowData[k] : EMPTY_ROW_DATA;
            }
          }
        }
      }
      // Sort data using first date range
      let dimData = linearPerformanceTableData[sortIndex].dimensionData;
      linearPerformanceTableData[i].dimensionData = dimData
        ? dimData.slice(0, numRowsAdjusted)
        : dimData;
      linearPerformanceTableData[i].rowData = sortedRowData;
    }
    footnote = footnote.trim();
    if (footnote) {
      footnote = `Note: ${footnote}`;
    }
    return {
      companyColor,
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      footnote,
      linearPerformanceTableData,
    };
  };
}

export default LinearPerformanceNewSlide;
