import React, { useContext, useState } from "react";
import { FormContext, metaAdFormDefault } from "../MetaBuyingContext";
import { MetaBuyingNamePreview } from "./MetaBuyingNamePreview";
import { MetaBuyingOptionsNoteCard } from "./MetaBuyingOptionsNoteCard";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { MetaBuyingFooterControls } from "./MetaBuyingFooterControls";
import { RouteComponentProps } from "@reach/router";
import {
  // selectionsToArray,
  filterSegmentationIds,
  generateNameFields,
  setNameField as setNameFieldUtil,
  filterInheritedFields,
} from "../MetaBuyingUtils";
import { MetaBuyingTabs } from "../MetaBuyingTabs";
import { DimensionType } from "@blisspointmedia/bpm-types/dist/MetaBuying";
import * as R from "ramda";

// for testing only
const DROPDOWN_TEST_PLACEHOLDER = [
  {
    label: "Option A",
    value: "test_a",
  },
  {
    label: "Option B",
    value: "test_b",
  },
  {
    label: "Option C",
    value: "test_c",
  },
];

const OPTIONS_NOTE_CARD_HEADER = "Ad Options to Populate in Ads Manager";
const OPTIONS_NOTE_CARD_OPTIONS = [
  "Catalog Ads",
  "Additional Text Options",
  "Promo Codes",
  "Advantage+ Creative",
  "Instant Experiences",
  "Facebook Events",
];

interface MetaBuyingAdProps {
  client: string;
  namingConvention: number[];
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  showReviewModal: () => void;
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  validateForm: (
    form: Record<string, any>,
    validator: () => Record<string, any>,
    type: string,
    navigate: (args: boolean | string) => void,
    navigateArgs?: boolean | string
  ) => void;
  creationTabs: {
    Campaign: boolean;
    "Ad Set": boolean;
    Ad: boolean;
  };
  granularity: string;
  dimensions: Record<string, any>;
  allNameFields: Record<string, any>;
}

const { CLIENT, AGENCY, SEGMENTATION_ID, SEGMENT_SELECTION } = DimensionType;

export const MetaBuyingAd = ({
  client,
  selectedAdAccount,
  namingConvention,
  showReviewModal,
  validateForm,
  creationTabs,
  granularity,
  dimensions,
  tabIndex,
  setTabIndex,
  allNameFields,
}: MetaBuyingAdProps & RouteComponentProps): JSX.Element => {
  const { metaAdForm, setMetaAdForm } = useContext(FormContext);

  const agency = selectedAdAccount.business_manager;

  const segmentationDimensionId = R.keys(
    R.filter(dimension => dimension.type === DimensionType.SEGMENTATION_ID, dimensions)
  )[0];

  const [nameFields, setNameFields] = useState<Record<string, any>>(
    generateNameFields(namingConvention, allNameFields, dimensions)
  );

  const setNameField = (id, value) => setNameFieldUtil(id, value, nameFields, setNameFields);

  // const creationTabArray = selectionsToArray(creationTabs);

  const updateFormField = (key: string, value: string) =>
    setMetaAdForm((current: any): typeof metaAdFormDefault => {
      return { ...current, [key]: value };
    });

  const validateAdForm = () => {
    let payloadAd = {};
    return payloadAd;
  };

  const renderNameFields = (): JSX.Element[] =>
    namingConvention.map(dimensionId => {
      const { value, inheritedFrom } = allNameFields[dimensionId];
      const dimension = dimensions[dimensionId];
      let dropdownOptions =
        dimension.type === DimensionType.SEGMENTATION_ID
          ? filterSegmentationIds(nameFields, dimensions, segmentationDimensionId)
          : dimension.options;
      let field;
      if (inheritedFrom) {
        field = <Form.Control value={value} disabled />;
      } else {
        switch (dimension.type) {
          case AGENCY:
          case CLIENT:
            field = <Form.Control value={dimension.type === CLIENT ? client : agency} disabled />;
            break;
          case SEGMENTATION_ID:
          case SEGMENT_SELECTION:
            field = (
              <DropdownButton
                title={nameFields[dimensionId]}
                onSelect={value => setNameField(dimensionId, value)}
                disabled={dropdownOptions.length === 0}
              >
                {dropdownOptions.map(option => (
                  <Dropdown.Item key={option} eventKey={option}>
                    {option}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            );
            break;
          default:
            field = (
              <Form.Control
                value={nameFields[dimensionId]}
                onChange={e => setNameField(dimensionId, e.target.value)}
              />
            );
            break;
        }
      }
      return (
        <Form.Group key={dimensionId}>
          <Form.Label>{dimension.name}</Form.Label>
          {field}
        </Form.Group>
      );
    });

  return (
    <>
      <MetaBuyingTabs
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        creationTabs={creationTabs}
        validate={index => validateForm(metaAdForm, validateAdForm, "ad", () => setTabIndex(index))}
      />
      <div className="metaBuyingCreate">
        <div className="metaBuyingContainer">
          <div className="metaBuyingCard">
            <div className="metaBuyingHeader">
              <h2>Ad</h2>
              <div className="metaBuyingNameWrapper">
                Name:
                <MetaBuyingNamePreview
                  dimensions={dimensions}
                  nameFields={nameFields}
                  namingConvention={namingConvention}
                  inheritedFields={filterInheritedFields(allNameFields)}
                />
              </div>
            </div>

            <div className="metaBuyingFormBody">
              <div className="metaBuyingFormSection">
                <h3>Ad Name Construction</h3>
                {renderNameFields()}
                <h3>Ad Set</h3>
                <Form.Group>
                  <Form.Label>Initiative</Form.Label>
                  <DropdownButton
                    title={metaAdForm.ad_set}
                    onSelect={value => updateFormField("ad_set", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <h3>Ad Accounts</h3>
                <Form.Group>
                  <Form.Label>Facebook Page</Form.Label>
                  <DropdownButton
                    title={metaAdForm.facebook_page}
                    onSelect={value => updateFormField("facebook_page", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Instagram Page</Form.Label>
                  <DropdownButton
                    title={metaAdForm.instagram_page}
                    onSelect={value => updateFormField("instagram_page", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <h3>Creative</h3>
                <Form.Group>
                  <Form.Label>Creative Format</Form.Label>
                  <DropdownButton
                    title={metaAdForm.creative_format}
                    onSelect={value => updateFormField("creative_format", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Creative Asset</Form.Label>
                  <DropdownButton
                    title={metaAdForm.creative_asset}
                    onSelect={value => updateFormField("creative_asset", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Headline</Form.Label>
                  <Form.Control
                    value={metaAdForm.headline}
                    onChange={e => updateFormField("headline", e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Copy</Form.Label>
                  <Form.Control
                    value={metaAdForm.copy}
                    onChange={e => updateFormField("copy", e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>CTA Text</Form.Label>
                  <Form.Control
                    value={metaAdForm.cta_text}
                    onChange={e => updateFormField("cta_text", e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>CTA Link</Form.Label>
                  <Form.Control
                    value={metaAdForm.cta_link}
                    onChange={e => updateFormField("cta_link", e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>CTA Link Description</Form.Label>
                  <Form.Control
                    value={metaAdForm.cta_link_description}
                    onChange={e => updateFormField("cta_link_description", e.target.value)}
                  />
                </Form.Group>
              </div>
              <MetaBuyingFooterControls
                handleReviewClick={() =>
                  validateForm(metaAdForm, validateAdForm, "ad", showReviewModal)
                }
              />
            </div>
          </div>

          <MetaBuyingOptionsNoteCard
            header={OPTIONS_NOTE_CARD_HEADER}
            options={OPTIONS_NOTE_CARD_OPTIONS}
          />
        </div>
      </div>
    </>
  );
};

export default MetaBuyingAd;
