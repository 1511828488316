import { CreativeInfoItem } from "@blisspointmedia/bpm-types/dist/Creative";
import { Video, Img, Spinner } from "../Components";
import {
  getCreativeAsset,
  getCreativeThumbnail,
} from "../SingleChannel/MetricsTable/metricsTableUtils";

interface CreativeMediaPlayerProps {
  cid: string;
  selectedCreative: CreativeInfoItem;
}

const CreativeMediaPlayer = ({ cid, selectedCreative }: CreativeMediaPlayerProps): JSX.Element => {
  return cid === "tinuititest" ? (
    <Img className="videoContainer" src={getCreativeThumbnail(cid, selectedCreative.isci)} />
  ) : (
    <div className="videoContainer">
      <Video
        src={getCreativeAsset(cid, selectedCreative.file)}
        poster={getCreativeThumbnail(cid, selectedCreative.isci)}
        controls
        className="videoPlayer"
        unloader={<Spinner size={20} />}
      />
    </div>
  );
};

export default CreativeMediaPlayer;
