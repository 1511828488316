import { useMemo } from "react";
import "./Input.scss";

interface InputProps {
  className?: string;
  [passthroughProp: string]: any;
}

export const Input: React.FC<InputProps> = ({ className: providedClassName, ...passthrough }) => {
  const className = useMemo(() => {
    let classes = ["cl-input"];

    if (providedClassName) {
      classes.push(providedClassName);
    }
    return classes.join(" ");
  }, [providedClassName]);

  return <input className={className} {...passthrough} />;
};
