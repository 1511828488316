import { MdOutlineOpenInNew } from "react-icons/md";
import { Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import { FormLayoutProps } from "../OnboardingFreeForm";
import { PlatformOptions } from "../PlatformChecklist/PlatformChecklistOptions";
import { Button, ButtonType } from "../../Components";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import { filterSelections, mergeSelections, mapToRows } from "../OnboardingUtils";

const PLATFORM_ACCESS_COLUMNS = [
  {
    title: "primary",
    type: "header",
    width: 2,
  },
  {
    title: "Access Guide",
    type: "custom",
    width: 1,
    required: false,
    enabler: true,
  },
  {
    title: "Access Granted",
    type: "checkbox",
    width: 1,
    required: false,
    enabler: true,
  },
  {
    title: "Country",
    type: "select",
    width: 1,
    required: true,
    selectOptions: ["USA", "Canada"],
    enabler: true,
  },
  {
    title: "Account Name",
    type: "text",
    width: 2,
    required: true,
    textPlaceholder: "Account Name",
    enabler: true,
  },
  {
    title: "Account ID",
    type: "text",
    width: 1,
    required: true,
    textPlaceholder: "Account ID",
    enabler: true,
  },
  {
    title: "Account Owner",
    type: "select",
    width: 1,
    required: true,
    selectOptions: ["Self", "Tinuiti"],
    enabler: true,
  },
  {
    title: "Notes / Security Steps",
    type: "text",
    width: 2,
    required: false,
    textPlaceholder: "Notes / Security Steps",
    enabler: true,
  },
  {
    title: "Add Account",
    type: "button",
    width: 1,
    enabler: true,
  },
];

const ACCESS_GUIDES = {
  "Amazon Ads":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/marketplaces/amazon-ads?authuser=0",
  Braze: "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/email/braze?authuser=0",
  "CJ Affiliate":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/affiliate/cj-affiliate?authuser=0",
  Criteo:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/display/criteo?authuser=0",
  "Google Ads":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-search/google-ads?authuser=0",
  "Google Analytics":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-search/google-analytics?authuser=0",
  "Google Campaign Manager":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/display/google-campaign-manager?authuser=0",
  "Google Display & Video 360":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/display/google-display-video-360?authuser=0",
  "Impact Radius":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/affiliate/impact-radius?authuser=0",
  Iterable:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/email/iterable?authuser=0",
  Klaviyo:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/email/klaviyo?authuser=0",
  LinkedIn:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-social/linkedin?authuser=0",
  Mailchimp:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/email/mailchimp?authuser=0",
  Meta:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-social/meta?authuser=0",
  "Microsoft Ads":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-search/microsoft-ads?authuser=0",
  Pepperjam:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/affiliate/pepperjam?authuser=0",
  Pinterest:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-social/pinterest?authuser=0",
  "Rakuten Advertising":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/affiliate/rakuten-advertising?authuser=0",
  Reddit:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-social/reddit?authuser=0",
  "Search Ads 360":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-search/search-ads-360?authuser=0",
  ShareASale:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/affiliate/shareasale?authuser=0",
  Skai:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/marketplaces/skai?authuser=0",
  Snapchat:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-social/snapchat?authuser=0",
  "The Trade Desk":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/display/the-trade-desk?authuser=0",
  TikTok:
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-social/tiktok?authuser=0",
  "Twitter / X":
    "https://sites.google.com/tinuiti.com/clientplatformaccessplaybook/paid-social/twitter?authuser=0",
};

const platformAccessForm = (
  responses: Response[],
  prevId: string,
  id: string
): FormLayoutProps => {
  const currentSectionSelections = filterSelections(responses, id, response => response.selections.length > 0);

  const previousSectionSelections = filterSelections(
    responses,
    prevId,
    response =>
      response.selections.some(
        sel => sel.label === "Do you run media on this platform?" && String(sel.openText) === "true"
      )
  );

  const mergedSelections = mergeSelections(currentSectionSelections, previousSectionSelections, id);

  const rows = mapToRows(
    mergedSelections.filter(platform => platform.additionalInfo),
    "2",
    (platform) => {
      const guideUrl = platform.additionalInfo ? ACCESS_GUIDES[platform.additionalInfo] : "";

      const parentPlatform =
        platform.parentPlatform ||
        previousSectionSelections.find(prev =>
          prev.additionalInfo === platform.additionalInfo
        )?.additionalInfo;

      return {
        title: platform.additionalInfo,
        parentPlatform,
        children: guideUrl ? (
          <Button
            type={ButtonType.EMPTY}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            disabled={!guideUrl}
            onClick={e => {
              e.preventDefault();
              window.open(guideUrl, "_blank");
            }}
          >
            <MdOutlineOpenInNew />
            See Guide
          </Button>
        ) : null,
      };
    }
  );

  return {
    columns: PLATFORM_ACCESS_COLUMNS,
    rows,
  } as FormLayoutProps;
};

export const platformAccessOptions = (selectedPlatforms: Response[]): PlatformOptions => {
  return {
    affiliateLifecycle: {
      simpleId: "2Affiliate",
      title: "Affiliate, Lifecycle",
      table: platformAccessForm(selectedPlatforms, "1Affiliate", "2Affiliate"),
    },
    marketplaces: {
      simpleId: "2Marketplaces",
      title: "Marketplaces",
      table: platformAccessForm(selectedPlatforms, "1Marketplaces", "2Marketplaces"),
    },
    programmaticDisplay: {
      simpleId: "2Display",
      title: "Display",
      table: platformAccessForm(selectedPlatforms, "1Display", "2Display"),
    },
    searchShopping: {
      simpleId: "2Search",
      title: "Paid Search",
      table: platformAccessForm(selectedPlatforms, "1Search", "2Search"),
    },
    seoCro: {
      simpleId: "2Email",
      title: "Email",
      table: platformAccessForm(selectedPlatforms, "1Email", "2Email"),
    },
    social: {
      simpleId: "2Social",
      title: "Paid Social",
      table: platformAccessForm(selectedPlatforms, "1Social", "2Social"),
    },
  };
};
