import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import {
  CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES,
  CUSTOMER_INSIGHTS_ORDERED_LEVELS,
  CustomerInsightsFields,
} from "./customerInsightsConstants";

// For some fields, "Alphabetical" sort is not literally alphabetical,
// but instead reflects a logical default ordering
export const getCustomerInsightsAlphabeticalSort = (
  fieldName: CustomerInsightsFields,
  data: KnowYourCustomerData[]
): KnowYourCustomerData[] => {
  const sortedData: KnowYourCustomerData[] = [];

  const ordering = CUSTOMER_INSIGHTS_ORDERED_LEVELS[fieldName];
  if (!ordering) {
    throw new Error(`No alphabetical ordering found for ${fieldName}`);
  }

  CUSTOMER_INSIGHTS_ORDERED_LEVELS[fieldName].forEach(dataName => {
    const found = data.find(val => val.levels === dataName);

    if (found) {
      const prettyName = CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES[fieldName][dataName];
      if (prettyName) {
        found.levels = prettyName;
      }

      sortedData.push(found);
    }
  });
  return sortedData;
};

export const getChartType = (name: string): "bar" | "pie" | undefined => {
  const fieldName = name as CustomerInsightsFields;

  if (
    [
      CustomerInsightsFields.INCOME,
      CustomerInsightsFields.HOUSEHOLD_SIZE,
      CustomerInsightsFields.AGE,
      CustomerInsightsFields.EDUCATION,
    ].includes(fieldName)
  ) {
    return "bar";
  }

  if (
    [
      CustomerInsightsFields.CHILDREN,
      CustomerInsightsFields.DWELLING,
      CustomerInsightsFields.URBANICITY,
      CustomerInsightsFields.ETHNICITY,
      CustomerInsightsFields.GENDER,
      CustomerInsightsFields.MARITAL_STATUS,
      CustomerInsightsFields.HOME_OWNERSHIP,
      CustomerInsightsFields.OCCUPATION,
      CustomerInsightsFields.PERSON_TYPE,
    ].includes(fieldName)
  ) {
    return "pie";
  }
};
