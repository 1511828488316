import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BPMDateRange, Page, Dropdown, DropdownToggleType } from "../Components";
import "./BrandHealthMetrics.scss";
import { formatDate } from "./BrandMetricsContent";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import { MetricsLambdaFetch, MiscLambdaFetch, awaitJSON, pollS3 } from "../utils/fetch-utils";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import {
  AdvertiserInfo,
  BrandHealthEntry,
  BrandHealthInfo,
  BrandHealthInfoParams,
  BrandHealthGqvQueryInfo,
} from "@blisspointmedia/bpm-types/dist/BrandHealthMetrics";
import { DateRange } from "../utils/types";
import { useMap } from "../utils/hooks/useData";
import * as Dfns from "date-fns/fp";
import { formatNumber } from "../utils/format-utils";
import { MdRefresh } from "react-icons/md";
import * as R from "ramda";
import { useTabbedNav } from "../utils/hooks/useNav";
import { RouteComponentProps, Router } from "@reach/router";
import YouGovBrandHealth from "./YouGovBrandHealth";
import GoogleBrandHealth from "./GoogleBrandHealth";
import { fetchAndParseData } from "../BrandEquity/BrandEquityUtils";

const enum TabKey {
  YOU_GOV = "you-gov",
  GOOGLE = "google",
}

const NAVS = [
  { label: "YouGov", key: TabKey.YOU_GOV },
  { label: "Google", key: TabKey.GOOGLE },
];

const BrandHealthMetrics: React.FC = ({ navigate }: RouteComponentProps) => {
  const { cid } = useCompanyInfo();
  const shouldEnableBrandHealthGoogleTabUsers = useExperimentFlag(
    "enableBrandHealthGoogleTabUsers"
  );
  const setError = useSetError();
  const [dateRange, setDateRange] = useState<DateRange>();
  const [dateRangeGoogle, setDateRangeGoogle] = useState<DateRange>();
  const [movingAvgDays, setMovingAvgDays] = useState<string>("14");
  const [ageMap, setAgeMap] = useMap<string, boolean>({
    "Age-All": true,
    "18-34": false,
    "35-49": false,
    "50+": false,
  });
  const ageGroup = useMemo(() => {
    if (ageMap["Age-All"]) {
      return "18-34,35-49,50+";
    }
    return Object.keys(ageMap)
      .filter(age => ageMap[age])
      .join(",");
  }, [ageMap]);
  const [genderMap, setGenderMap] = useMap<string, boolean>({
    "Gender-All": true,
    Male: false,
    Female: false,
  });
  const genderGroup = useMemo(() => {
    if (genderMap["Gender-All"]) {
      return "Male,Female";
    }
    return Object.keys(genderMap)
      .filter(gender => genderMap[gender])
      .join(",");
  }, [genderMap]);
  const [regionMap, setRegionMap] = useMap<string, boolean>({
    "Region-All": true,
    Northeast: false,
    South: false,
    Midwest: false,
    West: false,
  });
  const regionGroup = useMemo(() => {
    if (regionMap["Region-All"]) {
      return "Northeast,South,Midwest,West";
    }
    return Object.keys(regionMap)
      .filter(region => regionMap[region])
      .join(",");
  }, [regionMap]);
  const [incomeMap, setIncomeMap] = useMap<string, boolean>({
    All: true,
    "Under 40K": false,
    "40K - 80K": false,
    "80K - 120K": false,
    "Over 120K": false,
  });
  const incomeGroup = useMemo(() => {
    return Object.keys(incomeMap)
      .filter(income => incomeMap[income])
      .join(",");
  }, [incomeMap]);
  const [dateInterval, setDateInterval] = useState<string>("day");

  const movingAvgOptions = useMemo(() => {
    return [
      { value: "1", label: "None" },
      { value: "7", label: "7 days" },
      { value: "14", label: "14 days" },
      { value: "30", label: "30 days" },
      { value: "60", label: "60 days" },
    ];
  }, []);

  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: boolean | undefined }>({
    "Age-All": true,
    "18-34": false,
    "35-49": false,
    "50+": false,
    "Gender-All": true,
    Male: false,
    Female: false,
    "Region-All": true,
    Northeast: false,
    South: false,
    Midwest: false,
    West: false,
    All: true,
    "Under 40K": false,
    "40K - 80K": false,
    "80K - 120K": false,
    "Over 120K": false,
  });

  const [focalAdvertiser, setFocalAdvertiser] = useState<string>("");
  const [nonFocalAdvertisers, setNonFocalAdvertisers] = useState<string[]>([]);
  const [data, setData] = useState<BrandHealthEntry[]>([]);
  const [
    gqvQueryDataWithGeoNamesCodesAndPopulation,
    setGqvQueryDataWithGeoNamesCodesAndPopulation,
  ] = useState<BrandHealthGqvQueryInfo[]>([]);
  const [maxDate, setMaxDate] = useState<string>("");
  const [minDate, setMinDate] = useState<string>("");
  const [fetchingData, setFetchingData] = useState<boolean>(true);

  useEffect(() => {
    if (cid && !focalAdvertiser) {
      (async () => {
        try {
          let res = await MetricsLambdaFetch("/getAdvertiserInfo", {
            params: {
              cid,
            },
          });
          const advertiserInfo = await awaitJSON<AdvertiserInfo>(res);
          const { focalAdvertiser, nonFocalAdvertisers, minDate, maxDate } = advertiserInfo;
          setFocalAdvertiser(focalAdvertiser);
          setNonFocalAdvertisers(nonFocalAdvertisers);
          setMinDate(minDate);
          setMaxDate(maxDate);
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [cid, focalAdvertiser, setError]);

  useEffect(() => {
    setData([]);
    setFetchingData(true);
  }, [movingAvgDays, dateInterval]);

  useEffect(() => {
    (async () => {
      try {
        const timeSeriesData = await fetchAndParseData(
          "bpm-miguel-test/brand_health_gqv/query_data.csv",
          parsedData => parsedData,
          "gg"
        );

        const geoNamesData = await fetchAndParseData(
          "bpm-miguel-test/brand_health/GeoNames.csv",
          parsedData => parsedData,
          "gg"
        );

        const response = await MetricsLambdaFetch("/getDmaCodeAndPopulation", {
          params: {},
        });
        const dmaCodesAndPopulation = await awaitJSON(response);
        const dmaCodesToPopulationMap = new Map();
        dmaCodesAndPopulation.forEach(dmaCode => {
          dmaCodesToPopulationMap.set(Number(dmaCode.dma_code), dmaCode);
        });

        const geoNamesMap = new Map();
        geoNamesData.forEach(geo => {
          geoNamesMap.set(geo.GeoName, geo);
        });

        const joinedData = timeSeriesData.reduce(
          (acc, item) => {
            const geoData = geoNamesMap.get(item.GeoName);
            const dmaCode = geoData ? geoData.dma_code : null;
            const popData = dmaCode ? dmaCodesToPopulationMap.get(dmaCode) : null;

            const currentDate = new Date(item.ReportDate);
            if (!acc.minDate || currentDate < new Date(acc.minDate)) {
              acc.minDate = currentDate.toISOString().slice(0, 10);
            }
            if (!acc.maxDate || currentDate > new Date(acc.maxDate)) {
              acc.maxDate = currentDate.toISOString().slice(0, 10);
            }

            acc.data.push({
              ...item,
              ...(geoData || {}),
              ...(popData || {}),
            });

            return acc;
          },
          { data: [], minDate: null, maxDate: null }
        );
        setGqvQueryDataWithGeoNamesCodesAndPopulation(joinedData.data);
        setDateRangeGoogle({
          start: joinedData.minDate,
          end: joinedData.maxDate,
        });
      } catch (e) {
        setError({
          message: e.message,
          reportError: e,
        });
      }
    })();
  }, [maxDate, minDate, setError]);

  useEffect(() => {
    if (focalAdvertiser && maxDate && minDate && fetchingData) {
      (async () => {
        try {
          setFetchingData(false);
          setAppliedFilters({ ...ageMap, ...genderMap, ...regionMap, ...incomeMap });

          const params: BrandHealthInfoParams = {
            focalAdvertiser,
            nonFocalAdvertisers: nonFocalAdvertisers.join(","),
            minDate,
            maxDate,
            rollingAvgDaysMinusOne: Number.parseInt(movingAvgDays) - 1,
            ageGroup,
            genderGroup,
            regionGroup,
            incomeGroup,
            dateInterval,
          };

          const result = await MiscLambdaFetch("/kickOffLambda", {
            method: "POST",
            body: {
              fileType: "txt",
              lambdaArgs: params,
              lambdaName: "metrics-getBrandHealthInfo",
            },
          });
          const uuid = await awaitJSON(result);

          const content = await pollS3({
            autoDownload: false,
            bucket: "bpm-cache",
            filename: `${uuid}.txt`,
            mimeType: "text/plain",
          });
          const textContent = await content.text();
          let { data } = JSON.parse(textContent) as BrandHealthInfo;

          if (dateInterval !== "day") {
            data = data.sort((a, b) => {
              // Compare 'metric' fields
              const metricComparison = a.metric.localeCompare(b.metric);

              // If 'metric' fields are equal, compare 'date' fields
              if (metricComparison === 0) {
                return a.date.localeCompare(b.date);
              } else {
                return metricComparison;
              }
            });
          }

          setData(
            data.map(entry => {
              let updatedEntry: BrandHealthEntry = {
                metric: entry.metric,
                date: entry.date,
                focal_ma: formatNumber(parseFloat(entry.focal_ma as string), 1),
                focal_sample_size: formatNumber(parseFloat(entry.focal_sample_size), 0),
                non_focal_ma: formatNumber(parseFloat(entry.non_focal_ma as string), 1),
                non_focal_sample_size: formatNumber(parseFloat(entry.non_focal_sample_size), 0),
              };
              return updatedEntry;
            })
          );
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [
    ageGroup,
    cid,
    data,
    focalAdvertiser,
    genderGroup,
    incomeGroup,
    regionGroup,
    movingAvgDays,
    setError,
    fetchingData,
    ageMap,
    genderMap,
    regionMap,
    incomeMap,
    dateInterval,
    maxDate,
    nonFocalAdvertisers,
    minDate,
  ]);

  const [updatingDateRange, setUpdatingDateRange] = useState<boolean>(true);

  useEffect(() => {
    setUpdatingDateRange(true);
  }, [dateInterval]);

  useEffect(() => {
    // If statement covers initial load of the page.
    if (!dateRange && minDate && maxDate && updatingDateRange) {
      setUpdatingDateRange(false);

      let maxDateObject = Dfns.parseISO(maxDate);
      let tempStart = Dfns.format(
        "yyyy-MM-dd",
        maxDateObject.setDate(maxDateObject.getDate() - 180)
      );

      setDateRange({
        start: tempStart < minDate ? minDate : tempStart,
        end: maxDate,
      });
    } else if (dateRange && dateInterval === "week" && updatingDateRange) {
      setUpdatingDateRange(false);

      let previousMondayStart = Dfns.parseISO(dateRange.start);
      previousMondayStart.setDate(
        previousMondayStart.getDate() - ((previousMondayStart.getDay() + 6) % 7)
      );
      // Check if the start date is before the min date. If so, set to following week.
      if (previousMondayStart < Dfns.parseISO(minDate)) {
        previousMondayStart = Dfns.parseISO(minDate);
        previousMondayStart.setDate(
          previousMondayStart.getDate() + (8 - previousMondayStart.getDay())
        );
      }

      let previousMondayEnd = Dfns.parseISO(dateRange.end);
      previousMondayEnd.setDate(
        previousMondayEnd.getDate() - ((previousMondayEnd.getDay() + 6) % 7)
      );
      // Check if the end date is before the min date. If so, set to following week.
      if (previousMondayEnd < Dfns.parseISO(minDate)) {
        previousMondayEnd = Dfns.parseISO(minDate);
        previousMondayEnd.setDate(previousMondayEnd.getDate() + (8 - previousMondayEnd.getDay()));
      }

      setDateRange({
        start: Dfns.format("yyyy-MM-dd", previousMondayStart),
        end: Dfns.format("yyyy-MM-dd", previousMondayEnd),
      });
    } else if (dateRange && dateInterval === "month" && updatingDateRange) {
      setUpdatingDateRange(false);

      let firstOfMonthStart = Dfns.parseISO(dateRange.start);
      firstOfMonthStart.setDate(1);
      // Check if the start date is before the min date. If so, set to following month.
      if (firstOfMonthStart < Dfns.parseISO(minDate)) {
        firstOfMonthStart.setMonth(firstOfMonthStart.getMonth() + 1);
      }

      let firstOfMonthEnd = Dfns.parseISO(dateRange.end);
      firstOfMonthEnd.setDate(1);
      // Check if the end date is before the min date. If so, set to following month.
      if (firstOfMonthEnd < Dfns.parseISO(minDate)) {
        firstOfMonthEnd.setMonth(firstOfMonthEnd.getMonth() + 1);
      }

      setDateRange({
        start: Dfns.format("yyyy-MM-dd", firstOfMonthStart),
        end: Dfns.format("yyyy-MM-dd", firstOfMonthEnd),
      });
    } else if (dateRange && dateInterval === "quarter" && updatingDateRange) {
      setUpdatingDateRange(false);

      let quarterStart = Dfns.parseISO(dateRange.start);
      const quarterStartMonth = quarterStart.getMonth();
      if (quarterStartMonth < 3) {
        quarterStart.setMonth(0);
        quarterStart.setDate(1);
        // Check if the start date is before the min date. If so, set to following quarter.
        if (quarterStart < Dfns.parseISO(minDate)) {
          quarterStart.setMonth(3);
        }
      } else if (quarterStartMonth < 6) {
        quarterStart.setMonth(3);
        quarterStart.setDate(1);
        // Check if the start date is before the min date. If so, set to following quarter.
        if (quarterStart < Dfns.parseISO(minDate)) {
          quarterStart.setMonth(6);
        }
      } else if (quarterStartMonth < 9) {
        quarterStart.setMonth(6);
        quarterStart.setDate(1);
        // Check if the start date is before the min date. If so, set to following quarter.
        if (quarterStart < Dfns.parseISO(minDate)) {
          quarterStart.setMonth(9);
        }
      } else {
        quarterStart.setMonth(9);
        quarterStart.setDate(1);
        // Check if the start date is before the min date. If so, set to following quarter.
        if (quarterStart < Dfns.parseISO(minDate)) {
          quarterStart.setMonth(0);
          quarterStart.setFullYear(quarterStart.getFullYear() + 1);
        }
      }

      let quarterEnd = Dfns.parseISO(dateRange.end);
      const quarterEndMonth = quarterEnd.getMonth();
      if (quarterEndMonth < 3) {
        quarterEnd.setMonth(0);
        quarterEnd.setDate(1);
        // Check if the end date is before the min date. If so, set to following quarter.
        if (quarterEnd < Dfns.parseISO(minDate)) {
          quarterEnd.setMonth(3);
        }
      } else if (quarterEndMonth < 6) {
        quarterEnd.setMonth(3);
        quarterEnd.setDate(1);
        // Check if the end date is before the min date. If so, set to following quarter.
        if (quarterEnd < Dfns.parseISO(minDate)) {
          quarterEnd.setMonth(6);
        }
      } else if (quarterEndMonth < 9) {
        quarterEnd.setMonth(6);
        quarterEnd.setDate(1);
        // Check if the end date is before the min date. If so, set to following quarter.
        if (quarterEnd < Dfns.parseISO(minDate)) {
          quarterEnd.setMonth(9);
        }
      } else {
        quarterEnd.setMonth(9);
        quarterEnd.setDate(1);
        // Check if the end date is before the min date. If so, set to following quarter.
        if (quarterEnd < Dfns.parseISO(minDate)) {
          quarterEnd.setMonth(0);
          quarterEnd.setFullYear(quarterEnd.getFullYear() + 1);
        }
      }

      setDateRange({
        start: Dfns.format("yyyy-MM-dd", quarterStart),
        end: Dfns.format("yyyy-MM-dd", quarterEnd),
      });
    } else if (dateRange && dateInterval === "day" && updatingDateRange) {
      setUpdatingDateRange(false);
    }
  }, [dateInterval, dateRange, maxDate, minDate, updatingDateRange]);

  const calendarSelectionDropdownOptions = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
    { value: "quarter", label: "Quarter" },
  ];

  const isDayBlocked = useCallback(
    date => {
      let checkForDateInterval = false;
      if (dateInterval === "week") {
        checkForDateInterval = !R.pipe(Dfns.parseISO, Dfns.isMonday)(date);
      } else if (dateInterval === "month") {
        checkForDateInterval = !R.pipe(Dfns.parseISO, Dfns.isFirstDayOfMonth)(date);
      } else if (dateInterval === "quarter") {
        checkForDateInterval = !(
          date.substring(5) === "01-01" ||
          date.substring(5) === "04-01" ||
          date.substring(5) === "07-01" ||
          date.substring(5) === "10-01"
        );
      }

      return date < minDate || date > maxDate || checkForDateInterval;
    },
    [dateInterval, maxDate, minDate]
  );

  const inLoadingState = useMemo(() => {
    return data.length === 0;
  }, [data.length]);

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "brand-health-metrics",
    defaultKey: TabKey.YOU_GOV,
  });

  return (
    <Page
      app2Redesign
      title="Brand Health Metrics"
      pageType="Brand Health Metrics"
      navs={NAVS}
      selectedNav={tab}
      onNav={goToTab}
      actions={
        tab === TabKey.YOU_GOV
          ? maxDate && (
              <div className="BHMActions">
                <div className="lastUpdated">
                  <div className="lastUpdatedIcon">
                    <MdRefresh />
                  </div>
                  <div className="lastUpdatedText">{`Data last updated: ${formatDate(
                    maxDate
                  )}`}</div>
                </div>
                <Dropdown
                  type={DropdownToggleType.FILLED}
                  design="primary"
                  disabled={inLoadingState}
                  value={dateInterval}
                  label="Date Interval"
                  options={calendarSelectionDropdownOptions}
                  onChange={setDateInterval}
                ></Dropdown>
                <Dropdown
                  type={DropdownToggleType.FILLED}
                  design="primary"
                  disabled={inLoadingState}
                  label="Moving Average"
                  value={movingAvgDays}
                  options={movingAvgOptions}
                  onChange={setMovingAvgDays}
                ></Dropdown>
                <BPMDateRange
                  range={dateRange}
                  onChange={setDateRange}
                  isDayBlocked={date => isDayBlocked(date)}
                  maxDate={maxDate}
                />
              </div>
            )
          : tab === TabKey.GOOGLE &&
            dateRangeGoogle && (
              <BPMDateRange range={dateRangeGoogle} onChange={setDateRangeGoogle} />
            )
      }
    >
      <Router className="fullPageRouter BradHealthMetricsPage">
        <YouGovBrandHealth
          path={"/"}
          focalAdvertiser={focalAdvertiser}
          nonFocalAdvertisers={nonFocalAdvertisers}
          data={data}
          dateRange={dateRange}
          ageMap={ageMap}
          setAgeMap={setAgeMap}
          genderMap={genderMap}
          setGenderMap={setGenderMap}
          regionMap={regionMap}
          setRegionMap={setRegionMap}
          incomeMap={incomeMap}
          setIncomeMap={setIncomeMap}
          setFetchingData={setFetchingData}
          setData={setData}
          appliedFilters={appliedFilters}
          inLoadingState={inLoadingState}
        ></YouGovBrandHealth>
        {shouldEnableBrandHealthGoogleTabUsers && (
          <GoogleBrandHealth
            path={TabKey.GOOGLE}
            gqvQueryDataWithGeoNamesCodesAndPopulation={gqvQueryDataWithGeoNamesCodesAndPopulation}
            dateRange={dateRangeGoogle}
          ></GoogleBrandHealth>
        )}
      </Router>
    </Page>
  );
};

export default BrandHealthMetrics;
