import { useCallback, useMemo } from "react";
import * as R from "ramda";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import WidgetContainer from "../Components/WidgetContainer";
import { CBSA_TO_MSA_MAP, CbsaMap } from "../Components/CbsaMap";
import {
  HeatMappingDivergent0,
  HeatMappingDivergentNeg1,
  HeatMappingDivergentNeg3,
  HeatMappingDivergentPos1,
  HeatMappingDivergentPos3,
  HeatMappingSequential0,
  HeatMappingSequential1,
  HeatMappingSequential2,
  HeatMappingSequential3,
  HeatMappingSequential4,
} from "../utils/colors";
import { formatNumber } from "../utils/format-utils";
import {
  CUSTOMER_INSIGHTS_PRETTY_NAMES,
  CustomerInsightsFields,
  TabKey,
} from "./customerInsightsConstants";

interface CustomerInsightsGeoGroupProps {
  data: KnowYourCustomerData[];
  totalPopulation: number;
  header: string;
  tab: TabKey;
}

const CustomerInsightsGeoGroup: React.FC<CustomerInsightsGeoGroupProps> = ({
  data,
  totalPopulation,
  header,
  tab,
}) => {
  const DECIMALS_INDEX = 100;

  const knownPopulation = useMemo(() => {
    const unknownPop = data.find(row => row.levels === "Unknown")?.clientPopulation ?? 0;
    return totalPopulation - unknownPop;
  }, [data, totalPopulation]);

  const getMetricFromData = useCallback(
    (data: KnowYourCustomerData): number => {
      switch (tab) {
        case TabKey.CUSTOMER_BASE:
          return data.clientPopulation / knownPopulation;
        case TabKey.INDEX:
          return data.clientPopulationFraction / data.tuPopulationFraction;
        case TabKey.PENETRATION:
          return data.clientPopulation / data.tuPopulation;
      }
    },
    [tab, knownPopulation]
  );

  const metricMax: number = useMemo(() => Math.max(...data.map(val => getMetricFromData(val))), [
    data,
    getMetricFromData,
  ]);

  const mappedData: Record<string, KnowYourCustomerData> = useMemo(
    () => R.indexBy(R.prop("levels"), data),
    [data]
  );

  const roundData = useCallback(
    (n: number, type?: "min" | "max"): number => {
      switch (tab) {
        case TabKey.CUSTOMER_BASE:
        case TabKey.PENETRATION:
          if (type === "min") {
            return Math.ceil(n * 100 * DECIMALS_INDEX) / DECIMALS_INDEX;
          }
          if (type === "max") {
            return Math.floor(n * 100 * DECIMALS_INDEX) / DECIMALS_INDEX;
          }
          return Math.round(n * 100 * DECIMALS_INDEX) / DECIMALS_INDEX;
        case TabKey.INDEX:
          if (type === "min") {
            return Math.ceil(n * DECIMALS_INDEX) / DECIMALS_INDEX;
          }
          if (type === "max") {
            return Math.floor(n * DECIMALS_INDEX) / DECIMALS_INDEX;
          }
          return Math.round(n * DECIMALS_INDEX) / DECIMALS_INDEX;
      }
    },
    [tab]
  );

  const renderColorGroups: {
    min: number;
    max: number;
    color: string;
    label: string;
  }[] = useMemo(() => {
    const partialGroups: { min: number; max: number; color: string }[] = [];

    partialGroups.push({
      min: roundData(metricMax * 0.8, "min"),
      max: roundData(metricMax, "min"),
      color: tab === TabKey.CUSTOMER_BASE ? HeatMappingSequential4 : HeatMappingDivergentPos3,
    });
    partialGroups.push({
      min: roundData(metricMax * 0.6, "min"),
      max: roundData(metricMax * 0.8, "max"),
      color: tab === TabKey.CUSTOMER_BASE ? HeatMappingSequential3 : HeatMappingDivergentPos1,
    });
    partialGroups.push({
      min: roundData(metricMax * 0.4, "min"),
      max: roundData(metricMax * 0.6, "max"),
      color: tab === TabKey.CUSTOMER_BASE ? HeatMappingSequential2 : HeatMappingDivergent0,
    });
    partialGroups.push({
      min: roundData(metricMax * 0.2, "min"),
      max: roundData(metricMax * 0.4, "max"),
      color: tab === TabKey.CUSTOMER_BASE ? HeatMappingSequential1 : HeatMappingDivergentNeg1,
    });
    partialGroups.push({
      min: 0,
      max: roundData(metricMax * 0.2, "max"),
      color: tab === TabKey.CUSTOMER_BASE ? HeatMappingSequential0 : HeatMappingDivergentNeg3,
    });

    const fullGroups = partialGroups.map((group, index) => {
      const { min, max } = group;
      const formatAsPercent = tab !== TabKey.INDEX;

      if (index === 0) {
        return {
          ...group,
          label: `${formatNumber(min)}${formatAsPercent ? "%" : ""}+`,
        };
      }

      return {
        ...group,
        label: `
        ${formatNumber(min)}${formatAsPercent ? "%" : ""}-${formatNumber(max)}${
          formatAsPercent ? "%" : ""
        }`,
      };
    });

    return fullGroups;
  }, [metricMax, roundData, tab]);

  const getRenderColor = (cbsa: string): string => {
    const data = getMetricFromData(mappedData[cbsa] ?? mappedData[CBSA_TO_MSA_MAP[cbsa]]);
    const roundedData = roundData(data);

    for (const group of renderColorGroups) {
      const { color, min, max } = group;
      if (roundedData >= min && roundedData <= max) {
        return color;
      }
    }

    console.error(`No value found for cbsa ${cbsa} / mapped msa ${CBSA_TO_MSA_MAP[cbsa]}`);
    return "transparent";
  };

  const explanationText = useMemo(() => {
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        return "((Number of customers) / (ingested emails)) per MSA";
      case TabKey.INDEX:
        return "((percent of our customers) / (percent of total population)) per MSA";
      case TabKey.PENETRATION:
        return "((Number of customers) / (total population)) per MSA";
    }
  }, [tab]);

  const legendTitle = useMemo(() => {
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        return "Customers";
      case TabKey.INDEX:
        return "Index";
      case TabKey.PENETRATION:
        return "Saturation";
    }
  }, [tab]);

  return (
    <WidgetContainer header={header} collapsible>
      <div className="customerInsightsGroup fullWidth">
        <div className="chartContainer geoChartContainer">
          <div className="title">{CUSTOMER_INSIGHTS_PRETTY_NAMES[CustomerInsightsFields.MSA]}</div>
          <div className="geoMap">
            <CbsaMap
              renderColor={getRenderColor}
              renderTooltip={cbsa => {
                const data = getMetricFromData(
                  mappedData[cbsa] ?? mappedData[CBSA_TO_MSA_MAP[cbsa]]
                );
                return (
                  <div>
                    {cbsa}: {formatNumber(roundData(data))}
                    {tab !== TabKey.INDEX ? "%" : ""}
                  </div>
                );
              }}
            />
            <div className="legend">
              <div className="legendKey">
                <div className="legendTitle">{legendTitle}</div>
                <div className="explanation">{explanationText}</div>
              </div>
              {renderColorGroups.map(group => (
                <div className="legendItem" key={group.color}>
                  <div className="color" style={{ background: group.color }} />
                  <div className="range">{group.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </WidgetContainer>
  );
};

export default CustomerInsightsGeoGroup;
