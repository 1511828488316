import "./CustomerInsightsChart.scss";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import { formatDecimal, formatNumber, formatPercent } from "../utils/format-utils";
import {
  CUSTOMER_INSIGHTS_PRETTY_NAMES,
  CustomerInsightsFields,
} from "./customerInsightsConstants";
import ChartContainer from "../Components/ChartContainer";
import { getChannelSeriesColor } from "../utils/colors";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { CustomerInsightsLegend } from "./CustomerInsightsGroup";

interface DoubleBarProps {
  data: KnowYourCustomerData[];
  fieldPrettyName: string;
  setSelectedValue: Dispatch<SetStateAction<string | undefined>>;
}

const DoubleBar: React.FC<DoubleBarProps> = ({ data, fieldPrettyName, setSelectedValue }) => {
  const barFormattedData = useMemo(() => {
    return data.map(({ clientPopulationFraction, tuPopulationFraction, levels }) => {
      return {
        name: levels,
        val1: clientPopulationFraction,
        val2: tuPopulationFraction,
      };
    });
  }, [data]);

  const legendFormattedData = useMemo(() => {
    return data.map(({ levels, clientPopulationFraction, tuPopulationFraction }) => {
      return {
        name: levels,
        val1: formatPercent(clientPopulationFraction, 2),
        val2: formatPercent(tuPopulationFraction, 2),
        computedValue: formatDecimal(clientPopulationFraction / tuPopulationFraction, 2),
      };
    });
  }, [data]);

  return (
    <div className="customerInsightsBarChart indexBarChart">
      <ChartContainer title={fieldPrettyName}>
        {barFormattedData.map((entry, index) => {
          const fill = getChannelSeriesColor(index, true);
          return (
            <ResponsiveContainer
              width="100%"
              height={`${100 / barFormattedData.length}%`}
              key={entry.name}
            >
              <BarChart
                data={[entry]}
                layout="vertical"
                onClick={() => setSelectedValue(entry.name)}
              >
                <XAxis type="number" hide={true} />
                <YAxis dataKey="name" type="category" hide={true} />
                <Legend
                  content={() => (
                    <CustomerInsightsLegend
                      data={[legendFormattedData[index]]}
                      setSelectedValue={setSelectedValue}
                      selectBehavior="click"
                    />
                  )}
                  layout="vertical"
                  align="left"
                />
                <Bar
                  dataKey="val1"
                  fill={fill}
                  isAnimationActive={false}
                  onClick={() => {
                    setSelectedValue(entry.name);
                  }}
                />
                <Bar
                  dataKey="val2"
                  fill={fill}
                  isAnimationActive={false}
                  style={{ opacity: 0.25 }}
                />
              </BarChart>
            </ResponsiveContainer>
          );
        })}
      </ChartContainer>
    </div>
  );
};

interface SelectedPieProps {
  data: KnowYourCustomerData[];
  fieldPrettyName: string;
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string | undefined>>;
}

const SelectedPie: React.FC<SelectedPieProps> = ({
  data,
  fieldPrettyName,
  selectedValue,
  setSelectedValue,
}) => {
  const pieRef: RefObject<HTMLDivElement> = useRef(null);
  const getFillFromName = useCallback(
    (name: string): string => {
      const index = data.findIndex(entry => entry.levels === name);

      if (index !== -1) {
        return getChannelSeriesColor(index, true);
      }

      return "#DEE3EF";
    },
    [data]
  );

  const innerPieFormattedData = useMemo(() => {
    const formattedData: { name; value }[] = [];

    const selectedData = data.find(({ levels }) => levels === selectedValue);
    if (selectedData) {
      formattedData.push({
        name: selectedData.levels,
        value: Math.round(selectedData.clientPopulation),
      });
      formattedData.push({
        name: "Other",
        value: Math.round(selectedData.clientPopulation / selectedData.clientPopulationFraction),
      });
    }

    return formattedData;
  }, [data, selectedValue]);

  const outerPieFormattedData = useMemo(() => {
    const formattedData: { name; value }[] = [];

    const selectedData = data.find(({ levels }) => levels === selectedValue);
    if (selectedData) {
      formattedData.push({
        name: selectedData.levels,
        value: Math.round(selectedData.tuPopulation),
      });
      formattedData.push({
        name: "Other",
        value: Math.round(selectedData.tuPopulation / selectedData.tuPopulationFraction),
      });
    }

    return formattedData;
  }, [data, selectedValue]);

  const legendFormattedData = useMemo(() => {
    return data.map(
      ({
        levels,
        clientPopulation,
        tuPopulation,
        clientPopulationFraction,
        tuPopulationFraction,
      }) => {
        return {
          name: levels,
          val1: formatNumber(clientPopulation),
          val2: formatNumber(tuPopulation),
          computedValue: formatDecimal(clientPopulationFraction / tuPopulationFraction, 2),
        };
      }
    );
  }, [data]);

  useEffect(() => {
    const handleOutsideClick = event => {
      if (pieRef && pieRef.current && !pieRef.current.contains(event.target)) {
        setSelectedValue(undefined);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setSelectedValue]);

  return (
    <div className="customerInsightsPieChart indexPieChart" ref={pieRef}>
      <ChartContainer title={fieldPrettyName}>
        <ResponsiveContainer width="100%">
          <PieChart>
            <Legend
              content={() => (
                <CustomerInsightsLegend
                  data={legendFormattedData}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  selectBehavior="click"
                />
              )}
              layout="vertical"
              align="left"
              verticalAlign="top"
            />
            <Pie
              className="indexInnerPie"
              data={innerPieFormattedData}
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              innerRadius="15%"
              outerRadius="45%"
              dataKey="value"
            >
              {innerPieFormattedData.map(({ name }) => (
                <Cell className="pieCell" key={`cell-${name}`} fill={getFillFromName(name)} />
              ))}
            </Pie>
            <Pie
              className="indexOuterPie"
              data={outerPieFormattedData}
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              innerRadius="46%"
              outerRadius="76%"
              dataKey="value"
            >
              {outerPieFormattedData.map(({ name }) => (
                <Cell className="pieCell" key={`cell-${name}`} fill={getFillFromName(name)} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );
};

interface IndexChartProps {
  fieldName: CustomerInsightsFields;
  data: KnowYourCustomerData[];
}

const IndexChart: React.FC<IndexChartProps> = ({ fieldName, data }) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const fieldPrettyName = CUSTOMER_INSIGHTS_PRETTY_NAMES[fieldName];

  if (selectedValue) {
    return (
      <SelectedPie
        data={data}
        fieldPrettyName={fieldPrettyName}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
    );
  }

  return (
    <DoubleBar data={data} fieldPrettyName={fieldPrettyName} setSelectedValue={setSelectedValue} />
  );
};

export default IndexChart;
