import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "YouTube";

const YouTube = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Built on Google's{" "}
              <a href="https://support.google.com/google-ads/answer/12003020?hl=en">
                Conversion Lift
              </a>{" "}
              (
              <a href="https://www.thinkwithgoogle.com/intl/en-emea/marketing-strategies/monetisation-strategies/a-revolution-in-measuring-ad-effectiveness/">
                Ghost Ads
              </a>
              ) approach.
            </div>
            <br />
            <div className="basicText">
              Tinuiti provides the value-add of packaging YouTube Conversion Lift results into the
              Streaming+ app for apples-to-apples comparisons with other streaming publishers and
              easier-to-digest outputs for indiviuals without statistics backgrounds.
            </div>
            <br />
            <div className="basicText">
              YouTube's Conversion lift tool provides incrementality reads at a relatively low
              operational cost and a typical timeframe of about 4 weeks (with options to extend
              depending on other factors).
            </div>
            <br />
            <Img src={`${INC_CDN}/YouTube_IncrementalConversions.png`} />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Incrementality Testing Approaches",
                "Setup",
                "Outputs",
                "Signal Enrichment",
                "YouTube Power Calculation",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "Conversion Lift for Users",
                  Description: (
                    <ul className="basicList">
                      <li>
                        Measures the actual number of conversions, site visits, and any other action
                        directly driven by your audience viewing your YouTube ad.
                      </li>
                      <li>Ghost Ads approach</li>
                      <li>4 to 8 week test duration.</li>
                      <li>
                        Can receive results for multiple KPI’s and get subgroup incrementality reads
                      </li>
                      <li>
                        Recommended spend: 20x typical Display CPA, 30x typical non-branded search
                        CPA
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>Eliminates noise due to impression based tracking</li>
                      <li>Cross-device enabled for a truly user-based view</li>
                      <li>Comparable results to other digital video investments</li>
                      <li>
                        Only pay for the ads that users see during the test (no buying PSA ads)
                      </li>
                      <li>
                        70 / 30 split for treatment vs control group reduces your campaigns’
                        opportunity costs
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Significantly impacted by user privacy changes, inability to track logged
                        out users cross-channel Conversion Lift is a quasi-experimental approach
                        rather than an experimental approach. Treatment (exposure to the brand’s
                        ads) is not randomized, thus causal inference is not completely clean.
                      </li>
                      <li>
                        Low visibility into underlying data. Theoretical visibility into methodology
                      </li>
                      <li>Within the ad platform. No cross platform visibility</li>
                    </ul>
                  ),
                },
              ],
              R.filter(header => header.name !== "Method Type", methodHeaders),
              350
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <ol className="basicList">
              <li>In you Google Ads account click the Goals icon.</li>
              <li>Click the Measurements drop down in the section menu.</li>
              <li>Click Lift measurement.</li>
              <li>Click the plus + button.</li>
              <li>Select Conversion Lift.</li>
              <li>Choose which campaigns you’d like to opt into Conversion Lift.</li>
              <li>Select the start and end dates for your study.</li>
              <li>
                Review the feasibility status in the right-hand column for an estimate on how likely
                you are to get precise results based on the campaigns being measured. A “High”
                feasibility status will offer you the best chances of a successful study. Changing
                the duration of your experiment or optimizing your campaigns or budget will help you
                achieve a “High” feasibility status.
              </li>
              <li>Click Save.</li>
            </ol>
            <br />
            <div className="basicText">
              Campaigns can only be active in one study at a time. If you’re unable to select a
              campaign, it most likely means that it’s already being used in another study.
            </div>
            <br />
            <div className="basicText">
              To initiate an incrementality study across Google media, anyone interested in
              conducting a conversion lift (user-based) can easily initiate the process by
              submitting this{" "}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSeLrz3LKFGxEl98ev9oKoJuWxHVQH1sdE7q8ksGgbPPqbS3HQ/viewform">
                form
              </a>
              . By doing so, you'll notify both the conversion lift teams at Tinuiti and Google, who
              will subsequently get in touch with you and provide guidance throughout the process.
              For Geo Lift or if anyone has additional questions, please send an email to the
              <a href="mailto:googleincrementality@tinuiti.com">
                googleincrementality@tinuiti.com
              </a>{" "}
              for support.
            </div>
            <br />
            <iframe
              allowFullScreen
              src="https://docs.google.com/presentation/d/1Tmz3VcMArE-NqUV1Xr-Wf0kGhztJARhDf_G2sr0qVqw/preview?authuser=0"
              title="YouTube Setup"
            />
          </div>
        }
        id={"setup"}
        pageTitle={PAGE_TITLE}
        title={"Setup"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  YouTube: (
                    <ul className="basicList">
                      <li>No log level data passed back</li>
                      <li>No performance breakdowns by traffic source, creative, device</li>
                      <li>Readouts only given weekly</li>
                    </ul>
                  ),
                  "Others (e.g. Hulu, Tubi, etc.)": (
                    <ul className="basicList">
                      <li>Log level data is passed back</li>
                      <li>Performance by traffic source and campaign sub-dimensions</li>
                      <li>Can get next-day performance reads</li>
                    </ul>
                  ),
                },
              ],
              [
                {
                  flex: 1,
                  label: "YouTube",
                  name: "YouTube",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return (
                      //Reuse the channel class from the Playbook.scss
                      <div className="channelNames">
                        <div className="channelName single">
                          <div className="label">{row.YouTube}</div>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  flex: 1,
                  label: "Others (e.g. Hulu, Tubi, etc.)",
                  name: "Others (e.g. Hulu, Tubi, etc.)",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return (
                      //Reuse the channel class from the Playbook.scss
                      <div className="channelNames">
                        <div className="channelName single">
                          <div className="label">{row["Others (e.g. Hulu, Tubi, etc.)"]}</div>
                        </div>
                      </div>
                    );
                  },
                },
              ],
              100
            )}
          </div>
        }
        id={"outputs"}
        pageTitle={PAGE_TITLE}
        title={"Outputs"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              For all Google incrementality products that rely on a user-based protocol – thus
              excluding geo experiments – it is strongly recommended that{" "}
              <a href="https://support.google.com/google-ads/answer/9888656">
                Enhanced Conversions
              </a>{" "}
              be implemented in order to strengthen conversion modeling and increase the overall
              fidelity of measurement. This is especially important for tests of lower-funnel
              outcomes.
            </div>
            <br />
            <div className="basicText">
              Enhanced Conversions is a protocol for sending hashed first-party conversion data from
              a brand’s website to Google in order to improve the accuracy of conversion measurement
              and unlock more powerful bidding. The basic idea is that a site owner, using data they
              collect on site, can help fill in gaps in Google’s ability to understand user
              behaviors post-ad exposure.
            </div>
            <br />
            <div className="basicText">
              This sort of protocol (below) is part of the industry’s response to third-party cookie
              deprecation, which has limited the ability to maintain coherent pictures of user
              conversion journeys across platforms.
            </div>
            <br />
            <Img
              src={`${INC_CDN}/EnhancedConversionForWeb.png`}
              style={{ height: 400, width: 800 }}
            />
          </div>
        }
        id={"signal-enrichment"}
        pageTitle={PAGE_TITLE}
        title={"Signal Enrichment"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              The calculator below uses Google's guidance on how much spend is needed to run a
              YouTube Conversion Lift.
            </div>
            <br />
            <ol className="basicList">
              <li>
                Find your in-platform Cost Per Conversion and CPM, then enter them in the specified
                user input box.
              </li>
              <li>
                The output will display the spend and impressions needed for a low, medium, and high
                range of possibilities
                <ul>
                  <li>
                    low = 5,000 conversions medium = 6,000 conversions high = 7,000 conversions
                  </li>
                  <li>medium = 6,000 conversions</li>
                  <li>high = 7,000 conversions</li>
                </ul>
              </li>
            </ol>
            <br />
            <iframe
              allowFullScreen
              src="https://bppm.shinyapps.io/youtube_power_calc/"
              title="YouTube Power Calculation"
            />
            <br />
            <div className="basicText">
              To ensure a powered test, YouTube gives the guidance of 3,000 - 5,000 conversions. The
              above Calculator gives you cost and number of impressions estimated to run a YouTube
              conversion lift for users.
            </div>
          </div>
        }
        id={"youtube-power-calculation"}
        pageTitle={PAGE_TITLE}
        title={"YouTube Power Calculation"}
      />
    </div>
  );
});

export default YouTube;
