import { FormLayoutProps } from "../OnboardingFreeForm";

const PLATFORM_CHECKLIST_COLUMNS = [
  {
    title: "primary",
    type: "header",
    width: 2,
  },
  {
    title: "Do you run media on this platform?",
    type: "checkbox",
    width: 1,
    required: true,
    enabler: true,
  },
  {
    title: "Tinuiti Managing?",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["Yes", "No"],
  },
  {
    title: "Current Status",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["Net New", "Live", "Paused", "Unknown", "Other"],
  },
  {
    title: "Additional Info",
    type: "text",
    width: 5,
    required: false,
    textPlaceholder: "Add Additional Info",
  },
];

const AFFILIATE_CHECKLIST_FORM = {
  columns: PLATFORM_CHECKLIST_COLUMNS,
  rows:[
    {
      id: "1",
      questionNumber: "1Affiliate1",
      title: "CJ Affiliate",
    },
    {
      id: "2",
      questionNumber: "1Affiliate2",
      title: "Impact Radius",
    },
    {
      id: "3",
      questionNumber: "1Affiliate3",
      title: "Pepperjam",
    },
    {
      id: "4",
      questionNumber: "1Affiliate4",
      title: "Rakuten Advertising",
    },
    {
      id: "5",
      questionNumber: "1Affiliate5",
      title: "ShareASale",
    }
  ]
} as FormLayoutProps;

const MARKETPLACES_CHECKLIST_FORM = {
  columns: PLATFORM_CHECKLIST_COLUMNS,
  rows: [
    {
      id: "1",
      questionNumber: "1Marketplaces1",
      title: "Amazon Ads",
    },
    {
      id: "2",
      questionNumber: "1Marketplaces2",
      title: "Skai",
    },
  ]
} as FormLayoutProps;

const DISPLAY_CHECKLIST_FORM = {
  columns: PLATFORM_CHECKLIST_COLUMNS,
  rows: [
    {
      id: "1",
      questionNumber: "1Display1",
      title: "Google Display & Video 360",
    },
    {
      id: "2",
      questionNumber: "1Display2",
      title: "Criteo",
    },
    {
      id: "3",
      questionNumber: "1Display3",
      title: "Google Campaign Manager",
    },
    {
      id: "4",
      questionNumber: "1Display4",
      title: "The Trade Desk",
    }
  ]
} as FormLayoutProps;

const SEARCH_SHOPPING_CHECKLIST_FORM = {
  columns: PLATFORM_CHECKLIST_COLUMNS,
  rows: [
    {
      id: "1",
      questionNumber: "1Search1",
      title: "Google Ads",
    },
    {
      id: "2",
      questionNumber: "1Search2",
      title: "Google Analytics",
    },
    {
      id: "3",
      questionNumber: "1Search3",
      title: "Microsoft Ads",
    },
    {
      id: "4",
      questionNumber: "1Search4",
      title: "Search Ads 360",
    }
  ]
} as FormLayoutProps;

const EMAIL_CHECKLIST_FORM = {
  columns: PLATFORM_CHECKLIST_COLUMNS,
  rows: [
    {
      id: "1",
      questionNumber: "1Email1",
      title: "Braze",
    },
    {
      id: "2",
      questionNumber: "1Email2",
      title: "Iterable",
    },
    {
      id: "3",
      questionNumber: "1Email3",
      title: "Klaviyo",
    },
    {
      id: "4",
      questionNumber: "1Email4",
      title: "Mailchimp",
    }
  ]
} as FormLayoutProps;

const SOCIAL_CHECKLIST_FORM = {
  columns: PLATFORM_CHECKLIST_COLUMNS,
  rows: [
    {
      id: "1",
      questionNumber: "1Social1",
      title: "LinkedIn",
    },
    {
      id: "2",
      questionNumber: "1Social2",
      title: "Meta",
    },
    {
      id: "3",
      questionNumber: "1Social3",
      title: "Reddit",
    },
    {
      id: "4",
      questionNumber: "1Social4",
      title: "TikTok",
    },
    {
      id: "5",
      questionNumber: "1Social5",
      title: "Twitter / X",
    },
    {
      id: "6",
      questionNumber: "1Social6",
      title: "Snapchat",
    },
    {
      id: "7",
      questionNumber: "1Social7",
      title: "Pinterest",
    },
  ],
} as FormLayoutProps;

export interface PlatformOptions {
  [key: string]: {
    simpleId: string;
    title: string;
    table: FormLayoutProps;
  };
}

export const PLATFORM_OPTIONS = {
  affiliateLifecycle: {
    simpleId: "1Affiliate",
    title: "Affiliate, Lifecycle",
    table: AFFILIATE_CHECKLIST_FORM,
  },
  marketplaces: {
    simpleId: "1Marketplaces",
    title: "Marketplaces",
    table: MARKETPLACES_CHECKLIST_FORM,
  },
  programmaticDisplay: {
    simpleId: "1Display",
    title: "Display",
    table: DISPLAY_CHECKLIST_FORM,
  },
  searchShopping: {
    simpleId: "1Search",
    title: "Paid Search",
    table: SEARCH_SHOPPING_CHECKLIST_FORM,
  },
  email: {
    simpleId: "1Email",
    title: "Email",
    table: EMAIL_CHECKLIST_FORM,
  },
  social: {
    simpleId: "1Social",
    title: "Paid Social",
    table: SOCIAL_CHECKLIST_FORM,
  },
} as PlatformOptions;
