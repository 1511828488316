import React, { useEffect, useState } from "react";
import { ClientInfo, Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import OnboardingFormPage from "../OnboardingFormPage";
import QuestionOnlyForm from "../QuestionOnlyForm";
import { questions } from "./DataQuestions";
import { getCompletedPercentage } from "../OnboardingUtils";
import { ClientInfoComponentProps } from "../ClientInfo";

enum DataTabs {
  DATA_MEASUREMENT = "data-measurement",
}

const Data: React.FC<ClientInfoComponentProps> = ({
  clientInfo,
  onPercentageChange,
  onClientInfoChange,
}) => {
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);

  const [completedPercentage, setCompletedPercentage] = useState<number>(
    getCompletedPercentage(allResponses.responses, questions.length, "6")
  );

  const handleDataMeasurementChange = (updatedResponses: Response[]) => {
    const updatedClientInfo = {
      ...allResponses,
      responses: updatedResponses,
    };

    setAllResponses(updatedClientInfo);
    onClientInfoChange(updatedClientInfo);
  };

  useEffect(() => {
    const newPercentage = getCompletedPercentage(allResponses.responses, questions.length, "6");
    setCompletedPercentage(newPercentage);
    onPercentageChange(newPercentage);
  }, [allResponses, onPercentageChange]);

  const renderTabs = [
    {
      label: "Data and Measurement Durability",
      headerLabel: "A. Data and Measurement Durability",
      key: DataTabs.DATA_MEASUREMENT,
      percentage: completedPercentage,
      children: (
        <QuestionOnlyForm
          responses={clientInfo.responses}
          questions={questions}
          onChange={handleDataMeasurementChange}
        />
      ),
    },
  ];

  return (
    <OnboardingFormPage
      renderTabs={renderTabs}
      defaultActiveTab={DataTabs.DATA_MEASUREMENT}
      tabs={Object.values(DataTabs)}
    />
  );
};

export default Data;
