import { engineeringUsers } from "./experiment-utils";

// Experiment for Brand Health Google Tab

export const shouldEnableBrandHealthGoogleTabUsers = (userEmail: string): boolean => {
  const brandHealthGoogleTabUsers: string[] = [
    "lauren.wolfen@tinuiti.com",
    "micah.kilimann@tinuiti.com",
    "sean.odlum@tinuiti.com",
    ...engineeringUsers,
    // Add users here!
  ];
  return brandHealthGoogleTabUsers.includes(userEmail);
};
