import React, { useState, useEffect, useMemo } from "react";
import { CURRENT_WEEK_START, DATE_FORMAT } from "../LinearBuying/linearBuyingConstants";
import {
  formatDemo,
  formatTimestampToDate,
  formatTimestampToTime,
  exportToExcel,
} from "./linearUpfrontReconciliationUtils";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import { Page, DateRangePicker, BPMTable, Spinner, BPMToggleButton } from "../Components";
import { useCompanyInfo } from "../redux/company";
import { LinearBuyingLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { Form, Button } from "react-bootstrap";
import { formatMoney, formatNumberAsInt } from "../utils/format-utils";
import { convert24hrTo12hr } from "../utils/time-utils";
import {
  time24hr,
  NielsenEstimates,
  calculateAudienceInfo,
  AudienceInfo,
} from "../LinearBuying/linearBuyingUtils";
import "./LinearUpfrontReconciliation.scss";
import { LinearUpfrontReconRows } from "@blisspointmedia/bpm-types/src/LinearBuying";

export const CUTOFF_DATE_PAST = R.pipe(
  Dfns.parseISO,
  Dfns.subMonths(6),
  Dfns.format(DATE_FORMAT)
)(CURRENT_WEEK_START);
export const CUTOFF_DATE_FUTURE = R.pipe(
  Dfns.parseISO,
  Dfns.subDays(7),
  Dfns.format(DATE_FORMAT)
)(CURRENT_WEEK_START);

const LinearUpfrontReconciliation: React.FC = () => {
  const companyInfo = useCompanyInfo();
  const setError = useSetError();
  const company = companyInfo.cid;
  const [start, setStart] = useState(
    R.pipe(Dfns.parseISO, Dfns.subDays(7), Dfns.format(DATE_FORMAT))(CURRENT_WEEK_START)
  );
  const [end, setEnd] = useState(
    R.pipe(Dfns.parseISO, Dfns.format(DATE_FORMAT))(CURRENT_WEEK_START)
  );
  const [upfrontTableData, setUpfrontTableData] = useState();
  const [isEquivalized, setIsEquivalized] = useState(true);
  const [nielsenEstimates, setNielsenEstimates] = useState<NielsenEstimates>({});
  const [universeEstimate, setUniverseEstimate] = useState<number>();

  useEffect(() => {
    (async () => {
      try {
        let endOfWeek = end;
        if (start === end) {
          endOfWeek = R.pipe(Dfns.parseISO, Dfns.addDays(6), Dfns.format(DATE_FORMAT))(start);
        }
        const res = await LinearBuyingLambdaFetch("/get_linear_upfront_recon", {
          params: {
            company: company,
            weekStart: start,
            weekEnd: endOfWeek,
          },
        });
        const plansData = await awaitJSON(res);
        setUpfrontTableData(plansData);
      } catch (e) {
        setError({
          message: `Failed to get data for ${start} - ${end}. Error: ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [company, start, end, setError]);

  // Get Nielsen estimates
  useEffect(() => {
    if (!R.isEmpty(nielsenEstimates)) {
      return;
    }
    (async () => {
      try {
        const res = await LinearBuyingLambdaFetch("/getNielsenEstimates", {
          params: {
            demos: "hh",
            measurement: "Live+ Same Day AA",
          },
        });
        const nielsenData = await awaitJSON(res);
        const { estimatesByHH, universeEstimate } = nielsenData;

        setNielsenEstimates(estimatesByHH);
        setUniverseEstimate(universeEstimate);
      } catch (e) {
        setError({
          message: `Failed to get Nielsen estimates. Error: ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [nielsenEstimates, setError]);

  const editedRows: LinearUpfrontReconRows[] = useMemo(
    () =>
      (upfrontTableData || []).map((row: LinearUpfrontReconRows) => {
        let rowToUse = row;
        if (R.isNil(row.linear_override_demo) && R.isNil(row.linear_override_measurement)) {
          row.linear_override_demo = "hh";
          row.linear_override_measurement = "Live+ Same Day AA";
        }
        let nielsenEstimatesImpressions = 0;
        if (
          R.isEmpty(row.linear_override_impressions) ||
          R.isNil(row.linear_override_impressions)
        ) {
          const audienceInfo: AudienceInfo = {
            avail: row.avail,
            network: row.network,
            dow: row.dow,
            daypart_start: row.daypart_start,
            daypart_end: row.daypart_end,
            type: "upfront",
            cost: row.cost,
            count: row.count,
          };
          let assumedClearanceRate = 1;
          let impressionsVal = calculateAudienceInfo({
            row: audienceInfo,
            nielsenEstimates,
            universeEstimate,
            assumedClearanceRate,
          });
          const { impressions } = impressionsVal;
          nielsenEstimatesImpressions = impressions;
        }
        const data: LinearUpfrontReconRows = {
          ...rowToUse,
          nielsenEstimatesImpressions: nielsenEstimatesImpressions,
        };
        return data;
      }),
    [upfrontTableData, nielsenEstimates, universeEstimate]
  );

  return (
    <Page
      title="Linear Upfront Reconciliation"
      pageType="LinearUpfrontReconciliation"
      minHeight="300"
      actions={
        <div className="linearUpfrontActions">
          <Form.Group>
            <Button style={{ marginRight: "20px" }} onClick={() => exportToExcel(editedRows)}>
              {<span>Download Report</span>}
            </Button>
            <BPMToggleButton
              options={[
                {
                  key: "Equivalized",
                },
                {
                  key: "Unequivalized",
                },
              ]}
              selectedOption={isEquivalized ? "Equivalized" : "Unequivalized"}
              onChange={key => setIsEquivalized(key === "Equivalized")}
            />
            <DateRangePicker
              isOutsideRange={date => date < CUTOFF_DATE_PAST || date > CUTOFF_DATE_FUTURE}
              startDate={start}
              endDate={end}
              startDateId="tableDataStartDate"
              endDateId="tableDataEndDate"
              mondayOnly={true}
              onChange={({ startDate, endDate }) => {
                if (startDate && endDate) {
                  setStart(startDate);
                  setEnd(endDate);
                }
              }}
            />
          </Form.Group>
        </div>
      }
    >
      {upfrontTableData ? (
        <div>
          <div className="newTableView">
            <BPMTable
              noRowsRenderer={() => (
                <div className="noRows">
                  There are no orders for the selected week(s). Start by adding a row or importing a
                  buy.
                </div>
              )}
              data={editedRows}
              alternateColors
              headerHeight={35}
              rowHeight={35}
              filterBar={true}
              headers={[
                {
                  label: "Week",
                  name: "week",
                  sortPriority: 0,
                  sortAscending: true,
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Network",
                  name: "network",
                  sortPriority: 2,
                  sortAscending: true,
                  flex: 1,
                  minFlexWidth: 100,
                },
                {
                  label: "Avail",
                  name: "avail",
                  minFlexWidth: 85,
                },
                {
                  label: "Rotation",
                  name: "rotation",
                  sortPriority: 2,
                  sortAscending: true,
                  flex: 1,
                  minFlexWidth: 300,
                },
                {
                  label: "Length",
                  name: "length",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Program",
                  name: "program",
                  flex: 1,
                  minFlexWidth: 450,
                },
                {
                  label: "Cost",
                  name: "cost",
                  flex: 1,
                  minFlexWidth: 200,
                  renderer: row => formatMoney(row.cost, 0),
                },
                {
                  label: "Start Time",
                  name: "daypart_start",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => convert24hrTo12hr(time24hr(row.daypart_start)),
                },
                {
                  label: "End Time",
                  name: "daypart_end",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => convert24hrTo12hr(time24hr(row.daypart_end)),
                },
                {
                  label: "Post Log Date",
                  name: "air_date",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => formatTimestampToDate(row.airdate_timestamp),
                },
                {
                  label: "Post Log Time (EST)",
                  name: "airdate_timestamp",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => formatTimestampToTime(row.airdate_timestamp),
                },
                {
                  label: "Days of Week",
                  name: "dow",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "ISCI",
                  name: "isci",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Media Classification",
                  name: "media_classification",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Ordered Measurement",
                  name: "linear_ordered_measurement",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Ordered Demo",
                  name: "linear_ordered_demo",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => formatDemo(row.linear_ordered_demo),
                },
                {
                  label: "Ordered Impressions",
                  name: "linear_ordered_impressions",
                  flex: 1,
                  minFlexWidth: 250,
                  renderer: row => formatNumberAsInt(row.linear_ordered_impressions),
                },
                {
                  label: "Ordered Impressions Delivered",
                  name: "ordered_actuals",
                  flex: 1,
                  minFlexWidth: 250,
                  renderer: isEquivalized
                    ? row => formatNumberAsInt(row.ordered_actuals_e)
                    : row => formatNumberAsInt(row.ordered_actuals_non_e),
                },
                {
                  label: "Difference",
                  name: "difference",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => {
                    const difference = isEquivalized
                      ? row.ordered_actuals_e - row.linear_ordered_impressions
                      : row.ordered_actuals_non_e - row.linear_ordered_impressions;
                    const formattedDifference = formatNumberAsInt(difference);
                    const color = difference >= 0 ? "green" : "red";
                    return <span style={{ color: color }}>{formattedDifference}</span>;
                  },
                },
                {
                  label: "Secondary Demo",
                  name: "linear_override_demo",
                  flex: 1,
                  minFlexWidth: 200,
                  renderer: row => formatDemo(row.linear_override_demo),
                },
                {
                  label: "Secondary Measurement",
                  name: "linear_override_measurement",
                  flex: 1,
                  minFlexWidth: 200,
                },
                {
                  label: "Secondary Impressions Tracked",
                  name: "linear_override_impressions",
                  flex: 1,
                  minFlexWidth: 250,
                  renderer: row => {
                    if (
                      R.isEmpty(row.linear_override_impressions) ||
                      R.isNil(row.linear_override_impressions)
                    ) {
                      return formatNumberAsInt(row.nielsenEstimatesImpressions);
                    } else {
                      return formatNumberAsInt(row.linear_override_impressions);
                    }
                  },
                },
                {
                  label: "Secondary Impressions Delivered",
                  name: "override_actuals",
                  flex: 1,
                  minFlexWidth: 250,
                  renderer: isEquivalized
                    ? row => formatNumberAsInt(row.override_actuals_e)
                    : row => formatNumberAsInt(row.override_actuals_non_e),
                },
                {
                  label: "Difference",
                  name: "difference",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => {
                    const difference = isEquivalized
                      ? R.isEmpty(row.linear_override_impressions) ||
                        R.isNil(row.linear_override_impressions)
                        ? row.override_actuals_e - row.nielsenEstimatesImpressions
                        : row.override_actuals_e - row.linear_override_impressions
                      : R.isEmpty(row.linear_override_impressions) ||
                        R.isNil(row.linear_override_impressions)
                      ? row.override_actuals_non_e - row.nielsenEstimatesImpressions
                      : row.override_actuals_non_e - row.linear_override_impressions;
                    const formattedDifference = formatNumberAsInt(difference);
                    const color = difference >= 0 ? "green" : "red";
                    return <span style={{ color: color }}>{formattedDifference}</span>;
                  },
                },
                {
                  label: "Notes",
                  name: "notes",
                  flex: 1,
                  minFlexWidth: 150,
                },
              ]}
            />
          </div>
        </div>
      ) : (
        <Spinner size={100} />
      )}
    </Page>
  );
};

export default LinearUpfrontReconciliation;
