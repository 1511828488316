import { Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import { FormLayoutProps } from "../OnboardingFreeForm";
import { PlatformOptions } from "../PlatformChecklist/PlatformChecklistOptions";
import { filterSelections, mergeSelections, mapToRows } from "../OnboardingUtils";

const platformBudgetsColumns = [
  {
    title: "Platform",
    type: "header",
    width: 2,
  },
  {
    title: "Billing KPI",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["Spend", "Revenue"],
    enabler: true,
  },
  {
    title: "Spend Type",
    type: "select",
    width: 2,
    required: true,
    selectOptions: [
      "Campaign",
      "Spend with Agency and Platform Fees",
      "Agency Fees",
      "Platform Fees",
    ],
    enabler: true,
  },
  {
    title: "Payment Process",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["Tinuiti pays platform (passes through cost)", "Client pays platform"],
    enabler: true,
  },
  {
    title: "Currency",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["USD", "CA", "Euro", "GBP", "Other"],
    enabler: true,
  },
  {
    title: "Notes",
    type: "text",
    width: 2,
    required: false,
    enabler: true,
  },
];

const platformBudgetsForm = (
  responses: Response[],
  prevId: string,
  id: string
): FormLayoutProps => {
  const currentSectionSelections = filterSelections(
    responses,
    id,
    response => response.selections.length > 0
  );
  const previousSectionSelections = filterSelections(
    responses,
    prevId,
    response => response.selections.length > 0
  );

  const mergedSelections = mergeSelections(
    currentSectionSelections,
    previousSectionSelections,
    id
  ).filter(selection => {
    const budgetInfo = selection.selections[3]?.openText || "";
    return budgetInfo.trim() !== "";
  });

  const rows = mapToRows(mergedSelections, "5", platform => {
    const budgetInfo = platform.selections[3]?.openText || "";

    const parentPlatform =
      platform.parentPlatform ||
      previousSectionSelections.find(prev => prev.additionalInfo === platform.additionalInfo)
        ?.parentPlatform;

    return {
      title: `${parentPlatform} - ${budgetInfo}`,
      parentPlatform,
    };
  });

  return {
    columns: platformBudgetsColumns,
    rows,
  } as FormLayoutProps;
};

export const platformBudgetsOptions = (selectedPlatforms: Response[]): PlatformOptions => {
  return {
    affiliateLifecycle: {
      simpleId: "5Affiliate",
      title: "Affiliate, Lifecycle",
      table: platformBudgetsForm(selectedPlatforms, "2Affiliate", "5Affiliate"),
    },
    marketplaces: {
      simpleId: "5Marketplaces",
      title: "Marketplaces",
      table: platformBudgetsForm(selectedPlatforms, "2Marketplaces", "5Marketplaces"),
    },
    programmaticDisplay: {
      simpleId: "5Display",
      title: "Display",
      table: platformBudgetsForm(selectedPlatforms, "2Display", "5Display"),
    },
    searchShopping: {
      simpleId: "5Search",
      title: "Paid Search",
      table: platformBudgetsForm(selectedPlatforms, "2Search", "5Search"),
    },
    seoCro: {
      simpleId: "5Email",
      title: "Email",
      table: platformBudgetsForm(selectedPlatforms, "2Email", "5Email"),
    },
    social: {
      simpleId: "5Social",
      title: "Paid Social",
      table: platformBudgetsForm(selectedPlatforms, "2Social", "5Social"),
    },
  };
};
