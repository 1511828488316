// Experiment to allow clients to access time travel on performance page.

const timeTravelUsers: string[] = [
  "alynch@thumbtack.com",
  "suj@thumbtack.com",
  "petercharrison99@gmail.com",
  "lia.lankford@usenourish.com",
  "sususkelley@gmail.com",
  // Add users here!
];

export const shouldEnableTimeTravel = (userEmail: string): boolean => {
  return timeTravelUsers.includes(userEmail);
};
