import React, { useMemo, useState, useEffect } from "react";
import { Page, FullPageSpinner } from "../Components";
import { RouteComponentProps, Router } from "@reach/router";
import { useTabbedNav } from "../utils/hooks/useNav";
import MetaBuyingCreate from "./MetaBuyingCreate/MetaBuyingCreate";
import MetaBuyingUpload from "./MetaBuyingUpload";
import MetaBuyingDrafts from "./DraftsAndPublished/MetaBuyingDrafts";
import MetaBuyingPublished from "./DraftsAndPublished/MetaBuyingPublished";
import "./MetaBuying.scss";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
import { MetaBuyingProvider } from "./MetaBuyingContext";
import { awaitJSON, MetaLambdaFetch } from "../utils/fetch-utils";
// import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import {
  AdSetRow,
  AdRow,
  CampaignRowsMap,
  AdsManagerStatus,
  MBApprovalStage,
  CampaignRow,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";

const enum PageTab {
  CREATE = "create",
  UPLOAD = "upload",
  DRAFTS = "drafts",
  PUBLISHED = "published",
}

const NAVS = [
  { label: "Create", key: PageTab.CREATE },
  { label: "Upload", key: PageTab.UPLOAD },
  { label: "Drafts", key: PageTab.DRAFTS },
  { label: "Published", key: PageTab.PUBLISHED },
];

const MetaBuying = React.memo(({ navigate }: RouteComponentProps) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "social/meta-buying",
    defaultKey: PageTab.CREATE,
  });
  // const { cid } = useCompanyInfo();
  const cid = "culligan";
  const setError = useSetError();

  const isInternal = useSelector(UserRedux.isInternalSelector);

  // TODO: Add lambda for fetching ad accounts from the database
  const [adAccountOptions, setAdAccountOptions] = useState<
    {
      account_id: string;
      account_name: string;
      business_manager: string;
    }[]
  >([
    {
      account_id: "1504500373017084",
      account_name: "Bonafide",
      business_manager: "Ampush",
    },
  ]);
  const [selectedAdAccount, setSelectedAdAccount] = useState<{
    account_id: string;
    account_name: string;
    business_manager: string;
  }>({
    account_id: "1504500373017084",
    account_name: "Bonafide",
    business_manager: "Ampush",
  });
  const [namingConventions, setNamingConventions] = useState<{
    campaign: number[];
    adset: number[];
    ad: number[];
  }>({
    campaign: [],
    adset: [],
    ad: [],
  });
  const [dimensions, setDimensions] = useState<Record<string, any>>({});
  const [granularity, setGranularity] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        let res = await MetaLambdaFetch("/getClientInfo", { params: { company: cid } });
        let { data } = await awaitJSON(res);
        if (data.granularity) {
          setGranularity(data.granularity);
        }
        if (data.namingConventions) {
          setNamingConventions(data.namingConventions);
        }
        if (data.accounts) {
          setAdAccountOptions(data.accounts);
        }
        if (data.dimensions) {
          setDimensions(data.dimensions);
        }
      } catch (e) {
        setError({ message: e.message, reportError: e });
      } finally {
        setLoading(false);
      }
    })();
  }, [cid, setError]);

  // TODO: Add lambda for fetching drafts and published rows from the database
  // Maps account_id to an array of campaign rows
  const [campaignRowsMap, setCampaignRowsMap] = useState<CampaignRowsMap>();
  useEffect(() => {
    if (!campaignRowsMap) {
      (async () => {
        try {
          // TODO: Include isInternal as a param to filter in query
          let res = await MetaLambdaFetch("/getLocalCampaigns", {
            params: {
              accountIDs: "1504500373017084",
            },
          });
          const obj = await awaitJSON<CampaignRowsMap>(res);
          setCampaignRowsMap(obj);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [campaignRowsMap, selectedAdAccount, setError]);
  const campaignRows: CampaignRow[] = useMemo(() => {
    return [
      {
        id: "1",
        meta_id: null,
        campaign_name: "Campaign 1",
        objective: "SALES",
        special_ad_categories: [],
        special_ad_category_country: "",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "2",
        meta_id: null,
        campaign_name: "Campaign 2",
        objective: "SALES",
        special_ad_categories: [],
        special_ad_category_country: "",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-10-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "3",
        meta_id: null,
        campaign_name: "Campaign 3",
        objective: "SALES",
        special_ad_categories: [],
        special_ad_category_country: "",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-11-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
    ];
  }, []);
  const adSetRows: AdSetRow[] = useMemo(() => {
    return [
      {
        id: "1",
        meta_id: "1",
        adset_name: "Ad Set 1",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
        billing_event: "",
        optimization_goal: "",
        bid_strategy: "",
        bid_amount: "",
        promoted_object: { pixel_id: "", custom_event_type: "" },
      },
      {
        id: "2",
        meta_id: "2",
        adset_name: "Ad Set 2",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-10",
        lastuser: "berto.garciacarrillo@tinuiti.com",
        billing_event: "",
        optimization_goal: "",
        bid_strategy: "",
        bid_amount: "",
        promoted_object: { pixel_id: "", custom_event_type: "" },
      },
      {
        id: "3",
        meta_id: "3",
        adset_name: "Ad Set 3",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-10-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
        billing_event: "",
        optimization_goal: "",
        bid_strategy: "",
        bid_amount: "",
        promoted_object: { pixel_id: "", custom_event_type: "" },
      },
      {
        id: "4",
        meta_id: "4",
        adset_name: "Ad Set 4",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-10-10",
        lastuser: "berto.garciacarrillo@tinuiti.com",
        billing_event: "",
        optimization_goal: "",
        bid_strategy: "",
        bid_amount: "",
        promoted_object: { pixel_id: "", custom_event_type: "" },
      },
    ];
  }, []);
  const adRows: AdRow[] = useMemo(() => {
    return [
      {
        id: "1",
        meta_id: "1",
        ad_name: "Ad 1",
        adset_id: "1",
        adset_name: "Ad Set 1",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        client_facing: false,
        approval_stage: MBApprovalStage.NONE,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "2",
        meta_id: "2",
        ad_name: "Ad 2",
        adset_id: "1",
        adset_name: "Ad Set 1",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        client_facing: false,
        approval_stage: MBApprovalStage.NONE,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "3",
        meta_id: "3",
        ad_name: "Ad 3",
        adset_id: "2",
        adset_name: "Ad Set 2",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        client_facing: false,
        approval_stage: MBApprovalStage.NONE,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "4",
        meta_id: "4",
        ad_name: "Ad 4",
        adset_id: "2",
        adset_name: "Ad Set 2",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        client_facing: true,
        approval_stage: MBApprovalStage.NONE,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "5",
        meta_id: "5",
        ad_name: "Ad 5",
        adset_id: "3",
        adset_name: "Ad Set 3",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        client_facing: true,
        approval_stage: MBApprovalStage.NONE,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "6",
        meta_id: "6",
        ad_name: "Ad 6",
        adset_id: "3",
        adset_name: "Ad Set 3",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        client_facing: true,
        approval_stage: MBApprovalStage.CHANGES_REQUESTED,
        notes: "Needs updates",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "7",
        meta_id: "7",
        ad_name: "Ad 7",
        adset_id: "4",
        adset_name: "Ad Set 4",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        client_facing: true,
        approval_stage: MBApprovalStage.APPROVED,
        notes: "LGTM!",
        adsmanager_status: AdsManagerStatus.LIVE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "8",
        meta_id: "8",
        ad_name: "Ad 8",
        adset_id: "4",
        adset_name: "Ad Set 4",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        client_facing: true,
        approval_stage: MBApprovalStage.IN_REVIEW,
        adsmanager_status: AdsManagerStatus.LIVE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "9",
        meta_id: "9",
        ad_name: "Ad 9",
        adset_id: "1",
        adset_name: "Ad Set 1",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        client_facing: true,
        approval_stage: MBApprovalStage.IN_REVIEW,
        adsmanager_status: AdsManagerStatus.PAUSED,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "10",
        meta_id: "10",
        ad_name: "Ad 10",
        adset_id: "1",
        adset_name: "Ad Set 1",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        client_facing: true,
        approval_stage: MBApprovalStage.IN_REVIEW,
        adsmanager_status: AdsManagerStatus.PAUSED,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
    ];
  }, []);

  return (
    <MetaBuyingProvider>
      <Page
        app2Redesign
        title="Meta Buying"
        pageType="Meta Buying"
        navs={NAVS}
        selectedNav={tab}
        onNav={goToTab}
      >
        <div className="metaBuyingPage">
          {loading ? (
            <FullPageSpinner />
          ) : (
            <Router className="fullPageRouter">
              <MetaBuyingCreate
                default
                path={PageTab.CREATE}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={adAccountOptions}
                namingConventions={namingConventions}
                client={cid}
                granularity={granularity}
                dimensions={dimensions}
              />
              <MetaBuyingUpload path={PageTab.UPLOAD} />
              <MetaBuyingDrafts
                path={PageTab.DRAFTS}
                isInternal={isInternal}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={adAccountOptions}
                campaignRows={
                  campaignRows
                  // campaignRowsMap ? campaignRowsMap[selectedAdAccount.account_id] || [] : []
                }
                adSetRows={adSetRows}
                adRows={adRows}
              />
              <MetaBuyingPublished
                path={PageTab.PUBLISHED}
                isInternal={isInternal}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={adAccountOptions}
                campaignRows={[]}
                adSetRows={adSetRows}
                adRows={adRows}
              />
            </Router>
          )}
        </div>
      </Page>
    </MetaBuyingProvider>
  );
});

export default MetaBuying;
