import "./InvestToIncrease.scss";
import { useEffect, useLayoutEffect, useState, useRef, useCallback } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import MoreInfo from "../../MMM/MoreInfo";
import { SnapshotChart, IconToggleButton, DownloadDropdown } from "../../Components";
import MetricsTable from "../../SingleChannel/MetricsTable/MetricsTable";
import { Column, DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import * as R from "ramda";
import { roasDataFormatter } from "../../MMM/ModelResults/DataFormatters";
import { standardizeMetricName, snapShotDataSort } from "./BrandImpactUtils";
import { Neutral400 } from "../../utils/colors";
import { MdOutlineTableRows, MdShowChart } from "react-icons/md";
import { downloadPNG } from "../../utils/download-utils";

interface NextBestDollar {
  label: string;
  name: string;
  value: number;
}

interface SpendAndEffect {
  label: string;
  label2: string;
  name: string;
  value: number;
  value2: number;
}

interface InvestToIncreaseProps {
  company: string;
  groupByMetric: string;
  spendAndEffect: SpendAndEffect[];
  nextBestThousandDollars: NextBestDollar[];
  nextBestThousandDollarsAll: Record<string, NextBestDollar[]>;
  groupByMetricOptions: Array<{ label: string; value: string }>;
}

const ROW_HEIGHT = 50;
const TABLE_HEIGHT = 320;

const SPEND_AND_EFFECT_SORT_OPTIONS = [
  { value: "Alphabetical" },
  { value: "Spend to effect ratio" },
  { value: "Spend: High to low" },
  { value: "Spend: Low to high" },
  { value: "Effect: High to low" },
  { value: "Effect: Low to high" },
] as const;

const dataColumns: Column[] = [
  { label: "Awareness", dataVarName: "awareness", id: "column-1" },
  { label: "Buzz", dataVarName: "buzz", id: "column-2" },
  { label: "Consideration", dataVarName: "consideration", id: "column-3" },
  { label: "Intent", dataVarName: "intent", id: "column-4" },
];

const DimensionColumns: DimensionColumn[] = [
  {
    id: "name",
    dimensionVarName: "Channel",
    label:
      "Paid Display, Paid Search Nb, Paid Shopping Nb, Meta, Tik Tok, Streaming Video, Linear Television, Affiliate",
    dimensionTypeName: "Channel",
    divider: false,
  },
];

const fixData = (data: any[]) => {
  const awarenessData: any[] = [];
  const buzzData: any[] = [];
  const considerationData: any[] = [];
  const intentData: any[] = [];

  data.forEach(item => {
    const formattedValue = {
      name: item.name,
      value: (parseFloat(item.value) * 10000000).toFixed(2),
      label: (parseFloat(item.value) * 10000000).toFixed(2),
    };

    switch (item.label.brandHealthMetricValue) {
      case "awareness":
        awarenessData.push(formattedValue);
        break;
      case "buzz":
        buzzData.push(formattedValue);
        break;
      case "consideration":
        considerationData.push(formattedValue);
        break;
      case "intent":
        intentData.push(formattedValue);
        break;
      default:
        break;
    }
  });
  const platforms = [
    "Paid Display",
    "Paid Search - Non-brand",
    "Paid Shopping - Non-brand",
    "Meta",
    "TikTok",
    "Streaming Video",
    "Linear TV",
    "Affiliate",
  ];

  const tableData = platforms.map(platform => {
    const row = [
      awarenessData.find(d => d.name === platform) || { value: 0, label: "--" },
      buzzData.find(d => d.name === platform) || { value: 0, label: "--" },
      considerationData.find(d => d.name === platform) || { value: 0, label: "--" },
      intentData.find(d => d.name === platform) || { value: 0, label: "--" },
    ];
    return row;
  });
  return tableData;
};

const InvestToIncrease: React.FC<InvestToIncreaseProps> = ({
  groupByMetric,
  spendAndEffect,
  nextBestThousandDollars,
  nextBestThousandDollarsAll,
}) => {
  const [spendAndEffectSorted, setSpendAndEffectSorted] = useState<any[]>([]);
  const [nextBestThousandDollarsSorted, setNextBestThousandDollarsSorted] = useState<any[]>([]);
  const [snapChartSortValueSpendEffect, setSnapChartSortValueSpendEffect] = useState(
    "Spend: High to low"
  );
  const [snapChartSortValueNextBest, setSnapChartSortValueNextBest] = useState("Highest to lowest");
  const [graphView, setGraphView] = useState(true);

  const [metricsTableData, setMetricsTableData] = useState<any[]>([]);
  const [metricDimensionsData, setMetricDimensionsData] = useState<any[]>([]);
  const [tableWidth, setTableWidth] = useState(500);
  const containerRef = useRef(null);
  const [rerenderTable, setRerenderTable] = useState(false);

  useEffect(() => {
    if (!R.isEmpty(nextBestThousandDollars)) {
      const sortedNextBestData = snapShotDataSort(
        nextBestThousandDollars,
        snapChartSortValueNextBest
      );
      const formattedAndSortedNextBestDollar = sortedNextBestData.map(item => {
        const formattedName = item.name
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return {
          ...item,
          name: formattedName,
        };
      });
      setNextBestThousandDollarsSorted(formattedAndSortedNextBestDollar);
    }
  }, [nextBestThousandDollars, snapChartSortValueNextBest]);

  useEffect(() => {
    if (!R.isEmpty(spendAndEffect)) {
      const sortedSpendAndEffect = snapShotDataSort(spendAndEffect, snapChartSortValueSpendEffect);
      const formattedAndSortedSpendAndEffect = sortedSpendAndEffect.map(item => {
        const formattedName = item.name
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return {
          ...item,
          name: formattedName,
        };
      });
      setSpendAndEffectSorted(formattedAndSortedSpendAndEffect);
    }
  }, [spendAndEffect, snapChartSortValueSpendEffect]);

  useLayoutEffect(() => {
    const newTableWidth = containerRef.current
      ? (containerRef.current as any).offsetWidth
      : undefined;
    if (newTableWidth) {
      setTableWidth((containerRef.current as any).offsetWidth - 5);
    }
    if (rerenderTable) {
      setRerenderTable(false);
    }
  }, [rerenderTable]);

  useEffect(() => {
    if (!R.isEmpty(nextBestThousandDollarsAll)) {
      const result = Object.entries(nextBestThousandDollarsAll).flatMap(([key, arr]) =>
        (arr as { label: string; name: string; value: number }[]).map(obj => ({
          ...obj,
          name: obj.name
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          label: { brandHealthMetricValue: key },
        }))
      );
      const tableData = fixData(result);
      setMetricsTableData(tableData);
      const formattedData = roasDataFormatter({
        data: result,
        tableHeaders: DimensionColumns,
        disabledPlatform: false,
      });
      setMetricDimensionsData(formattedData);
    }
  }, [nextBestThousandDollarsAll]);

  const pngDownload = useCallback(async () => {
    await downloadPNG("metricsTable", "next_best_$1000");
  }, []);

  return (
    <WidgetContainer
      collapsible
      header={`Which channels should we invest in to increase ${standardizeMetricName(
        groupByMetric
      )}?`}
      subHeader={
        <>
          {
            "Below is the marginal impact of each media dollar, given the existing allocation of resources."
          }
          <MoreInfo rightLabel="More info" size="sm">
            {
              "The marginal impact of a media dollar is based on where one is on the channel saturation curve (see section below). Next-dollar effectiveness is the slope of the line tangent to the saturation curve at the current level of investment. Intuitively, you want to invest where the slope of the curve is steepest."
            }
          </MoreInfo>
        </>
      }
    >
      <div className="investMain">
        <div className="investLeft">
          <SnapshotChart
            data={spendAndEffectSorted}
            dropdownOptions={SPEND_AND_EFFECT_SORT_OPTIONS}
            dropdownValue={snapChartSortValueSpendEffect}
            setDropdownValue={setSnapChartSortValueSpendEffect}
            doubleBarChart={true}
            header={undefined}
            title={"Spend and Effect"}
            valueFormatter={value => (value === null ? "--" : `${(value * 100).toFixed(0)}%`)}
          />
        </div>
        <div className="investRight">
          {graphView && (
            <SnapshotChart
              title={"Next Best Dollar"}
              data={nextBestThousandDollarsSorted}
              tooltipItems={{
                tooltipText: "One basis point is equal to 1/100th of 1 percent",
                beforeTooltipText: "Relative Value",
                color: Neutral400,
              }}
              dropdownOptions={[
                { value: "Alphabetical" },
                { value: "Highest to lowest" },
                { value: "Lowest to highest" },
              ]}
              dropdownValue={snapChartSortValueNextBest}
              setDropdownValue={setSnapChartSortValueNextBest}
              doubleBarChart={false}
              header={`The most efficient channel investment strategy to raise ${groupByMetric} by 1% point. Channels allocated larger investments drive the most dollar for dollar incremental impact at current investment levels`}
              additionalRightActions={
                <div>
                  <IconToggleButton
                    options={[
                      { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
                      { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
                    ]}
                    size="sm"
                    selectedOption={graphView ? "showGraph" : "showTable"}
                    onChange={() => setGraphView(prev => !prev)}
                  />
                </div>
              }
              valueFormatter={value => (value === null ? "--" : `${value.toFixed(2)}`)}
            />
          )}
          {!graphView && !R.isEmpty(metricsTableData) && !R.isEmpty(metricDimensionsData) && (
            <ChartContainer
              enableHoverDesign
              height={405}
              title="Next Best Dollar"
              rightActions={
                <div className="investToIncreaseRightActions">
                  <IconToggleButton
                    options={[
                      { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
                      { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
                    ]}
                    size="sm"
                    selectedOption={graphView ? "showGraph" : "showTable"}
                    onChange={() => setGraphView(prev => !prev)}
                  />
                  <DownloadDropdown size="sm" onClickOptions={[pngDownload]} />
                </div>
              }
            >
              <MetricsTable
                aggregateData={[]}
                dataColumns={dataColumns}
                dimensionColumns={DimensionColumns}
                dimensionData={metricDimensionsData}
                tableData={metricsTableData}
                width={tableWidth}
                overrideParams={{
                  maxColWidth: 130,
                  rowHeight: ROW_HEIGHT,
                }}
                height={TABLE_HEIGHT}
              />
            </ChartContainer>
          )}
        </div>
      </div>
    </WidgetContainer>
  );
};

export default InvestToIncrease;
