import "../IncrementalityPlaybook.scss";
import { IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Linear TV";

const LinearTV = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Linear television, being a form of broadcast media, is among the most challenging
              channels in which to design and execute a clean test of incrementality. This is due to
              the nature of ad delivery (broadcast), the lack of user-level signifiers, and the
              expense of the experiment.
            </div>
            <br />
            <div className="basicText">For these reasons, we emphasize:</div>
            <ul className="basicList">
              <li>
                Incrementality tests of linear television are only suitable for clients who are, or
                who hope to be, very scaled spenders in the channel. Otherwise the cost of the
                experiment will be too high relative to the value of the learning.
              </li>
              <li>
                Clients who are interested should be prepared to make a significant six-figure
                investment in order to run the experiment. See below for additional details on
                estimating experimental cost.
              </li>
              <li>
                Designing and executing an incrementality test for linear TV will require active
                support from econometrics and media. There are no self-serve options available.
              </li>
            </ul>
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Incrementality Testing Approaches",
                "Media Options & Considerations",
                "Sample Outputs",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "Geo Test",
                  Description: (
                    <ul className="basicList">
                      <li>
                        The Geo Test is a comparative experiment where the treatment and control
                        groups are a selection of the 210 U.S.{" "}
                        <a href="https://markets.nielsen.com/us/en/contact-us/intl-campaigns/dma-maps/#:~:text=DMA%20(Designated%20Market%20Area)%20regions,geographic%20areas%20within%20their%20business.">
                          DMAs
                        </a>
                        , the most granular level at which TV media can feasibly be turned on & off.
                      </li>
                      <li>
                        The test consists of withholding TV media from the control DMAs while
                        running TV –{" "}
                        <a href="https://www.bloomads.com/blog/broadcast-local-cable-whats-the-difference/">
                          local broadcast and/or local cable
                        </a>{" "}
                        – in the treatment DMAs.
                      </li>
                      <li>
                        The key idea is that the DMAs assigned to receive TV media are selected at
                        random, which lays the foundation for{" "}
                        <a href="https://towardsdatascience.com/why-are-randomized-experiments-the-gold-standard-in-causal-inference-f3fa240a1d02">
                          justified causal inference
                        </a>
                        .
                      </li>
                      <li>
                        The treatment effect is estimated by comparing the KPI trends in the treated
                        DMAs to the KPI trends in the control DMAs, often using a{" "}
                        <a href="https://en.wikipedia.org/wiki/Difference_in_differences">
                          difference-in-difference
                        </a>{" "}
                        analytical framework.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        <a href="https://en.wikipedia.org/wiki/Causal_inference">
                          Causal inference
                        </a>{" "}
                        is very clean. Assuming proper randomization of DMAs into treatment and
                        control, we are manipulating a single variable (running TV ads) while all
                        other characteristics can be assumed to be equivalent between the two
                        groups. This allows us to confidently attribute any observed differences in
                        behavior to the treatment.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Running this kind of test can be very costly; see below for details on
                        experiment cost estimation, but $250k and up is very common.
                      </li>
                      <li>
                        Running this kind of test can be time consuming; typically a minimum or four
                        weeks, and often up to eight weeks.
                      </li>
                      <li>
                        There can be concerns over{" "}
                        <a href="https://en.wikipedia.org/wiki/External_validity">
                          external validity
                        </a>
                        , i.e. our ability to confidently extrapolate from the narrow experiment to
                        the broader campaign, because these tests often entail the use of local
                        broadcast media. This external validity concern can be mitigated somewhat by
                        utilizing local cable (see Description at left), but this raises costs even
                        further.
                      </li>
                    </ul>
                  ),
                },
                {
                  Method: "Matched Market Test",
                  Description: (
                    <ul className="basicList">
                      <li>
                        A variation on the Geo Test, a Matched Market Test involves choosing a small
                        number of DMAs (typically 2 - 12) for assignment to treatment and the
                        construction of synthetic controls to serve as our holdout.
                      </li>
                      <li>
                        Oftentimes the test design entails composing the treatment group of some “A
                        markets” (e.g. New York), some “B markets” (e.g. Nashville), and some “C
                        markets” (e.g. Topeka); and then constructing{" "}
                        <a href="https://www.statology.org/matched-pairs-design/">
                          matched controls
                        </a>{" "}
                        to serve as the held out comparison.
                      </li>
                      <li>
                        These matched controls are typically constructed based on observable
                        characteristics such as population, average income, ethnic composition,
                        average age, etc.
                      </li>
                      <li>
                        Similar to a Geo Test, the treatment effect is estimated by comparing the
                        KPI trends in the treated DMAs to the KPI trends in their respective matched
                        control DMAs, often using a{" "}
                        <a href="https://en.wikipedia.org/wiki/Difference_in_differences">
                          difference-in-difference
                        </a>{" "}
                        analytical framework.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Typically less expensive than a Geo Test due to the smaller number of DMAs
                        that are assigned to receive media.
                      </li>
                      <li>
                        Allows for hand-picking live DMAs. In other words, if a client is set on
                        running TV in certain markets, this approach can accommodate that desire by
                        constructing appropriate synthetic controls.
                      </li>
                      <li>
                        <a href="https://en.wikipedia.org/wiki/Causal_inference">
                          Causal inference
                        </a>{" "}
                        is pretty clean, though typically less robust than with a Geo Test.
                        Attenuated robustness is due to the smaller sample and the heightened risk
                        of differences on latent (non-observable) characteristics, e.g. regional
                        brand preferences or other group traits that may differ across treatment and
                        control but are very difficult to measure directly.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        While less costly than a Geo Test, it will be significantly more expensive
                        than a digital platform with the same learning objective.
                      </li>
                      <li>
                        Like a Geo Test, it will be time consuming, on the order of four to eight
                        weeks.
                      </li>
                      <li>
                        Compared with a Geo Test,{" "}
                        <a href="https://en.wikipedia.org/wiki/External_validity">
                          external validity
                        </a>{" "}
                        is an even larger concern here because, in addition to the the inventory
                        class (see above), the DMAs chosen in designing the experiment are very
                        unlikely to be representative of the U.S. as a whole.
                      </li>
                    </ul>
                  ),
                },
                {
                  Method: "Pulse Test",
                  Description: (
                    <ul className="basicList">
                      <li>
                        A Pulse Test involves turning national TV media on & off (possibly multiple
                        times) to understand whether meaningful differences in KPI outcomes
                        correlate with those on/off periods.
                      </li>
                      <li>
                        A typical cadence looks like:
                        <ul>
                          <li>
                            Media Burnout Period – client goes dark on TV for ~2 weeks to allow TV
                            effects to revert to baseline
                          </li>
                          <li>Pulse Up – client runs national TV media for ~2 - 6 weeks</li>
                          <li>
                            Media Burnout Period – client goes dark on TV again for at least ~2
                            weeks to allow TV effects to revert to baseline
                          </li>
                        </ul>
                      </li>
                      <li>
                        This approach entails setting up an{" "}
                        <a href="https://en.wikipedia.org/wiki/Event_study">event study</a>, i.e. an
                        attempt to assess the impact of an “event” (turning TV on/off) on an outcome
                        of interest (a KPI).
                      </li>
                      <li>
                        It is important to note that short-horizon event studies, i.e. those where
                        the response to the event occurs rapidly, are more reliable than
                        long-horizon event studies.
                      </li>
                      <li>
                        The treatment effect is typically estimated by constructing a multivariate
                        model to estimate a{" "}
                        <a href="http://bayes.cs.ucla.edu/PRIMER/primer-ch4.pdf">counterfactual</a>,
                        and comparing the realized results with that counterfactual.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        This type of test typically utilizes less costly media than a Geo Test or a
                        Matched Market Test. Because it is run nationally, national cable can be
                        used and its CPMs are far lower than local broadcast or local cable.
                        <ul>
                          <li>
                            NB: this does not necessarily mean the test will be less costly overall;
                            this will depend on test design
                          </li>
                        </ul>
                      </li>
                      <li>
                        We have stronger grounds for{" "}
                        <a href="https://en.wikipedia.org/wiki/External_validity">
                          external validity
                        </a>{" "}
                        with this type of test. This is because the type of media utilized in the
                        experiment – national cable – is the same as the type of media most often
                        used in an ongoing, evergreen TV campaign, and because we are serving media
                        to the entire country rather than a small subset.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        <a href="https://en.wikipedia.org/wiki/Causal_inference">
                          Causal inference
                        </a>{" "}
                        is much less clean with this type of testing approach.
                        <ul>
                          <li>
                            Unlike an experiment involving randomized assignment, we’re not changing
                            just a single variable; we know for certain that we’re changing at least
                            two variables, TV (on|off) and time (when exposure and non-exposure
                            occurred).
                          </li>
                          <li>
                            Other factors can also change over the course of the test that can muddy
                            our understanding of cause and effect: seasonality can change, product
                            availability can change, weather can change, major holidays can
                            intervene, etc. While we can statistically{" "}
                            <a href="https://en.wikipedia.org/wiki/Controlling_for_a_variable">
                              control
                            </a>{" "}
                            for some of these, it is not possible to perfectly adjust for these
                            effects.
                          </li>
                          <li>
                            The “media burnout” periods can’t be specified with high precision. A
                            couple of weeks is a useful rule of thumb, but this can vary a lot from
                            advertiser to advertiser, and it can’t be empirically estimated at all
                            for an advertiser that is new to TV.
                          </li>
                        </ul>
                      </li>
                      <li>
                        It is best for testing hygiene if clients hold other marketing activities
                        roughly constant over the testing period. This can mean asking clients to
                        significantly curtail the flexibility/agility of their marketing programs
                        for up to ten weeks, which is never ideal and often not possible. As above,
                        variation in these activities can be{" "}
                        <a href="https://en.wikipedia.org/wiki/Controlling_for_a_variable">
                          controlled
                        </a>{" "}
                        for analytically, but this is not a panacea.
                      </li>
                    </ul>
                  ),
                },
              ],
              R.filter(header => header.name !== "Method Type", methodHeaders),
              900
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  "Media Type": "Local Broadcast",
                  Description: (
                    <ul className="basicList">
                      <li>
                        Local Broadcast refers to local insertion – at the DMA level – on a
                        broadcast network. A broadcast network is typically a local affiliate of a
                        national broadcaster like ABC, CBS, FOX, or NBC.
                      </li>
                      <li>
                        National broadcasters typically own the local broadcasters carrying their
                        programming in the largest markets; these are referred to as{" "}
                        <a href="https://en.wikipedia.org/wiki/Network_affiliate#Network_owned-and-operated_stations">
                          owned & operated, or “O&O”
                        </a>{" "}
                        stations. For example, WABC, WCBS, and WNBC are all O&O stations in New York
                        City owned by their respective parents. Outside the largest markets,
                        independently owned affiliate stations carry programming from the national
                        broadcasters.
                      </li>
                      <li>
                        Broadcast is increasingly a medium for older viewers – in 2023, the median
                        age of a broadcast TV is approximately 59 - 61 years old.
                      </li>
                    </ul>
                  ),
                  Cost: (
                    <ul className="basicList">
                      <li>
                        Local broadcast is typically priced at a $15 - $30 CPM on a household basis,
                        usually netting out toward the lower end of the range.
                      </li>
                    </ul>
                  ),
                },
                {
                  "Media Type": "Local Cable",
                  Description: (
                    <ul className="basicList">
                      <li>
                        Local Cable refers to local insertion – at the DMA level – on a cable
                        network (ESPN, HGTV, Fox News, etc.).
                      </li>
                      <li>
                        In a{" "}
                        <a href="https://digiday.com/future-of-tv/wtf-are-tv-carriage-fees/">
                          carriage agreement
                        </a>
                        , the station group (e.g. Discovery) and the cable operator (e.g. Comcast)
                        divvy up the ad time, with two minutes per hour being an industry custom
                        (but not a formal rule or law) that the cable operator can turn around and
                        sell to local advertisers. Classic local advertisers would be local car
                        dealerships, restaurants, etc.
                      </li>
                      <li>
                        Most local cable is bought through{" "}
                        <a href="https://ampersand.tv/who-we-are/">Ampersand</a>, a joint venture
                        among the three largest cable operators in the U.S. – Comcast, Charter, and
                        Cox.
                      </li>
                      <li>
                        While not quite as grey as broadcast, cable TV viewers still skew older – in
                        2023, the median age of a cable TV viewer is approximately 51 - 55 years
                        old.
                      </li>
                    </ul>
                  ),
                  Cost: (
                    <ul className="basicList">
                      <li>
                        Local cable is typically priced at a $15 - $30 CPM on a household basis,
                        usually netting out toward the higher end of the range.
                      </li>
                    </ul>
                  ),
                },
                {
                  "Media Type": "National Cable",
                  Description: (
                    <ul className="basicList">
                      <li>
                        National Cable refers to national insertion – i.e. reaching the entire U.S.
                        – on a cable network (ESPN, HGTV, Fox News, etc.).
                      </li>
                      <li>
                        National Cable inventory is almost always sourced from the network directly.
                      </li>
                      <li>
                        As above, the median age of a cable TV viewer is approximately 51 - 55 years
                        old.
                      </li>
                    </ul>
                  ),
                  Cost: (
                    <ul className="basicList">
                      <li>
                        National cable is typically priced at a $5 - $15 CPM on a household basis
                      </li>
                    </ul>
                  ),
                },
              ],
              [
                {
                  flex: 1,
                  label: "Media Type",
                  name: "Media Type",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return (
                      //Reuse the channel class from the Playbook.scss
                      <div className="channelNames">
                        <div className="channelName single">
                          <div className="label">{row["Media Type"]}</div>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  flex: 5,
                  label: "Description",
                  name: "Description",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return <div className="bulletedList">{row.Description}</div>;
                  },
                },
                {
                  flex: 5,
                  label: "Cost",
                  name: "Cost",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return <div className="bulletedList">{row.Cost}</div>;
                  },
                },
              ],
              350
            )}
          </div>
        }
        id={"media-options-&-considerations"}
        pageTitle={PAGE_TITLE}
        title={"Media Options & Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicHeader">Pulse Test</div>
            <iframe
              allowFullScreen
              src="https://drive.google.com/file/d/1beCXGeTor-kB9HSwPSA2dm1qpoUKLYz2/preview?authuser=0"
              title={"Pulse Test"}
            />
            <br />
            <div className="basicHeader">Matched Market Test</div>
            <iframe
              allowFullScreen
              src="https://drive.google.com/file/d/1gLE5foFEzd6IvRibP49XRXsKgDtjhDqX/preview?authuser=0"
              title={"Pulse Test"}
            />
          </div>
        }
        id={"sample-outputs"}
        pageTitle={PAGE_TITLE}
        title={"Sample Outputs"}
      />
    </div>
  );
});

export default LinearTV;
