import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Page } from "../Components";
import { SetError, useSetError } from "../redux/modals";
import AmcInstanceMapping from "./Tools/AmcInstanceMapping";
import "./CommerceTool.scss";

export interface CommerceToolProps {
  utils: CommerceUtilFunctions;
}

export interface CommerceUtilFunctions {
  setError: SetError;
  setModalMessage: React.Dispatch<React.SetStateAction<string>>;
  setShowModalConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
}

const CommerceTool: React.FC = () => {
  const setError = useSetError();
  const [modalMessage, setModalMessage] = useState("");
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);

  const utils = { setError, setModalMessage, setShowModalConfirmation };

  const ModalConfirmation = ({ show }) => {
    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowModalConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              setShowModalConfirmation(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Page title="Commerce Tools" pageType="Commerce Tools">
      <div className="commerceToolContainer">
        <div>
          <div className="commerceToolRow">
            <AmcInstanceMapping utils={utils} />
          </div>
        </div>
      </div>
      <ModalConfirmation show={showModalConfirmation} />
    </Page>
  );
};

export default CommerceTool;
