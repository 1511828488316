import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import * as uuid from "uuid";

import {
  PlanRow,
  RowEdit,
  AVAILS,
  makeKey,
  TYPES_TO_NAMES,
  TYPES_MAP,
  LinearImpressionOverrides,
} from "@blisspointmedia/bpm-types/dist/LinearBuying";
import {
  CURRENT_WEEK_END,
  DATE_FORMAT,
  DAYS_OF_WEEK,
  LOCAL_AUDIENCE_CORRECTION_FACTOR,
} from "./linearBuyingConstants";
import { CreativeMap, CreativeMapItem } from "../redux/creative";
import { CreativePercentMap } from "./Modals/CreativeAllocationModalV2";

export interface FormattedRow extends PlanRow {
  formattedType: string;
  totalSpend: number;
  impressions: number;
  cpm: number;
  trp: number;
  orderedCpm?: number;
  rows: FormattedRow[];
}

export interface RowsByWeek {
  [week: string]: PlanRow[];
}

export interface EditsMap {
  [key: string]: PlanRow;
}

export interface NetworkMetaData {
  network: string;
  rows: PlanRow[];
  isNewNetwork: boolean;
  hasNewRows: boolean;
}

export interface NetworkMetaDataMap {
  [network: string]: NetworkMetaData;
}

export interface RotationPricing {
  network: string;
  avail: typeof AVAILS[number];
  cost30s: number;
  rotationName: string;
  daypartBegin: string;
  daypartEnd: string;
  daysOfWeek: string[];
  rotationLabel: string;
  status: string;
  market: string | null;
}

export interface RotationsAndPricing {
  [network: string]: {
    [avail: string]: RotationPricing[];
  };
}

export interface ExtendEditModalData {
  row: PlanRow;
  edits: RowEdit;
  selectedEditToExtend: string | null;
}

interface ConstraintRow {
  network: string;
  avail?: string;
  length?: string;
  rotation?: string;
  length15sUnits?: string;
  length30sUnits?: string;
  length60sUnits?: string;
  spend?: string;
}

export interface Constraints {
  avoid: Record<string, ConstraintRow[]>;
  maximum: Record<string, ConstraintRow[]>;
}

interface AudienceEstimateByTimeBlock {
  [halfHourBlock: string]: number;
}

export interface NielsenEstimates {
  [network: string]: AudienceEstimateByTimeBlock;
}

export interface SpendByAvailLength {
  avail: typeof AVAILS[number];
  totalPercentByAvail: number;
  totalSpendByAvail: number;
  15: number | undefined;
  30: number | undefined;
}

interface SummaryData {
  totalSpend: number;
  byLength: SpendObj[];
  byNetwork: SpendObj[];
  byRotation: SpendObj[];
  byCreative: SpendObj[];
  byAvailLength: SpendByAvailLength[];
}

/**
 * Corrects the format of a time in 24hr format.
 * Start and end times that come from rotations and pricing don't have a leading 0 for times before 10:00,
 * which doesn't follow the 24hr time standard, and messes up the time input.
 * e.g. 9:00 -> 09:00
 */
export const time24hr = (daypart: string): string => {
  if (!daypart) {
    return "";
  }
  let time = R.pipe(Dfns.parse(new Date(), "H:mm"), Dfns.format("HH:mm"))(daypart);
  return time;
};

/** Checks dates selected to see if more than one week of data is being displayed */

export const isMultiDatePicker = (weeks: string[]): boolean => {
  if (weeks.length > 1) {
    return true;
  }
  return false;
};

/**
 * Receives a date string and returns the Monday of the following week.
 */
export const getNextWeek = (week: string): string => {
  return R.pipe(
    Dfns.parseISO,
    Dfns.startOfISOWeek,
    Dfns.addWeeks(1),
    Dfns.format(DATE_FORMAT)
  )(week);
};

/**
 * Calculates total spend and spot counts for a network (National, Local, and Total).
 */
export const getNetworkTotals = (rows: PlanRow[]): Record<string, number> => {
  let localSpend = 0;
  let localSpots = 0;
  let nationalSpend = 0;
  let nationalSpots = 0;
  let totalSpend = 0;
  let totalSpots = 0;
  for (let row of rows) {
    const { avail, cost, count } = row;
    let spend = cost * count;
    totalSpend += spend;
    totalSpots += count;
    if (avail === "N") {
      nationalSpend += spend;
      nationalSpots += count;
    }
    if (avail === "L") {
      localSpend += spend;
      localSpots += count;
    }
  }
  return { localSpend, localSpots, nationalSpend, nationalSpots, totalSpend, totalSpots };
};

/**
 * Since new rows for the same network are initialized to the same values, their keys
 * will be the same (which makes editing new rows not work correctly). Adding a unique id to the
 * end of the key for new rows.
 */
export const makeKeyWithUUID = (row: PlanRow): string => {
  let key = makeKey(row);
  let keyWithUUID = `${key}_${uuid.v4()}`;
  return keyWithUUID;
};

/**formats to week of "M/d/yyyy" from iso */
export const weekFormatter = (date: string): string => {
  return `Spots Week of ${Dfns.format("M/d/yyyy", Dfns.parseISO(date))}`;
};

/**
 * Merges plan rows and edits to reflect the current pending state for a given week.
 */
export const mergeRowsAndEdits = (rows: PlanRow[], editsMap: EditsMap): PlanRow[] => {
  if (!rows) {
    return [];
  }
  let mergedRowsAndEdits: PlanRow[] = [];

  for (let row of rows) {
    const { key } = row;
    if (editsMap[key]) {
      let rowWithMergedEdits = { ...editsMap[key], ...editsMap[key].edits };
      mergedRowsAndEdits.push(rowWithMergedEdits);
    } else {
      mergedRowsAndEdits.push(row);
    }
  }

  return mergedRowsAndEdits;
};

/**
 * Merges plan rows and edits to reflect the current pending state for a given week, plus adds
 * new rows that have been added to the plan.
 */
export const mergeAllRows = (
  rows: PlanRow[],
  editsMap: EditsMap,
  newRows: Record<string, PlanRow>
): PlanRow[] => {
  const rowsByWeek = R.groupBy(R.prop("week"), rows);

  let dups = checkForDuplicateRows(rowsByWeek, editsMap, newRows);
  const mergedRowsAndEdits = mergeRowsAndEdits(rows, editsMap);
  if (dups.hasDuplicates) {
    const rowsToRemove = Object.values(dups.duplicates);
    let allRows: PlanRow[] = [...Object.values(newRows), ...mergedRowsAndEdits];
    return R.filter(row => !R.contains(row, rowsToRemove), allRows);
  }
  return [...Object.values(newRows), ...mergedRowsAndEdits];
};

/**
 * returns the plan row when searching by week
 */
export const getPlanRow = (planRows: PlanRow[], key: string): PlanRow | undefined => {
  return planRows.find(row => row.week === key);
};

/**
 * returns the plan row when searching by week
 */
export const getFormattedRow = (
  formattedRows: FormattedRow[],
  key: string
): FormattedRow | undefined => {
  return formattedRows.find(row => row.week === key);
};

export const isNew = (formattedRows: FormattedRow[], key: string): boolean | undefined => {
  if (formattedRows) {
    let individualRow = formattedRows.find(row => row.week === key);
    return individualRow?.isNewRow ? individualRow?.isNewRow : false;
  }
  return false;
};
/**
 * Check if there are any duplicate rows.
 */
export const checkForDuplicateRows = (
  rowsByWeek: RowsByWeek,
  editsMap: EditsMap,
  newRows: Record<string, PlanRow>
): { hasDuplicates: boolean; lines: JSX.Element; duplicates: Record<string, PlanRow> } => {
  let uniqueKeyMap: Record<string, boolean> = {};
  let duplicates: Record<string, PlanRow> = {};
  // Existing rows merged with edits.
  for (let week of R.keys(rowsByWeek)) {
    for (let row of rowsByWeek[week]) {
      if (editsMap[row.key]) {
        row = { ...row, ...editsMap[row.key].edits };
      }
      let key = makeKey(row);
      if (uniqueKeyMap[key]) {
        duplicates[key] = row;
      } else {
        uniqueKeyMap[key] = true;
      }
    }
  }
  // New rows
  for (let row of R.values(newRows)) {
    let key = makeKey(row);
    if (uniqueKeyMap[key]) {
      duplicates[key] = row;
    } else {
      uniqueKeyMap[key] = true;
    }
  }

  let lines = (
    <div>
      {R.values(duplicates).map(row => {
        let str = `${row.week} - ${row.network} - ${row.avail} - ${row.rotation} - ${
          row.length
        } - ${TYPES_TO_NAMES[`${row.type}`]}`;
        return <div key={str}>{str}</div>;
      })}
    </div>
  );

  return { hasDuplicates: !R.isEmpty(duplicates), lines, duplicates };
};

/** creates a concatenation of the isci and week */
export const transformToHash = (
  week: string,
  isci: string,
  percent: number
): CreativePercentMap => {
  let creativePercent: CreativePercentMap = {};
  creativePercent[R.concat(week, isci)] = {
    week: week,
    isci: isci,
    percent: percent,
  };
  return creativePercent;
};

/**
 * Checks if the constraint is relevant to the plan row.
 */
const isRelevantConstraint = (row: PlanRow, constraint: ConstraintRow) => {
  const { avail: rowAvail, rotation: rowRotation } = row;
  const { avail: constraintAvail, rotation: constraintRotation } = constraint;

  // If there's no avail and rotation specified for this constraint, then it's relevant for all
  // plan rows for this network.
  if (!constraintAvail && !constraintRotation) {
    return true;
  }

  // If there's no avail specified for this constraint, but there is a rotation and it matches the
  // row rotation, then it is a relevant constraint.
  if (!constraintAvail && constraintRotation && constraintRotation === rowRotation) {
    return true;
  }

  // If there's no rotation specified for this constraint, but there is an avail and it matches the
  // row avail, then it is a relevant constraint.
  if (!constraintRotation && constraintAvail && constraintAvail === rowAvail) {
    return true;
  }

  // If there's both an avail and rotation specified for this constraint, and they both match the
  // row values, then it is a relevant constraint.
  if (
    constraintAvail &&
    constraintRotation &&
    constraintAvail === rowAvail &&
    constraintRotation === rowRotation
  ) {
    return true;
  }

  // If we don't hit on any of the above, then not relevant.
  return false;
};

/**
 * Checks if the plan row is violating the constraint.
 */
const isViolatingConstraint = (
  row: PlanRow,
  constraint: ConstraintRow,
  constraintType: "avoid" | "maximum"
) => {
  const { length15sUnits, length30sUnits, length60sUnits, spend, length } = constraint;

  if (constraintType === "avoid") {
    // We already checked for avail and rotation in isRelevantConstraint, so the last thing to
    // check for avoid constraints is if there's a matching length or if length isn't specified at all.
    if (!length) {
      return true;
    }
    if (length && parseInt(length) === row.length) {
      return true;
    }
  }
  if (constraintType === "maximum") {
    const rowSpend = row.count * row.cost;
    if (spend && rowSpend > parseInt(spend)) {
      return true;
    }
    if (length15sUnits && row.length === 15 && row.count > parseInt(length15sUnits)) {
      return true;
    }
    if (length30sUnits && row.length === 30 && row.count > parseInt(length30sUnits)) {
      return true;
    }
    if (length60sUnits && row.length === 60 && row.count > parseInt(length60sUnits)) {
      return true;
    }
  }

  return false;
};

/**
 * Check if any edits or new rows violate constraints.
 */
export const checkForConstraints = (
  editsMap: EditsMap,
  newRows: Record<string, PlanRow>,
  constraints: Constraints | undefined
): { isViolatingConstraints: boolean; rows: JSX.Element } => {
  if (!constraints) {
    return { isViolatingConstraints: false, rows: <div></div> };
  }
  let avoidViolations: Record<string, PlanRow> = {};
  let maximumViolations: Record<string, PlanRow> = {};

  const allRows = [...R.values(editsMap), ...R.values(newRows)];

  for (let row of allRows) {
    const networkAvoid = constraints.avoid[row.network];
    const networkMaximum = constraints.maximum[row.network];
    if (networkAvoid) {
      for (let avoid of networkAvoid) {
        if (isRelevantConstraint(row, avoid) && isViolatingConstraint(row, avoid, "avoid")) {
          avoidViolations[row.key] = row;
        }
      }
    }
    if (networkMaximum) {
      for (let maximum of networkMaximum) {
        if (isRelevantConstraint(row, maximum) && isViolatingConstraint(row, maximum, "maximum")) {
          maximumViolations[row.key] = row;
        }
      }
    }
  }

  const rows = (
    <div>
      <div>The following rows violate a constraint. Are you sure you want to save?</div>
      {!R.isEmpty(avoidViolations) && (
        <>
          <strong>AVOID</strong>
          {R.values(avoidViolations).map(row => {
            let str = `${row.week} - ${row.network} - ${row.avail} - ${row.rotation} - ${
              row.length
            } - ${TYPES_TO_NAMES[`${row.type}`]}`;
            return <div key={str}>{str}</div>;
          })}
        </>
      )}
      {!R.isEmpty(maximumViolations) && (
        <>
          <strong>MAXIMUM</strong>
          {R.values(maximumViolations).map(row => {
            let str = `${row.week} - ${row.network} - ${row.avail} - ${row.rotation} - ${
              row.length
            } - ${TYPES_TO_NAMES[`${row.type}`]}`;
            return <div key={str}>{str}</div>;
          })}
        </>
      )}
    </div>
  );

  return {
    isViolatingConstraints: !R.isEmpty(avoidViolations) || !R.isEmpty(maximumViolations),
    rows,
  };
};

/**
 * Check if a creative is marked live during the provided date range.
 */
export const isCreativeLive = (creative: CreativeMapItem, start: string, end: string): boolean => {
  const selectedWeekInterval = { start: new Date(start), end: new Date(end) };
  const { ranges } = creative;

  if (!ranges.length) {
    return false;
  }

  // Check the live ranges for the creative to see if the currently selected week overlaps
  // with any of the ranges. If so, it is live.
  let doIntervalsOverlap = false;
  for (let range of ranges) {
    const { startDate, endDate } = range;
    // If end date is null, it's live indefinitely. So I default it to far in the future.
    const interval = { start: new Date(startDate), end: new Date(endDate || "2100-01-01") };
    const doesOverlap = Dfns.areIntervalsOverlapping(interval, selectedWeekInterval);

    if (doesOverlap) {
      doIntervalsOverlap = true;
      break;
    }
  }

  return doIntervalsOverlap;
};

/**
 * Get list of all live creatives.
 */
export const getLiveCreatives = (
  creativeMap: CreativeMap | undefined,
  start: string,
  end: string
): CreativeMapItem[] => {
  if (!creativeMap) {
    return [];
  }
  const creativeMapIscis = R.keys(creativeMap);

  if (creativeMapIscis.length) {
    let options: CreativeMapItem[] = [];
    for (let isci of creativeMapIscis) {
      const creative = creativeMap[isci];
      const creativeIsLive = isCreativeLive(creative, start, end);

      if (creativeIsLive) {
        options.push(creative);
      }
    }
    return R.sortBy(R.prop("isci"), options);
  }
  return [];
};

export const processExtendSelected = (
  rowsToExtend: Record<string, PlanRow>,
  week: string,
  existingRows: PlanRow[]
): Record<string, PlanRow> => {
  // Create a map of existing keys for the selected week.
  let existingRowKeys = {};
  for (let row of existingRows) {
    existingRowKeys[row.key] = true;
  }
  // For each row that we're extending, copy it but change the week and key. Also set the plan_id,
  // order_id, and traffic_id to null because it should be treated like a new row.
  const newRows = {};
  for (let row of R.values(rowsToExtend)) {
    let rowWithNewWeek = {
      ...row,
      week,
      plan_id: null,
      order_id: null,
      traffic_id: null,
      isNewRow: true,
    };
    let key = makeKey(rowWithNewWeek);
    // If the key already exists, skip this row so we don't have duplicates for the same week.
    if (existingRowKeys[key]) {
      continue;
    }
    let rowWithNewKey = { ...rowWithNewWeek, key };
    newRows[key] = rowWithNewKey;
  }
  return newRows;
};

export const createNewRowFromSpots = (
  spots: number,
  aggregateRow: FormattedRow,
  week: string
): PlanRow => {
  let totalSpend = aggregateRow.cost * spots;
  let rowCombined: FormattedRow = {
    company: aggregateRow.company,
    channel: aggregateRow.channel,
    plan_id: null,
    network: aggregateRow.network,
    week: week,
    dow: aggregateRow.dow,
    avail: aggregateRow.avail,
    rotation: aggregateRow.rotation,
    market: aggregateRow.market,
    media_classification: aggregateRow.media_classification,
    daypart_start: aggregateRow.daypart_start,
    daypart_end: aggregateRow.daypart_end,
    notes: null,
    length: aggregateRow.length,
    count: spots,
    cost: aggregateRow.cost,
    type: aggregateRow.type,
    key: "",
    order_id: null,
    traffic_id: null,
    isNewNetwork: true,
    isNewRow: true,
    is_plan_pending: true,
    campaign_id: aggregateRow.campaign_id,
    formattedType: "",
    totalSpend: totalSpend,
    impressions: 0,
    cpm: 0,
    trp: 0,
    rows: [],
  };
  rowCombined.key = makeKeyWithUUID(rowCombined);
  return rowCombined;
};

//flatten rowsbyWeek into an array of rows so it can be iterated on all dates instead of one
export const flattenedRowsByWeek = (rowsByWeek: RowsByWeek): PlanRow[] => {
  return R.chain(R.identity, R.values(rowsByWeek));
};
interface CalculateAudienceInfo {
  row: PlanRow | AudienceInfo;
  nielsenEstimates: NielsenEstimates;
  universeEstimate?: number;
  assumedClearanceRate?: number | undefined;
}

export interface AudienceInfo {
  avail: typeof AVAILS[number];
  network: string;
  dow: string[];
  daypart_start: string;
  daypart_end: string;
  type: string;
  cost: number;
  count: number;
}

export const compareDates = (rowsByWeek: RowsByWeek, mondayDates: string[]): boolean => {
  if (!(Object.keys(rowsByWeek).length === 0)) {
    return R.all(R.has(R.__, rowsByWeek), mondayDates);
  } else {
    return false;
  }
};

//compare current week to date array to determine what dates are greater than current week
export const datesLessThanCurrentWeek = (weeks: string[]): boolean => {
  let greaterDates: string[] = [];
  for (let index = 0; index < weeks.length; index++) {
    if (weeks[index] > CURRENT_WEEK_END) {
      greaterDates.push(weeks[index]);
      index++;
    }
    return true;
  }
  return false;
};

//finds the first edited string metric
export const findOldMetricString = (rows: FormattedRow[], kpiString: string): string => {
  let kpi = "";
  for (let row of rows) {
    if (row?.edits) {
      kpi = row[kpiString];
      return kpi;
    }
  }
  return kpi;
};

//finds the first edited metric
export const findOldMetricNumber = (rows: FormattedRow[], kpiNum: string): number => {
  let kpi = 0;
  for (let row of rows) {
    if (row?.edits) {
      kpi = row[kpiNum];
      return kpi;
    }
  }
  return kpi;
};

//finds if aggregated row has edits to particular kpi
export const isMultiWeekEdited = (rows: FormattedRow[], kpi: string): boolean => {
  for (let row of rows) {
    if (row?.edits) {
      if (row.edits[kpi]) {
        return true;
      }
    }
  }
  return false;
};

//finds if aggregated row has edits to particular kpi
export const isNewRowInAggregate = (rows: FormattedRow[]): boolean => {
  let count = 0;
  for (let row of rows) {
    if (row.isNewRow) {
      count++;
    }
  }
  if (count === rows.length && count !== 0) {
    return true;
  }
  return false;
};

/**
 * Calculates impressions, CPM, and TRP for a plan row.
 */
export const calculateAudienceInfo = ({
  row,
  nielsenEstimates,
  universeEstimate,
  assumedClearanceRate,
}: CalculateAudienceInfo): { impressions: number; cpm: number; trp: number } => {
  const { avail, network, dow, daypart_start, daypart_end, type } = row;
  const spend = row.cost * row.count;
  const daysMap = dow.reduce((prev, curr) => ({ ...prev, [curr]: true }), {});

  // Check if the rotation spans across 2 days (past midnight).
  let rotationOverlapsDays = false;
  if (daypart_end < daypart_start) {
    rotationOverlapsDays = true;
  }

  let totalAudience = 0;
  let timeBlocks = 0;
  for (let day of R.keys(daysMap)) {
    const networkDayEstimates: Record<string, number> | undefined = R.path(
      [network, day],
      nielsenEstimates
    );
    for (let time of R.keys(networkDayEstimates)) {
      if (!rotationOverlapsDays && time >= daypart_start && time <= daypart_end) {
        let audience = R.pathOr(0, [time], networkDayEstimates);
        if (avail === "L") {
          audience = audience * LOCAL_AUDIENCE_CORRECTION_FACTOR;
        }
        totalAudience += audience;
        timeBlocks++;
      } else if (rotationOverlapsDays && time >= daypart_start) {
        let audience = R.pathOr(0, [time], networkDayEstimates);
        if (avail === "L") {
          audience = audience * LOCAL_AUDIENCE_CORRECTION_FACTOR;
        }
        totalAudience += audience;
        timeBlocks++;
      } else if (rotationOverlapsDays && time <= daypart_end) {
        let audience = R.pathOr(0, [time], networkDayEstimates);
        if (avail === "L") {
          audience = audience * LOCAL_AUDIENCE_CORRECTION_FACTOR;
        }
        totalAudience += audience;
        timeBlocks++;
      }
    }
  }

  const averageAudience = totalAudience / timeBlocks;
  // TODO: do we want to use impression overrides if they exist here?
  let impressions = averageAudience * row.count;
  let cpm = (spend / impressions) * 1000;
  if (cpm === Infinity) {
    cpm = 0;
  }

  if (assumedClearanceRate && type !== TYPES_MAP.Secured) {
    impressions = impressions * assumedClearanceRate;
  }

  let trp;
  if (universeEstimate) {
    trp = (100 * (impressions / 1000)) / universeEstimate;
    if (assumedClearanceRate) {
      trp *= assumedClearanceRate;
    }
  }
  return { impressions: Math.round(impressions || 0), cpm: cpm || 0, trp: trp || 0 };
};

/**
 * Creates summary level spend totals by network, rotation, avail, and length.
 */
interface MakeSummaryData {
  rows: PlanRow[];
  nielsenEstimates: NielsenEstimates;
  creativeMap: CreativeMap | undefined;
  assumedClearanceRate?: number | undefined;
}
export const makeSummaryData = ({
  rows,
  nielsenEstimates,
  creativeMap,
  assumedClearanceRate,
}: MakeSummaryData): SummaryData => {
  let totalSpend = 0;
  let spendByAvail: Record<string, number> = {};
  let spendByLength: Record<string, number> = {};
  let spendByNetwork: Record<string, number> = {};
  let spendByRotation: Record<string, number> = {};
  let spendByCreative: Record<string, number> = {};
  let spendByAvailLength: Record<string, Record<string, number>> = {};

  let impressionsByNetwork: Record<string, number> = {};
  let impressionsByRotation: Record<string, number> = {};
  let impressionsByLength: Record<string, number> = {};
  let impressionsByCreative: Record<string, number> = {};

  for (let row of rows) {
    let { cost, count, avail, length, network, rotation } = row;
    let { impressions } = calculateAudienceInfo({ row, nielsenEstimates });
    if (assumedClearanceRate) {
      count = count * assumedClearanceRate;
      impressions = impressions * assumedClearanceRate;
    }
    let rotationPrefix = (rotation || "").split("(")[0].trim();
    let spend = cost * count;
    totalSpend += spend;
    spendByLength[length] = (spendByLength[length] || 0) + spend;
    spendByNetwork[network] = (spendByNetwork[network] || 0) + spend;
    spendByAvail[avail] = (spendByAvail[avail] || 0) + spend;
    spendByRotation[rotationPrefix] = (spendByRotation[rotationPrefix] || 0) + spend;
    spendByAvailLength[avail] = {
      ...(spendByAvailLength[avail] || {}),
      [length]: ((spendByAvailLength[avail] || {})[length] || 0) + spend,
    };
    impressionsByNetwork[network] = (impressionsByNetwork[network] || 0) + impressions;
    impressionsByRotation[rotationPrefix] =
      (impressionsByRotation[rotationPrefix] || 0) + impressions;
    impressionsByLength[length] = (impressionsByLength[length] || 0) + impressions;

    if (row.creatives && creativeMap) {
      for (let creative of row.creatives) {
        const { isci, percent } = creative;
        const creativeName = `${creativeMap[isci].name} (${row.length}s)`;
        const adjustedSpend = (percent / 100) * spend;
        const adjustedImpressions = (percent / 100) * impressions;
        spendByCreative[creativeName] = (spendByCreative[creativeName] || 0) + adjustedSpend;
        impressionsByCreative[creativeName] =
          (impressionsByCreative[creativeName] || 0) + adjustedImpressions;
      }
    }
  }

  let byAvailLength: SpendByAvailLength[] = [];
  for (let avail of R.keys(spendByAvailLength)) {
    let row: any = { avail };
    for (let length of R.keys(spendByAvailLength[avail])) {
      let spend = spendByAvailLength[avail][length];
      let percent = spend / totalSpend;
      row = { ...row, [length]: percent };
    }
    let totalSpendByAvail = spendByAvail[avail];
    let totalPercentByAvail = totalSpendByAvail / totalSpend;
    row = { ...row, totalPercentByAvail, totalSpendByAvail };
    byAvailLength.push(row);
  }

  const byNetwork = getSpendAsArray(spendByNetwork, impressionsByNetwork, totalSpend, "network");
  const byRotation = getSpendAsArray(
    spendByRotation,
    impressionsByRotation,
    totalSpend,
    "rotation"
  );
  const byLength = getSpendAsArray(spendByLength, impressionsByLength, totalSpend, "length");
  const byCreative = getSpendAsArray(
    spendByCreative,
    impressionsByCreative,
    totalSpend,
    "creative"
  );

  return {
    totalSpend,
    byLength,
    byNetwork,
    byRotation,
    byCreative,
    byAvailLength,
  };
};

export interface SpendObj {
  spend: number;
  percent: number;
  impressions: number;
  cpm: number;
  [property: string]: any;
}
export const getSpendAsArray = (
  spendByProperty: Record<string, number>,
  impressionsByProperty: Record<string, number>,
  totalSpend: number,
  propertyName: string
): SpendObj[] =>
  R.keys(spendByProperty)
    .map(property => {
      const spend = spendByProperty[property];
      const percent = spend / totalSpend;
      const impressions = impressionsByProperty[property];

      let cpm = (spend / impressions) * 1000;
      if (cpm === Infinity) {
        cpm = 0;
      }

      return {
        [propertyName]: property,
        spend,
        percent,
        impressions,
        cpm,
      };
    })
    .sort((a, b) => b.spend - a.spend);

/**
 * Creates the metadata for a new row with placeholder values.
 */
export const makeNewRow = (
  rotationsAndPricing: RotationsAndPricing,
  network: string,
  week: string,
  company: string,
  isNewNetwork = false
): PlanRow => {
  const defaultRow: PlanRow = {
    plan_id: null,
    network,
    week,
    dow: ["M", "Tu", "W", "Th", "F", "Sa", "Su"],
    avail: "N",
    rotation: "",
    market: "",
    daypart_start: "",
    daypart_end: "",
    notes: null,
    length: 30,
    count: 0,
    cost: 0,
    type: 0,
    company,
    key: "",
    order_id: null,
    traffic_id: null,
    isNewNetwork,
    isNewRow: true,
    is_plan_pending: true,
    campaign_id: null,
  };

  // We don't know what avails a network will have (N and/or L) so we just loop through avails. We
  // grab the first rotation item and use it to fill in placeholder values when adding a new row.
  for (let avail of R.keys(rotationsAndPricing[network])) {
    const networkAvailPricing = rotationsAndPricing[network][avail];

    defaultRow.rotation = networkAvailPricing[0].rotationLabel || "Not Selected";
    defaultRow.cost = networkAvailPricing[0].cost30s;
    defaultRow.daypart_start = time24hr(networkAvailPricing[0].daypartBegin);
    defaultRow.daypart_end = time24hr(networkAvailPricing[0].daypartEnd);
    defaultRow.avail = networkAvailPricing[0].avail;
    defaultRow.dow = networkAvailPricing[0].daysOfWeek;
    defaultRow.type = networkAvailPricing[0].status === "secured" ? 1 : 0;
    defaultRow.market = networkAvailPricing[0].market;
    break;
  }

  let key = makeKeyWithUUID(defaultRow);

  let rowWithKey = { ...defaultRow, key };

  return rowWithKey;
};

/**
 * Compares all plan rows to Rotations and Pricing to check if any rates need to be updated.
 */
export const checkUpdatedRates = (
  rotationsAndPricing: RotationsAndPricing,
  rows: PlanRow[]
): EditsMap => {
  let flattenedRatesMap = {};
  for (let network of R.keys(rotationsAndPricing)) {
    for (let avail of R.keys(rotationsAndPricing[network])) {
      for (let rotation of rotationsAndPricing[network][avail]) {
        let key = `${network}_${avail}_${rotation.rotationLabel}_${rotation.status}`;
        flattenedRatesMap[key] = rotation.cost30s;
      }
    }
  }

  let rateChanges = {};
  for (let row of rows) {
    const { cost, network, avail, rotation, length, key, type } = row;
    let currentRate = cost;
    let mostRecentRate =
      flattenedRatesMap[`${network}_${avail}_${rotation}_${type === 1 ? "secured" : "buyable"}`];
    if (!mostRecentRate) {
      continue;
    }

    // If length is 15s or 60s, halve or double most recent rate to accurately compare.
    // Rotations and Pricing refers to the 30s rates.
    if (length === 15) {
      mostRecentRate = Math.round(mostRecentRate / 2);
    }
    if (length === 60) {
      mostRecentRate = mostRecentRate * 2;
    }

    // If the two rates aren't equal, we'll want to update.
    if (currentRate !== mostRecentRate) {
      let costEdit = { cost: mostRecentRate };
      let rowWithCostEdit = { ...row, edits: costEdit };

      rateChanges = { ...rateChanges, [key]: rowWithCostEdit };
    }
  }

  return rateChanges;
};

/**
 * Decides which creatives to show in the creative allocation modal for a plan row.
 */

/**
 * Decides which creatives to show in the creative allocation modal for a plan row.
 */
export const getCreativeOptions = (
  planRow: PlanRow,
  creativeMap: CreativeMap,
  colorMap: Record<string, string>
): Record<string, any>[] => {
  if (!creativeMap) {
    return [];
  }
  const { avail: planRowAvail, length: planRowLength, creatives: planRowCreatives, week } = planRow;
  let creativeMapIscis: string[] = Object.values(creativeMap).map(creative => creative.isci);

  // Creatives that are currently in the rotation.
  let currentCreativesInRotation: Record<string, boolean> = {};
  if (planRowCreatives) {
    for (let creative of planRowCreatives) {
      currentCreativesInRotation[creative.isci] = true;
    }
  }

  if (creativeMapIscis.length) {
    let options: Record<string, any>[] = [];
    for (let isci of creativeMapIscis) {
      const creative = creativeMap[isci];
      const { avail, length } = creative;

      const creativeIsLive = isCreativeLive(
        creative,
        week,
        Dfns.format(DATE_FORMAT, Dfns.addDays(6, new Date(week)))
      );

      const showCreative = creativeIsLive && avail === planRowAvail && length === planRowLength;

      // Include a creative in the modal if it matches the requirements (same avail, same length, and is live)
      // or if the creative is currently in the rotation. The second condition is for the case when a creative
      // is in the rotation but then gets set to not-live in the creative map after the fact. We still want
      // to show it so that it can be removed, but will give a visual indication in the modal.
      if (showCreative || currentCreativesInRotation[isci]) {
        options.push({
          ...creative,
          name: isci,
          creative: creative.name,
          color: colorMap[isci] || "white",
          notLive: !showCreative && currentCreativesInRotation[isci],
        });
      }
    }
    return R.sortBy(R.prop("isci"), options);
  }
  return [];
};

/**
 * Convert days of week from v1 schema to v2 schema format
 * i.e. MTuWThF----  => ["M", "Tu", "W", "Th", "F"]
 */
export const convertDaysOfWeekStringToArray = (dow: string): string[] => {
  let daysMap = {
    M: dow.substring(0, 1) === "M",
    Tu: dow.substring(1, 3) === "Tu",
    W: dow.substring(3, 4) === "W",
    Th: dow.substring(4, 6) === "Th",
    F: dow.substring(6, 7) === "F",
    Sa: dow.substring(7, 9) === "Sa",
    Su: dow.substring(9, 11) === "Su",
  };

  let daysOfWeek: string[] = [];
  for (let day of DAYS_OF_WEEK) {
    if (daysMap[day]) {
      daysOfWeek.push(day);
    }
  }

  return daysOfWeek;
};

/*
 * Merges the impression override edits into the current impression overrides.
 */
export const deepMergeLinearImpressionOverrides = (
  current: LinearImpressionOverrides,
  edits: LinearImpressionOverrides
): LinearImpressionOverrides => {
  const result: LinearImpressionOverrides = { ...current };

  Object.keys(edits).forEach(demo => {
    if (!result[demo]) {
      result[demo] = {};
    }
    Object.keys(edits[demo]).forEach(measurement => {
      const value = edits[demo][measurement];
      result[demo][measurement] = value;
    });
  });

  return result;
};
