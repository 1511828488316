import {
  CDN,
  CellData,
  ColumnMetaData,
  DimensionMap,
  GlossaryItem,
  toPretty1000sInteger,
  toPrettyCPX,
  toPrettyNumber,
  toPrettyPercent,
  toPrettySpend,
} from "../MetricsTable/metricsTableUtils";
import { DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import * as SS from "@blisspointmedia/bpm-types/dist/SearchShoppingPerformance";
import * as R from "ramda";

export const getDimensionCell = (
  dimensionData: DimensionMap,
  dimensionHeader: DimensionColumn
): CellData => {
  const { dimensionVarName, dimensionTypeName } = dimensionHeader;
  const resolvedDimensionData = dimensionData as Record<SS.Dimension, string>;
  const dimensionValue = resolvedDimensionData[dimensionVarName];
  const defaultCell = {
    value: dimensionValue,
    label: dimensionValue,
  };
  if (dimensionTypeName === "Platform") {
    const parsedDimensionValue = dimensionValue.replace(/\s+/g, "");
    const url = `${CDN}/assets/img/platforms/${parsedDimensionValue}.png`;
    return {
      label: dimensionValue,
      overlayText: dimensionValue,
      value: dimensionValue,
      url,
    };
  }
  return defaultCell;
};

export const dimensionColumnMetaDataMap: Record<
  SS.DimensionColumnType,
  ColumnMetaData<SS.Dimension>
> = {
  "Ad Group": {
    contentType: "text",
    dimensionVarName: "ad_group",
    displayName: "Ad Group",
  },
  "Ad Label": {
    contentType: "text",
    dimensionVarName: "ad_label",
    displayName: "Ad Label",
  },
  "Ad Type": {
    contentType: "text",
    dimensionVarName: "ad_type",
    displayName: "Ad Type",
  },
  Audience: {
    contentType: "text",
    dimensionVarName: "audience",
    displayName: "Audience",
  },
  Campaign: {
    contentType: "text",
    dimensionVarName: "campaign",
    displayName: "Campaign",
  },
  "Campaign Type": {
    contentType: "text",
    dimensionVarName: "campaign_type",
    displayName: "Campaign Type",
  },
  Channel: {
    contentType: "text",
    dimensionVarName: "channel",
    displayName: "Channel",
  },
  Keyword: {
    contentType: "text",
    dimensionVarName: "keyword",
    displayName: "Keyword",
  },
  Network: {
    contentType: "text",
    dimensionVarName: "network",
    displayName: "Network",
  },
  Platform: {
    contentType: "text",
    dimensionVarName: "platform",
    displayName: "Platform",
    iconStyle: "logo",
  },
  Tactic: {
    contentType: "text",
    dimensionVarName: "tactic",
    displayName: "Tactic",
  },
};

export const columnMetaDataMap: Record<SS.ColumnType, ColumnMetaData> = {
  aov: {
    aggregator: agg =>
      agg.conversions && (agg.conversions as number) > 0 && agg.revenue
        ? (agg.revenue as number) / R.defaultTo(0.0, agg.conversions as number)
        : "--",
    displayName: "AOV",
    fetchGetter: fetch =>
      fetch.conversions && (fetch.conversions as number) > 0 && fetch.revenue
        ? (fetch.revenue as number) / R.defaultTo(0.0, fetch.conversions as number)
        : "--",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: ["revenue", "conversions"],
  },
  clicks: {
    displayName: "Clicks",
    formatValue: toPrettyNumber,
  },
  clicksConversionRate: {
    aggregator: agg =>
      agg.clicks && (agg.clicks as number) > 0 && agg.conversions
        ? ((agg.conversions as number) * 1.0) / R.defaultTo(0.0, agg.clicks as number)
        : "--",
    displayName: "Clicks Conversion Rate",
    fetchGetter: fetch =>
      fetch.clicks && (fetch.clicks as number) > 0 && fetch.conversions
        ? ((fetch.conversions as number) * 1.0) / R.defaultTo(0.0, fetch.clicks as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["clicks", "conversions"],
  },
  conversions: {
    displayName: "KPI Volume",
    formatValue: toPrettyNumber,
  },
  costPerConversion: {
    aggregator: agg =>
      agg.conversions && (agg.conversions as number) > 0 && agg.spend
        ? ((agg.spend as number) * 1000.0) / R.defaultTo(0.0, agg.conversions as number)
        : "--",
    displayName: "CPX",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.conversions && (fetch.conversions as number) > 0 && fetch.spend
        ? ((fetch.spend as number) * 1000.0) / R.defaultTo(0.0, fetch.conversions as number)
        : "--",
    formatValue: toPrettyCPX,
    minIsBest: true,
    requiredTotalsColumns: ["conversions", "spend"],
  },
  cpc: {
    aggregator: agg =>
      agg.clicks && (agg.clicks as number) > 0 && agg.spend
        ? (agg.spend as number) / R.defaultTo(0.0, agg.clicks as number)
        : "--",
    displayName: "CPC",
    fetchGetter: fetch =>
      fetch.clicks && (fetch.clicks as number) > 0 && fetch.spend
        ? (fetch.spend as number) / R.defaultTo(0.0, fetch.clicks as number)
        : "--",
    formatValue: toPrettyCPX,
    minIsBest: true,
    requiredTotalsColumns: ["clicks", "spend"],
  },
  cpm: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.spend
        ? ((agg.spend as number) * 1000.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    displayName: "CPM",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.spend
        ? ((fetch.spend as number) * 1000.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyCPX,
    minIsBest: true,
    requiredTotalsColumns: ["impressions", "spend"],
  },
  ctr: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.clicks
        ? ((agg.clicks as number) * 1.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    decimals: 2,
    displayName: "CTR",
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.clicks
        ? ((fetch.clicks as number) * 1.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "clicks"],
  },
  impressions: {
    displayName: "Impressions",
    formatValue: toPretty1000sInteger,
  },
  impressionShare: {
    aggregator: () => 1,
    displayName: "Impression Share",
    formatValue: toPrettyPercent,
  },
  impsConversionRate: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.conversions
        ? ((agg.conversions as number) * 1.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    decimals: 2,
    displayName: "Imps Conversion Rate",
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.conversions
        ? ((fetch.conversions as number) * 1.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "conversions"],
  },
  revenue: {
    displayName: "Revenue",
    formatValue: toPrettySpend,
  },
  roas: {
    aggregator: agg =>
      agg.spend && (agg.spend as number) > 0 && agg.revenue
        ? ((agg.revenue as number) * 1.0) / R.defaultTo(0.0, agg.spend as number)
        : "--",
    displayName: "ROAS",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.spend && (fetch.spend as number) > 0 && fetch.revenue
        ? ((fetch.revenue as number) * 1.0) / R.defaultTo(0.0, fetch.spend as number)
        : "--",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: ["revenue", "spend"],
  },
  spend: {
    displayName: "Spend",
    formatValue: toPrettySpend,
  },
  topImpressionShare: {
    aggregator: () => 1,
    displayName: "Top Impression Share",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["topImpressionShare", "impressions"],
  },
};

export const GLOSSARY: GlossaryItem[] = [
  {
    term: "Revenue",
    definition:
      "The number of dollars generated in return. Typically commerce uses 14d attribution for attributing revenue to an ad.",
  },

  {
    term: "Clicks Conversion Rate",
    definition: "Conversion Rate = KPI volume / clicks",
  },
  {
    term: "Impressions Conversion Rate",
    definition: "Conversion Rate = KPI volume / impressions",
  },
  {
    term: "ROAS",
    definition: "Return on Ad Spend = revenue / spend",
  },
  {
    term: "Spend",
    definition: "The number of dollars invested.",
  },
  {
    term: "Impressions",
    definition: "The number of times the ad(s) are delivered.",
  },
  {
    term: "Clicks",
    definition: "The number of times the ad is clicked.",
  },
  {
    term: "CPM",
    definition: "Cost per thousand impressions = (spend x 1,000) / impressions",
  },
  {
    term: "CPC",
    definition: "Cost per Click = spend / clicks",
  },
];
