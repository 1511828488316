import { Dispatch, SetStateAction, useMemo, useState } from "react";
import cn from "classnames";
import * as R from "ramda";
import { MdOutlineTableRows, MdShowChart } from "react-icons/md";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import WidgetContainer from "../Components/WidgetContainer";
import { Dropdown, DropdownToggleType, IconToggleButton } from "../Components";
import {
  CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES,
  CustomerInsightsFields,
  SORT_DROPDOWN_OPTIONS,
  SortOptions,
  TabKey,
  ViewOptions,
} from "./customerInsightsConstants";
import { getCustomerInsightsAlphabeticalSort } from "./customerInsightsUtils";
import CustomerBaseChart from "./CustomerBaseChart";
import IndexChart from "./IndexChart";
import PenetrationChart from "./PenetrationChart";

interface CustomerInsightsGroupProps {
  fields: string[];
  data: Record<string, KnowYourCustomerData[]>;
  totalPopulation: number;
  header: string;
  // groupSortValue is optional, but if set, takes precedence over pageSortValue
  // pageSortValue is required and has a default value
  pageSortValue: SortOptions;
  // groupSortValue is optional, but if set, takes precedence over pageSortValue
  // pageSortValue is required and has a default value
  pageGraphView: ViewOptions;
  tab: TabKey;
}

const CustomerInsightsGroup: React.FC<CustomerInsightsGroupProps> = ({
  fields,
  data,
  totalPopulation,
  header,
  pageSortValue,
  pageGraphView,
  tab,
}) => {
  const [groupSortValue, setGroupSortValue] = useState<SortOptions | undefined>();
  const [groupGraphView, setGroupGraphView] = useState<ViewOptions | undefined>();

  const sortValue = useMemo(() => {
    return groupSortValue ?? pageSortValue;
  }, [groupSortValue, pageSortValue]);

  const graphView = useMemo(() => {
    return groupGraphView ?? pageGraphView;
  }, [groupGraphView, pageGraphView]);

  const sortedData = useMemo(() => {
    const dataToSort = R.clone(data);
    let by: (a: KnowYourCustomerData, b: KnowYourCustomerData) => number;
    switch (sortValue) {
      case "alphabetical":
        Object.keys(dataToSort).forEach(key => {
          const value = dataToSort[key];
          const fieldKey = key as CustomerInsightsFields;
          dataToSort[key] = getCustomerInsightsAlphabeticalSort(fieldKey, value);
        });
        return dataToSort;
      case "high to low":
        by = R.descend(R.prop("clientPopulation"));
        break;
      case "low to high":
        by = R.ascend(R.prop("clientPopulation"));
        break;
    }

    // Apply numerical sort
    Object.keys(dataToSort).forEach(fieldName => {
      const values = dataToSort[fieldName];
      dataToSort[fieldName] = R.sort(by)(values);

      // Replace original levels with pretty names
      Object.keys(CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES[fieldName]).forEach(dataName => {
        const prettyName = CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES[fieldName][dataName];
        const valsIndex = dataToSort[fieldName].findIndex(item => item.levels === dataName);
        if (valsIndex !== -1) {
          dataToSort[fieldName][valsIndex].levels = prettyName;
        }
      });
    });

    return dataToSort;
  }, [data, sortValue]);

  const renderGraph: (field: string) => React.ReactNode = useMemo(() => {
    return field => {
      const fieldName = field as CustomerInsightsFields;
      switch (tab) {
        case TabKey.CUSTOMER_BASE:
          return (
            <CustomerBaseChart
              fieldName={fieldName}
              data={sortedData[field]}
              totalPopulation={totalPopulation}
            />
          );
        case TabKey.INDEX:
          return <IndexChart fieldName={fieldName} data={sortedData[field]} />;
        case TabKey.PENETRATION:
          return <PenetrationChart fieldName={fieldName} data={sortedData[field]} />;
      }
    };
  }, [sortedData, tab, totalPopulation]);

  const renderedTable = useMemo(() => {
    return <div>TODO: Table view</div>;
  }, []);

  return (
    <WidgetContainer
      header={header}
      rightActions={
        <div className="customerInsightsGroupActions">
          <div className="sortContainer">
            <Dropdown
              type={DropdownToggleType.FILLED}
              value={sortValue}
              options={SORT_DROPDOWN_OPTIONS}
              onChange={change => setGroupSortValue(change)}
            />
          </div>
          <IconToggleButton
            options={[
              { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
              { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
            ]}
            size="sm"
            selectedOption={graphView}
            onChange={() =>
              setGroupGraphView(prev => (prev === "showGraph" ? "showTable" : "showGraph"))
            }
          />
        </div>
      }
      collapsible
    >
      {fields.map(field => (
        <div
          key={field}
          className={cn("customerInsightsGroup", {
            fullWidth: CustomerInsightsFields.INCOME === field,
          })}
        >
          {graphView === "showGraph" && renderGraph(field)}
          {graphView === "showTable" && renderedTable}
        </div>
      ))}
    </WidgetContainer>
  );
};

interface CustomerInsightsLegendProps {
  data: {
    name: string;
    val1: string;
    val2?: string;
    computedValue: string;
    color?: string;
  }[];
  selectedValue?: string;
  setSelectedValue?: Dispatch<SetStateAction<string | undefined>>;
  selectBehavior?: "click" | "hover";
}

export const CustomerInsightsLegend: React.FC<CustomerInsightsLegendProps> = ({
  data,
  selectedValue,
  setSelectedValue,
  selectBehavior,
}) => (
  <div className="legend">
    {data.map(({ name, val1, val2, computedValue, color }) => (
      <div
        key={name}
        className={cn("legendItem", {
          selectedValue: name === selectedValue,
          unselectedValue: Boolean(selectedValue) && name !== selectedValue,
        })}
        onMouseEnter={
          setSelectedValue && selectBehavior === "hover" ? () => setSelectedValue(name) : undefined
        }
        onMouseLeave={
          setSelectedValue && selectBehavior === "hover"
            ? () => setSelectedValue(undefined)
            : undefined
        }
        onClick={
          setSelectedValue && selectBehavior === "click" ? () => setSelectedValue(name) : undefined
        }
      >
        <div className="itemLabel">
          {color && <div className="itemColor" style={{ backgroundColor: color }} />}
          <div>{name}</div>
        </div>
        <div className="itemComputedValue">{computedValue}</div>
        <div className="stackedItemValues">
          <div className="itemValue">{val1}</div>
          <div className="itemValue">{val2}</div>
        </div>
      </div>
    ))}
  </div>
);

export default CustomerInsightsGroup;
