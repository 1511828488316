import { formatInTimeZone } from "date-fns-tz";
import { PRETTY_DEMO_NAMES } from "../LinearBuying/linearBuyingConstants";
import {
  convertDaypartToExcelFormat,
  convertDaysOfWeekToExcelFormat,
} from "../LinearBuying/excelUtils";
import * as XLSX from "xlsx";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { convert24hrTo12hr } from "../utils/time-utils";
import "./LinearUpfrontReconciliation.scss";
import { LinearUpfrontReconRows } from "@blisspointmedia/bpm-types/src/LinearBuying";

const TIMESTAMP_FORMAT = "HH:mm";

//format post log timestamp to time//
export const formatTimestampToTime = (timestamp: string): string => {
  try {
    let date = new Date(timestamp);
    let formattedDate = formatInTimeZone(date, "America/New_York", TIMESTAMP_FORMAT);
    return convert24hrTo12hr(formattedDate);
  } catch (error) {
    console.error("Error parsing time:", error);
    return "Invalid Time";
  }
};

//format post log timestamp to date//
export const formatTimestampToDate = (timestamp: string): string => {
  try {
    let date = Dfns.parseJSON(timestamp);
    let formattedDate = formatInTimeZone(date, "America/New_York", "MM/dd/yyyy");
    return formattedDate;
  } catch (error) {
    console.error("Error parsing date:", error);
    return "Invalid Date";
  }
};

//format demo to pretty name//
export const formatDemo = (demo: string): string => {
  try {
    const formattedDemo = PRETTY_DEMO_NAMES[demo];
    return formattedDemo;
  } catch (error) {
    console.error("Error parsing demo:", error);
    return "Invalid demo";
  }
};

//export page to excel//
export const exportToExcel = (data: LinearUpfrontReconRows[] | undefined): void => {
  if (!data) {
    return;
  }
  const excelData = (data || []).map((row: LinearUpfrontReconRows) => {
    const convertedAirTimestamp = formatTimestampToTime(`${row.airdate_timestamp}`);
    const convertedAirDate = formatTimestampToDate(`${row.airdate_timestamp}`);
    const convertedDow = convertDaysOfWeekToExcelFormat(row.dow);
    const convertedDaypart = convertDaypartToExcelFormat(row.daypart_start, row.daypart_end);

    //ordered
    let convertedOrderedDemo = formatDemo(row.linear_ordered_demo);
    let orderedDifferenceEquivalized = row.ordered_actuals_e - row.linear_ordered_impressions;
    let orderedDifferenceNonEquivalized =
      row.ordered_actuals_non_e - row.linear_ordered_impressions;

    //override
    let overrideImpressions =
      R.isNil(row.linear_override_impressions) || row.linear_override_impressions === 0
        ? row.nielsenEstimatesImpressions
        : row.linear_override_impressions;
    let overrideDifferenceEquivalized = row.override_actuals_e - (overrideImpressions || 0);
    let overrideDifferenceNonEquivalized = row.override_actuals_non_e - (overrideImpressions || 0);

    return {
      week: row.week,
      network: row.network,
      avail: row.avail,
      rotation: row.rotation,
      length: row.length,
      program: row.program,
      cost: row.cost,
      daypart: convertedDaypart,
      post_log_date: convertedAirDate,
      post_logs_airtime: convertedAirTimestamp,
      days_of_week: convertedDow,
      isci: row.isci,
      media_classification: row.media_classification,
      ordered_measurement: row.linear_ordered_measurement,
      ordered_demo: convertedOrderedDemo,
      ordered_impressions: row.linear_ordered_impressions,
      ordered_delivered_equivalized: row.ordered_actuals_e,
      ordered_difference_equivalized: orderedDifferenceEquivalized,
      ordered_delivered_non_equivalized: row.ordered_actuals_non_e,
      ordered_difference_non_equivalized: orderedDifferenceNonEquivalized,
      secondary_demo: row.linear_override_demo,
      secondary_measurement: row.linear_override_measurement,
      secondary_impressions: overrideImpressions,
      secondary_actuals_equivalized: row.override_actuals_e,
      secondary_difference_equivalized: overrideDifferenceEquivalized,
      secondary_actuals_non_equivalized: row.override_actuals_non_e,
      secondary_difference_non_equivalized: overrideDifferenceNonEquivalized,
      notes: row.notes || "",
    };
  });

  //save
  let fileName = "LinearUpfrontReconReport.xlsx";

  let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
  let worksheet = XLSX.utils.json_to_sheet(excelData);

  workbook.SheetNames.push(fileName);
  workbook.Sheets[fileName] = worksheet;

  XLSX.writeFile(workbook, fileName);
};
