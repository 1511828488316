import React from "react";
import { Button, ButtonType } from "../../Components";

interface MetaBuyingFooterControlsProps {
  nextTabName?: string;
  // tabName: string;
  // tabIndex: number;
  // setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  // showReviewModal: () => void;
  handleReviewClick: () => void;
  handleNextClick?: () => void;
}

export const MetaBuyingFooterControls = ({
  nextTabName,
  handleReviewClick,
  handleNextClick,
}: MetaBuyingFooterControlsProps): JSX.Element => {
  return (
    <div className="metaBuyingButtons">
      <Button type={ButtonType.OUTLINED} onClick={() => handleReviewClick()}>
        Review and Finish
      </Button>
      {nextTabName && handleNextClick && (
        <Button type={ButtonType.FILLED} onClick={() => handleNextClick()}>
          Next (Create {nextTabName})
        </Button>
      )}
    </div>
  );
};
