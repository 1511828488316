import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Card, CircularProgressBar } from "../Components";

interface OnboardingFormPageProps {
  renderTabs: {
    label: string;
    headerLabel: string;
    key: string;
    percentage: number;
    children: React.ReactNode;
  }[];
  defaultActiveTab: string;
  tabs: string[];
}

const OnboardingFormPage = ({
  renderTabs,
  defaultActiveTab,
  tabs,
}: OnboardingFormPageProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<typeof tabs>(
    (defaultActiveTab as unknown) as typeof tabs
  );

  return (
    <Card className="onboardingPage formPage">
      <div className="formPageNavBar">
        <Nav
          activeKey={activeTab}
          onSelect={value => {
            const valueTab = value as keyof typeof value;

            if (!valueTab || valueTab === activeTab) {
              return;
            }
            setActiveTab(valueTab);
          }}
        >
          {renderTabs.map(({ headerLabel, key, percentage }) => (
            <Nav.Item key={key}>
              <Nav.Link href={`#${key}`} eventKey={key}>
                <span key={key} className="onboardingProgress">
                  <CircularProgressBar
                    className="onboardingProgressBar"
                    percentage={percentage}
                    size={55}
                  />
                  {headerLabel}
                </span>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <hr />
      {renderTabs.map(({ label, key, children }) => (
        <div key={key} id={key}>
          <h2 className="formPageHeader">{label}</h2>
          {children}
          <hr />
        </div>
      ))}
    </Card>
  );
};

export default OnboardingFormPage;
