// import React, { useState, useEffect, useContext } from "react";
import React, { useState } from "react";
// import { FormContext, metaCampaignFormDefault } from "../MetaBuyingContext";
import { RouteComponentProps } from "@reach/router";
// import { Button, ButtonType } from "../../Components";
// import { awaitJSON, MetaLambdaFetch } from "../../utils/fetch-utils";
// import { useCompanyInfo } from "../../redux/company";
import { useSetError } from "../../redux/modals";
import MetaBuyingAd from "./MetaBuyingAd";
import MetaBuyingAdSet from "./MetaBuyingAdSet";
import MetaBuyingCampaign from "./MetaBuyingCampaign";
import MetaBuyingCreationPrompt from "./MetaBuyingCreationPrompt";
import MetaBuyingReviewModal from "./MetaBuyingReviewModal";
// import { selectionsToArray } from "../MetaBuyingUtils";
import * as R from "ramda";
import "./MetaBuyingCreate.scss";
// import { fieldsToExpression } from "cron-parser";

interface MetaBuyingCreateProps {
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  setSelectedAdAccount: React.Dispatch<
    React.SetStateAction<{
      account_id: string;
      account_name: string;
      business_manager: string;
    }>
  >;
  adAccountOptions: {
    account_id: string;
    account_name: string;
    business_manager: string;
  }[];
  namingConventions: {
    campaign: number[];
    adset: number[];
    ad: number[];
  };
  client: string;
  granularity: string;
  dimensions: Record<string, any>;
}

export const MetaBuyingCreate = ({
  selectedAdAccount,
  setSelectedAdAccount,
  adAccountOptions,
  namingConventions,
  client,
  granularity,
  dimensions,
}: RouteComponentProps & MetaBuyingCreateProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [creationTabs, setCreationTabs] = useState({
    Campaign: false,
    "Ad Set": false,
    Ad: false,
  });
  const setError = useSetError();

  const [selectionsSubmitted, setSelectionsSubmitted] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [payloads, setPayloads] = useState({
    campaign: {},
    adset: {},
    ad: {},
  });
  // const [inheritedNameFields, setInheritedNameFields] = useState(() => {
  //   const { campaign, adset, ad } = namingConventions;
  //   const originCampaign = R.intersection(campaign, R.union(ad, adset));
  //   const originAdSet = R.filter(
  //     element => !R.includes(element, originCampaign),
  //     R.intersection(adset, ad)
  //   );
  //   return {
  //     campaign: originCampaign,
  //     adset: originAdSet,
  //   };
  // });
  const [allNameFields, setAllNameFields] = useState(() => {
    let fields = {};
    const { campaign, adset, ad } = namingConventions;
    const originCampaign = R.intersection(campaign, R.union(ad, adset));
    const originAdSet = R.filter(
      element => !R.includes(element, originCampaign),
      R.intersection(adset, ad)
    );
    R.keys(dimensions).forEach(dimensionId => {
      let field: { value: string; inheritedFrom?: string } = {
        value: "",
      };
      if (R.includes(Number(dimensionId), originCampaign)) {
        field.inheritedFrom = "campaign";
      } else if (R.includes(Number(dimensionId), originAdSet)) {
        field.inheritedFrom = "adset";
      }
      fields[dimensionId] = field;
    });
    return fields;
  });

  const constructNameUsingConvention = (
    form: Record<string, string | number>,
    namingConvention: string[]
  ): string => namingConvention.map(element => form[element]).join("_");

  const validateForm = (form, validate, type, navigate) => {
    try {
      let newPayloads = R.clone(payloads);
      newPayloads[type] = validate(form);
      setPayloads(newPayloads);
      navigate();
    } catch (e) {
      const error = e as Error;
      setError({
        title: "Validation Error",
        message: error.message,
      });
    }
  };

  const CreationTab = (): JSX.Element => {
    switch (tabIndex) {
      case 1:
        return (
          <MetaBuyingAdSet
            client={client}
            selectedAdAccount={selectedAdAccount}
            namingConvention={namingConventions.adset}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            showReviewModal={() => setShowReviewModal(true)}
            validateForm={validateForm}
            creationTabs={creationTabs}
            granularity={granularity}
            dimensions={dimensions}
            allNameFields={allNameFields}
            // nameFields={nameFields}
            // setNameFields={setNameFields}
          />
        );
      case 2:
        return (
          <MetaBuyingAd
            client={client}
            selectedAdAccount={selectedAdAccount}
            namingConvention={namingConventions.ad}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            showReviewModal={() => setShowReviewModal(true)}
            validateForm={validateForm}
            creationTabs={creationTabs}
            granularity={granularity}
            dimensions={dimensions}
            allNameFields={allNameFields}
            // nameFields={nameFields}
            // setNameFields={setNameFields}
          />
        );
      default:
        return (
          <MetaBuyingCampaign
            client={client}
            selectedAdAccount={selectedAdAccount}
            namingConvention={namingConventions.campaign}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            showReviewModal={() => setShowReviewModal(true)}
            validateForm={validateForm}
            creationTabs={creationTabs}
            granularity={granularity}
            dimensions={dimensions}
            allNameFields={allNameFields}
            setAllNameFields={setAllNameFields}
            // nameFields={nameFields}
            // setNameFields={setNameFields}
          />
        );
    }
  };

  return (
    <div className="metaBuyingCreate">
      {selectionsSubmitted ? (
        <CreationTab />
      ) : (
        <MetaBuyingCreationPrompt
          adAccountOptions={adAccountOptions}
          selectedAdAccount={selectedAdAccount}
          setSelectedAdAccount={setSelectedAdAccount}
          creationTabs={creationTabs}
          setCreationTabs={setCreationTabs}
          setSelectionsSubmitted={setSelectionsSubmitted}
        />
      )}
      <MetaBuyingReviewModal
        selectedAdAccount={selectedAdAccount}
        client={client}
        show={showReviewModal}
        closeModal={() => setShowReviewModal(!showReviewModal)}
        creationTabs={Object.keys(creationTabs).filter(tab => creationTabs[tab])}
        namingConventions={namingConventions}
        constructNameUsingConvention={constructNameUsingConvention}
        payloads={payloads}
      />
    </div>
  );
};

export default MetaBuyingCreate;
