import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Spotify";

const Spotify = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Spotify is a popular digital audio streaming service that provides access to millions
              of songs, podcasts, and videos from artists around the world. It allows users to
              discover new music, create and share playlists, and listen on a wide variety of
              devices, making music and other audio entertainment readily available anytime and
              anywhere.
            </div>
            <br />
            <div className="basicText">
              As a purely digital platform, running an incrementality test on Spotify can be
              relatively straightforward, with low operational lift and generally modest
              out-of-pocket cost to a client.
            </div>
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Incrementality Testing Approaches",
                "Considerations",
                "Sample Output",
                "Signal Enrichment",
                "Requesting a Conversion Lift Study",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "Conversion Lift",
                  Description: (
                    <ul className="basicList">
                      <li>
                        Conversion Lift is an in-platform approach that measures the incremental
                        impact of ad exposures on driving user actions – such as website visits,
                        sign ups, purchases, etc. – relative to listeners who were not exposed to
                        the brand’s ads.
                      </li>
                      <li>
                        Spotify does not use a ghost ad/bid paradigm for the construction of the
                        exposed and control groups; thus this is not a true randomized experiment.
                      </li>
                      <li>
                        Instead, Spotify runs ads to a set of users who form the treatment group,
                        then finds another set of users to serve as a{" "}
                        <a href="https://www.statology.org/matched-pairs-design/">
                          matched control
                        </a>{" "}
                        group.
                      </li>
                      <li>
                        This matched control group is selected so as to be as similar to the exposed
                        group as possible in terms of time and geography of media consumption.
                      </li>
                      <li>
                        The control group is used to establish a baseline rate of response to the
                        KPI of interest; the exposed group’s rate of response is compared to that
                        baseline, and the difference between the two is taken to be the incremental
                        effect of the ad exposures.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        The test will be inexpensive to run. Spotify recommends a minimum threshold
                        of 250k impressions, which will normally equate to a few thousand dollars.
                      </li>
                      <li>
                        The operational lift is light, and testing can usually be completed in four
                        weeks.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Conversion Lift is a{" "}
                        <a href="https://dimewiki.worldbank.org/Quasi-Experimental_Methods">
                          quasi-experimental
                        </a>{" "}
                        approach rather than an experimental approach. Treatment (exposure to the
                        brand’s ads) is not randomized, thus{" "}
                        <a href="https://en.wikipedia.org/wiki/Causal_inference">
                          causal inference
                        </a>{" "}
                        is not completely clean.
                      </li>
                    </ul>
                  ),
                },
                {
                  Method: "Geo Test",
                  Description: (
                    <ul className="basicList">
                      <li>
                        Geo testing is a comparative experiment where the treatment and control
                        groups are geographic units, often selections of the 210 U.S.{" "}
                        <a href="https://markets.nielsen.com/us/en/contact-us/intl-campaigns/dma-maps/#:~:text=DMA%20(Designated%20Market%20Area)%20regions,geographic%20areas%20within%20their%20business.">
                          DMAs
                        </a>
                        .
                      </li>
                      <li>
                        The test consists of withholding Spotify media from the control DMAs while
                        running Spotify media in the treatment DMAs.
                      </li>
                      <li>
                        The key idea is that the DMAs assigned to receive Spotify media are selected
                        at random, which lays the foundation for{" "}
                        <a href="https://towardsdatascience.com/why-are-randomized-experiments-the-gold-standard-in-causal-inference-f3fa240a1d02">
                          justified causal inference
                        </a>
                        .
                      </li>
                      <li>
                        The treatment effect is estimated by comparing the KPI trends in the treated
                        DMAs to the KPI trends in the control DMAs, often using a{" "}
                        <a href="https://en.wikipedia.org/wiki/Difference_in_differences">
                          difference-in-difference
                        </a>{" "}
                        analytical framework.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        <a href="https://en.wikipedia.org/wiki/Causal_inference">
                          Causal inference
                        </a>{" "}
                        is very clean. Assuming proper randomization of DMAs into treatment and
                        control, we are manipulating a single variable (running Spotify ads) while
                        all other characteristics can be assumed to be equivalent between the two
                        groups. This allows us to confidently attribute any observed differences in
                        behavior to the treatment.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Running this kind of test will be much costlier than using Conversion Lift.
                      </li>
                      <li>
                        Running this kind of test will typically be more time consuming than using
                        Conversion Lift.
                      </li>
                      <li>The operational lift is much higher.</li>
                      <li>
                        A greater fraction of the potential audience needs to be suppressed,
                        entailing an opportunity cost in terms of foregone user acquisition.
                      </li>
                    </ul>
                  ),
                },
              ],
              R.filter(header => header.name !== "Method Type", methodHeaders),
              800
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <ul className="basicList">
              <li>
                Conversion Lift is available in the U.S., Australia, Canada, Japan, and Mexico to
                advertisers with an attribution campaign via the{" "}
                <a href="https://ads.spotify.com/en-US/ad-analytics/spotify-pixel/">
                  Spotify Pixel
                </a>{" "}
                (see <a href="#signal-enrichment">details below</a>).
              </li>
              <li>
                Conversion Lift can be used to measure audio ads across both Spotify and non-Spotify
                media.
              </li>
              <li>
                Links for additional technical details:
                <ul>
                  <li>
                    <a href="https://help.adanalytics.spotify.com/about-data-collection-for-attribution">
                      Data Collection
                    </a>
                  </li>
                  <li>
                    <a href="https://help.adanalytics.spotify.com/about-device-matching-for-attribution">
                      Spotify Device Graph
                    </a>
                  </li>
                  <li>
                    <a href="https://help.adanalytics.spotify.com/about-modeled-vs.-unmodeled-results-for-attribution">
                      Modeled vs Unmodeled Attribution
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        }
        id={"considerations"}
        pageTitle={PAGE_TITLE}
        title={"Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Conversion Lift study results are made available in the Spotify dashboard and
              typically look like the following:
            </div>
            <br />
            <Img src={`${INC_CDN}/Spotify_ConversionLift.png`} />
          </div>
        }
        id={"sample-output"}
        pageTitle={PAGE_TITLE}
        title={"Sample Output"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              In order to run a Conversion Lift study, it is required that the brand have the{" "}
              <a href="https://ads.spotify.com/en-US/ad-analytics/spotify-pixel/">Spotify Pixel</a>{" "}
              implemented on its website. The Spotify Pixel is a piece of JavaScript code placed on
              a website that allows for analysis of the actions that listeners took after hearing a
              brand's ads. Any brand that is already live on Spotify is almost certain to have the
              pixel implemented. There are several options for installation depending on a client's
              needs:
            </div>
            <br />
            <ul className="basicList">
              <li>
                <a href="https://help.adanalytics.spotify.com/installing-the-spotify-pixel-with-google-tag-manager">
                  Implementation in Google Tag Manager
                </a>
              </li>
              <li>
                <a href="https://help.adanalytics.spotify.com/implementing-your-spotify-pixel-with-floodlight">
                  Implementation via Floodlight
                </a>
              </li>
              <li>
                <a href="https://help.adanalytics.spotify.com/install-the-podsights-pixel-using-a-custom-tealium-container">
                  Implementation using a Custom Tealium Container
                </a>
              </li>
              <li>
                <a href="https://help.adanalytics.spotify.com/installing-the-spotify-pixel-on-shopify">
                  Implementation in Shopify
                </a>
              </li>
            </ul>
            <br />
            <div className="basicText">
              If a brand wants to include mobile conversions as an outcome in a Conversion Lift
              study, then an MMP integration is required. The details will differ somewhat depending
              on the MMP:
            </div>
            <ul className="basicList">
              <li>
                <a href="https://help.adanalytics.spotify.com/adjust-spotify-ad-analytics-integration">
                  Adjust
                </a>
              </li>
              <li>
                <a href="https://help.adanalytics.spotify.com/appsflyer-spotify-ad-analytics-integration">
                  AppsFlyer
                </a>
              </li>
              <li>
                <a href="https://help.adanalytics.spotify.com/kochava-spotify-ad-analytics-integration">
                  Kochava
                </a>
              </li>
              <li>
                <a href="https://help.adanalytics.spotify.com/segment-spotify-ad-analytics-integration">
                  Segment
                </a>
              </li>
              <li>
                <a href="https://help.adanalytics.spotify.com/singular-spotify-ad-analytics-mobile-integration">
                  Singular
                </a>
              </li>
            </ul>
          </div>
        }
        id={"signal-enrichment"}
        pageTitle={PAGE_TITLE}
        title={"Signal Enrichment"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              To{" "}
              <a href="https://help.adanalytics.spotify.com/conversion-lift-reports">
                request a Conversion Lift study
              </a>
              ,
            </div>
            <ol className="basicList">
              <li>
                {
                  "Request a Conversion Lift report directly in the Spotify dashboard by navigating to Measure > Lift Reports > Conversion Lift > New Lift Report > select campaign(s) and lift report date range "
                }
              </li>
              <li>
                Wait 3 business days to allow us time to create and QA the results. When the report
                is ready, you will get an email notification.
              </li>
              <li>
                View your report in the Lift Report tab of your dashboard, download a .CSV, and/or
                take a screenshot.
              </li>
            </ol>
          </div>
        }
        id={"requesting-a-conversion-lift-study"}
        pageTitle={PAGE_TITLE}
        title={"Requesting a Conversion Lift Study"}
      />
    </div>
  );
});

export default Spotify;
