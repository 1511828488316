import "./AlertDialog.scss";
import React from "react";
import { Modal } from "react-bootstrap";
import { Button, ButtonType } from "../../Components";

export enum AlertDialogVariants {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

interface AlertDialogProps {
  variant?: AlertDialogVariants;
  escapable?: boolean;
  show: boolean;
  onHide: () => void;
  icon: React.ReactNode;
  title: string;
  body: string | React.ReactNode;
  primaryAction?: () => void;
  secondaryAction?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  className?: string;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  variant = AlertDialogVariants.INFO,
  escapable = true,
  show,
  onHide,
  icon,
  title,
  body,
  primaryAction = onHide,
  secondaryAction,
  primaryButtonLabel = "Okay",
  secondaryButtonLabel = "Cancel",
  className,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      contentClassName={`alertDialog ${variant}${className ? ` ${className}` : ""}`}
      centered
      backdrop={escapable ? true : "static"}
      keyboard={escapable}
    >
      <Modal.Body>
        <div>{icon}</div>
        <div>
          <h4 className="dialogTitle">{title}</h4>
          <div className="dialogBody">{body}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {secondaryAction && (
          <Button type={ButtonType.OUTLINED} design="secondary" onClick={secondaryAction}>
            {secondaryButtonLabel}
          </Button>
        )}
        <Button type={ButtonType.FILLED} onClick={primaryAction}>
          {primaryButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertDialog;
