import { useMemo } from "react";
import { NAVS, TabKey } from "./customerInsightsConstants";
import { InfoTooltip } from "../Components";
import { formatNumber } from "../utils/format-utils";
import { Neutral200, Neutral600, Success700 } from "../utils/colors";
import ProgressBar from "@ramonak/react-progress-bar";

interface CustomerInsightsHeaderProps {
  tab: TabKey;
  matchRate?: { matchedPopulation: number; totalPopulation: number; fraction: number };
}

const CustomerInsightsHeader: React.FC<CustomerInsightsHeaderProps> = ({ tab, matchRate }) => {
  const { matchedPopulation, totalPopulation, fraction } = matchRate ?? {
    matchedPopulation: 0,
    totalPopulation: 0,
    fraction: 0,
  };

  const prettyLabel = useMemo(() => {
    return NAVS.find(nav => nav.key === tab)?.label ?? "";
  }, [tab]);

  const aboutCopy = useMemo(() => {
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        return "Customer Base describes the composition of your customers by observable characteristics such as their age, household income, place of residence, etc.";
      case TabKey.INDEX:
        return "-------------------";
      case TabKey.PENETRATION:
        return "-------------------";
    }
  }, [tab]);

  return (
    <div className="customerInsightsHeader">
      <div className="headerItem about">
        <div className="headerTitle">About {prettyLabel}</div>
        <div className="headerBody">{aboutCopy}</div>
      </div>
      <div className="headerItem emailMatchRate">
        <div className="headerTitle">
          Email Match Rate
          <InfoTooltip size="reg" color={Neutral600}>
            TODO: Copy about Email Match Rate
          </InfoTooltip>
        </div>
        <div className="headerBody">
          <div className="progressBarLabel">
            <span>{formatNumber(totalPopulation)} emails ingested</span>
            <span>{formatNumber(matchedPopulation)} emails recognized</span>
          </div>
          <div className="progressBarContainer">
            <ProgressBar
              baseBgColor={Neutral200}
              bgColor={Success700}
              borderRadius="8px"
              completed={Math.round(fraction * 10000) / 100}
              height="28px"
              labelAlignment="right"
              className="progressBarWrapper"
              labelClassName="progressBarCompletion"
              maxCompleted={100}
              width={"100%"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInsightsHeader;
