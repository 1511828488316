import "./TwoStageSpendAndEffectContainer.scss";
import React, { useMemo, useCallback, useState } from "react";
import { DownloadDropdown, Dropdown, DropdownToggleType } from "../../Components";
import ExpandModalButton from "../../Components/ExpandModalButton/ExpandModalButton";
import { SpendAndEffectModal } from "../../Components/SpendAndEffectModal";
import { SpendAndEffectBarChart } from "../../MMM/ModelResults/SpendAndEffectBarChart";
import { VisualLegendHorizontal } from "../../Components/VisualLegendHorizontal";
import { exportToExcel } from "../../utils/download-utils";
import { colorMap as colors } from "../../utils/colors";
import ChartContainer from "../../Components/ChartContainer";
import { ShadedPattern } from "../FillPatterns/ShadedPattern";

interface TwoStageContainerProps {
  colorOverrides?: { [key: string]: string };
  dropdownOptions: readonly any[]; // DropdownOption
  dropdownValue: string;
  setDropdownValue: (value: string) => void;
  title: string;
  valueFormatter: (value: number) => string;
  twoStageData: any[];
  exportFormattedData?: any[];
  kpiType: string;
}

export const TwoStageSpendandEffectContainer: React.FC<TwoStageContainerProps> = ({
  colorOverrides,
  dropdownOptions,
  dropdownValue,
  setDropdownValue,
  title,
  valueFormatter,
  twoStageData,
  exportFormattedData,
  kpiType,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const excelDownload = useCallback(() => {
    const dataToExport = exportFormattedData ? exportFormattedData : twoStageData;
    exportToExcel(dataToExport, `${title.replace(/ /g, "_")}_actualized`);
  }, [title, twoStageData, exportFormattedData]);

  const twoStagecolorMap = useMemo(() => {
    if (twoStageData) {
      const colorsOriginal = colors(twoStageData);

      return { ...colorsOriginal, ...colorOverrides };
    }
  }, [colorOverrides, twoStageData]);

  return (
    <>
      <div className="snapshotChartContainer twoStageSpendAndEffectContainer">
        <ChartContainer
          enableHoverDesign
          title={title}
          rightActions={
            <>
              {dropdownOptions && dropdownValue && setDropdownValue && (
                <Dropdown
                  type={DropdownToggleType.OUTLINED}
                  design="secondary"
                  size="sm"
                  value={dropdownValue}
                  options={dropdownOptions}
                  onChange={setDropdownValue}
                />
              )}
              <ExpandModalButton size="sm" setShowModal={setShowModal} />
              <DownloadDropdown size="sm" onClickOptions={[excelDownload]} menuOptions={["XLSX"]} />
            </>
          }
          subHeader={<VisualLegendHorizontal inModal={false} />}
        >
          <div id="snapshotGraphContents" className="snapshotGraphContents">
            <SpendAndEffectBarChart
              expandedModal={false}
              activeChannel={"All Channels"}
              barData={twoStageData}
              data={twoStageData}
              actualValues={false}
              valueFormatter={valueFormatter}
              colorMap={twoStagecolorMap}
              barSize={20}
            />
            <div className="patternDefinitions">
              <ShadedPattern color={"grey"} visibleTag={false} />
              <ShadedPattern
                color="white"
                backgroundColor={"grey"}
                id={"Neggrey"}
                visibleTag={false}
              />
              {twoStageData.map(item => (
                <div key={`patternDefContainer${item.name}`}>
                  <ShadedPattern color={twoStagecolorMap[item.name]} visibleTag={false} />
                  <ShadedPattern
                    color="white"
                    backgroundColor={twoStagecolorMap[item.name]}
                    id={`Neg${twoStagecolorMap[item.name]}`}
                    visibleTag={false}
                  />
                </div>
              ))}
            </div>
          </div>
        </ChartContainer>
      </div>
      {showModal && twoStageData && dropdownOptions && (
        <SpendAndEffectModal
          valueFormatter={valueFormatter}
          data={[...twoStageData]}
          onClose={() => setShowModal(false)}
          sortOptions={[...dropdownOptions]}
          kpiType={kpiType}
          excelDownload={excelDownload}
        />
      )}
    </>
  );
};
