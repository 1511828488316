import React, { useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import "./MetaBuyingDrafts.scss";
import MetaTableWidget from "./Components/MetaTableWidget";
import {
  Button,
  ButtonType,
  CheckBox,
  Header,
  OverlayTrigger,
  ToggleSwitch,
} from "../../Components";
import { MdCancel, MdCheck, MdClose, MdFacebook, MdMoreVert, MdRemoveRedEye } from "react-icons/md";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import {
  AdAccountInfo,
  MetaBuyingTableRow,
  CampaignRow,
  AdSetRow,
  AdRow,
  MBApprovalStage,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import * as R from "ramda";
import {
  UseAlertDialog,
  DESELECT_ROW,
  MBAlertDialogType,
  MessageIcon,
  UseReviewModal,
  SELECT_ROW,
  UseTableData,
  useAlertDialog,
  useFilterBar,
  useGetButtonPosition,
  useMetaBuyingTable,
  useReviewModal,
  useSelectRow,
  useTableData,
  useTableHeadersRenderer,
} from "../MetaBuyingUtils";
import MoreActionsMenu from "./Components/MoreActionsMenu";
import { createPortal } from "react-dom";
import { Tooltip } from "react-bootstrap";
import { ReviewModalTab, ReviewModalVariant } from "./Components/ReviewModal";

interface MetaBuyingDraftsProps {
  isInternal: boolean;
  selectedAdAccount: AdAccountInfo;
  setSelectedAdAccount: React.Dispatch<React.SetStateAction<AdAccountInfo>>;
  adAccountOptions: AdAccountInfo[];
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  adRows: AdRow[];
}

const MetaBuyingDrafts: React.FC<MetaBuyingDraftsProps & RouteComponentProps> = React.memo(
  ({
    isInternal,
    selectedAdAccount,
    setSelectedAdAccount,
    adAccountOptions,
    campaignRows,
    adSetRows,
    adRows,
  }: MetaBuyingDraftsProps & RouteComponentProps): JSX.Element => {
    // Used to manage table data
    const {
      setFilter,
      selectedLevel,
      setSelectedLevel,
      selectedRows,
      setSelectedRows,
      selectAll,
      setSelectAll,
      tableData,
      filteredTableData,
    }: UseTableData = useTableData({
      isInternal,
      campaignRows,
      adSetRows,
      adRows,
    });

    const selectRow: (row: MetaBuyingTableRow, remove?: boolean) => void = useSelectRow({
      setSelectedRows,
      selectedLevel,
    });

    const tableHeadersRenderer: ({
      data,
      columnIndex,
    }: any) => JSX.Element = useTableHeadersRenderer({
      filteredTableData,
      selectAll,
      setSelectAll,
      selectedRows,
      setSelectedRows,
      selectedLevel,
    });

    const buttonRefs = useRef({}); // Object to hold refs for each row
    const getButtonPosition: (
      rowId: string
    ) => {
      top: number;
      left: number;
    } = useGetButtonPosition({ buttonRefs, selectedLevel });
    const menuRef: React.LegacyRef<HTMLDivElement> | undefined = useRef(null);
    const [activeActionMenuRow, setActiveActionMenuRow] = useState<MetaBuyingTableRow>();
    // Close menu if clicking outside of it
    useEffect(() => {
      const handleOutsideClick = event => {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target) &&
          !buttonRefs.current[activeActionMenuRow?.id || ""]?.contains(event.target)
        ) {
          const filteredTableRows = Object.values(
            selectedRows[selectedLevel] as Record<string, MetaBuyingTableRow>
          ).filter(row => row.id !== activeActionMenuRow?.id);
          // Filter out current active row from selected rows
          setSelectedRows(current => {
            let newSelectedRows = {};
            for (let row of filteredTableRows) {
              newSelectedRows[row.id] = row;
            }

            return {
              ...current,
              [selectedLevel]: newSelectedRows,
            };
          });
          setActiveActionMenuRow(undefined);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [activeActionMenuRow, menuRef, buttonRefs, selectedRows, selectedLevel, setSelectedRows]);

    // Used to manage alert dialogs
    const {
      alertDialogType,
      alertDialogActivatedRow,
      openAlertDialog,
      alertDialogComponent,
    }: UseAlertDialog = useAlertDialog({
      isInternal,
      selectedLevel,
      selectedRows,
      setSelectedRows,
      selectRow,
      adSetRows,
      adRows,
    });

    // Used to manage review modal
    const {
      setReviewModalActivatedRow,
      reviewModalSetSelectedTab,
      reviewModalComponent,
    }: UseReviewModal = useReviewModal({
      reviewModalVariant: ReviewModalVariant.DRAFT,
      selectedLevel,
      campaignRows,
      adSetRows,
      isInternal,
      selectRow,
      openAlertDialog,
    });

    const tableColumns: Header[] = useMemo(() => {
      let headers: Header[] = [
        {
          label: "",
          name: "id",
          width: 40,
          nonInteractive: true,
          renderer: (row: MetaBuyingTableRow) => {
            return (
              <CheckBox
                className="checkboxCell"
                checked={R.has(row.id, selectedRows[selectedLevel])}
                onCheck={() => selectRow(row)}
              />
            );
          },
        },
      ];

      // For client facing table
      if (!isInternal) {
        headers.push(
          ...[
            {
              label: "Ad",
              name: "ad_name",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="adNameCell highlighted">{row.ad_name}</div>;
              },
            },
            {
              label: "Ad Set",
              name: "adset_name",
              flex: 4,
              renderer: (row: AdSetRow) => {
                return <div className="adSetNameCell">{row.adset_name}</div>;
              },
            },
            {
              label: "Campaign",
              name: "campaign_name",
              flex: 4,
              renderer: (row: CampaignRow) => {
                return <div className="campaignNameCell">{row.campaign_name}</div>;
              },
            },
            {
              label: "Created On",
              name: "created",
              width: 130,
              renderer: (row: MetaBuyingTableRow) => {
                return <div className="createdCell">{row.created}</div>;
              },
            },
            {
              width: 381,
              nonInteractive: true,
              renderer: (row: AdRow) => {
                return (
                  <div className="actionsCell">
                    <Button
                      className="viewAdPreviewButton"
                      variant={ButtonFrameworkVariant.TRAILING_ICON}
                      icon={<MdRemoveRedEye />}
                      type={ButtonType.OUTLINED}
                      size="sm"
                      onClick={() => {
                        setSelectedRows(current => {
                          return { ...current, ad: { [row.id]: row } };
                        });
                        setReviewModalActivatedRow(row);
                        reviewModalSetSelectedTab(ReviewModalTab.AD_PREVIEW);
                      }}
                    >
                      View Ad Preview
                    </Button>
                    {row.approval_stage === MBApprovalStage.NONE && (
                      <div className="approvalPlaceholder">No Approval Requests</div>
                    )}
                    {row.approval_stage === MBApprovalStage.IN_REVIEW && (
                      <div className="approvalSection">
                        <div className="approvalLabel">Approve?</div>
                        <Button
                          className="approveButton"
                          variant={ButtonFrameworkVariant.ICON_ONLY}
                          icon={<MdCheck />}
                          type={ButtonType.OUTLINED}
                          size="sm"
                          onClick={() => {
                            let filteredAdRows = Object.values(
                              selectedRows.ad as Record<string, AdRow>
                            ).filter(ad => ad.approval_stage === MBApprovalStage.IN_REVIEW);
                            // Only ads that are in review can be approved
                            setSelectedRows(current => {
                              let newSelectedAds = {};
                              for (let ad of filteredAdRows) {
                                newSelectedAds[ad.id] = ad;
                              }

                              return {
                                ...current,
                                ad: { ...newSelectedAds, [row.id]: row },
                              };
                            });
                            openAlertDialog(MBAlertDialogType.APPROVED, row);
                          }}
                        />
                        <Button
                          className="rejectButton"
                          variant={ButtonFrameworkVariant.ICON_ONLY}
                          icon={<MdClose />}
                          type={ButtonType.OUTLINED}
                          size="sm"
                          onClick={() => {
                            let filteredAdRows = Object.values(
                              selectedRows.ad as Record<string, AdRow>
                            ).filter(ad => ad.approval_stage === MBApprovalStage.IN_REVIEW);
                            // Only ads that are in review can be rejected
                            setSelectedRows(current => {
                              let newSelectedAds = {};
                              for (let ad of filteredAdRows) {
                                newSelectedAds[ad.id] = ad;
                              }

                              return {
                                ...current,
                                ad: { ...newSelectedAds, [row.id]: row },
                              };
                            });
                            openAlertDialog(MBAlertDialogType.CHANGES_REQUESTED, row);
                          }}
                        />
                      </div>
                    )}
                    {row.approval_stage === MBApprovalStage.CHANGES_REQUESTED && (
                      <div className="approvalSection">
                        <Button
                          className="unapprovedButton"
                          variant={ButtonFrameworkVariant.TRAILING_ICON}
                          type={ButtonType.OUTLINED}
                          size="sm"
                          icon={<MdCancel />}
                          onClick={() => {
                            let filteredAdRows = Object.values(
                              selectedRows.ad as Record<string, AdRow>
                            ).filter(ad => {
                              return (
                                ad.approval_stage === MBApprovalStage.CHANGES_REQUESTED ||
                                ad.approval_stage === MBApprovalStage.APPROVED
                              );
                            });
                            // Keep ads that are approved or have changes requested selected since
                            // only those need to be updated to be in review
                            setSelectedRows(current => {
                              let newSelectedAds = {};
                              for (let ad of filteredAdRows) {
                                newSelectedAds[ad.id] = ad;
                              }

                              return {
                                ...current,
                                ad: { ...newSelectedAds, [row.id]: row },
                              };
                            });
                            openAlertDialog(MBAlertDialogType.STATUS_REMOVED, row);
                          }}
                        >
                          Unapproved
                        </Button>
                      </div>
                    )}
                    {row.approval_stage === MBApprovalStage.APPROVED && (
                      <div className="approvalSection">
                        <Button
                          className="approvedButton"
                          variant={ButtonFrameworkVariant.TRAILING_ICON}
                          type={ButtonType.OUTLINED}
                          size="sm"
                          icon={<MdCancel />}
                          onClick={() => {
                            let filteredAdRows = Object.values(
                              selectedRows.ad as Record<string, AdRow>
                            ).filter(ad => {
                              return (
                                ad.approval_stage === MBApprovalStage.CHANGES_REQUESTED ||
                                ad.approval_stage === MBApprovalStage.APPROVED
                              );
                            });
                            // Keep ads that are approved or have changes requested selected since
                            // only those need to be updated to be in review
                            setSelectedRows(current => {
                              let newSelectedAds = {};
                              for (let ad of filteredAdRows) {
                                newSelectedAds[ad.id] = ad;
                              }

                              return {
                                ...current,
                                ad: { ...newSelectedAds, [row.id]: row },
                              };
                            });
                            openAlertDialog(MBAlertDialogType.STATUS_REMOVED, row);
                          }}
                        >
                          Approved
                        </Button>
                      </div>
                    )}
                  </div>
                );
              },
            },
          ]
        );
      } else {
        if (selectedLevel === "ad") {
          headers.push(
            ...[
              {
                label: "Ad",
                name: "ad_name",
                flex: 4,
                renderer: (row: AdRow) => {
                  return <div className="adNameCell highlighted">{row.ad_name}</div>;
                },
              },
              {
                label: "Ad Set",
                name: "adset_name",
                flex: 4,
                renderer: (row: AdSetRow) => {
                  return <div className="adSetNameCell">{row.adset_name}</div>;
                },
              },
              {
                label: "Campaign",
                name: "campaign_name",
                flex: 4,
                renderer: (row: CampaignRow) => {
                  return <div className="campaignNameCell">{row.campaign_name}</div>;
                },
              },
            ]
          );
        } else if (selectedLevel === "adset") {
          headers.push(
            ...[
              {
                label: "Ad Set",
                name: "adset_name",
                flex: 4,
                renderer: (row: AdSetRow) => {
                  return <div className="adSetNameCell highlighted">{row.adset_name}</div>;
                },
              },
              {
                label: "Campaign",
                name: "campaign_name",
                flex: 4,
                renderer: (row: CampaignRow) => {
                  return <div className="campaignNameCell">{row.campaign_name}</div>;
                },
              },
            ]
          );
        } else {
          headers.push({
            label: "Campaign",
            name: "campaign_name",
            flex: 4,
            renderer: (row: CampaignRow) => {
              return <div className="campaignNameCell highlighted">{row.campaign_name}</div>;
            },
          });
        }

        headers.push(
          ...[
            {
              label: "Created On",
              name: "created",
              width: 130,
              renderer: (row: MetaBuyingTableRow) => {
                return <div className="createdCell">{row.created}</div>;
              },
            },
            {
              label: "Creator",
              name: "lastuser",
              flex: 4,
              renderer: (row: MetaBuyingTableRow) => {
                return <div className="creatorCell">{row.lastuser}</div>;
              },
            },
          ]
        );
        if (selectedLevel === "ad") {
          headers.push(
            {
              label: "Client-Facing",
              name: "client_facing",
              width: 130,
              renderer: (row: AdRow) => {
                let toggleOn;
                if (
                  alertDialogType === MBAlertDialogType.CLIENT_FACING_DISABLED &&
                  alertDialogActivatedRow?.id === row.id
                ) {
                  // If we are turning off the row's toggle
                  toggleOn = false;
                } else if (
                  alertDialogType === MBAlertDialogType.CLIENT_FACING_ENABLED &&
                  alertDialogActivatedRow?.id === row.id
                ) {
                  // If we are turning on the row's toggle
                  toggleOn = true;
                } else if (
                  alertDialogType === MBAlertDialogType.CLIENT_FACING_DISABLED &&
                  selectedRows.ad[row.id]
                ) {
                  // If row is selected and we are turning off another row's toggle
                  toggleOn = false;
                } else if (
                  alertDialogType === MBAlertDialogType.CLIENT_FACING_ENABLED &&
                  selectedRows.ad[row.id]
                ) {
                  // If row is selected and we are turning on another row's toggle
                  toggleOn = true;
                } else if (row.client_facing) {
                  toggleOn = true;
                } else {
                  toggleOn = false;
                }

                return (
                  <div className="clientFacingCell">
                    <ToggleSwitch
                      label={""}
                      labelPosition="none"
                      checked={toggleOn}
                      onChange={() => {
                        if (row.client_facing) {
                          const filteredAdRows = Object.values(
                            selectedRows.ad as Record<string, AdRow>
                          ).filter(ad => ad.client_facing);
                          // Keep ads that are client facing selected since
                          // only those need to be updated to not be client facing
                          setSelectedRows(current => {
                            let newSelectedAds = {};
                            for (let ad of filteredAdRows) {
                              newSelectedAds[ad.id] = ad;
                            }

                            return {
                              ...current,
                              ad: { ...newSelectedAds, [row.id]: row },
                            };
                          });
                          openAlertDialog(MBAlertDialogType.CLIENT_FACING_DISABLED, row);
                        } else {
                          const filteredAdRows = Object.values(
                            selectedRows.ad as Record<string, AdRow>
                          ).filter(ad => !ad.client_facing);
                          // Keep ads that are not client facing selected since
                          // only those need to be updated to be client facing
                          setSelectedRows(current => {
                            let newSelectedAds = {};
                            for (let ad of filteredAdRows) {
                              newSelectedAds[ad.id] = ad;
                            }

                            return {
                              ...current,
                              ad: { ...newSelectedAds, [row.id]: row },
                            };
                          });
                          openAlertDialog(MBAlertDialogType.CLIENT_FACING_ENABLED, row);
                        }
                      }}
                    />
                  </div>
                );
              },
            },
            {
              label: "Approval Status",
              name: "approval_stage",
              width: 175,
              renderer: (row: AdRow) => {
                if (row.approval_stage === "NONE" || row.approval_stage === "CHANGES_REQUESTED") {
                  return (
                    <div className="requestApprovalCell">
                      {!row.notes && (
                        <Button
                          disabled={!row.client_facing}
                          type={ButtonType.FILLED}
                          design="secondary"
                          size="sm"
                          onClick={() => {
                            let filteredAdRows = Object.values(
                              selectedRows.ad as Record<string, AdRow>
                            ).filter(ad => {
                              return (
                                (ad.approval_stage === "NONE" ||
                                  ad.approval_stage === "CHANGES_REQUESTED") &&
                                ad.client_facing
                              );
                            });
                            // Filter out ads that aren't ready for requesting approval
                            setSelectedRows(current => {
                              let newSelectedAds = {};
                              for (let ad of filteredAdRows) {
                                newSelectedAds[ad.id] = ad;
                              }

                              return {
                                ...current,
                                ad: { ...newSelectedAds, [row.id]: row },
                              };
                            });
                            openAlertDialog(MBAlertDialogType.REQUEST_APPROVAL, row);
                          }}
                        >
                          Request Approval
                        </Button>
                      )}
                      {row.notes && (
                        <Button
                          className="unapprovedCell"
                          type={ButtonType.FILLED}
                          variant={ButtonFrameworkVariant.TRAILING_ICON}
                          icon={<MessageIcon color="white" />}
                          size="sm"
                          tooltipText={row.notes}
                          enableAnimation={false}
                          onClick={() => {
                            let filteredAdRows = Object.values(
                              selectedRows.ad as Record<string, AdRow>
                            ).filter(ad => {
                              return (
                                (ad.approval_stage === "NONE" ||
                                  ad.approval_stage === "CHANGES_REQUESTED") &&
                                ad.client_facing
                              );
                            });
                            // Filter out ads that aren't ready for requesting approval
                            setSelectedRows(current => {
                              let newSelectedAds = {};
                              for (let ad of filteredAdRows) {
                                newSelectedAds[ad.id] = ad;
                              }

                              return {
                                ...current,
                                ad: { ...newSelectedAds, [row.id]: row },
                              };
                            });
                            openAlertDialog(MBAlertDialogType.REQUEST_APPROVAL, row);
                          }}
                        >
                          Unapproved
                        </Button>
                      )}
                    </div>
                  );
                } else if (row.approval_stage === "IN_REVIEW") {
                  return <div className="pendingCell">Pending</div>;
                } else {
                  return row.notes ? (
                    <OverlayTrigger
                      placement="bottom center"
                      overlay={<Tooltip id={`id:${row.notes}`}>{row.notes}</Tooltip>}
                    >
                      <div className="approvedCell">
                        <div className="approvedCellLabel">Approved</div>
                        <MessageIcon color="black" />
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <div className="approvedCell">
                      <div className="approvedCellLabel">Approved</div>
                    </div>
                  );
                }
              },
            }
          );
        }

        headers.push({
          width: 247,
          nonInteractive: true,
          renderer: (row: MetaBuyingTableRow) => {
            const buttonPosition = getButtonPosition(row.id);

            return (
              <div className="actionsCell">
                <Button
                  variant={ButtonFrameworkVariant.TRAILING_ICON}
                  icon={<MdFacebook />}
                  size="sm"
                  type={ButtonType.FILLED}
                  onClick={() => {
                    openAlertDialog(MBAlertDialogType.PUBLISH_AS_PAUSED, row);
                    selectRow(row, SELECT_ROW);
                  }}
                >
                  Publish As Paused
                </Button>
                <div className="moreActionsContainer">
                  <Button
                    ref={el => {
                      buttonRefs.current[row.id] = el;
                    }} // Assign ref for each button
                    variant={ButtonFrameworkVariant.ICON_ONLY}
                    type={ButtonType.FILLED}
                    icon={<MdMoreVert />}
                    size="sm"
                    onClick={() => {
                      if (
                        selectedRows[selectedLevel][row.id] &&
                        activeActionMenuRow?.id === row.id
                      ) {
                        selectRow(row, DESELECT_ROW);
                      } else {
                        selectRow(row, SELECT_ROW);
                      }
                      setActiveActionMenuRow(activeActionMenuRow?.id === row.id ? undefined : row);
                    }}
                  />
                  {activeActionMenuRow?.id === row.id &&
                    createPortal(
                      <div
                        style={{
                          borderRadius: "6px",
                          backgroundColor: "white",
                          position: "fixed",
                          top: buttonPosition.top,
                          left: buttonPosition.left,
                          display: "flex",
                          zIndex: 1000,
                        }}
                        ref={menuRef}
                      >
                        <MoreActionsMenu
                          selectedRows={selectedRows}
                          selectedLevel={selectedLevel}
                          tab="drafts"
                          viewDetailsAction={() => {
                            reviewModalSetSelectedTab(ReviewModalTab.DETAILS);
                            setActiveActionMenuRow(undefined);
                            setReviewModalActivatedRow(row);
                          }}
                          viewAdPreviewAction={() => {
                            reviewModalSetSelectedTab(ReviewModalTab.AD_PREVIEW);
                            setActiveActionMenuRow(undefined);
                            setReviewModalActivatedRow(row);
                          }}
                          deleteAction={() => {
                            setActiveActionMenuRow(undefined);
                            openAlertDialog(MBAlertDialogType.DELETE, row);
                          }}
                        />
                      </div>,
                      document.body
                    )}
                </div>
              </div>
            );
          },
        });
      }

      return headers;
    }, [
      activeActionMenuRow,
      alertDialogActivatedRow,
      alertDialogType,
      getButtonPosition,
      isInternal,
      openAlertDialog,
      reviewModalSetSelectedTab,
      selectRow,
      selectedLevel,
      selectedRows,
      setReviewModalActivatedRow,
      setSelectedRows,
    ]);

    const tableComponent: JSX.Element = useMetaBuyingTable({
      className: "draftsTable",
      filteredTableData,
      columns: tableColumns,
      headersRenderer: tableHeadersRenderer,
    });

    const filterBar: JSX.Element = useFilterBar({
      pageTab: "drafts",
      tableData,
      isInternal,
      selectedLevel,
      setFilter,
    });

    return (
      <div className="metaBuyingDrafts">
        {reviewModalComponent}
        {alertDialogComponent}
        <MetaTableWidget
          isInternal={isInternal}
          title="Drafts"
          selectedAdAccount={selectedAdAccount}
          setSelectedAdAccount={setSelectedAdAccount}
          adAccountOptions={adAccountOptions}
          selectedLevel={selectedLevel}
          setSelectedLevel={setSelectedLevel}
          filterBar={filterBar}
          setFilter={setFilter}
          tableComponent={tableComponent}
          selectedRows={selectedRows}
        />
      </div>
    );
  }
);

export default MetaBuyingDrafts;
