import React, { useMemo } from "react";

import * as R from "ramda";

import { Dropdown as BSDropdown } from "react-bootstrap";

import { typedReactMemo } from "../../utils/types";

import "./DropdownToggle.scss";
import { DropdownToggleType } from "./Dropdown";

export type DropdownOption<T extends string = string> =
  | T
  | {
      label?: string;
      value: T;
    };

interface DropdownToggleProps<T extends string> {
  type?: DropdownToggleType;
  design?: "primary" | "secondary";
  background?: "light" | "dark";
  className?: string;
  label?: string | JSX.Element;
  value?: T;
  options: readonly DropdownOption<T>[];
  leadingIcon?: React.ReactElement;
  size?: "sm" | "lg";
  disabled?: boolean;
  placeholder?: string;
  width?: number;
  [passthroughProp: string]: any;
}

export const DropdownToggle = typedReactMemo(
  <T extends string>({
    type = DropdownToggleType.FILLED,
    design = "primary",
    background = "light",
    className,
    label,
    value,
    options,
    leadingIcon,
    size = "lg",
    disabled,
    placeholder,
    width,
  }: DropdownToggleProps<T>): JSX.Element => {
    const resolvedClassName = useMemo(() => {
      let classes = ["cl-dropdown-toggle", type, design, background, size, className];
      if (leadingIcon) {
        classes.push("has-leading-icon");
      }
      if (disabled) {
        classes.push("disabled");
      }

      return classes.join(" ");
    }, [type, design, background, size, className, leadingIcon, disabled]);

    const selectedOption = useMemo(
      () =>
        R.find(option => (typeof option === "string" ? option : option.value) === value, options) ||
        value,
      [value, options]
    );
    const title = useMemo(
      () => (
        <span className="dt-text">
          {label && (
            <span className="dt-label">{typeof label === "string" ? `${label}: ` : label}</span>
          )}
          {selectedOption ? (
            <span className="dt-value">{`${
              typeof selectedOption === "string"
                ? selectedOption
                : selectedOption?.label || selectedOption?.value
            }`}</span>
          ) : (
            <span className="dt-placeholder">{placeholder || ""}</span>
          )}
        </span>
      ),
      [label, selectedOption, placeholder]
    );

    if (leadingIcon) {
      return (
        <BSDropdown.Toggle className={resolvedClassName} disabled={disabled}>
          <div className="dt-icon">{leadingIcon}</div>
          {title}
        </BSDropdown.Toggle>
      );
    }
    return (
      <BSDropdown.Toggle className={resolvedClassName} disabled={disabled}>
        {title}
      </BSDropdown.Toggle>
    );
  }
);
