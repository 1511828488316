import React, { useState, useMemo } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Page, OverlayTrigger, SingleDatePicker, DateRangePicker } from "../Components";

import Clearance from "./LinearPacingClearance";
import Forecast from "./LinearPacingForecast";
import Detail from "./LinearPacingDetail";
import Detail2 from "../LinearPacing2/LinearPacingDetail2";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import { ToggleButtonGroup, ToggleButton, Tooltip } from "react-bootstrap";

import {
  DATE_FORMAT,
  GROSS_LABEL,
  NET_LABEL,
  ACCEPTED_LABEL,
  ALL_LABEL,
  DEFAULT_WEEKS_BACK,
  COMPANY_DIMENSION,
  COMPANY_LABEL,
  NETWORK_DIMENSION,
  NETWORK_LABEL,
} from "./LinearPacingUtils";
import "../StreamingPacing/StreamingPacing.scss";
import "./LinearPacing.scss";

const LINEAR_PACING_CLIENT_DETAIL = "Client Detail";
const LINEAR_PACING_CLEARANCE = "Historical Clearance";
const LINEAR_PACING_CLEARANCE_VS_FORECAST = "Clearance Vs. Forecast";
const LINEAR_PACING_CLIENT_DETAIL_V2 = "Client Detail V2 (by Campaign)";

const MEDIA_TYPE_UNSECURED = "Unsecured";
const MEDIA_TYPE_SECURED = "Secured";
const MEDIA_TYPE_UPFRONT = "Upfront";
const MEDIA_TYPE_ALL = "All";

const NAVS = [
  {
    key: LINEAR_PACING_CLIENT_DETAIL,
    label: LINEAR_PACING_CLIENT_DETAIL,
  },
  {
    key: LINEAR_PACING_CLEARANCE,
    label: LINEAR_PACING_CLEARANCE,
  },
  {
    key: LINEAR_PACING_CLEARANCE_VS_FORECAST,
    label: LINEAR_PACING_CLEARANCE_VS_FORECAST,
  },
  {
    key: LINEAR_PACING_CLIENT_DETAIL_V2,
    label: LINEAR_PACING_CLIENT_DETAIL_V2,
  },
];

const LinearPacing = () => {
  const [selectedView, setSelectedView] = useState(LINEAR_PACING_CLIENT_DETAIL);
  const [spendDimension, setSpendDimension] = useState(NET_LABEL);
  const [acceptedDimension, setAcceptedDimension] = useState(ACCEPTED_LABEL);
  const [mediaTypeDimension, setMediaTypeDimension] = useState(MEDIA_TYPE_ALL);
  const [primaryDimension, setPrimaryDimension] = useState(COMPANY_DIMENSION);

  const [detailStart, setDetailStart] = useState(
    Dfns.format(DATE_FORMAT, Dfns.startOfISOWeek(new Date()))
  );
  const [clearanceStartDate, setClearanceStartDate] = useState(
    R.pipe(
      Dfns.startOfISOWeek,
      Dfns.subWeeks(DEFAULT_WEEKS_BACK),
      Dfns.format(DATE_FORMAT)
    )(new Date())
  );
  const [clearanceEndDate, setClearanceEndDate] = useState(
    R.pipe(
      Dfns.startOfISOWeek,
      Dfns.subWeeks(Dfns.getISODay(new Date()) >= 4 ? 1 : 2),
      Dfns.format(DATE_FORMAT)
    )(new Date())
  );

  const upfrontView = useExperimentFlag("enableUpfrontLinearPacingView");

  const actions = useMemo(() => {
    if (
      selectedView === LINEAR_PACING_CLEARANCE ||
      selectedView === LINEAR_PACING_CLEARANCE_VS_FORECAST
    ) {
      return (
        <DateRangePicker
          startDate={clearanceStartDate}
          endDate={clearanceEndDate}
          startDateId="pacingStartDate"
          endDateId="pacingEndDate"
          mondayOnly
          onChange={({ startDate, endDate }) => {
            if (startDate && endDate) {
              setClearanceStartDate(startDate);
              setClearanceEndDate(endDate);
            }
          }}
        />
      );
    } else if (
      selectedView === LINEAR_PACING_CLIENT_DETAIL ||
      selectedView === LINEAR_PACING_CLIENT_DETAIL_V2
    ) {
      return (
        <div className="pacingDashboardActions">
          <div className="legend">
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
              overlay={
                <Tooltip id="tooltip_unsecured_delivery">
                  The sum of unsecured dollars reflected in pre-logs and/or electronic detections.
                </Tooltip>
              }
            >
              <div className="linearPacingLegendGroup">
                <div className="circle deliveryUnsecured" />
                <div className="legendLabel">On the logs (unsecured)</div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={
                <Tooltip id="tooltip_secured_delivery">
                  The sum of secured dollars reflected in pre-logs and/or electronic detections.
                </Tooltip>
              }
            >
              <div className="linearPacingLegendGroup">
                <div className="circle deliverySecured" />
                <div className="legendLabel">On the logs (secured)</div>
              </div>
            </OverlayTrigger>
            {upfrontView ? (
              <OverlayTrigger
                placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                overlay={
                  <Tooltip id="tooltip_upfront_delivery">
                    The sum of secured dollars reflected in pre-logs and/or electronic detections.
                  </Tooltip>
                }
              >
                <div className="linearPacingLegendGroup">
                  <div className="circle deliveryUpfront" />
                  <div className="legendLabel">On the logs (upfront)</div>
                </div>
              </OverlayTrigger>
            ) : null}
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={
                <Tooltip id="tooltip_pacing">
                  Total estimated clearance. Where we have pre-logs and/or electronic detection, we
                  take that as read; where we have neither, we assume a clearance rate based on the
                  historical trend for that network/avail.
                </Tooltip>
              }
            >
              <div className="linearPacingLegendGroup">
                <div className="circle pacing" />
                <div className="legendLabel">Pacing</div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={<Tooltip id="tooltip_booked">Total amount of booked media.</Tooltip>}
            >
              <div className="linearPacingLegendGroup">
                <div className="circle booked" />
                <div className="legendLabel">Booked</div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={
                <Tooltip id="tooltip_client_target">
                  The spend target for the week as directed by the client.
                </Tooltip>
              }
            >
              <div className="linearPacingLegendGroup">
                <div className="circle clientTarget" />
                <div className="legendLabel">Client Target</div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={
                <Tooltip id="tooltip_booked_target">
                  The IMPLIED spend target based on the amount of media we’ve booked and the
                  clearance assumptions we’ve made. Any material divergence from Client Target
                  signals a departure from booking strategy.
                </Tooltip>
              }
            >
              <div className="linearPacingLegendGroup">
                <div className="circle bookedTarget" />
                <div className="legendLabel">Booked Target</div>
              </div>
            </OverlayTrigger>
          </div>
          <ToggleButtonGroup
            type="radio"
            name="showCompanyOrNetworkToggle"
            value={primaryDimension}
            onChange={val => {
              setPrimaryDimension(val);
            }}
          >
            <ToggleButton
              key={COMPANY_DIMENSION}
              variant="primary"
              className="noOutline"
              value={COMPANY_DIMENSION}
            >
              {COMPANY_LABEL}
            </ToggleButton>
            <ToggleButton
              key={NETWORK_DIMENSION}
              variant="primary"
              className="noOutline"
              value={NETWORK_DIMENSION}
            >
              {NETWORK_LABEL}
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            type="radio"
            name="showGrossOrNetToggle"
            value={spendDimension}
            onChange={val => {
              setSpendDimension(val);
            }}
          >
            {[GROSS_LABEL, NET_LABEL].map(label => (
              <ToggleButton key={label} variant="primary" className="noOutline" value={label}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            type="radio"
            name="showAcceptedOrAllToggle"
            value={acceptedDimension}
            onChange={val => {
              setAcceptedDimension(val);
            }}
          >
            {[ACCEPTED_LABEL, ALL_LABEL].map(label => (
              <ToggleButton key={label} variant="primary" className="noOutline" value={label}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            type="radio"
            name="showMediaTypeToggle"
            value={mediaTypeDimension}
            onChange={val => {
              setMediaTypeDimension(val);
            }}
          >
            {upfrontView
              ? [MEDIA_TYPE_UNSECURED, MEDIA_TYPE_SECURED, MEDIA_TYPE_UPFRONT, MEDIA_TYPE_ALL].map(
                  label => (
                    <ToggleButton key={label} variant="primary" className="noOutline" value={label}>
                      {label}
                    </ToggleButton>
                  )
                )
              : [MEDIA_TYPE_UNSECURED, MEDIA_TYPE_SECURED, MEDIA_TYPE_ALL].map(label => (
                  <ToggleButton key={label} variant="primary" className="noOutline" value={label}>
                    {label}
                  </ToggleButton>
                ))}
          </ToggleButtonGroup>
          Week of:
          <SingleDatePicker
            mondayOnly
            date={detailStart}
            isOutsideRange={() => false}
            onChange={date => {
              if (date) {
                setDetailStart(date);
              }
            }}
          />
        </div>
      );
    }
  }, [
    selectedView,
    clearanceStartDate,
    clearanceEndDate,
    detailStart,
    spendDimension,
    primaryDimension,
    acceptedDimension,
    mediaTypeDimension,
    upfrontView,
  ]);

  return (
    <Page
      title="Linear Pacing"
      pageType="Linear Pacing"
      navs={NAVS}
      selectedNav={selectedView}
      onNav={setSelectedView}
      actions={actions}
    >
      {selectedView === LINEAR_PACING_CLIENT_DETAIL && (
        <Detail
          spendDimension={spendDimension}
          acceptedDimension={acceptedDimension}
          mediaTypeDimension={mediaTypeDimension}
          leftKey={primaryDimension}
          start={detailStart}
        />
      )}
      {selectedView === LINEAR_PACING_CLEARANCE && (
        <Clearance startDate={clearanceStartDate} endDate={clearanceEndDate} />
      )}
      {selectedView === LINEAR_PACING_CLEARANCE_VS_FORECAST && (
        <Forecast startDate={clearanceStartDate} endDate={clearanceEndDate} />
      )}
      {selectedView === LINEAR_PACING_CLIENT_DETAIL_V2 && (
        <Detail2
          spendDimension={spendDimension}
          acceptedDimension={acceptedDimension}
          mediaTypeDimension={mediaTypeDimension}
          leftKey={primaryDimension}
          start={detailStart}
        />
      )}
    </Page>
  );
};

export default LinearPacing;
