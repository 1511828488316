import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { playbookTable } from "../PlaybookTable";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Shopping";

export const methodHeaders = [
  {
    flex: 2,
    label: "Method",
    name: "Method",
    nonInteractive: true,
    renderer: (row: Record<string, any>): JSX.Element => {
      return (
        //Reuse the channel class from the Playbook.scss
        <div className="channelNames">
          <div className="channelName single">
            <div className="label">{row.Method}</div>
          </div>
        </div>
      );
    },
  },
  {
    flex: 2,
    label: "Method Type",
    name: "Method Type",
    nonInteractive: true,
    renderer: (row: Record<string, any>): JSX.Element => {
      let subItemIndex = -1;
      return (
        //Reuse the channel class from the Playbook.scss
        <div className="basicTextIcons">
          {R.map(methodType => {
            const { label, img } = methodType;
            subItemIndex++;
            return (
              <div
                className={`basicTextIcon ${subItemIndex === 0 ? "first" : ""} ${
                  subItemIndex === row["Method Type"].length - 1 ? "last" : ""
                }`}
                style={{ flex: 1 }}
              >
                <Img src={`${INC_CDN}/${img}`} />
                <div className="label"> {label}</div>
              </div>
            );
          }, row["Method Type"])}
        </div>
      );
    },
  },
  {
    flex: 3,
    label: "Description",
    name: "Description",
    nonInteractive: true,
    renderer: (row: Record<string, any>): JSX.Element => {
      return <div className="bulletedList">{row.Description}</div>;
    },
  },
  {
    flex: 3,
    label: "Pros",
    name: "Pros",
    nonInteractive: true,
    renderer: (row: Record<string, any>): JSX.Element => {
      return <div className="bulletedList">{row.Pros}</div>;
    },
  },
  {
    flex: 3,
    label: "Cons",
    name: "Cons",
    nonInteractive: true,
    renderer: (row: Record<string, any>): JSX.Element => {
      return <div className="bulletedList">{row.Cons}</div>;
    },
  },
];

export const CommonShoppingSections = typedReactMemo<
  React.FC<{ pageTitle: string; sections?: string[] }>
>(
  ({
    pageTitle,
    sections = [
      "Incrementality Testing Approaches",
      "Media Options & Considerations",
      "Google Power Calculation",
      "Signal Enrichment",
      "Sample Output",
    ],
  }) => {
    const contents = (
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(section => {
              return (
                <a
                  href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                  onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                >
                  {section}
                </a>
              );
            }, sections)}
          </div>
        }
        pageTitle={pageTitle}
        title={"Contents"}
      />
    );
    const availableApproaches = (
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "Conversion Lift for Users",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native",
                    },
                    {
                      img: "Icon_ServiceModel_ManagedService.png",
                      label: "Managed Service",
                    },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>
                        Conversion Lift for users is designed to measure the number of incremental
                        conversions driven by your Google advertising.
                      </li>
                      <li>
                        At its core, Conversion Lift looks to answer the question: “What was the
                        true, causal impact of my ads at driving incremental sales and conversions?”
                      </li>
                      <li>It is availble in Google Ads</li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>More sensitive - Eliminates noise due to impression based tracking.</li>
                      <li>
                        Impression-tracking of control group users allows us to compare the behavior
                        of users who received the ad impression versus those users who would have
                        been served the ad impression.
                      </li>
                      <li>
                        Cross-device enabled for a truly user-based view (limited to signed-in users
                        for CTV and heavily dependent on modeled conversion for iOS).
                      </li>
                      <li>
                        Cheaper - 90-99% less expensive than other methods (no need to pay for PSA
                        ads either).
                      </li>
                      <li>
                        70 / 30 split for treatment vs control group reduces your campaigns’
                        opportunity costs.
                      </li>
                      <li>
                        Fast - Initial results available after ~7 days, with statistically
                        significant results available when confidence levels reached (campaign max
                        of 56 days).
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Measurement Gaps - Significantly impacted by user privacy changes, inability
                        to track logged out users x-channel.
                      </li>
                      <li>
                        Transparency - Low visibility into underlying data. Theoretical visibility
                        into methodology.
                      </li>
                      <li>
                        Visibility - Lift results for one Google channel or format only, depending
                        on campaign setup. Not cross-platform or for comparison of 2 channels or
                        formats.
                      </li>
                    </ul>
                  ),
                },

                {
                  Method: "Conversion Lift for Geo",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native (betas)",
                    },
                    {
                      img: "Icon_ExperimentMode_OffPlatform.png",
                      label: "Off Platform",
                    },
                    { img: "Icon_ServiceModel_SelfService.png", label: "Self Service" },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>
                        Geo experiments leverage DMAs/GMAs as non-overlapping geographic regions
                        (‘geos’) with minimal potential contamination (i.e. user crossover),randomly
                        assigning them to treatment and control groups.
                      </li>
                      <li>
                        It includes making one major investment change per treatment group, allowing
                        any lift in success metrics between the groups to be attributed to the
                        difference in investment.
                      </li>
                      <li>
                        It is available in Google Ads. GeoX Google-led power analysis is also
                        available outside of Google Ads.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Accurate Ground Truth Measurement with Granular Insights - Gain a
                        comprehensive understanding of the incremental impact of marketing on a wide
                        range of business outcomes, including but not limited to clicks, web
                        traffic, transactions, revenue, and the acquisition of new customers. This
                        insight is based on a robust, post-3PCD clean control/exposed lift result.
                      </li>
                      <li>
                        Establish causality - Isolate what happens as a direct result of digital
                        marketing.
                      </li>
                      <li>
                        Direct Actionability - Scientific evaluation and optimization of media
                        plans.
                      </li>
                      <li>Google in-platform Geo-X can run across channels in Ads.</li>
                    </ul>
                  ),

                  Cons: (
                    <ul className="basicList">
                      <li>
                        Cost - May require high investment or going dark; Determined during test
                        design. There will be loss in revenue from turing off geos’.
                      </li>
                      <li>
                        Measurement Gaps - Minor contamination of users purchasing outside the DMA
                        where they've seen the ad. This can be mitigated by leveraging GMAs
                        (generalized marketing area), which are composed of aggregated postal codes
                        drawn such that their boundaries run through the least populous areas
                        possible, and do not cross well known commutes
                      </li>
                      <li>
                        Implementation Complexity - Resource intensive, and running multiple in a
                        period or sequentially requires significant cross-functional coordination.
                      </li>
                      <li>
                        Google in-platform Ads-based pixel; offline would be external Google managed
                        service geo based or Tinuiti geo-based test.
                      </li>
                    </ul>
                  ),
                },
              ],
              methodHeaders,
              index => 700
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={pageTitle}
        title={"Incrementality Testing Approaches"}
      />
    );
    const mediaOptions = (
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              <p>
                To initiate an incrementality study across Google media, anyone interested in
                conducting a conversion lift (user-based) can easily initiate the process by
                submitting this{" "}
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeLrz3LKFGxEl98ev9oKoJuWxHVQH1sdE7q8ksGgbPPqbS3HQ/viewform?usp=header_link">
                  form
                </a>
                . By doing so, you'll notify both the conversion lift teams at Tinuiti and Google,
                who will subsequently get in touch with you and provide guidance throughout the
                process.
              </p>
              <p>
                For Geo Lift or if anyone has additional questions, please send an email to the{" "}
                <a href="mailto:googleincrementality@tinuiti.com">googleincrementality</a>
                @tinuiti.com for support.
              </p>
            </div>
            <br />
            <div className="basicHeader">Supported Test Types</div>
            <ol className="basicList">
              <li>
                Conversion Lift for Users
                <ul>
                  <li>Experiment Mode: Platform Native</li>
                  <li>
                    Service Model: Managed service
                    <ul>
                      <li>
                        If channel is a Google managed service, reach out to Google team for
                        allowlisting and setup
                      </li>
                    </ul>
                  </li>
                  <li>
                    Feasibility Check: <strong>Test must pass google feasibility checks</strong>{" "}
                    (i.e. test plan must have sufficient statistical power) to qualify for launch.
                    Please leverage calculator below for pre test planning.
                  </li>
                  <li>
                    <strong>Est. Timing</strong>
                    <ul>
                      <li>
                        Test Planning and Design: <strong>~4 weeks before campaign launch</strong>
                      </li>
                      <li>
                        Live Test - <strong>~4-6 weeks</strong>
                      </li>
                      <li>
                        Post Test Analysis - <strong>~2 weeks</strong>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Here are{" "}
                    <a href="https://docs.google.com/document/d/1V0WnDTASDmjpoI6ISvA7WYIvEBVlmuJAvYIiW8C8BCI/edit?resourcekey=0-UldUBrbMe1mxq72J81baxA#heading=h.samm227lohz6">
                      Google best practices for Conversion Lift experiments
                    </a>
                    , emphasizing the importance of setting up campaigns to maximize incremental
                    conversions through strategic adjustments, including feasibility estimates,
                    conversion funnel actions, bidding strategies, attribution models, audience
                    targeting, experiment duration, budgeting, and creative alignment.
                  </li>
                </ul>
              </li>
              <li>
                Conversion Lift for Geos (Geo Lift)
                <ul>
                  <li>Experiment Mode: Off Platform & Platform Native</li>
                  <li>
                    Service Model: Managed Service & Self service
                    <ul>
                      <li>
                        If channel is a Google managed service, reach out to Google team for
                        allowlisting and setup
                      </li>
                    </ul>
                  </li>
                  <li>
                    Feasibility Check: For in-platform (platform native) CL for Geo solution, Google
                    do not have minimum recommended volume.{" "}
                    <strong>
                      A power analysis will run in-platform looking at historical data for 3x the
                      length of the flight being planned
                    </strong>{" "}
                    (i.e. if you’re hoping to run a test on a 30 day campaign, you’d need 90 days of
                    historical data in the account to power the analysis)
                  </li>
                  <li>
                    Google strongly suggest leveraging accounts with most conversions tracked via
                    Google Ads tags (roadmap TBD on offline conversions, GA4 conversions, etc. to
                    leverage for the test){" "}
                  </li>
                  <li>
                    <strong>Est. Timing</strong>
                    <ul>
                      <li>
                        Test Planning and Design: <strong>~4 weeks before campaign launch</strong>
                      </li>
                      <li>
                        Live Test - <strong>~4-8 weeks</strong>
                      </li>
                      <li>
                        Post Test Analysis - <strong>~2 weeks</strong>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        }
        id={"media-options-&-considerations"}
        pageTitle={pageTitle}
        title={"Media Options & Considerations"}
      />
    );
    const sampleOutput = (
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicHeader">Conversion Lift for Users</div>
            <iframe
              allowFullScreen={true}
              sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox allow-downloads allow-modals allow-storage-access-by-user-activation"
              src="https://docs.google.com/spreadsheets/d/1D7hKzuoh9_g-YzvyMvacZ76a-SPkP-Gz8mEmmNk6SbE/edit?gid=1731444618#gid=1731444618"
              title="Conversion Lift for Users"
            />
            <br />
            <div className="basicHeader">Conversion Lift for Geos</div>
            <iframe
              allowFullScreen={true}
              sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox allow-downloads allow-modals allow-storage-access-by-user-activation"
              src="https://docs.google.com/presentation/d/1rVUK9aEhmztuVPKiacZL3yyQ0mKU9Z76rRqOyG9IxlA/embed?authuser=0&amp;slide=11"
              title="Conversion Lift for Geos"
            />
            <br />
            <p>
              Feel free to{" "}
              <a href="https://docs.google.com/presentation/d/1F2ggZMOOhdlHxdb4wzFgf536-NSLrk6qKlyNZfeHfMk/edit#slide=id.g278e1be2cd6_0_1374">
                leverage this Google deck
              </a>{" "}
              for best practices on how to showcase the incrementality results data
            </p>
          </div>
        }
        id={"sample-output"}
        pageTitle={pageTitle}
        title={"Sample Output"}
      />
    );
    const signalEnrichment = (
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              For all Google incrementality products that rely on a user-based protocol – thus
              excluding geo experiments – it is strongly recommended that{" "}
              <a href="https://support.google.com/google-ads/answer/9888656">
                Enhanced Conversions
              </a>{" "}
              be implemented in order to strengthen conversion modeling and increase the overall
              fidelity of measurement. This is especially important for tests of lower-funnel
              outcomes.
            </div>
            <br />
            <div className="basicText">
              Enhanced Conversions is a protocol for sending hashed first-party conversion data from
              a brand’s website to Google in order to improve the accuracy of conversion measurement
              and unlock more powerful bidding. The basic idea is that a site owner, using data they
              collect on site, can help fill in gaps in Google’s ability to understand user
              behaviors post-ad exposure.
            </div>
            <br />
            <div className="basicText">
              This sort of protocol (below) is part of the industry’s response to third-party cookie
              deprecation, which has limited the ability to maintain coherent pictures of user
              conversion journeys across platforms.
            </div>
            <br />
            <Img
              src={`${INC_CDN}/EnhancedConversionForWeb.png`}
              style={{ height: 400, width: 800 }}
            />
            <br />
            <div className="basicText">
              If a brand wants to include{" "}
              <a href="https://support.google.com/displayvideo/answer/10956727?hl=en">
                mobile conversions
              </a>{" "}
              as an outcome in a Conversion Lift study, then an MMP integration is required. Here
              are the{" "}
              <a href="https://ads.google.com/intl/en_us/home/?sjid=15880728450552318259-NA#?modal_active=none">
                App Attribution Partners
              </a>{" "}
              that is supported by Google.
            </div>
          </div>
        }
        id={"signal-enrichment"}
        pageTitle={pageTitle}
        title={"Signal Enrichment"}
      />
    );
    const googlePowerCalculation = (
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <iframe
              allowFullScreen={true}
              sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox allow-downloads allow-storage-access-by-user-activation"
              src="https://bppm.shinyapps.io/youtube_power_calc/"
              title="Google Power Calculation"
            />
            <br />
            <div className="basicText">
              To ensure a powered test, Google gives the guidance of 3,000 - 5,000 conversions. The
              above Calculator gives you cost and number of impressions estimated to run a Google
              conversion lift for users.
            </div>
          </div>
        }
        id={"google-power-calculation"}
        pageTitle={pageTitle}
        title={"Google Power Calculation"}
      />
    );
    const sectionMap = {
      "Incrementality Testing Approaches": availableApproaches,
      "Media Options & Considerations": mediaOptions,
      "Sample Output": sampleOutput,
      "Signal Enrichment": signalEnrichment,
      "Google Power Calculation": googlePowerCalculation,
    };
    return (
      <>
        {contents}
        {R.map(section => sectionMap[section], sections)}
      </>
    );
  }
);

const Shopping = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              Shopping ads, sometimes referred to as product listing ads or PLAs, are the product
              ads that appear at the top of Google search results.
            </div>
            <br />
            <div className="basicText">
              Of note, methods outlined below are similar across google platforms for Search,
              Shopping, Display ( DV360) & Demand Gen (fka Discovery).
            </div>
            <br />
            <Img src={`${INC_CDN}/Google_Shopping.png`} />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <CommonShoppingSections pageTitle={PAGE_TITLE} />
    </div>
  );
});

export default Shopping;
