import "./CustomerInsights.scss";
import React, { useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import {
  KnowYourCustomerConfigRow,
  KnowYourCustomerData,
  KnowYourCustomerDataResponse,
} from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import {
  Button,
  ButtonType,
  Dropdown,
  DropdownToggleType,
  FullPageSpinner,
  IconToggleButton,
  Page,
} from "../Components";
import { useCompanyInfo } from "../redux/company";
import { awaitJSON, MiscLambdaFetch } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import CustomerInsightsGroup from "./CustomerInsightsGroup";
import { format } from "date-fns";
import CustomerInsightsGeoGroup from "./CustomerInsightsGeoGroup";
import { useTabbedNav } from "../utils/hooks/useNav";
import { RouteComponentProps } from "@reach/router";
import CustomerInsightsHeader from "./CustomerInsightsHeader";
import {
  CUSTOMER_INSIGHTS_ORDERED_GROUPS,
  CustomerInsightsFields,
  NAVS,
  SORT_DROPDOWN_OPTIONS,
  SortOptions,
  TabKey,
  ViewOptions,
} from "./customerInsightsConstants";
import { MdOutlineTableRows, MdShowChart } from "react-icons/md";

const CustomerInsights = React.memo(({ navigate }: RouteComponentProps) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "customer-insights",
    defaultKey: TabKey.CUSTOMER_BASE,
  });
  const { cid: company } = useCompanyInfo();
  const setError = useSetError();
  const [data, setData] = useState<Record<string, KnowYourCustomerData[]>>();
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [matchRate, setMatchRate] = useState<{
    matchedPopulation: number;
    totalPopulation: number;
    fraction: number;
  }>();
  const [fetching, setFetching] = useState<boolean>(true);
  const [pageSortValue, setPageSortValue] = useState<SortOptions>("alphabetical");
  const [pageGraphView, setPageGraphView] = useState<ViewOptions>("showGraph");

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        try {
          const configList = await MiscLambdaFetch<{ company: string }>(
            "/list_know_your_customer_configs",
            {
              method: "GET",
              params: {
                company,
              },
            }
          );
          const configListJson: KnowYourCustomerConfigRow[] = await awaitJSON(configList);

          const allDefaults = configListJson.filter(row => row.is_default);
          const defaultConfig = allDefaults.length > 0 ? allDefaults[0] : undefined;

          if (!defaultConfig) {
            throw new Error("No default Know Your Customer config found");
          }

          const configData = await MiscLambdaFetch<{ id: string }>(
            "/get_know_your_customer_config",
            {
              method: "GET",
              params: {
                id: String(defaultConfig.id),
              },
            }
          );
          const { rows, lastUpdated }: KnowYourCustomerDataResponse = await awaitJSON(configData);
          setLastUpdated(new Date(lastUpdated));

          const typedRows: KnowYourCustomerData[] = rows.map(row => {
            return {
              clientPopulation: Number(row.client_population),
              clientPopulationFraction: Number(row.client_population_fraction),
              fieldName: row.field_name,
              levels: row.levels,
              tuPopulation: Number(row.tu_population),
              tuPopulationFraction: Number(row.tu_population_fraction),
            };
          });
          const groupedRows = R.groupBy(row => row.fieldName, typedRows);

          const matchRateRows = groupedRows[CustomerInsightsFields.MATCH_RATE];
          if (matchRateRows && matchRateRows.length > 0) {
            const matchRate = matchRateRows[0];
            setMatchRate({
              matchedPopulation: matchRate?.clientPopulation,
              totalPopulation: Math.round(
                matchRate?.clientPopulation / matchRate?.clientPopulationFraction
              ),
              fraction: matchRate?.clientPopulationFraction,
            });
          }

          delete groupedRows[""];
          setData(groupedRows);
          setFetching(false);
        } catch (e: any) {
          setError(e.message);
          setFetching(false);
        }
      };

      fetchData();
    }
  }, [company, data, setError]);

  const body = useMemo(() => {
    if (!data && fetching) {
      return <FullPageSpinner />;
    }

    if (!data && !fetching) {
      return <div>TODO: Empty State</div>;
    }

    if (data) {
      return (
        <div className="customerInsightsBody">
          <CustomerInsightsHeader tab={tab} matchRate={matchRate} />
          <CustomerInsightsGroup
            fields={CUSTOMER_INSIGHTS_ORDERED_GROUPS.household}
            data={data}
            totalPopulation={matchRate?.totalPopulation ?? 0}
            header="What types of households do our customers reside in?"
            pageSortValue={pageSortValue}
            pageGraphView={pageGraphView}
            tab={tab}
          />
          <CustomerInsightsGroup
            fields={CUSTOMER_INSIGHTS_ORDERED_GROUPS.individual}
            data={data}
            totalPopulation={matchRate?.totalPopulation ?? 0}
            header="What types of individuals form our customer base?"
            pageSortValue={pageSortValue}
            pageGraphView={pageGraphView}
            tab={tab}
          />
          <CustomerInsightsGeoGroup
            data={data[CustomerInsightsFields.MSA]}
            totalPopulation={matchRate?.totalPopulation ?? 0}
            header="Where do our customers reside?"
            tab={tab}
          />
        </div>
      );
    }
  }, [data, fetching, matchRate, pageGraphView, pageSortValue, tab]);

  return (
    <Page
      app2Redesign
      title="Customer Insights"
      pageType="Customer Insights"
      navs={NAVS}
      selectedNav={tab}
      onNav={goToTab}
      actions={
        <div className="customerInsightsActions">
          {lastUpdated && <div>Last Updated: {format(lastUpdated, "M/d/yy h:mmaaa")}</div>}
          <Button
            type={ButtonType.FILLED}
            design="secondary"
            onClick={() => setError({ message: "TODO: Refresh not implemented" })}
          >
            Refresh
          </Button>
          <div className="sortContainer">
            <Dropdown
              type={DropdownToggleType.FILLED}
              value={pageSortValue}
              options={SORT_DROPDOWN_OPTIONS}
              onChange={change => setPageSortValue(change)}
            />
          </div>
          <IconToggleButton
            options={[
              { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
              { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
            ]}
            size="sm"
            selectedOption={pageGraphView ? "showGraph" : "showTable"}
            onChange={() =>
              setPageGraphView(prev => (prev === "showGraph" ? "showTable" : "showGraph"))
            }
          />
        </div>
      }
    >
      {body}
    </Page>
  );
});

export default CustomerInsights;
