// Experiment for enabling Brand Health Metrics page.
const brandHealthMetricsExternalCompanies: string[] = [
  "homechef",
  "instacart",
  "elf",
  "bonobos",
  "maurices",
  "joann",
  "liquidiv",
  "butcherbox",
  "poppi",
  "dsw",
  "tiktoksmb",
];

const brandHealthMetricsInternalCompanies: string[] = [
  "test",
  "unittest",
  "prospect",
  "tinuititest",
  "interview",
  "interviewppm",
  "ancestry",
];

export const shouldEnableBrandHealthMetrics = (company: string, userRole: number): boolean => {
  if (brandHealthMetricsExternalCompanies.includes(company) && userRole <= 10) {
    return true;
  } else if (brandHealthMetricsInternalCompanies.includes(company) && userRole <= 2) {
    return true;
  } else {
    return false; // page does not exist
  }
};
