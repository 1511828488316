import { YouTubePlanData } from "./YouTubePlanning";
import ToplineNumber from "./ToplineNumber";
import { formatNumber } from "../utils/format-utils";
import { Button, ButtonType } from "../Components";
import { MdClose, MdOutlineFileDownload } from "react-icons/md";
import { useCallback } from "react";
import "./Plan.scss";

interface PlanProps {
  planData?: YouTubePlanData;
  onClose: () => any;
}

export const Plan: React.FC<PlanProps> = ({ planData, onClose }) => {
  const downloadPlan = useCallback(() => {
    // TODO: functionality to export a plan
  }, []);

  return (
    <div className="youTubePlan">
      <div className="youTubePlanHeader">
        <div className="youTubePlanHeaderTextContainer">
          <div className="youTubePlanHeaderText">Recommendations and Estimates</div>
          <div className="youTubePlanHeaderSubtext">
            Maximize Views, $1.2M Budget, All Converters, Northwest
          </div>
        </div>
        <div className="youTubePlanHeaderControls">
          <Button
            className="youTubePlanDownloadButton"
            type={ButtonType.FILLED}
            onClick={downloadPlan}
          >
            <MdOutlineFileDownload />
          </Button>
          <Button className="youTubePlanCloseButton" type={ButtonType.EMPTY} onClick={onClose}>
            <MdClose />
          </Button>
        </div>
      </div>
      <div className="youTubePlanToplineNumbersContainer">
        <ToplineNumber
          label="Budget"
          value={`$${formatNumber(planData?.suggestedBudget || null)}`}
        />
        <ToplineNumber
          label="On Target Reach"
          value={formatNumber(planData?.onTargetReach || null)}
        />
        <ToplineNumber label="Avg. Frequency" value={formatNumber(planData?.averageFreq || null)} />
        <ToplineNumber label="CPM" value={formatNumber(planData?.cpm || null)} />
        <ToplineNumber
          label="YouTube Population"
          value={formatNumber(planData?.youTubePopulation || null)}
        />
      </div>
      <div className="youTubePlanSpendChartsContainer"></div>
      <div className="youTubePlanTable"></div>
    </div>
  );
};

export default Plan;
