import { format } from "date-fns";

/*
 * Converts a numerical value to a prettified string.
 */
export const formatNumber = (num: number | null, numDecimals?: number): string => {
  return (num || 0).toLocaleString("en-us", {
    maximumFractionDigits: numDecimals,
    minimumFractionDigits: numDecimals,
  });
};

/*
 * Converts a numerical value to a prettified string rounded to an integer value.
 */
export const formatNumberAsInt = (num: number | null): string => {
  return formatNumber(num || 0, 0);
};

/*
 * Converts a monetary value to a prettified string with the specified number of
 * decimal places.
 */
export const formatMoney = (value: number | null, numDecimals = 2): string => {
  return (value || 0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: numDecimals,
    minimumFractionDigits: numDecimals,
  });
};

/*
 * Converts a monetary value to a prettified string rounded to an integer value.
 */
export const formatMoneyAsInt = (value: number | null): string => {
  return formatMoney(value || 0, 0);
};

export const formatPacingNumber = (number: number, prefix: string): string => {
  if (Math.abs(number) > 1000000) {
    return `${prefix}${(number / 1000000).toFixed(prefix === "$" ? 3 : 1)}M`;
  }
  if (Math.abs(number) > 1000) {
    return `${prefix}${(number / 1000).toFixed(1)}K`;
  }
  return `${prefix}${Math.round(number).toLocaleString()}`;
};

// Converts a Date in any time zone to a Date in UTC
export const convertDateToUTC = (date: Date): Date => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

// Converts a Date in any time zone to a MM/dd/YYYY formatted string in UTC
export const formatDateInUTC = (date: Date): string => {
  return format(convertDateToUTC(date), "MM/dd/yyyy");
};

// Rounds a decimal to the specified number of digits after the decimal point
export const formatDecimal = (num: number, decimalPlace: number): string => {
  const multiple = 10 ** decimalPlace;
  const rounded = Math.round(num * multiple) / multiple;
  return rounded.toFixed(decimalPlace);
};

// Formats a percent to be rounded to a specified number of digits after the decimal point and include the % symbol
export const formatPercent = (percent: number, decimalPlace: number): string => {
  return `${formatDecimal(percent * 100, decimalPlace)}%`;
};
