import "./CompareMetrics.scss";
import { Metric, Metrics, MetricTotalsByDate } from "@blisspointmedia/bpm-types/dist/CrossChannel";
import { CrossChannelContext } from "../CrossChannel";
import { DateRange } from "../../utils/types";
import { downloadPNG, exportToExcel } from "../../utils/download-utils";
import { getCompareMetricsCartesianComponents } from "./cartesianComponents";
import { MdOutlineTableRows, MdShowChart, MdClose } from "react-icons/md";
import { DESCRIPTIVE_METRICS, OVERVIEW_TAB_METRIC_OPTIONS } from "../crossChannelConstants";
import { getPrettyMetricName } from "../crossChannelUtils";
import { XAxis, Tooltip, CartesianGrid, ComposedChart } from "recharts";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import ChartTooltipV2 from "../../Components/Charts/ChartTooltip/ChartTooltipV2";
import React, { useState, useMemo, useContext, useCallback } from "react";
import {
  ToggleSwitch,
  Dropdown,
  DropdownToggleType,
  IconToggleButton,
  DownloadDropdown,
  BPMTable,
} from "../../Components";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
  X_AXIS_PADDING,
  DATE_GROUPING_OPTIONS,
  DateGroupingKey,
} from "../../TVADCrossChannel/homePageConstants";
import ChartContainer from "../../Components/ChartContainer";
import {
  formatDateLabel,
  getGroupingValue,
  metricFormatter,
  RELATIVE_FORMATTER,
} from "../../TVADCrossChannel/homePageUtils";

const MAX_COMPARISON_METRICS = 3;

interface CompareMetricsProps {
  data: MetricTotalsByDate[];
  otherData: MetricTotalsByDate[];
  defaultMetrics?: Metric[];
  defaultIncludeOtherDates: boolean;
  pngDownloadLabel?: string;
  pngFileIdentifiers?: string[];
  dates?: DateRange;
  otherDates?: DateRange;
  defaultDateGroupingKey?: DateGroupingKey;
  dateIncrementOptions?: DateGroupingKey[];
  includeFooter?: boolean;
  metricOptions?: { label: string; value: Metric }[];
}

/**
 * Depending on the date grouping selected (Day, Week, Month, etc.), sum up the metrics for each date.
 */
const totalsByDateIncrement = (
  data: MetricTotalsByDate[],
  dateGrouping: DateGroupingKey
): MetricTotalsByDate[] => {
  // The data is at the daily level by default, so no need to aggregate.
  if (dateGrouping === "Day") {
    return data;
  }

  const totalsByDate: Record<string, Metrics> = {};
  for (const item of data) {
    const { date, metrics } = item;
    const dateToUse = getGroupingValue(date, dateGrouping as DateGroupingKey);
    totalsByDate[dateToUse] = totalsByDate[dateToUse] || {};
    totalsByDate[dateToUse] = R.mergeWith(R.add, totalsByDate[dateToUse] || {}, metrics);
  }
  // For metrics that require calculations, we need to redo that here.
  for (const date of R.keys(totalsByDate)) {
    const metrics = totalsByDate[date];
    totalsByDate[date] = {
      ...metrics,
      acos: (metrics.spend / metrics.revenue) * 100.0,
      aov: metrics.revenue / metrics.volume,
      clicksConversionRate: (metrics.volume / metrics.clicks) * 100.0,
      cpc: metrics.spend / metrics.clicks,
      cpm: (metrics.spend / metrics.impressions) * 1000,
      cpx: metrics.spend / metrics.volume,
      ctr: (metrics.clicks / metrics.impressions) * 100.0,
      impsConversionRate: (metrics.volume / metrics.impressions) * 100.0,
      roas: metrics.revenue / metrics.spend,
    } as any;
  }

  return Object.entries(totalsByDate)
    .map(([date, totals]) => ({ date, metrics: totals }))
    .sort((a, b) => a.date.localeCompare(b.date));
};

/**
 * Combine data and otherData into one array so that they can be visualized on the same chart, even
 * though they can be of differing lengths/date ranges.
 * Add "_other" suffix to the keys of the comparison data to avoid conflicts.
 */
const joinData = (
  data: MetricTotalsByDate[],
  otherData: MetricTotalsByDate[],
  showPercentChange: boolean,
  defaultIncludeOtherDates: boolean
) => {
  const maxLength = defaultIncludeOtherDates
    ? Math.max(data.length, otherData.length)
    : data.length;
  let joined: Record<string, any>[] = [];
  for (let i = 0; i < maxLength; i++) {
    const item = data[i] || {};
    const otherItem = otherData[i] || {};
    if (showPercentChange) {
      const percentChangeItem = R.keys(item.metrics).reduce((acc, key) => {
        const value = R.pathOr(0, ["metrics", key], item);
        const otherValue = R.pathOr(0, ["metrics", key], otherItem);
        acc[key] = otherValue === 0 ? "-" : ((value - otherValue) * 100.0) / otherValue;
        return acc;
      }, {});
      joined.push({
        date: item.date,
        date_other: otherItem.date,
        metrics: percentChangeItem,
      });
    } else {
      // Rename keys of comparison data
      const renamedOtherItem = R.keys(otherItem).reduce((acc, key) => {
        acc[`${key}_other`] = otherItem[key];
        return acc;
      }, {});

      joined.push({
        ...item,
        ...renamedOtherItem,
      });
    }
  }

  return joined;
};

const CompareMetrics: React.FC<CompareMetricsProps> = React.memo(
  ({
    data,
    otherData,
    defaultMetrics,
    defaultIncludeOtherDates,
    pngDownloadLabel,
    pngFileIdentifiers = [],
    dates: datesInput,
    otherDates: otherDatesInput,
    defaultDateGroupingKey = "Day",
    dateIncrementOptions,
    includeFooter = false,
    metricOptions = OVERVIEW_TAB_METRIC_OPTIONS,
  }) => {
    const [groupingKey, setGroupingKey] = useState(defaultDateGroupingKey);
    const [primaryMetric, setPrimaryMetric] = useState<Metric>(
      defaultMetrics && defaultMetrics[0] ? defaultMetrics[0] : Metric.SPEND
    );
    const [comparisonMetrics, setComparisonMetrics] = useState<Metric[]>(
      defaultMetrics && defaultMetrics.length > 1
        ? R.filter(metric => !R.isNil(metric), defaultMetrics.slice(1))
        : [Metric.IMPRESSIONS]
    );
    const [selectedToggleOption, setSelectedToggleOption] = useState<string>("showGraph");
    const [includeOtherDateRange, setIncludeOtherDateRange] = useState<boolean>(
      defaultIncludeOtherDates
    );
    const [showPercentChange, setShowPercentChange] = useState(false);
    const [hoveredLegendItem, setHoveredLegendItem] = useState<string>("");
    const [focusedMetric, setFocusedMetric] = useState<string>("");

    const {
      dates: contextDates,
      otherDates: contextOtherDates,
      platformAsSourceOfData,
    } = useContext(CrossChannelContext);
    const dates = R.defaultTo(contextDates, datesInput);
    const otherDates = R.defaultTo(contextOtherDates, otherDatesInput);

    const metricsToPrettyNameMap = useMemo(() => {
      const prettyNameMap = {};
      for (let metric of metricOptions) {
        prettyNameMap[metric.value] = metric.label;
      }
      return prettyNameMap;
    }, [metricOptions]);

    const footerContent = useMemo(() => {
      const metricPrettyNamesByGroup = [...comparisonMetrics, primaryMetric].reduce(
        (acc, cv) => {
          const isDescriptive = DESCRIPTIVE_METRICS.includes(cv);
          if (platformAsSourceOfData) {
            acc.platformMetricsInUse.push(metricsToPrettyNameMap[cv]);
          } else if (isDescriptive) {
            acc.platformMetricsInUse.push(metricsToPrettyNameMap[cv]);
          } else {
            acc.analyticMetricsInUse.push(metricsToPrettyNameMap[cv]);
          }

          if (isDescriptive) {
            acc.descriptiveMetricsInUse.push(metricsToPrettyNameMap[cv]);
          }
          return acc;
        },
        {
          descriptiveMetricsInUse: [] as string[],
          platformMetricsInUse: [] as string[],
          analyticMetricsInUse: [] as string[],
        }
      );
      const formattedFooterArr: string[] = [];

      if (!R.isEmpty(metricPrettyNamesByGroup.descriptiveMetricsInUse)) {
        formattedFooterArr.push(
          `KPI picker doesn't apply to ${metricPrettyNamesByGroup.descriptiveMetricsInUse.join(
            ", "
          )}`
        );
      }
      if (!R.isEmpty(metricPrettyNamesByGroup.platformMetricsInUse)) {
        formattedFooterArr.push(
          `Source for ${metricPrettyNamesByGroup.platformMetricsInUse.join(", ")}: Platform`
        );
      }
      if (!R.isEmpty(metricPrettyNamesByGroup.analyticMetricsInUse)) {
        formattedFooterArr.push(
          `Source for ${metricPrettyNamesByGroup.analyticMetricsInUse.join(", ")}: Source of Truth`
        );
      }

      return formattedFooterArr;
    }, [comparisonMetrics, metricsToPrettyNameMap, platformAsSourceOfData, primaryMetric]);

    const dateGroupingOptions = useMemo(() => {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(
        Math.abs((new Date(dates.start).getTime() - new Date(dates.end).getTime()) / oneDay)
      );
      const diffOtherDays = R.isNil(otherDates)
        ? 0
        : Math.round(
            Math.abs(
              (new Date(otherDates.start).getTime() - new Date(otherDates.end).getTime()) / oneDay
            )
          );
      const maxDateRange = R.max(diffDays, diffOtherDays);
      const dateGroupingsAllowed =
        maxDateRange >= 365
          ? 4
          : maxDateRange >= 90
          ? 3
          : maxDateRange >= 30
          ? 2
          : maxDateRange >= 7
          ? 1
          : 0;
      const filteredDateGroupOptions: {
        label: string;
        value: "Day" | "Week" | "Month" | "Quarter" | "Year";
        className?: string;
      }[] = [];
      for (let i = 0; i < DATE_GROUPING_OPTIONS.length; i++) {
        filteredDateGroupOptions.push({
          label: DATE_GROUPING_OPTIONS[i].label,
          value: DATE_GROUPING_OPTIONS[i].value,
          className: dateGroupingsAllowed < i ? "disabled" : "",
        });
      }
      if (!R.isNil(dateIncrementOptions)) {
        const newOptions = R.filter(
          option => dateIncrementOptions.includes(option.value),
          filteredDateGroupOptions
        );
        newOptions[0].className = "";
        if (!newOptions.map(option => option.value).includes(groupingKey)) {
          setGroupingKey(newOptions[0].value);
        }
        return newOptions;
      }
      return filteredDateGroupOptions;
    }, [dateIncrementOptions, dates, groupingKey, otherDates]);

    const { dataGroupedByDateIncrement, otherDataGroupedByDateIncrement } = useMemo(() => {
      const dataGroupedByDateIncrement = totalsByDateIncrement(data, groupingKey);
      const otherDataGroupedByDateIncrement = totalsByDateIncrement(otherData, groupingKey);
      return { dataGroupedByDateIncrement, otherDataGroupedByDateIncrement };
    }, [data, otherData, groupingKey]);

    const joinedData = useMemo(
      () =>
        joinData(
          dataGroupedByDateIncrement,
          otherDataGroupedByDateIncrement,
          showPercentChange,
          defaultIncludeOtherDates
        ),
      [
        dataGroupedByDateIncrement,
        otherDataGroupedByDateIncrement,
        showPercentChange,
        defaultIncludeOtherDates,
      ]
    );

    const comparisonDropdowns = useMemo(() => {
      return comparisonMetrics.map((metric, index) => (
        <>
          {/* Not allowed to remove the first comparison metric. Always have to be comparing at least 2 metrics to each other. */}
          {index !== 0 && (
            <div
              className="removeButton"
              onClick={() =>
                setComparisonMetrics(prev => {
                  prev.splice(index, 1);
                  return [...prev];
                })
              }
            >
              <MdClose />
            </div>
          )}
          <Dropdown
            key={index}
            type={DropdownToggleType.WIDGET_TITLE}
            value={metric}
            options={R.sort((a, b) => a.label.localeCompare(b.label), metricOptions)}
            onChange={option =>
              setComparisonMetrics(prev => {
                prev[index] = option;
                return [...prev];
              })
            }
          />
        </>
      ));
    }, [comparisonMetrics, metricOptions]);

    const { bars, lines, yAxes, legend } = useMemo(
      () =>
        getCompareMetricsCartesianComponents(
          primaryMetric,
          comparisonMetrics,
          dates,
          otherDates,
          includeOtherDateRange,
          showPercentChange,
          hoveredLegendItem,
          setHoveredLegendItem,
          focusedMetric,
          setFocusedMetric,
          metricsToPrettyNameMap
        ),
      [
        primaryMetric,
        comparisonMetrics,
        includeOtherDateRange,
        dates,
        otherDates,
        showPercentChange,
        hoveredLegendItem,
        setFocusedMetric,
        focusedMetric,
        metricsToPrettyNameMap,
      ]
    );

    const [headers, superHeaders] = useMemo(() => {
      let superHeaders;
      let headers: any[] = [
        {
          label: "Date",
          name: "date",
          width: 60,
          nonInteractive: true,
          renderer: (item: any) => formatDateLabel(item.date, "", true) || "-",
        },
        {
          label: metricsToPrettyNameMap[primaryMetric],
          name: primaryMetric,
          flex: 1,
          nonInteractive: true,
          renderer: (item: any) =>
            R.path(["metrics", primaryMetric], item) === "-"
              ? "-"
              : metricFormatter(showPercentChange ? "relative" : primaryMetric)(
                  R.pathOr(0, ["metrics", primaryMetric], item)
                ) || "-",
        },
      ];

      for (let metric of comparisonMetrics) {
        headers.push({
          label: metricsToPrettyNameMap[metric],
          name: metric,
          flex: 1,
          nonInteractive: true,
          renderer: (item: any) =>
            R.path(["metrics", metric], item) === "-"
              ? "-"
              : metricFormatter(showPercentChange ? "relative" : metric)(
                  R.pathOr(0, ["metrics", metric], item)
                ) || "-",
        });
      }

      if (includeOtherDateRange && !showPercentChange) {
        headers.push({
          label: metricsToPrettyNameMap[primaryMetric],
          name: primaryMetric,
          flex: 1,
          nonInteractive: true,
          renderer: (item: any) =>
            metricFormatter(primaryMetric)(R.pathOr(0, ["metrics_other", primaryMetric], item)) ||
            "-",
        });

        for (let metric of comparisonMetrics) {
          headers.push({
            label: metricsToPrettyNameMap[metric],
            name: metric,
            flex: 1,
            nonInteractive: true,
            renderer: (item: any) =>
              metricFormatter(metric)(R.pathOr(0, ["metrics_other", metric], item)) || "-",
          });
        }

        headers.push({
          label: "Date",
          name: "date_other",
          width: 60,
          nonInteractive: true,
          renderer: (item: any) => formatDateLabel(item.date_other, "", true) || "-",
        });

        const superHeaderSpan = comparisonMetrics.length + 2; // +1 for date, +1 for primaryMetric

        superHeaders = [
          { span: superHeaderSpan, data: "Primary Period" },
          { span: superHeaderSpan, data: "Comparison Period" },
        ];
      } else if (showPercentChange) {
        headers.push({
          label: "Date",
          name: "date_other",
          width: 60,
          nonInteractive: true,
          renderer: (item: any) => formatDateLabel(item.date_other, "", true) || "-",
        });

        const superHeaderSpan = comparisonMetrics.length + 1; // +1 for primaryMetric

        superHeaders = [
          { span: 1, data: "Primary Period" },
          {
            span: superHeaderSpan,
            data: "Percent change from Comparison Period to Primary Period",
          },
          { span: 1, data: "Comparison Period" },
        ];
      }
      return [headers, superHeaders];
    }, [
      metricsToPrettyNameMap,
      primaryMetric,
      includeOtherDateRange,
      comparisonMetrics,
      showPercentChange,
    ]);
    const fileNameIdentifiers = useMemo(() => {
      const date =
        DATE_GROUPING_OPTIONS.find(item => item.value === groupingKey)?.label || groupingKey;
      const metricPrettyNames = comparisonMetrics.map(
        metric => (metricOptions as any).find(item => item.value === metric)?.label || metric
      );
      const primaryMetricsPrettyName =
        (metricOptions as any).find(item => item.value === primaryMetric)?.label || primaryMetric;

      const fileNameItems = [
        ...pngFileIdentifiers,
        date,
        primaryMetricsPrettyName,
        ...metricPrettyNames,
        `${dates.start}–${dates.end}`,
      ];
      if (includeOtherDateRange) {
        fileNameItems.push(`vs_${otherDates.start}–${otherDates.end}`);
      }
      return fileNameItems;
    }, [
      groupingKey,
      comparisonMetrics,
      pngFileIdentifiers,
      dates.start,
      dates.end,
      includeOtherDateRange,
      metricOptions,
      otherDates.start,
      otherDates.end,
      primaryMetric,
    ]);

    const excelDownload = useCallback(() => {
      const unionData = [
        ...data.map(item => ({ date: item.date, ...item.metrics })),
        ...otherData.map(item => ({ date: item.date, ...item.metrics })),
      ];
      exportToExcel(unionData, `${fileNameIdentifiers.join("_")}`);
    }, [data, otherData, fileNameIdentifiers]);

    const pngDownload = useCallback(async () => {
      await downloadPNG(".compareMetricsContainer", fileNameIdentifiers, pngDownloadLabel, [
        ".rightSide",
      ]);
    }, [pngDownloadLabel, fileNameIdentifiers]);

    return (
      <div className={`compareMetricsContainer ${showPercentChange ? "showPercentChange" : ""}`}>
        <ChartContainer
          enableHoverDesign
          title={
            <>
              <Dropdown
                type={DropdownToggleType.WIDGET_TITLE}
                value={groupingKey}
                options={dateGroupingOptions} // TODO: calculate date grouping options to show
                onChange={option => setGroupingKey(option as DateGroupingKey)}
              />
              <Dropdown
                type={DropdownToggleType.WIDGET_TITLE}
                value={primaryMetric}
                options={R.sort((a, b) => a.label.localeCompare(b.label), metricOptions)}
                onChange={option => setPrimaryMetric(option)}
              />
              <div style={{ fontSize: 22 }}>vs</div>
              {comparisonDropdowns}
              {comparisonDropdowns.length < MAX_COMPARISON_METRICS && (
                <Dropdown
                  className="addComparisonMetricDropdown"
                  type={DropdownToggleType.FILLED}
                  design="secondary"
                  placeholder={"+"}
                  // @ts-ignore
                  value=""
                  //@ts-ignore
                  options={
                    R.sort(
                      (a, b) => a.label.localeCompare(b.label),
                      (metricOptions as {
                        label: string;
                        value: Metric;
                      }[]).filter(
                        option => ![...comparisonMetrics, primaryMetric].includes(option.value)
                      )
                    ) as {
                      label: string;
                      value: Metric;
                    }[]
                  }
                  onChange={(option: Metric) => setComparisonMetrics(prev => [...prev, option])}
                />
              )}
              <div className="titleDivider"></div>
              <div className="primaryDateRange">{`${formatDateLabel(
                dates.start
              )} – ${formatDateLabel(dates.end)}`}</div>
              {!includeOtherDateRange && (
                <div className="addOtherDateRange" onClick={() => setIncludeOtherDateRange(true)}>
                  +
                </div>
              )}
              {includeOtherDateRange && (
                <>
                  <div className="otherDateRange">{`vs ${formatDateLabel(
                    otherDates.start
                  )} – ${formatDateLabel(otherDates.end)}`}</div>
                  <div
                    className="removeButton"
                    onClick={() => {
                      setIncludeOtherDateRange(false);
                      setShowPercentChange(false);
                    }}
                  >
                    <MdClose />
                  </div>
                </>
              )}
            </>
          }
          rightActions={
            <>
              {includeOtherDateRange && (
                <ToggleSwitch
                  label="% Change"
                  checked={showPercentChange}
                  onChange={setShowPercentChange}
                />
              )}
              <IconToggleButton
                size="sm"
                options={[
                  { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
                  { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
                ]}
                selectedOption={selectedToggleOption}
                onChange={setSelectedToggleOption}
              />
              <DownloadDropdown
                size="sm"
                onClickOptions={[excelDownload, pngDownload]}
                disabledMenuOptions={
                  selectedToggleOption === "showGraph"
                    ? { XLSX: false, PNG: false }
                    : { XLSX: false, PNG: true }
                }
              />
            </>
          }
          footerContent={includeFooter ? footerContent : undefined}
        >
          {R.isEmpty(joinedData) ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                fontSize: "26px",
              }}
            >
              No data to show
            </div>
          ) : selectedToggleOption === "showGraph" ? (
            <div className="cm-Graph">
              {comparisonMetrics.length <= 1 && !showPercentChange && (
                <div className="cm-aboveGraph">
                  <div className="cm-leftTitle">
                    {metricsToPrettyNameMap[primaryMetric]
                      ? metricsToPrettyNameMap[primaryMetric]
                      : getPrettyMetricName(primaryMetric)}
                  </div>
                  <div className="cm-rightTitle">
                    {metricsToPrettyNameMap[comparisonMetrics[0]]
                      ? metricsToPrettyNameMap[comparisonMetrics[0]]
                      : getPrettyMetricName(comparisonMetrics[0])}
                  </div>
                </div>
              )}
              <div className="cm-GraphContents">
                <div id="cm-Graph" className="cm-Graph">
                  <AutoSizer>
                    {({ width, height }) => (
                      <ComposedChart data={joinedData} width={width} height={height}>
                        <CartesianGrid
                          vertical={false}
                          stroke={CARTESIAN_GRID_STROKE}
                          strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                        />
                        <Tooltip
                          content={({ label, payload, ...rest }) => {
                            const fullPayload = payload?.[0]?.payload || {};

                            let headerLabel = ""; // Label for the date
                            let items; // Metrics
                            let comparisonHeaderLabel = ""; // Label for the comparison date
                            let comparisonItems; // Comparison metrics

                            // If including other date range, we have to split out the
                            // payload into the two date ranges.
                            if (includeOtherDateRange) {
                              headerLabel = formatDateLabel(fullPayload.date, groupingKey, true);

                              comparisonHeaderLabel = formatDateLabel(
                                fullPayload.date_other,
                                groupingKey,
                                true
                              );

                              items = R.sortBy(
                                (item: any) => -Math.abs(item.value),
                                payload
                                  ?.filter(item =>
                                    R.pathOr("", ["dataKey"], item).includes("metrics.")
                                  )
                                  .map(item => {
                                    const { name, value, stroke } = item;
                                    const formattedValue = showPercentChange
                                      ? value === "-"
                                        ? "-"
                                        : RELATIVE_FORMATTER(value as number)
                                      : metricFormatter(((name || "") as string).toLowerCase())(
                                          value as number
                                        );
                                    return {
                                      label: name as string,
                                      value: formattedValue,
                                      color: stroke,
                                    };
                                  }) || []
                              );

                              comparisonItems = R.sortBy(
                                (item: any) => -Math.abs(item.value),
                                payload
                                  ?.filter(item =>
                                    R.pathOr("", ["dataKey"], item).includes("metrics_other.")
                                  )
                                  .map(item => {
                                    const { name, value, stroke } = item;
                                    const formattedValue = metricFormatter(
                                      ((name || "") as string).toLowerCase()
                                    )(value as number);
                                    return {
                                      label: name as string,
                                      value: formattedValue,
                                      color: stroke,
                                    };
                                  }) || []
                              );

                              if (showPercentChange) {
                                headerLabel = `${fullPayload.date} vs ${fullPayload.date_other}`;
                                comparisonHeaderLabel = ""; // No need to show the comparison date in this case
                              }
                            } else {
                              headerLabel = formatDateLabel(fullPayload.date, groupingKey, true);
                              items = R.sortBy(
                                (item: any) => -Math.abs(item.value),
                                payload?.map(item => {
                                  const { name, value, stroke } = item;
                                  const formattedValue = metricFormatter(
                                    ((name || "") as string).toLowerCase()
                                  )(value as number);
                                  return {
                                    label: name as string,
                                    value: formattedValue,
                                    color: stroke,
                                  };
                                }) || []
                              );
                            }

                            return (
                              <ChartTooltipV2
                                headers={[headerLabel, comparisonHeaderLabel]}
                                items={items}
                                comparisonItems={comparisonItems}
                              />
                            );
                          }}
                          isAnimationActive={false}
                        />
                        <XAxis
                          dataKey={
                            defaultIncludeOtherDates
                              ? R.length(data) + 1 >= R.length(otherData)
                                ? "date"
                                : "date_other"
                              : "date"
                          }
                          padding={X_AXIS_PADDING}
                          tick={TICK_STYLE}
                          tickFormatter={date => formatDateLabel(date, groupingKey)}
                          axisLine={false}
                        />
                        {yAxes}
                        {bars}
                        {lines}
                      </ComposedChart>
                    )}
                  </AutoSizer>
                </div>
                <div className="rightOfChart">{legend}</div>
              </div>
            </div>
          ) : (
            <BPMTable
              superHeaders={superHeaders}
              headers={headers}
              data={joinedData}
              filterBar={false}
              superHeaderHeight={30}
              headerHeight={30}
            />
          )}
        </ChartContainer>
      </div>
    );
  }
);

export default CompareMetrics;
