import React, { useState, useEffect } from "react";
import { Response, ClientInfo } from "@blisspointmedia/bpm-types/dist/Onboarding";
import useLocation from "../../utils/hooks/useLocation";
import WidgetContainer from "../../Components/WidgetContainer";
import { PLATFORM_OPTIONS, PlatformOptions } from "./PlatformChecklistOptions";
import OnboardingFreeForm from "../OnboardingFreeForm";
import { getCompletedPlatformPercentage } from "../OnboardingUtils";
import { indexOf } from "ramda";
import { ClientInfoComponentProps } from "../ClientInfo";

const PlatformChecklist: React.FC<ClientInfoComponentProps> = ({
  clientInfo,
  onClientInfoChange,
  onPercentageChange,
}) => {
  const { company } = useLocation();
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);
  const [tables, setTables] = useState<PlatformOptions | null>(null);

  useEffect(() => {
    if (tables === null) {
      const newTables: PlatformOptions = {};
      Object.keys(PLATFORM_OPTIONS).forEach(key => {
        const values = PLATFORM_OPTIONS[key];
        const responses = allResponses.responses.filter(response =>
          response.questionNumber.startsWith(values.simpleId)
        );
        if (responses.length === 0) {
          newTables[key] = {
            ...values,
          };
          return;
        }
        const table = responses.map(response => {
          return {
            id: (indexOf(response, responses) + 1).toString(),
            questionNumber: response.questionNumber,
            title: response.additionalInfo || "",
          };
        });
        newTables[key] = {
          ...values,
          table: {
            columns: values.table.columns,
            rows: table,
          },
        };
      });
      setTables(newTables);
    }
  }, [allResponses.responses, tables]);

  useEffect(() => {
    const totalPercentage = getCompletedPlatformPercentage(
      allResponses.responses,
      PLATFORM_OPTIONS
    );
    onPercentageChange(totalPercentage);
  }, [allResponses.responses, onPercentageChange]);

  const handlePlatformChecklistChange = (updatedResponses: Response[]) => {
    const uncheckedItems = updatedResponses
      .filter(response => {
        const mediaSelection = response.selections.find(
          sel => sel.label === "Do you run media on this platform?"
        );
        return mediaSelection && !mediaSelection.openText;
      })
      .map(response => response.additionalInfo);

    const cleanedResponses = updatedResponses.filter(response => {
      const isUnchecked = uncheckedItems.includes(response.parentPlatform);

      if (isUnchecked) {
        if (response.questionNumber.startsWith("1")) {
          response.selections = response.selections.map(sel => ({
            ...sel,
            openText: "",
          }));
          return true;
        }
        if (response.questionNumber.startsWith("2")) {
          if (!response.questionNumber.includes("-")) {
            response.selections = [];
            return true;
          }
          return false;
        }
        if (response.questionNumber.startsWith("5")) {
          return false;
        }
      }

      return true;
    });

    const updatedClientInfo = {
      ...allResponses,
      company,
      responses: cleanedResponses,
    };
    setAllResponses(updatedClientInfo);
    onClientInfoChange(updatedClientInfo);
  };

  const platformForm = (key: string, option) => {
    return (
      <div>
        <OnboardingFreeForm
          questions={option.table}
          responses={allResponses.responses}
          sectionKey={key}
          sectionId={option.simpleId}
          renderAddButton
          onDataChange={handlePlatformChecklistChange}
        />
      </div>
    );
  };

  return (
    <div>
      {tables &&
        Object.keys(PLATFORM_OPTIONS).map(key => {
          return (
            <WidgetContainer
              key={key}
              backgroundColor="white"
              header={PLATFORM_OPTIONS[key].title}
              design="secondary"
              collapsible
              startOpen={false}
            >
              {platformForm(key, tables[key])}
            </WidgetContainer>
          );
        })}
    </div>
  );
};

export default PlatformChecklist;
