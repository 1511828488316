import "./MetricsTable.scss";
import {
  CellData,
  ColumnMetaData,
  ColumnMetaDataMap,
  getExportedData,
  getHeatMap,
  getHeatMapInfo as getHeatMapInfoUtil,
  sortData,
  SuperHeader,
  TEXT_FONT_SIZE_MAP,
  TEXT_SIZE_MAP,
} from "./metricsTableUtils";
import {
  CellRenderer,
  CornerLocation,
  Img,
  OverlayTrigger,
  Placement,
  StickyTable,
  SuperTopItemType,
  Virtual,
} from "../../Components";
import { Column, DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import { makeMemoizedGetter } from "../../utils/data";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { SortInfo } from "@blisspointmedia/bpm-types/dist/Performance";
import { StateSetter } from "../../utils/types";
import { Tooltip } from "react-bootstrap";
import * as R from "ramda";
import * as uuid from "uuid";
import cn from "classnames";
import PercentAOIPlaceholder from "../../Performance/PercentAOIPlaceholder";
import React, { useCallback, useEffect, useMemo, useState } from "react";

interface RenderProps {
  classes?: string[];
  columnIndex: number;
  data:
    | CellData // Table Cells
    | Column // Table Headers
    | Record<string, CellData> // Dimension Cells
    | SuperHeader; // Super Headers
  rowIndex?: number;
  style?: object;
  virtual?: Virtual;
}

export interface OverrideParams {
  colPadding?: number;
  colWidth?: number | number[];
  dimensionColWidth?: number | number[];
  maxColWidth?: number;
  rowHeight?: number | number[];
  rowPadding?: number;
  headerTextHeight?: number;
}

interface MetricsTableProps {
  aggregateData: CellData[];
  columnMetaDataMap?: ColumnMetaDataMap;
  dataColumns: Column[];
  defaultSorting?: SortInfo[];
  dimensionColumns: DimensionColumn[];
  dimensionData: Record<string, CellData>[];
  disableMultiSorting?: boolean;
  exportedData?: (string | number)[][];
  height?: number;
  overrideParams?: OverrideParams;
  percentAOIPlaceholder?: number;
  role?: number; // We don't want to use to redux here because we cannot use it in storybook testing
  setExportedData?: StateSetter<(string | number)[][]>;
  superHeaders?: SuperTopItemType<SuperHeader>[];
  tableData: CellData[][];
  variant?: "Metrics" | "Widget" | "SparkChart";
  width?: number;
}

const DEFAULT_HEIGHT = 500;
const DEFAULT_WIDTH = 1204;

export const MetricsTable = React.memo<MetricsTableProps>(
  ({
    aggregateData = [],
    columnMetaDataMap = {},
    dataColumns = [],
    defaultSorting = [],
    dimensionColumns = [],
    dimensionData = [],
    disableMultiSorting = true,
    exportedData,
    height: inputHeight = DEFAULT_HEIGHT,
    overrideParams = {},
    percentAOIPlaceholder,
    role = 10,
    setExportedData = ((exportedData: (string | number)[][]) => {}) as StateSetter<
      (string | number)[][]
    >,
    superHeaders: superHeadersInput,
    tableData = [],
    variant = "Metrics",
    width: inputWidth = DEFAULT_WIDTH,
  }) => {
    const {
      colPadding: overrideColPadding,
      colWidth: overrideColWidth,
      dimensionColWidth: overrideDimensionColWidth,
      maxColWidth: overrideMaxColWidth,
      rowHeight: overrideRowHeight,
      rowPadding: overrideRowPadding,
      headerTextHeight: overrideHeaderTextHeight,
    } = overrideParams as OverrideParams;
    const COL_DIVIDER_BORDER_WIDTH = 2;
    const DIMENSION_COLUMNS_RIGHT_MARGIN =
      !R.isNil(overrideColPadding) || !R.isNil(overrideColWidth) ? 0 : 16;
    const FOOTER_CONTAINER_BOTTOM_PADDING = 16;
    const FOOTER_CONTAINER_TOP_PADDING = 8;
    const FOOTER_TABLE_PADDING = 32;
    const FOOTER_HEIGHT = 63 + FOOTER_TABLE_PADDING;
    const FOOTER_PADDING = variant === "SparkChart" ? 0 : 4;
    const HEADER_FOOTER_PADDING = variant === "SparkChart" ? 0 : 3;
    const HEADER_SUBHEADER_GAP = 4;
    const HEADER_TEXT_HEIGHT = !R.isNil(overrideHeaderTextHeight) ? overrideHeaderTextHeight : 23;
    const LEFT_BORDER_WIDTH = 4;
    const LOGO_PADDING = 16;
    const LOGO_WIDTH = 50;
    const MAX_COL_PADDING = variant === "Metrics" ? 72 : 24;
    const MAX_DIM_WIDTH = !R.isNil(overrideMaxColWidth)
      ? 2 * overrideMaxColWidth
      : variant === "Metrics"
      ? 224
      : variant === "SparkChart"
      ? 112
      : 56;
    const MAX_GRID_COL_WIDTH = !R.isNil(overrideMaxColWidth)
      ? overrideMaxColWidth
      : variant === "Widget"
      ? 56
      : 136;
    const MIN_COL_PADDING = variant === "Metrics" ? 12 : variant === "SparkChart" ? 8 : 4;
    const MIN_HEADER_CONTAINER_HEIGHT = 43;
    const MIN_ROW_HEIGHT = variant === "Widget" ? 24 : 28;
    const ROW_PADDING = !R.isNil(overrideRowPadding)
      ? overrideRowPadding
      : variant === "Metrics"
      ? 5
      : variant === "SparkChart"
      ? 4
      : 0;
    const SORTING_ICON_SIZE = 16;
    const SUB_HEADER_HEIGHT = 20;
    const SUPER_HEADER_PADDING = 16;
    const THREE_LINE_HEADER_TEXT_HEIGHT = 53;
    const THREE_LINE_ROW_HEIGHT = 58;
    const THUMBNAIL_WIDTH = 90;
    const TWO_LINE_HEADER_TEXT_HEIGHT = 38;
    const TWO_LINE_ROW_HEIGHT = 43;

    const dataHeaders: Column[] = useMemo(
      () =>
        R.map(
          column => ({
            ...column,
            id: R.defaultTo(uuid.v4(), column.id),
          }),
          R.filter(
            col => (col && col.role ? col.role >= role : true),
            dataColumns ? dataColumns : []
          ) as Column[]
        ),
      [dataColumns, role]
    );

    const dimensionDataHeaders: DimensionColumn[] = useMemo(
      () =>
        R.map(
          column => ({
            ...column,
            id: R.defaultTo(uuid.v4(), column.id),
          }),
          R.filter(
            col => (col && col.role ? col.role >= role : true),
            dimensionColumns ? dimensionColumns : []
          ) as DimensionColumn[]
        ),
      [dimensionColumns, role]
    );

    const hasAggregateData = useMemo(() => !R.isEmpty(aggregateData), [aggregateData]);
    const hasDimensionData = useMemo(
      () => !R.isEmpty(dimensionData) && !R.isEmpty(dimensionDataHeaders),
      [dimensionData, dimensionDataHeaders]
    );
    const TABLE_RIGHT_PADDING = hasDimensionData ? 16 : 0;
    const hasDataHeaders = useMemo(() => !R.isEmpty(dataHeaders), [dataHeaders]);
    const hasSuperHeaders = useMemo(
      () => !R.isNil(superHeadersInput) && !R.isEmpty(superHeadersInput),
      [superHeadersInput]
    );
    const SUPER_HEADER_HEIGHT = hasSuperHeaders ? 26 + 2 * 16 : 0;
    const [isLeftDataInitiallyRenderedRaw, setIsLeftDataInitiallyRendered] = useState<boolean>(
      false
    );
    const isLeftDataInitiallyRendered = !hasDimensionData || isLeftDataInitiallyRenderedRaw;
    const [isTableDataInitiallyRendered, setIsTableDataInitiallyRendered] = useState<boolean>(
      false
    );

    const { height, width } = useMemo(() => {
      setIsLeftDataInitiallyRendered(false);
      setIsTableDataInitiallyRendered(false);
      return { height: inputHeight, width: inputWidth };
    }, [inputHeight, inputWidth]);

    const [sorting, setSorting] = useState<SortInfo[]>(defaultSorting ? defaultSorting : []);

    const addSorting = useCallback(
      (id: string, disableMulti = false) => {
        //  If a user selects  an already-sorted column, it flips it if it's descending, and removes it otherwise.
        setSorting(sorting => {
          let newSorting: SortInfo[] = [];
          let sawSelf = false;
          for (const sortItem of sorting) {
            if (sortItem.id === id) {
              sawSelf = true;
              // If it's descending, then flip. Otherwise, skip.
              if (!sortItem.asc) {
                newSorting.push({ id, asc: true });
              }
            } else {
              newSorting.push(sortItem);
            }
          }
          if (!sawSelf) {
            if (disableMulti) {
              // If we pick an entirely new column to sort, we want to clear out
              // all the current sorting.
              newSorting = [{ id, asc: false }];
            } else {
              newSorting.push({ id, asc: false });
            }
          }
          return newSorting;
        });
        setExportedData([]);
      },
      [setExportedData]
    );

    const sort = useCallback(
      (
        dataHeaders: Column[],
        dimensionData: Record<string, CellData>[],
        dimensionDataHeaders: DimensionColumn[],
        sorting: SortInfo[],
        tableData: CellData[][]
      ) => {
        setIsLeftDataInitiallyRendered(false);
        setIsTableDataInitiallyRendered(false);
        return sortData(
          dataHeaders,
          dimensionData,
          dimensionDataHeaders,
          sorting,
          tableData,
          hasDimensionData
        );
      },
      [hasDimensionData]
    );

    const [sortedDimensionData, sortedTableData] = useMemo(
      () => sort(dataHeaders, dimensionData, dimensionDataHeaders, sorting, tableData),
      [sorting, sort, dataHeaders, dimensionData, dimensionDataHeaders, tableData]
    );
    const numDimCols = dimensionDataHeaders.length;
    const numDataCols = dataHeaders.length;
    const numCols = numDataCols + numDimCols;
    const numRows = tableData.length;

    useEffect(() => {
      if (R.isEmpty(exportedData) && sortedDimensionData && sortedTableData) {
        const exportedData = getExportedData({
          columnMetaDataMap,
          dataHeaders,
          dimensionDataHeaders,
          numDataCols,
          numDimCols,
          sortedDimensionData,
          sortedTableData,
        });
        setExportedData(exportedData);
      }
    }, [
      columnMetaDataMap,
      dataHeaders,
      dimensionDataHeaders,
      exportedData,
      numDataCols,
      numDimCols,
      setExportedData,
      sortedDimensionData,
      sortedTableData,
    ]);

    const heatMap = useMemo(() => getHeatMap(dataHeaders, sortedTableData), [
      dataHeaders,
      sortedTableData,
    ]);

    const getHeatMapInfo = useMemo(() => {
      if (sortedTableData) {
        return makeMemoizedGetter({
          calculate: ({ columnIndex, value }: { columnIndex: number; value: number }) => {
            return getHeatMapInfoUtil(columnIndex, columnMetaDataMap, dataHeaders, heatMap, value);
          },
        });
      } else {
        return () => ({ backgroundColor: "transparent", outline: "none" });
      }
    }, [columnMetaDataMap, dataHeaders, heatMap, sortedTableData]);

    const innerCellRenderer = (
      innerCellContent,
      overlayText,
      overlayPlacement: Placement = "right center"
    ) => {
      return overlayText ? (
        <OverlayTrigger
          placement={overlayPlacement}
          overlay={<Tooltip id={overlayText}>{overlayText}</Tooltip>}
        >
          {innerCellContent}
        </OverlayTrigger>
      ) : (
        innerCellContent
      );
    };

    const superHeaders: SuperTopItemType<SuperHeader>[] = useMemo(
      () =>
        superHeadersInput && !R.isEmpty(superHeadersInput)
          ? superHeadersInput
          : [
              {
                data: { text: "", leftDivider: false, rightDivider: false },
                span: numDataCols,
              },
            ],
      [numDataCols, superHeadersInput]
    );

    const { leftColWidths, colWidths, heatMappingWidths } = useMemo(() => {
      const leftColWidths = {};
      const colWidths = {};
      const heatMappingWidths = {};

      if (isLeftDataInitiallyRendered && isTableDataInitiallyRendered) {
        for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
          const numCols = R.max(numDataCols, numDimCols);
          for (let colIndex = 0; colIndex < numCols; colIndex++) {
            if (rowIndex === 0) {
              const leftHeaderElement = document.getElementById(`left_header_col${colIndex}`);
              if (leftHeaderElement) {
                const header = R.defaultTo([], dimensionDataHeaders)[colIndex];
                // Include extra space for the scrollbar
                const genericColumnMetaData = columnMetaDataMap[header.dimensionTypeName];
                const extraImageGapWidth = header && header.icon === "hasIcon" ? 14 : 0;
                const extraImageWidth =
                  header && header.icon === "hasIcon"
                    ? genericColumnMetaData.contentType === "thumbnail"
                      ? THUMBNAIL_WIDTH + extraImageGapWidth
                      : LOGO_WIDTH + extraImageGapWidth
                    : 0;
                const elemWidth =
                  Math.ceil(
                    R.max(R.max(16, leftHeaderElement.offsetWidth), leftHeaderElement.scrollWidth) +
                      1
                  ) + SORTING_ICON_SIZE;
                if (leftColWidths[colIndex]) {
                  leftColWidths[colIndex] = R.min(
                    MAX_DIM_WIDTH + extraImageWidth,
                    R.max(leftColWidths[colIndex], elemWidth)
                  );
                } else {
                  leftColWidths[colIndex] = elemWidth;
                }
              }
              const headerElement = document.getElementById(`header_col${colIndex}`);
              if (headerElement) {
                // Include extra space for the scrollbar
                const elemWidth =
                  Math.ceil(
                    R.max(R.max(16, headerElement.offsetWidth), headerElement.scrollWidth) + 2
                  ) + SORTING_ICON_SIZE;
                if (colWidths[colIndex]) {
                  colWidths[colIndex] = R.min(
                    MAX_GRID_COL_WIDTH,
                    R.max(colWidths[colIndex], elemWidth)
                  );
                } else {
                  colWidths[colIndex] = elemWidth;
                }
              }
              const footerElement = document.getElementById(`footer_col${colIndex}`);
              if (footerElement) {
                // Include extra space for the scrollbar
                const elemWidth = Math.ceil(
                  R.max(R.max(16, footerElement.offsetWidth), footerElement.scrollWidth) +
                    SORTING_ICON_SIZE
                );
                if (colWidths[colIndex]) {
                  colWidths[colIndex] = R.min(
                    MAX_GRID_COL_WIDTH,
                    R.max(colWidths[colIndex], elemWidth)
                  );
                } else {
                  colWidths[colIndex] = elemWidth;
                }
              }
            }
            const leftElement = document.getElementById(`left_col${colIndex}_row${rowIndex}`);
            if (leftElement) {
              const header = R.defaultTo([], dimensionDataHeaders)[colIndex];
              // Include extra space for the scrollbar
              const genericColumnMetaData = columnMetaDataMap[header.dimensionTypeName];
              const extraImageGapWidth = header && header.icon === "hasIcon" ? 14 : 0;
              const extraImageWidth =
                header && header.icon === "hasIcon"
                  ? genericColumnMetaData.contentType === "thumbnail"
                    ? THUMBNAIL_WIDTH + extraImageGapWidth
                    : LOGO_WIDTH + extraImageGapWidth
                  : 0;
              const elemWidth =
                columnMetaDataMap[header.dimensionTypeName] &&
                ["logo", "thumbnail"].includes(
                  columnMetaDataMap[header.dimensionTypeName].contentType
                )
                  ? columnMetaDataMap[header.dimensionTypeName].contentType === "thumbnail"
                    ? THUMBNAIL_WIDTH + 2 * LOGO_PADDING
                    : LOGO_WIDTH + 2 * LOGO_PADDING
                  : Math.ceil(
                      R.max(R.max(16, leftElement.offsetWidth), leftElement.scrollWidth) + 2
                    );
              const height = R.max(
                R.max(MIN_ROW_HEIGHT, leftElement.scrollWidth),
                leftElement.scrollHeight
              );
              if (height > MIN_ROW_HEIGHT) {
                leftColWidths[colIndex] = MAX_DIM_WIDTH + extraImageWidth;
              } else if (leftColWidths[colIndex]) {
                leftColWidths[colIndex] = R.min(
                  MAX_DIM_WIDTH + extraImageWidth,
                  R.max(leftColWidths[colIndex], elemWidth + extraImageWidth)
                );
              } else {
                leftColWidths[colIndex] = elemWidth;
              }
            }
            const element = document.getElementById(`grid_col${colIndex}_row${rowIndex}`);
            if (element) {
              const elemWidth = Math.ceil(
                R.max(R.max(16, element.offsetWidth), element.scrollWidth) + 6
              );
              if (colWidths[colIndex]) {
                colWidths[colIndex] = R.min(
                  MAX_GRID_COL_WIDTH,
                  R.max(colWidths[colIndex], elemWidth)
                );
              } else {
                colWidths[colIndex] = elemWidth;
              }
              if (heatMappingWidths[colIndex]) {
                heatMappingWidths[colIndex] = R.min(
                  MAX_GRID_COL_WIDTH,
                  R.max(heatMappingWidths[colIndex], elemWidth)
                );
              } else {
                heatMappingWidths[colIndex] = elemWidth;
              }
            }
          }
        }
      }
      if (!R.isNil(overrideDimensionColWidth)) {
        for (let colIndex = 0; colIndex < R.keys(leftColWidths).length; colIndex++) {
          const overrideWidth: number = Array.isArray(overrideDimensionColWidth)
            ? overrideDimensionColWidth[colIndex]
            : overrideDimensionColWidth;
          if (!isNaN(overrideWidth)) {
            colWidths[colIndex] = overrideWidth;
          }
        }
      }
      if (!R.isNil(overrideColWidth)) {
        for (let colIndex = 0; colIndex < R.keys(colWidths).length; colIndex++) {
          const overrideWidth = Array.isArray(overrideColWidth)
            ? overrideColWidth[colIndex]
            : overrideColWidth;
          if (!isNaN(overrideWidth)) {
            colWidths[colIndex] = overrideWidth;
          }
        }
      }
      return {
        leftColWidths: R.keys(leftColWidths).length ? leftColWidths : undefined,
        colWidths: R.keys(colWidths).length ? colWidths : undefined,
        heatMappingWidths: R.keys(heatMappingWidths).length ? heatMappingWidths : undefined,
      };
    }, [
      isLeftDataInitiallyRendered,
      isTableDataInitiallyRendered,
      overrideDimensionColWidth,
      overrideColWidth,
      numRows,
      numDataCols,
      numDimCols,
      dimensionDataHeaders,
      columnMetaDataMap,
      MAX_DIM_WIDTH,
      MAX_GRID_COL_WIDTH,
      MIN_ROW_HEIGHT,
    ]);

    const leftWidth = useMemo(() => {
      let sum = LEFT_BORDER_WIDTH;
      R.map(colIndex => {
        if (leftColWidths && leftColWidths[colIndex]) {
          sum += leftColWidths[colIndex];
        }
        return colIndex;
      }, R.keys(leftColWidths));
      return hasDimensionData ? sum : 0;
    }, [hasDimensionData, leftColWidths]);

    const gridWidth = useMemo(() => {
      let sum = 0;
      R.map(colIndex => {
        if (colWidths && colWidths[colIndex]) {
          const columnWidth =
            colWidths && colWidths[colIndex]
              ? colWidths[colIndex]
              : MAX_GRID_COL_WIDTH + 2 * MAX_COL_PADDING;
          const borderWidth =
            dataHeaders && dataHeaders[colIndex] && dataHeaders[colIndex].divider
              ? 2 * COL_DIVIDER_BORDER_WIDTH
              : parseInt(colIndex) === R.keys(colWidths).length - 1
              ? 0.25
              : 0.5;
          sum += columnWidth + borderWidth;
        }
        return colIndex;
      }, R.keys(colWidths));
      return sum;
    }, [MAX_COL_PADDING, MAX_GRID_COL_WIDTH, colWidths, dataHeaders]);

    const rowHeights = useMemo(() => {
      const rowHeights: Record<string, number> = {};
      if (
        !colWidths ||
        !heatMappingWidths ||
        !isLeftDataInitiallyRendered ||
        !isTableDataInitiallyRendered ||
        (!dimensionData && hasDimensionData) ||
        (!leftColWidths && hasDimensionData) ||
        (!leftWidth && hasDimensionData) ||
        !dataHeaders
      ) {
        return;
      }
      for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
        const numCols = R.max(numDataCols, numDimCols);
        for (let colIndex = 0; colIndex < numCols; colIndex++) {
          if (rowIndex === 0) {
            const leftHeaderElement = document.getElementById(`left_header_col${colIndex}`);
            if (leftHeaderElement) {
              const elemHeight = R.max(
                R.max(MIN_ROW_HEIGHT, leftHeaderElement.offsetHeight),
                leftHeaderElement.scrollHeight
              );
              if (rowHeights.header) {
                rowHeights.header = R.max(rowHeights.header, elemHeight);
              } else {
                rowHeights.header = elemHeight;
              }
            }
            const headerElement = document.getElementById(`header_col${colIndex}`);
            if (headerElement) {
              const elemHeight = R.max(
                R.max(MIN_ROW_HEIGHT, headerElement.offsetHeight),
                headerElement.scrollHeight
              );
              if (rowHeights.header) {
                rowHeights.header = R.max(rowHeights.header, elemHeight);
              } else {
                rowHeights.header = elemHeight;
              }
            }
          }
          const leftElement = document.getElementById(`left_col${colIndex}_row${rowIndex}`);
          if (leftElement) {
            const elemHeight = R.max(
              R.max(MIN_ROW_HEIGHT, leftElement.offsetHeight),
              leftElement.scrollHeight
            );
            if (rowHeights[rowIndex]) {
              rowHeights[rowIndex] = R.max(rowHeights[rowIndex], elemHeight);
            } else {
              rowHeights[rowIndex] = elemHeight;
            }
          }
          const element = document.getElementById(`grid_col${colIndex}_row${rowIndex}`);
          if (element) {
            const elemHeight = R.max(
              R.max(MIN_ROW_HEIGHT, element.offsetHeight),
              element.scrollHeight
            );
            if (rowHeights[rowIndex]) {
              rowHeights[rowIndex] = R.max(rowHeights[rowIndex], elemHeight);
            } else {
              rowHeights[rowIndex] = elemHeight;
            }
          }
        }
      }

      for (const rowIndex of R.keys(rowHeights)) {
        let hasLogo = false;
        for (const dimKey of R.keys(dimensionData[rowIndex])) {
          if (dimensionData[rowIndex][dimKey].url) {
            hasLogo = true;
          }
        }
        if (rowHeights[rowIndex] && rowIndex !== "header") {
          if (!R.isNil(overrideRowHeight)) {
            rowHeights[rowIndex] = Array.isArray(overrideRowHeight)
              ? overrideRowHeight[rowIndex]
              : overrideRowHeight;
          } else if (
            (rowHeights[rowIndex] > TWO_LINE_ROW_HEIGHT + 2 * ROW_PADDING || hasLogo) &&
            variant !== "SparkChart"
          ) {
            rowHeights[rowIndex] = THREE_LINE_ROW_HEIGHT + 2 * ROW_PADDING;
          } else if (
            rowHeights[rowIndex] > MIN_ROW_HEIGHT + 2 * ROW_PADDING &&
            variant !== "SparkChart"
          ) {
            rowHeights[rowIndex] = TWO_LINE_ROW_HEIGHT + 2 * ROW_PADDING;
          } else {
            rowHeights[rowIndex] = MIN_ROW_HEIGHT + 2 * ROW_PADDING;
          }
        } else if (rowHeights[rowIndex] && rowIndex === "header") {
          if (rowHeights[rowIndex] > TWO_LINE_HEADER_TEXT_HEIGHT && variant !== "SparkChart") {
            rowHeights[rowIndex] = THREE_LINE_HEADER_TEXT_HEIGHT;
          } else if (rowHeights[rowIndex] > HEADER_TEXT_HEIGHT + 5 && variant !== "SparkChart") {
            // Add in the + 5 to account for the header defaulting to 28px for one line of text
            rowHeights[rowIndex] = TWO_LINE_HEADER_TEXT_HEIGHT;
          } else {
            rowHeights[rowIndex] = HEADER_TEXT_HEIGHT;
          }
        } else {
          rowHeights[rowIndex] = HEADER_TEXT_HEIGHT;
        }
      }
      return R.keys(rowHeights).length ? rowHeights : undefined;
    }, [
      colWidths,
      dataHeaders,
      dimensionData,
      hasDimensionData,
      heatMappingWidths,
      isLeftDataInitiallyRendered,
      isTableDataInitiallyRendered,
      leftColWidths,
      leftWidth,
      MIN_ROW_HEIGHT,
      numDataCols,
      numDimCols,
      numRows,
      overrideRowHeight,
      ROW_PADDING,
      variant,
      HEADER_TEXT_HEIGHT,
    ]);

    const cellPadding = useMemo(() => {
      if (!R.isNil(overrideColPadding)) {
        return overrideColPadding;
      }
      if (leftWidth && hasDimensionData && dataHeaders) {
        const remainingWidth =
          width - leftWidth - gridWidth - TABLE_RIGHT_PADDING - DIMENSION_COLUMNS_RIGHT_MARGIN;
        const cellPadding = R.min(
          MAX_COL_PADDING,
          R.max(MIN_COL_PADDING, Math.floor(remainingWidth / (2 * numCols)))
        );
        return cellPadding;
      }
      return MIN_COL_PADDING;
    }, [
      overrideColPadding,
      leftWidth,
      hasDimensionData,
      dataHeaders,
      MIN_COL_PADDING,
      width,
      gridWidth,
      TABLE_RIGHT_PADDING,
      DIMENSION_COLUMNS_RIGHT_MARGIN,
      MAX_COL_PADDING,
      numCols,
    ]);

    const columnWidths = useCallback(
      colIndex => {
        const columnWidth =
          colWidths && colWidths[colIndex]
            ? colWidths[colIndex] + 2 * cellPadding
            : MAX_GRID_COL_WIDTH + 2 * MAX_COL_PADDING;
        const borderWidth =
          dataHeaders && dataHeaders[colIndex] && dataHeaders[colIndex].divider
            ? 2 * COL_DIVIDER_BORDER_WIDTH
            : 0.25;
        if (!R.isNil(overrideColPadding) || !R.isNil(overrideColWidth)) {
          const overrideWidth = Array.isArray(overrideColWidth)
            ? overrideColWidth[colIndex]
            : overrideColWidth;
          return R.defaultTo(columnWidth, overrideWidth) + borderWidth;
        }
        return (
          columnWidth +
          borderWidth +
          (colIndex === 0 ? DIMENSION_COLUMNS_RIGHT_MARGIN : 0) +
          (colIndex === numDataCols - 1 ? TABLE_RIGHT_PADDING : 0)
        );
      },
      [
        colWidths,
        cellPadding,
        MAX_GRID_COL_WIDTH,
        MAX_COL_PADDING,
        dataHeaders,
        overrideColPadding,
        overrideColWidth,
        DIMENSION_COLUMNS_RIGHT_MARGIN,
        numDataCols,
        TABLE_RIGHT_PADDING,
      ]
    );

    const hasSubHeader = useMemo(() => {
      if (!!percentAOIPlaceholder || percentAOIPlaceholder === 0) {
        return true;
      }
      if (dataHeaders) {
        return R.any(({ subHeader }) => !!subHeader, dataHeaders);
      }
    }, [dataHeaders, percentAOIPlaceholder]);

    const headerContainerHeight = useMemo(
      () =>
        hasDataHeaders
          ? R.max(
              rowHeights && rowHeights.header
                ? rowHeights.header + (hasSubHeader ? HEADER_SUBHEADER_GAP + SUB_HEADER_HEIGHT : 0)
                : MIN_HEADER_CONTAINER_HEIGHT,
              MIN_HEADER_CONTAINER_HEIGHT
            )
          : 0,
      [hasDataHeaders, hasSubHeader, rowHeights]
    );

    const calculatedTableHeight = useMemo(() => {
      const calculatedTableHeight =
        R.sum(R.values(R.defaultTo({}, rowHeights))) +
        FOOTER_HEIGHT +
        headerContainerHeight +
        (hasSuperHeaders ? SUPER_HEADER_HEIGHT + SUPER_HEADER_PADDING : 0) -
        (rowHeights && rowHeights.header ? rowHeights.header : 0);
      return R.min(calculatedTableHeight, height);
    }, [
      rowHeights,
      FOOTER_HEIGHT,
      headerContainerHeight,
      hasSuperHeaders,
      SUPER_HEADER_HEIGHT,
      height,
    ]);

    const dividerLines = useMemo(() => {
      const dividerLines = {};
      if (dataHeaders) {
        for (let i = 0; i < numDataCols; i++) {
          // If we are on the first column, ignore since we already have a dimension divider
          const leftDividerKey = i === 0 ? undefined : `${i - 1}_${i}`;
          const rightDividerKey = i === numDataCols - 1 ? i : `${i}_${i + 1}`;
          if (dataHeaders[i].divider) {
            if (leftDividerKey) {
              dividerLines[leftDividerKey] = true;
            }
            dividerLines[rightDividerKey] = true;
          }
        }
      }
      if (superHeaders) {
        let leftColumnIndex = 0;
        for (let i = 0; i < superHeaders.length; i++) {
          const rightColumnIndex = leftColumnIndex + superHeaders[i].span - 1;
          const superHeader = superHeaders[i];
          const leftDividerKey =
            leftColumnIndex === 0 ? undefined : `${leftColumnIndex - 1}_${leftColumnIndex}`;
          const rightDividerKey =
            rightColumnIndex === numDataCols - 1
              ? rightColumnIndex
              : `${rightColumnIndex}_${rightColumnIndex + 1}`;
          if (superHeader.data.leftDivider && leftDividerKey) {
            dividerLines[leftDividerKey] = true;
          }
          if (superHeader.data.rightDivider) {
            dividerLines[rightDividerKey] = true;
          }
          leftColumnIndex += superHeader.span;
        }
      }
      return dividerLines;
    }, [dataHeaders, numDataCols, superHeaders]);

    const rowHeight = useCallback(
      rowIndex => {
        return rowHeights && rowHeights[rowIndex] ? rowHeights[rowIndex] : MIN_ROW_HEIGHT;
      },
      [MIN_ROW_HEIGHT, rowHeights]
    );

    const dataHeaderRenderer: CellRenderer<Column> = useMemo(
      () => ({ columnIndex, classes = [], data, style = {} }: RenderProps) => {
        const { label: columnLabel, subHeader } = data as Column;
        const header = dataHeaders[columnIndex];
        const overlayText =
          columnMetaDataMap && columnMetaDataMap[header.dataVarName]
            ? columnMetaDataMap[header.dataVarName].overlayText
            : undefined;
        const overlayPlacement =
          columnMetaDataMap && columnMetaDataMap[header.dataVarName]
            ? columnMetaDataMap[header.dataVarName].overlayPlacement
            : undefined;
        const mySorting = R.find(({ id }) => id === header.id, sorting);
        const contentType =
          !R.isNil(columnIndex) &&
          header &&
          header.dataVarName &&
          columnMetaDataMap[header.dataVarName] &&
          columnMetaDataMap[header.dataVarName].contentType
            ? columnMetaDataMap[header.dataVarName].contentType
            : "number";
        const alignItems = contentType === "text" ? "flex-start" : "flex-end";
        const headerHeight = R.max(
          HEADER_TEXT_HEIGHT,
          rowHeights && rowHeights.header ? rowHeights.header : HEADER_TEXT_HEIGHT
        );
        const justifyContent = alignItems;
        const maxWidth =
          MAX_GRID_COL_WIDTH +
          cellPadding * 2 +
          COL_DIVIDER_BORDER_WIDTH +
          (columnIndex === 0 ? DIMENSION_COLUMNS_RIGHT_MARGIN : 0);
        const minHeight = MIN_ROW_HEIGHT;
        const paddingBottom = ROW_PADDING;
        const paddingLeft = cellPadding + (columnIndex === 0 ? DIMENSION_COLUMNS_RIGHT_MARGIN : 0);
        const paddingRight =
          cellPadding + (columnIndex === numDataCols - 1 ? TABLE_RIGHT_PADDING : 0);
        const paddingTop = ROW_PADDING;
        const subHeaderHeight = SUB_HEADER_HEIGHT;
        const textAlign = contentType === "text" ? "left" : "right";
        const label = columnLabel
          ? columnLabel
          : columnMetaDataMap[header.dataVarName]
          ? columnMetaDataMap[header.dataVarName].displayName
          : header.dataVarName;
        const headerText = (
          <div
            className={`text ${TEXT_SIZE_MAP[variant]}`}
            id={`header_col${columnIndex}`}
            style={{
              alignItems: "center",
              display: "flex",
              height: headerHeight,
              textAlign,
              width: "fit-content",
            }}
          >
            {label}
          </div>
        );
        // We want to maintain the same spacing if there is just one subheader in the table,
        // so we add a placeholder subheader if there isn't one but make the text transparent
        const subHeaderText = subHeader && (
          <div
            className="subHeader text"
            style={{
              color: subHeader ? "$Brand100" : "transparent",
              height: subHeaderHeight,
              justifyContent,
              textAlign,
              width: "fit-content",
            }}
          >
            {subHeader}
          </div>
        );
        const sortIconSide = contentType === "text" ? "right" : "left";
        const sortIcon = contentType !== "logo" && (
          <div className={`sortIconContainer ${R.isNil(mySorting) ? "" : "sorted"}`}>
            {mySorting && mySorting.asc ? <MdArrowUpward /> : <MdArrowDownward />}
          </div>
        );
        const headerContainerJustifyContent = subHeader ? "space-between" : "flex-end";
        const cursor = contentType !== "logo" ? "pointer" : "default";
        return innerCellRenderer(
          <div
            style={{
              ...style,
              alignItems,
              height: headerContainerHeight,
              justifyContent: headerContainerJustifyContent,
              maxWidth,
              minHeight,
              paddingBottom,
              paddingLeft,
              paddingRight,
              paddingTop,
              textAlign,
            }}
            className={cn(...classes, "tableHeaderContainer", {
              end: columnIndex === numDataCols - 1,
              leftDivider: dividerLines[`${columnIndex - 1}_${columnIndex}`],
              rightDivider: dividerLines[`${columnIndex}_${columnIndex + 1}`],
              rightEndDivider: dividerLines[columnIndex],
            })}
          >
            <div
              className="tableHeader"
              onClick={() => {
                if (contentType !== "logo") {
                  addSorting(header.id, disableMultiSorting);
                }
              }}
              style={{
                alignItems,
                cursor,
                justifyContent,
                textAlign,
              }}
            >
              {sortIconSide === "right" ? headerText : sortIcon}
              {sortIconSide === "right" ? sortIcon : headerText}
            </div>
            {(!!percentAOIPlaceholder || percentAOIPlaceholder === 0) &&
              header.dataVarName.toLowerCase().includes("percentincremental") && (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <PercentAOIPlaceholder percentage={percentAOIPlaceholder} />
                </div>
              )}
            {hasSubHeader && subHeaderText}
          </div>,
          overlayText,
          overlayPlacement
        );
      },
      [
        dataHeaders,
        sorting,
        rowHeights,
        MAX_GRID_COL_WIDTH,
        cellPadding,
        MIN_ROW_HEIGHT,
        ROW_PADDING,
        DIMENSION_COLUMNS_RIGHT_MARGIN,
        numDataCols,
        TABLE_RIGHT_PADDING,
        columnMetaDataMap,
        variant,
        headerContainerHeight,
        dividerLines,
        percentAOIPlaceholder,
        hasSubHeader,
        addSorting,
        disableMultiSorting,
        HEADER_TEXT_HEIGHT,
      ]
    );

    const cellRenderer: CellRenderer<CellData> = useMemo(
      () => ({
        classes = [],
        columnIndex = 0,
        data,
        rowIndex = 0,
        style = {},
        virtual,
      }: RenderProps) => {
        const { label, overlayText, value } = data as CellData;
        const ourClasses = [...classes, "bodyCell"];
        if (columnIndex === 0) {
          ourClasses.push("start");
        }
        if (columnIndex === numDataCols - 1) {
          ourClasses.push("end");
        }
        if (rowIndex === numRows - 1) {
          ourClasses.push("lastRow");
        }
        if (dividerLines[`${columnIndex - 1}_${columnIndex}`]) {
          ourClasses.push("leftDivider");
        }
        if (dividerLines[`${columnIndex}_${columnIndex + 1}`]) {
          ourClasses.push("rightDivider");
        }
        if (dividerLines[columnIndex]) {
          ourClasses.push("rightEndDivider");
        }
        const { backgroundColor, outline } =
          typeof value === "number"
            ? getHeatMapInfo({
                columnIndex,
                value: value,
              })
            : { backgroundColor: "transparent", outline: "none" };
        const header = dataHeaders[columnIndex];
        const contentType =
          !R.isNil(columnIndex) &&
          header &&
          header.dataVarName &&
          columnMetaDataMap[header.dataVarName] &&
          columnMetaDataMap[header.dataVarName].contentType
            ? columnMetaDataMap[header.dataVarName].contentType
            : "number";
        const height =
          colWidths &&
          colWidths[columnIndex] &&
          rowHeights &&
          (rowIndex || rowIndex === 0) &&
          rowHeights[rowIndex]
            ? rowHeights[rowIndex] - 2 * ROW_PADDING
            : "fit-content";
        const justifyContent =
          contentType === "text" && backgroundColor !== "transparent" ? "flex-start" : "flex-end";
        const maxWidth =
          MAX_GRID_COL_WIDTH +
          cellPadding * 2 +
          COL_DIVIDER_BORDER_WIDTH +
          (columnIndex === 0 ? DIMENSION_COLUMNS_RIGHT_MARGIN : 0);
        const minHeight = MIN_ROW_HEIGHT;
        // Padding in between cells (column,row)
        const paddingLeft = cellPadding + (columnIndex === 0 ? DIMENSION_COLUMNS_RIGHT_MARGIN : 0);
        const paddingRight =
          cellPadding + (columnIndex === numDataCols - 1 ? TABLE_RIGHT_PADDING : 0);
        // We need some extra padding on the first row to account for the border
        const bodyCellPaddingTop =
          rowIndex === 0 ? HEADER_FOOTER_PADDING + ROW_PADDING : ROW_PADDING;
        const bodyCellPaddingBottom =
          rowIndex === numRows - 1 ? HEADER_FOOTER_PADDING + ROW_PADDING : ROW_PADDING;
        // Inner Cell Bottom/Top Padding inside of the background color
        const paddingBottom =
          variant !== "SparkChart" ? (MIN_ROW_HEIGHT - TEXT_FONT_SIZE_MAP[variant]) / 2 : 0;
        const paddingTop = paddingBottom;
        const textAlign =
          contentType === "text" && backgroundColor !== "transparent" ? "left" : "right";
        const width =
          heatMappingWidths && heatMappingWidths[columnIndex]
            ? heatMappingWidths[columnIndex]
            : "fit-content";
        if (
          virtual &&
          virtual.rows &&
          virtual.rows.visible &&
          virtual.cols &&
          virtual.cols.visible
        ) {
          // We will rerender when the second to last visible cell hasn't been rendered yet.
          // checking the last cell leads to issues when we use the javascript console.
          const maxVisibleColIndex = virtual.cols.visible.max;
          const maxVisibleRowIndex = virtual.rows.visible.max;
          const minVisibleColIndex = virtual.cols.visible.min;
          const minVisibleRowIndex = virtual.rows.visible.min;
          if (
            (maxVisibleRowIndex === rowIndex && maxVisibleColIndex === columnIndex) ||
            (rowIndex === numRows - 1 && columnIndex === numDataCols - 1)
          ) {
            setIsTableDataInitiallyRendered(true);
          } else if (
            (colWidths &&
              R.keys(colWidths).length > 1 &&
              (R.isNil(colWidths[maxVisibleColIndex - 1]) ||
                R.isNil(colWidths[minVisibleColIndex + 1]))) ||
            (rowHeights &&
              R.keys(rowHeights).length > 1 &&
              (R.isNil(rowHeights[maxVisibleRowIndex - 1]) ||
                R.isNil(rowHeights[minVisibleRowIndex + 1])))
          ) {
            setIsTableDataInitiallyRendered(false);
          }
        }
        let bodyCellStyling = {
          ...style,
          justifyContent,
          maxWidth,
          paddingBottom: bodyCellPaddingBottom,
          paddingLeft,
          paddingRight,
          paddingTop: bodyCellPaddingTop,
        };
        return (
          <div style={bodyCellStyling} className={ourClasses.join(" ")}>
            <div
              className="cellWrapper"
              style={{
                backgroundColor,
                height,
                justifyContent,
                outline,
                width,
              }}
            >
              {innerCellRenderer(
                <div
                  className={`innerCell ${contentType !== "logo" ? "text" : ""} ${
                    TEXT_SIZE_MAP[variant]
                  } ${backgroundColor !== "transparent" || outline !== "none" ? "heatMapped" : ""}`}
                  id={`grid_col${columnIndex}_row${rowIndex}`}
                  style={{
                    justifyContent,
                    minHeight,
                    paddingBottom,
                    paddingTop,
                    textAlign,
                    width,
                  }}
                >
                  {label}
                </div>,
                overlayText
              )}
            </div>
          </div>
        );
      },
      [
        cellPadding,
        columnMetaDataMap,
        colWidths,
        dataHeaders,
        DIMENSION_COLUMNS_RIGHT_MARGIN,
        dividerLines,
        getHeatMapInfo,
        HEADER_FOOTER_PADDING,
        heatMappingWidths,
        MAX_GRID_COL_WIDTH,
        MIN_ROW_HEIGHT,
        numDataCols,
        numRows,
        ROW_PADDING,
        rowHeights,
        TABLE_RIGHT_PADDING,
        variant,
      ]
    );

    const dimensionHeaderRenderer = useMemo(
      () => (corner: CornerLocation) => {
        // Render Dimension Columns
        if (corner === "nw") {
          const classes = ["dimensionCornerContainer"];
          if (superHeaders) {
            classes.push("withSuperHeaders");
          }
          let columnIndex = 0;
          const height = headerContainerHeight;
          return (
            <div className={classes.join(" ")} style={{ height }}>
              {R.map(dimension => {
                const classes = ["tableHeader"];
                const mySorting = R.find(({ id }) => id === dimension.id, sorting);
                const dimHeader = dimensionDataHeaders[columnIndex];
                const overlayText =
                  dimHeader && columnMetaDataMap[dimHeader.dimensionTypeName]
                    ? columnMetaDataMap[dimHeader.dimensionTypeName].overlayText
                    : undefined;
                const contentType =
                  dimHeader &&
                  columnMetaDataMap[dimHeader.dimensionTypeName] &&
                  columnMetaDataMap[dimHeader.dimensionTypeName].contentType
                    ? columnMetaDataMap[dimHeader.dimensionTypeName].contentType
                    : "text";
                const iconStyle =
                  dimHeader &&
                  columnMetaDataMap[dimHeader.dimensionTypeName] &&
                  columnMetaDataMap[dimHeader.dimensionTypeName].iconStyle
                    ? columnMetaDataMap[dimHeader.dimensionTypeName].iconStyle
                    : undefined;
                const sortIconSide =
                  contentType === "text" ? "right" : contentType === "logo" ? "center" : "left";
                const justifyContent =
                  contentType === "text" || contentType === "logo" ? "flex-start" : "flex-end";
                const textAlign =
                  contentType === "text" || contentType === "logo" ? "left" : "right";
                const headerHeight = R.max(
                  rowHeights && rowHeights.header ? rowHeights.header : HEADER_TEXT_HEIGHT,
                  HEADER_TEXT_HEIGHT
                );
                const label = dimension.label
                  ? dimension.label
                  : columnMetaDataMap[dimension.dimensionTypeName]
                  ? columnMetaDataMap[dimension.dimensionTypeName].displayName
                  : dimension.dimensionTypeName;
                const headerText = (
                  <div
                    className="tableHeaderLabel"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      height: headerHeight,
                      padding: "4px 0px",
                      textAlign,
                    }}
                  >
                    {label}
                  </div>
                );
                const headerContainerJustifyContent = "flex-end";
                const extraImageWidth =
                  dimHeader && dimHeader.icon === "hasIcon" && iconStyle
                    ? (iconStyle === "logo" ? 50 : 90) + 14
                    : 0;
                const maxContainerWidth = MAX_DIM_WIDTH + 2 * MAX_COL_PADDING + extraImageWidth;
                const padding = `${ROW_PADDING}px ${cellPadding}px`;
                const width =
                  leftColWidths && leftColWidths[columnIndex]
                    ? leftColWidths[columnIndex]
                    : "fit-content";
                const sortIcon = contentType !== "logo" && (
                  <div className={`sortIconContainer ${R.isNil(mySorting) ? "" : "sorted"}`}>
                    {mySorting && mySorting.asc ? <MdArrowUpward /> : <MdArrowDownward />}
                  </div>
                );
                const cursor = contentType !== "logo" ? "pointer" : "default";
                const header = innerCellRenderer(
                  <div
                    key={`left_header_col${columnIndex}`}
                    className="tableHeaderContainer"
                    style={{
                      height,
                      justifyContent: headerContainerJustifyContent,
                      maxWidth: maxContainerWidth,
                      padding,
                    }}
                  >
                    <div
                      className={classes.join(" ")}
                      id={`left_header_col${columnIndex}`}
                      onClick={() => {
                        if (contentType !== "logo") {
                          addSorting(dimension.id, disableMultiSorting);
                        }
                      }}
                      style={{
                        cursor,
                        justifyContent,
                        width,
                      }}
                    >
                      {sortIconSide === "right" ? headerText : sortIcon}
                      {sortIconSide === "right" ? sortIcon : headerText}
                    </div>
                  </div>,
                  overlayText
                );
                columnIndex++;
                return header;
              }, dimensionDataHeaders)}
            </div>
          );
        } else {
          return <div />; // We aren't rendering anything else for the other corners
        }
      },
      [
        superHeaders,
        headerContainerHeight,
        dimensionDataHeaders,
        sorting,
        rowHeights,
        columnMetaDataMap,
        MAX_DIM_WIDTH,
        MAX_COL_PADDING,
        ROW_PADDING,
        cellPadding,
        leftColWidths,
        addSorting,
        disableMultiSorting,
        HEADER_TEXT_HEIGHT,
      ]
    );

    const dimensionCellRenderer: CellRenderer<Record<string, CellData>> = useMemo(
      () => ({ classes = [], data, rowIndex = 0, style = {}, virtual }: RenderProps) => {
        const columns: JSX.Element[] = [];
        const alignItems = "center";
        let colIndex = 0;
        for (const header of dimensionDataHeaders) {
          const { content: contentOverride, label, overlayText, url } = (data as Record<
            string,
            CellData
          >)[header.dimensionTypeName];
          const genericColumnMetaData =
            header && columnMetaDataMap && columnMetaDataMap[header.dimensionTypeName]
              ? columnMetaDataMap[header.dimensionTypeName]
              : undefined;
          const iconStyle =
            url && header && genericColumnMetaData && genericColumnMetaData.iconStyle
              ? genericColumnMetaData.iconStyle
              : undefined;
          const contentType =
            header && iconStyle && header.icon === "iconOnly"
              ? iconStyle
              : header && iconStyle && header.icon === "hasIcon"
              ? `text ${iconStyle}`
              : header && genericColumnMetaData && genericColumnMetaData.contentType
              ? genericColumnMetaData.contentType
              : "text";
          const height =
            leftColWidths &&
            leftColWidths[colIndex] &&
            rowHeights &&
            (rowIndex || rowIndex === 0) &&
            rowHeights[rowIndex]
              ? rowHeights[rowIndex]
              : "fit-content";
          const justifyContent = contentType.includes("text")
            ? "flex-start"
            : contentType === "logo"
            ? "center"
            : "flex-end";
          const maxHeight =
            leftColWidths &&
            leftColWidths[colIndex] &&
            rowHeights &&
            (rowIndex || rowIndex === 0) &&
            rowHeights[rowIndex]
              ? `${rowHeights[rowIndex]} !important`
              : THREE_LINE_ROW_HEIGHT + 2 * ROW_PADDING;
          const extraImageWidth =
            header && header.icon === "hasIcon" && iconStyle ? (iconStyle === "logo" ? 50 : 90) : 0;
          const maxWidth = MAX_DIM_WIDTH + 2 * cellPadding + extraImageWidth;
          // Padding in between cells (column,row)
          const padding = `${ROW_PADDING}px ${cellPadding}px`;
          // Inner Cell Bottom/Top Padding inside of the background color
          const paddingBottom =
            variant !== "SparkChart" ? (MIN_ROW_HEIGHT - TEXT_FONT_SIZE_MAP[variant]) / 2 : 0;
          const paddingTop = paddingBottom;
          const textAlign = contentType.includes("text")
            ? "left"
            : contentType === "logo"
            ? "center"
            : "right";
          const width =
            leftColWidths && leftColWidths[colIndex] ? leftColWidths[colIndex] : "fit-content";
          const content = !R.isNil(contentOverride) ? (
            contentOverride
          ) : !R.isNil(url) && header.icon === "iconOnly" ? (
            innerCellRenderer(<Img src={url} alt={label} unloader={label} />, overlayText)
          ) : !R.isNil(url) && header.icon ? (
            <div className="dimensionCellWithIcon">
              {innerCellRenderer(
                <div className={iconStyle}>{<Img src={url} alt={label} unloader={label} />}</div>,
                overlayText
              )}
              <div className={`label ${leftColWidths && leftColWidths[colIndex] ? "" : "noWrap"}`}>
                {label}
              </div>
            </div>
          ) : (
            innerCellRenderer(
              <div className={`label ${leftColWidths && leftColWidths[colIndex] ? "" : "noWrap"}`}>
                {label}
              </div>,
              overlayText
            )
          );
          columns.push(
            <div
              key={`left_col${colIndex}_row${rowIndex}`}
              className="cellWrapper"
              style={{
                alignItems,
                height,
                maxHeight,
                maxWidth,
                padding,
              }}
            >
              <div
                className={`innerCell ${contentType} ${TEXT_SIZE_MAP[variant]}`}
                id={`left_col${colIndex}_row${rowIndex}`}
                style={{
                  justifyContent,
                  paddingBottom,
                  paddingTop,
                  textAlign,
                  width,
                }}
              >
                {content}
              </div>
            </div>
          );
          colIndex += 1;
        }
        if (
          virtual &&
          virtual.rows &&
          virtual.rows.visible &&
          virtual.cols &&
          virtual.cols.visible
        ) {
          // We will rerender when the second to last visible cell hasn't been rendered yet.
          // checking the last cell leads to issues when we use the javascript console.
          const maxVisibleRowIndex = virtual.rows.visible.max;
          const minVisibleRowIndex = virtual.rows.visible.min;
          if (maxVisibleRowIndex === rowIndex || rowIndex === numRows - 1) {
            setIsLeftDataInitiallyRendered(true);
          } else if (
            rowHeights &&
            R.keys(rowHeights).length > 1 &&
            (R.isNil(rowHeights[maxVisibleRowIndex - 1]) ||
              R.isNil(rowHeights[minVisibleRowIndex + 1]))
          ) {
            setIsLeftDataInitiallyRendered(false);
          }
        }
        const paddingTop = rowIndex === 0 ? HEADER_FOOTER_PADDING + ROW_PADDING : ROW_PADDING;
        const paddingBottom =
          rowIndex === numRows - 1 ? HEADER_FOOTER_PADDING + ROW_PADDING : ROW_PADDING;
        const height =
          (rowHeights ? rowHeight(rowIndex) : MIN_ROW_HEIGHT) +
          (rowIndex === 0 || rowIndex === numRows - 1 ? HEADER_FOOTER_PADDING : 0);
        const maxHeight = rowHeights ? rowHeight(rowIndex) : MIN_ROW_HEIGHT;
        const minHeight = MIN_ROW_HEIGHT;
        if (rowIndex === numRows - 1) {
          classes.push("lastRow");
        }
        return (
          <div
            style={{
              ...style,
              height,
              maxHeight,
              minHeight,
              paddingBottom,
              paddingTop,
            }}
            className={[...classes, "leftCell"].join(" ")}
          >
            {columns}
          </div>
        );
      },
      [
        cellPadding,
        columnMetaDataMap,
        dimensionDataHeaders,
        HEADER_FOOTER_PADDING,
        leftColWidths,
        MAX_DIM_WIDTH,
        MIN_ROW_HEIGHT,
        numRows,
        ROW_PADDING,
        rowHeight,
        rowHeights,
        variant,
      ]
    );

    const aggregateDataRenderer: CellRenderer<CellData> = useMemo(
      () => ({ classes = [], columnIndex, data, style = {} }: RenderProps) => {
        const { label } = data as CellData;
        const ourClasses = [...classes, "subtotalCell", "bodyCell"];
        if (columnIndex === 0) {
          ourClasses.push("start");
        }
        if (columnIndex === numDataCols - 1) {
          ourClasses.push("end");
        }
        const dataHeader =
          dataHeaders && !R.isNil(columnIndex) ? dataHeaders[columnIndex] : undefined;
        const contentType =
          !R.isNil(columnIndex) &&
          dataHeader &&
          dataHeader.dataVarName &&
          columnMetaDataMap[dataHeader.dataVarName] &&
          columnMetaDataMap[dataHeader.dataVarName].contentType
            ? columnMetaDataMap[dataHeader.dataVarName].contentType
            : "number";
        const justifyContent =
          contentType === "text" ? "flex-start" : contentType === "logo" ? "center" : "flex-end";
        const containerAlignItems = "flex-start";
        const maxWidth = MAX_GRID_COL_WIDTH;
        const minHeight = MIN_ROW_HEIGHT;
        const paddingBottom = FOOTER_PADDING;
        const paddingLeft = cellPadding + (columnIndex === 0 ? DIMENSION_COLUMNS_RIGHT_MARGIN : 0);
        const paddingRight =
          cellPadding + (columnIndex === numDataCols - 1 ? TABLE_RIGHT_PADDING : 0);
        const paddingTop = FOOTER_PADDING;
        const textAlign =
          contentType === "text" ? "left" : contentType === "logo" ? "center" : "right";
        const footerTextWidth = "fit-content";
        const width = colWidths && colWidths[columnIndex] ? colWidths[columnIndex] : "fit-content";
        const height = FOOTER_HEIGHT;
        const columnMetaData: ColumnMetaData | undefined =
          columnMetaDataMap && dataHeader && dataHeader.dataVarName
            ? columnMetaDataMap[dataHeader.dataVarName]
            : undefined;
        let usingAverage = false;
        if (columnMetaData && columnMetaData.requiredTotalsColumns) {
          for (const column of columnMetaData.requiredTotalsColumns) {
            if (
              column === "numRows" ||
              (typeof column === "object" &&
                column.dataVarName &&
                column.dataVarName.toLowerCase().includes("weighted"))
            ) {
              usingAverage = true;
            }
          }
        }
        const footerType =
          dataHeader && dataHeader.footerLabel
            ? dataHeader.footerLabel
            : usingAverage
            ? "AVG"
            : columnMetaData && !R.isNil(columnMetaData.aggregator)
            ? "OVERALL"
            : "TOTAL";
        return (
          <div
            style={{
              ...style,
              alignItems: containerAlignItems,
              height,
              paddingLeft,
              paddingRight,
            }}
            className={ourClasses.join(" ")}
          >
            <div
              className="cellWrapper"
              style={{
                alignItems: containerAlignItems,
                height,
                justifyContent,
                maxWidth,
                minHeight,
                paddingBottom: FOOTER_CONTAINER_BOTTOM_PADDING,
                paddingTop: FOOTER_CONTAINER_TOP_PADDING,
              }}
            >
              <div
                className={`footer innerCell ${contentType !== "logo" ? "text" : ""} ${
                  TEXT_SIZE_MAP[variant]
                }`}
                id={`footer_col${columnIndex}`}
                style={{
                  alignItems: justifyContent,
                  justifyContent,
                  maxWidth,
                  paddingBottom,
                  paddingTop,
                  textAlign,
                  width,
                  minHeight,
                }}
              >
                <div
                  className={"footerTypeContainer"}
                  style={{
                    justifyContent,
                    textAlign,
                  }}
                >
                  {footerType}
                </div>
                <div
                  className={"footerContainer"}
                  style={{
                    justifyContent,
                    maxWidth,
                    textAlign,
                    width: footerTextWidth,
                  }}
                >
                  {label}
                </div>
              </div>
            </div>
          </div>
        );
      },
      [
        cellPadding,
        columnMetaDataMap,
        colWidths,
        dataHeaders,
        DIMENSION_COLUMNS_RIGHT_MARGIN,
        FOOTER_HEIGHT,
        FOOTER_PADDING,
        MAX_GRID_COL_WIDTH,
        MIN_ROW_HEIGHT,
        numDataCols,
        TABLE_RIGHT_PADDING,
        variant,
      ]
    );

    const superHeaderRenderer: CellRenderer<SuperHeader> = useMemo(
      () => ({ style = {}, classes = [], columnIndex, rowData }) => {
        const superData = rowData as SuperTopItemType<SuperHeader>;
        const ourClasses = ["superHeaderContainer"];
        const rightColumnIndex = columnIndex + superData.span - 1;
        if (dividerLines[`${columnIndex - 1}_${columnIndex}`]) {
          ourClasses.push("leftDivider");
        }
        if (dividerLines[`${rightColumnIndex}_${rightColumnIndex + 1}`]) {
          ourClasses.push("rightDivider");
        }
        if (dividerLines[rightColumnIndex]) {
          ourClasses.push("rightEndDivider");
        }
        if (superData && superData.data && superData.data.text.length) {
          ourClasses.push("hasText");
        }
        if (rightColumnIndex === numDataCols - 1) {
          ourClasses.push("end");
        }
        return (
          <div
            className={classes.join(" ")}
            style={{
              ...style,
              paddingTop: SUPER_HEADER_PADDING,
            }}
          >
            <div
              style={{
                padding: `0px ${cellPadding}px ${SUPER_HEADER_PADDING}px ${cellPadding}px`,
              }}
              className={ourClasses.join(" ")}
            >
              <div className="content innerCell">{superData.data.text}</div>
            </div>
          </div>
        );
      },
      [cellPadding, dividerLines, numDataCols]
    );

    const bottomData = hasAggregateData ? aggregateData : undefined;
    const leftData = hasDimensionData ? sortedDimensionData : undefined;
    const superTopData = hasSuperHeaders ? superHeaders : undefined;
    const topData = hasDataHeaders ? dataHeaders : undefined;

    return (
      <div className="metricsTable">
        <StickyTable<CellData, Column, Record<string, CellData>, CellData, undefined, SuperHeader>
          bottomData={bottomData}
          bottomRenderer={aggregateDataRenderer}
          bottomHeight={FOOTER_HEIGHT}
          cellRenderer={cellRenderer}
          columnWidth={columnWidths}
          cornerRenderer={dimensionHeaderRenderer}
          data={sortedTableData}
          height={calculatedTableHeight}
          leftData={leftData}
          leftRenderer={dimensionCellRenderer}
          leftWidth={
            leftColWidths && !R.isEmpty(leftColWidths)
              ? leftWidth + 2 * R.defaultTo(0, cellPadding) * R.keys(leftColWidths).length
              : dimensionColumns
              ? dimensionColumns.length * MAX_DIM_WIDTH
              : 0
          }
          overscan={(numRows ? numRows : 10) * 2} // We want to render every row
          rowHeight={i => rowHeight(i)}
          superTopData={superTopData}
          superTopHeight={SUPER_HEADER_HEIGHT}
          superTopRenderer={superHeaderRenderer}
          topData={topData}
          topHeight={headerContainerHeight}
          topRenderer={dataHeaderRenderer}
          width={width}
        />
      </div>
    );
  }
);

export default MetricsTable;
