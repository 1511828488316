import "./NotFound.scss";
import { awaitJSON, UsersLambdaFetch } from "../utils/fetch-utils";
import { Location } from "@reach/router";
import { Page } from "../Components";
import * as R from "ramda";
import React, { useMemo } from "react";

const NotFound = ({ invalidCompany }) => {
  const doesCompanyExist = useMemo(() => {
    return (async () => {
      if (invalidCompany && invalidCompany.length > 0) {
        const companyInfoRes = await UsersLambdaFetch("/company", {
          params: { company: invalidCompany },
        });
        const companyInfo = await awaitJSON(companyInfoRes);
        return R.isNil(companyInfo) && R.isNil(companyInfo.name) && companyInfo.name.length > 0;
      }
    })();
  }, [invalidCompany]);
  return (
    <Page title="Page Not Found" pageType="Page Not Found">
      <Location>
        {({ location }) => {
          const { href, pathname } = location;
          let message = "This page doesn't exist.";
          let hint = "";
          if (invalidCompany) {
            const moreThanMatch = pathname.match(/^\/[^/]+\/.+$/);
            if (moreThanMatch) {
              message = doesCompanyExist
                ? `You do not have access to "${invalidCompany}", please reach out to a member of that team to provide you access.`
                : `"${invalidCompany}" is not a valid company. This was the url you used.`;
            }
          } else if (pathname !== pathname.toLowerCase()) {
            hint = "Do you have uppercase letters in your URL? Try making them lowercase.";
          }
          return (
            <div className="pageNotFound">
              <div className="message">{message}</div>
              <code className="href">{href}</code>
              {hint && <div className="hint">{hint}</div>}
            </div>
          );
        }}
      </Location>
    </Page>
  );
};

export default NotFound;
