import React, { useContext, useState } from "react";
import { FormContext, metaAdSetFormDefault } from "../MetaBuyingContext";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { MetaBuyingNamePreview } from "./MetaBuyingNamePreview";
import { MetaBuyingOptionsNoteCard } from "./MetaBuyingOptionsNoteCard";
import { MetaBuyingFooterControls } from "./MetaBuyingFooterControls";
import { RouteComponentProps } from "@reach/router";
import {
  selectionsToArray,
  filterSegmentationIds,
  generateNameFields,
  setNameField as setNameFieldUtil,
  filterInheritedFields,
} from "../MetaBuyingUtils";
import { MetaBuyingTabs } from "../MetaBuyingTabs";
import { DimensionType } from "@blisspointmedia/bpm-types/dist/MetaBuying";
import * as R from "ramda";

const BUDGET_TYPES_ADSET = [
  {
    label: "Ad Set Daily Budget",
    value: "AD_SET_DAILY_BUDGET",
  },
  {
    label: "Ad Set Lifetime Budget",
    value: "AD_SET_LIFETIME_BUDGET",
  },
];

const PLATFORM_OPTIONS = [
  {
    label: "Facebook",
    value: "FACEBOOK",
  },
  {
    label: "Instagram",
    value: "INSTAGRAM",
  },
  {
    label: "Audience Network",
    value: "AUDIENCE_NETWORK",
  },
  {
    label: "Messenger",
    value: "MESSENGER",
  },
];

const BID_STRATEGIES = [
  {
    label: "Lowest Cost w/out Cap",
    value: "LOWEST_COST_WITHOUT_CAP",
  },
  {
    label: "Lowest Cost w/ Bid Cap",
    value: "LOWEST_COST_WITH_BID_CAP",
  },
  {
    label: "Cost Cap",
    value: "COST_CAP",
  },
  {
    label: "Lowest Cost w/ Min. ROAS",
    value: "LOWEST_COST_WITH_MIN_ROAS",
  },
];

const ATTRIBUTION_WINDOW_OPTIONS = [
  {
    label: "0 Days",
    value: 0,
  },
  {
    label: "1 Day",
    value: 1,
  },
  {
    label: "7 Days",
    value: 7,
  },
];

// for testing only
const DROPDOWN_TEST_PLACEHOLDER = [
  {
    label: "Option A",
    value: "test_a",
  },
  {
    label: "Option B",
    value: "test_b",
  },
  {
    label: "Option C",
    value: "test_c",
  },
];

const OPTIONS_NOTE_CARD_HEADER = "Ad Set Options to Populate in Ads Manager";
const OPTIONS_NOTE_CARD_TEXT =
  "If you need to adjust these, you’ll need to build your campaign here and adjust it in meta once you’ve published as paused in Bliss Point.";
const OPTIONS_NOTE_CARD_OPTIONS = [
  "Catalog Ads",
  "Additional Text Options",
  "Promo Codes",
  "Advantage+ Creative",
  "Instant Experiences",
  "Facebook Events",
];

interface MetaBuyingAdSetProps {
  client: string;
  namingConvention: number[];
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  showReviewModal: () => void;
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  validateForm: (
    form: Record<string, any>,
    validator: () => Record<string, any>,
    type: string,
    navigate: (args: boolean | string) => void,
    navigateArgs?: boolean | string
  ) => void;
  creationTabs: {
    Campaign: boolean;
    "Ad Set": boolean;
    Ad: boolean;
  };
  granularity: string;
  dimensions: Record<string, any>;
  allNameFields: Record<string, any>;
}

const { CLIENT, AGENCY, SEGMENTATION_ID, SEGMENT_SELECTION } = DimensionType;

export const MetaBuyingAdSet = ({
  client,
  selectedAdAccount,
  namingConvention,
  tabIndex,
  setTabIndex,
  showReviewModal,
  validateForm,
  creationTabs,
  granularity,
  dimensions,
  allNameFields,
}: MetaBuyingAdSetProps & RouteComponentProps): JSX.Element => {
  const { metaAdSetForm, setMetaAdSetForm } = useContext(FormContext);

  const agency = selectedAdAccount.business_manager;

  const segmentationDimensionId = R.keys(
    R.filter(dimension => dimension.type === DimensionType.SEGMENTATION_ID, dimensions)
  )[0];

  const [nameFields, setNameFields] = useState<Record<string, any>>(
    generateNameFields(namingConvention, allNameFields, dimensions)
  );

  const setNameField = (id, value) => setNameFieldUtil(id, value, nameFields, setNameFields);

  const creationTabArray = selectionsToArray(creationTabs);

  const updateFormField = (key: string, value: string | number) =>
    setMetaAdSetForm((current: any): typeof metaAdSetFormDefault => {
      return { ...current, [key]: value };
    });

  const validateAdSetForm = () => {
    let payloadAdSet = {};
    return payloadAdSet;
  };

  const selectExistingAdSet = adSet => {
    console.log("ad set: ", adSet);
  };

  const renderNameFields = (): JSX.Element[] =>
    namingConvention.map(dimensionId => {
      const { value, inheritedFrom } = allNameFields[dimensionId];
      const dimension = dimensions[dimensionId];
      let dropdownOptions =
        dimension.type === DimensionType.SEGMENTATION_ID
          ? filterSegmentationIds(nameFields, dimensions, segmentationDimensionId)
          : dimension.options;
      let field;
      if (inheritedFrom) {
        field = <Form.Control value={value} disabled />;
      } else {
        switch (dimension.type) {
          case AGENCY:
          case CLIENT:
            field = <Form.Control value={dimension.type === CLIENT ? client : agency} disabled />;
            break;
          case SEGMENTATION_ID:
          case SEGMENT_SELECTION:
            field = (
              <DropdownButton
                title={nameFields[dimensionId]}
                onSelect={value => setNameField(dimensionId, value)}
                disabled={dropdownOptions.length === 0}
              >
                {dropdownOptions.map(option => (
                  <Dropdown.Item key={option} eventKey={option}>
                    {option}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            );
            break;
          default:
            field = (
              <Form.Control
                value={nameFields[dimensionId]}
                onChange={e => setNameField(dimensionId, e.target.value)}
              />
            );
            break;
        }
      }
      return (
        <Form.Group key={dimensionId}>
          <Form.Label>{dimension.name}</Form.Label>
          {field}
        </Form.Group>
      );
    });

  return (
    <>
      <MetaBuyingTabs
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        creationTabs={creationTabs}
        validate={index =>
          validateForm(metaAdSetForm, validateAdSetForm, "adset", () => setTabIndex(index))
        }
      />
      <div className="metaBuyingCreate">
        <div className="metaBuyingContainer">
          <div className="metaBuyingCard">
            <div className="metaBuyingHeader">
              <h2>Ad&nbsp;Set</h2>
              <div className="metaBuyingNameWrapper">
                Name:
                <MetaBuyingNamePreview
                  dimensions={dimensions}
                  nameFields={nameFields}
                  namingConvention={namingConvention}
                  inheritedFields={filterInheritedFields(allNameFields)}
                />
              </div>
            </div>

            <div className="metaBuyingFormBody">
              <div className="metaBuyingFormSection">
                <h3>Ad Set Name Construction</h3>
                <Form.Group>
                  <Form.Label>Audience</Form.Label>
                  <Form.Control
                    value={metaAdSetForm.audience}
                    onChange={e => updateFormField("audience", e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Start From Existing Ad Set</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.pixel}
                    onSelect={value => selectExistingAdSet(value)}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                {renderNameFields()}
              </div>

              <div className="metaBuyingFormSection">
                <h3>Conversion Options</h3>
                <Form.Group>
                  <Form.Label>Pixel</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.pixel}
                    onSelect={value => updateFormField("pixel", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Conversion Event</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.conversion_event}
                    onSelect={value => updateFormField("conversion_event", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Optimization Goal</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.optimization_goal}
                    onSelect={value => updateFormField("optimization_goal", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Bid Strategy</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.bid_strategy || "Select"}
                    onSelect={value => updateFormField("bid_strategy", value || "")}
                  >
                    {BID_STRATEGIES.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Bid Amount</Form.Label>
                  <Form.Control
                    value={metaAdSetForm.bid_amount}
                    onChange={e => updateFormField("bid_amount", e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="metaBuyingFormSection">
                <h3>Campaign</h3>
                {/* <p>
                  Don't see your campaign? <a>Enter Campaign ID instead</a>
                </p> */}
                <Form.Group>
                  <Form.Label>Campaign</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.campaign}
                    onSelect={value => updateFormField("campaign", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
              </div>

              <div className="metaBuyingFormSection">
                <h3>Budget and Schedule</h3>
                <Form.Group>
                  <Form.Label>Budget Type</Form.Label>
                  {BUDGET_TYPES_ADSET.map(({ label, value }) => (
                    <Form.Check
                      key={value}
                      type="radio"
                      label={label}
                      checked={metaAdSetForm.budget_type === value}
                      id={value}
                      onChange={() => updateFormField("budget_type", value)}
                    />
                  ))}
                </Form.Group>
                <div className="metaBuyingFormSubsection">
                  <Form.Group>
                    <Form.Label>Budget</Form.Label>
                    <Form.Control
                      value={metaAdSetForm.budget}
                      onChange={e => updateFormField("budget", e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="metaBuyingFormSection">
                <h3>Ad Set Targeting</h3>
                <Form.Group>
                  <Form.Label>Custom Audiences</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.custom_audiences}
                    onSelect={value => updateFormField("custom_audiences", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Geo</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.geo}
                    onSelect={value => updateFormField("geo", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Likes & Interests</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.interests}
                    onSelect={value => updateFormField("interests", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Gender</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.gender}
                    onSelect={value => updateFormField("gender", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Placements</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.placements}
                    onSelect={value => updateFormField("placements", value || "")}
                  >
                    {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Age</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.custom_audiences}
                    onSelect={value => updateFormField("age", value || 18)}
                  >
                    {R.range(18, 65).map(age => (
                      <Dropdown.Item key={age} eventKey={age.toString()}>
                        {age}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
              </div>

              <div className="metaBuyingFormSection">
                <h3>Platforms and Placements</h3>
                <h4>Platforms</h4>
                {PLATFORM_OPTIONS.map(({ label, value }) => (
                  <Form.Check
                    key={value}
                    label={label}
                    checked={metaAdSetForm.budget_type === value}
                    id={value}
                    onChange={() => updateFormField("budget_type", value)}
                  />
                ))}
                <h4>Placements</h4>
              </div>

              <div className="metaBuyingFormSection">
                <h3>Ad Set Attribution Specs</h3>
                <Form.Group>
                  <Form.Label>Click Through Attribution Window</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.attribution_window || "Select"}
                    onSelect={value => updateFormField("attribution_window", value || "")}
                  >
                    {ATTRIBUTION_WINDOW_OPTIONS.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value.toString()}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
              </div>

              <MetaBuyingFooterControls
                nextTabName={creationTabArray[tabIndex + 1]}
                handleReviewClick={() =>
                  validateForm(metaAdSetForm, validateAdSetForm, "adset", showReviewModal)
                }
                handleNextClick={
                  creationTabArray[tabIndex + 1]
                    ? () =>
                        validateForm(metaAdSetForm, validateAdSetForm, "adset", () =>
                          setTabIndex(tabIndex + 1)
                        )
                    : undefined
                }
              />
            </div>
          </div>

          <MetaBuyingOptionsNoteCard
            header={OPTIONS_NOTE_CARD_HEADER}
            text={OPTIONS_NOTE_CARD_TEXT}
            options={OPTIONS_NOTE_CARD_OPTIONS}
          />
        </div>
      </div>
    </>
  );
};

export default MetaBuyingAdSet;
