import React, { useState, useEffect } from "react";
import { Response, ClientInfo } from "@blisspointmedia/bpm-types/dist/Onboarding";
import WidgetContainer from "../../Components/WidgetContainer";
import { platformAccessOptions } from "./PlatformAccessOptions";
import OnboardingFreeForm from "../OnboardingFreeForm";
import { getCompletedPlatformPercentage } from "../OnboardingUtils";
import { ClientInfoComponentProps } from "../ClientInfo";

const PlatformAccess: React.FC<ClientInfoComponentProps> = ({
  clientInfo,
  onPercentageChange,
  onClientInfoChange,
}) => {
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);
  const [pendingResponses, setPendingResponses] = useState<Response[]>([]);

  const platformOptions = platformAccessOptions(allResponses.responses);

  useEffect(() => {
    const totalPercentage = getCompletedPlatformPercentage(allResponses.responses, platformOptions);
    onPercentageChange(totalPercentage);
  }, [allResponses.responses, onPercentageChange, platformOptions]);

  const handlePlatformAccessChange = (updatedResponses: Response[]) => {
    setPendingResponses(updatedResponses);
    onClientInfoChange({
      ...allResponses,
      responses: updatedResponses,
    });
  };

  useEffect(() => {
    if (pendingResponses.length > 0) {
      setAllResponses(prevState => ({
        ...prevState,
        responses: pendingResponses,
      }));
    }
  }, [pendingResponses]);

  const platformForm = (key: string, option) => {
    return (
      <div>
        <OnboardingFreeForm
          questions={option.table}
          responses={allResponses.responses}
          onDataChange={handlePlatformAccessChange}
        />
      </div>
    );
  };

  return (
    <div>
      {Object.keys(platformOptions).map(key => {
        const disabled = platformOptions[key].table.rows.length === 0;
        return (
          <WidgetContainer
            key={key}
            backgroundColor="white"
            header={platformOptions[key].title}
            design="secondary"
            disabled={disabled}
            collapsible
            startOpen={false}
          >
            {platformForm(key, platformOptions[key])}
          </WidgetContainer>
        );
      })}
    </div>
  );
};

export default PlatformAccess;
