import "./PerformanceSnapshot.scss";
import { Metric, Metrics, CrossChannelMetrics } from "@blisspointmedia/bpm-types/dist/CrossChannel";
import React, { useState, useMemo, useContext, useCallback } from "react";
import * as R from "ramda";
import { Dropdown, DropdownToggleType, DownloadDropdown } from "../../Components";
import ChartContainer from "../../Components/ChartContainer";
import { metricFormatter } from "../../TVADCrossChannel/homePageUtils";
import { CrossChannelContext } from "../CrossChannel";
import { downloadPNG, exportToExcel } from "../../utils/download-utils";
import SnapshotChart, { BarPart, BarType, DataItem } from "./SnapshotChart";
import { PERFORMANCE_METRIC_OPTIONS } from "../crossChannelConstants";

interface PerformanceSnapshotProps {
  data: CrossChannelMetrics[];
  otherData: CrossChannelMetrics[];
  defaultMetric?: Metric;
}

interface TotalsByChannel {
  [channel: string]: {
    totals: Metrics;
    otherTotals: Metrics;
  };
}

const getTotalsByChannel = (
  data: CrossChannelMetrics[],
  otherData: CrossChannelMetrics[],
  toggledChannels: Record<string, boolean>,
  deliveryAndPerformanceChannels: Record<string, boolean>
) => {
  let totalsByChannel: TotalsByChannel = {};

  for (let item of data) {
    const { channel, metrics } = item;
    if (!toggledChannels[channel] || !deliveryAndPerformanceChannels[channel]) {
      continue;
    }

    totalsByChannel[channel] = totalsByChannel[channel] || {};
    totalsByChannel[channel].totals = R.mergeWith(
      R.add,
      totalsByChannel[channel].totals || {},
      metrics
    );

    // Compute running average of CPC, CPX, and ROAS when aggregating metrics.
    const newCPC =
      R.pathOr(0, [channel, "totals", "spend"], totalsByChannel) /
      R.pathOr(0, [channel, "totals", "clicks"], totalsByChannel);
    totalsByChannel[channel].totals.cpc = isFinite(newCPC) ? newCPC : 0;

    const newCPX =
      R.pathOr(0, [channel, "totals", "spend"], totalsByChannel) /
      R.pathOr(0, [channel, "totals", "volume"], totalsByChannel);
    totalsByChannel[channel].totals.cpx = isFinite(newCPX) ? newCPX : 0;

    totalsByChannel[channel].totals.roas =
      R.pathOr(0, [channel, "totals", "revenue"], totalsByChannel) /
      R.pathOr(0, [channel, "totals", "spend"], totalsByChannel);
  }

  for (let item of otherData) {
    const { channel, metrics } = item;
    if (!toggledChannels[channel] || !deliveryAndPerformanceChannels[channel]) {
      continue;
    }

    totalsByChannel[channel] = totalsByChannel[channel] || {};
    totalsByChannel[channel].otherTotals = R.mergeWith(
      R.add,
      totalsByChannel[channel].otherTotals || {},
      metrics
    );

    // Compute running average of CPX, and ROAS when aggregating metrics.
    const newCPX =
      R.pathOr(0, [channel, "otherTotals", "spend"], totalsByChannel) /
      R.pathOr(0, [channel, "otherTotals", "volume"], totalsByChannel);

    totalsByChannel[channel].otherTotals.cpx = isFinite(newCPX) ? newCPX : 0;

    totalsByChannel[channel].otherTotals.roas =
      R.pathOr(0, [channel, "otherTotals", "revenue"], totalsByChannel) /
      R.pathOr(0, [channel, "otherTotals", "spend"], totalsByChannel);
  }

  return totalsByChannel;
};

/**
 * Average/Sum the metrics for each channel for both date ranges.
 */
const processSnapshotData = (
  totalsByChannel: TotalsByChannel,
  toggledChannels: Record<string, boolean>,
  metric: string,
  channelColors: Record<string, string>,
  availableChannels: Record<string, boolean>
): DataItem[] => {
  let processedData: DataItem[] = [];

  for (let channel of R.keys(availableChannels).sort()) {
    if (!toggledChannels[channel]) {
      continue;
    }

    const topValue = R.pathOr(0, [channel, "totals", metric], totalsByChannel);
    const bottomValue = R.pathOr(0, [channel, "otherTotals", metric], totalsByChannel);

    const parts: BarPart[] = [
      { val: topValue, type: BarType.HORIZONTAL },
      { val: bottomValue, type: BarType.HORIZONTAL_GREY },
    ];

    const numericalDifference = topValue - bottomValue;
    const percentDifference = (numericalDifference / bottomValue) * 100;

    const formattedNumericalDifference = metricFormatter(metric)(numericalDifference);
    const formattedPercentDifference = isFinite(percentDifference)
      ? `(${Math.round(percentDifference)}%)`
      : "";

    const subLabel = `${formattedNumericalDifference} ${formattedPercentDifference}`;

    processedData.push({
      label: channel,
      subLabel,
      topValue,
      bottomValue,
      parts,
      color: channelColors[channel],
    });
  }

  return processedData.sort(
    (a, b) => Math.max(b.topValue, b.bottomValue || 0) - Math.max(a.topValue, a.bottomValue || 0)
  );
};

const PerformanceSnapshot: React.FC<PerformanceSnapshotProps> = React.memo(
  ({ data, otherData, defaultMetric = Metric.CPX }) => {
    const [metric, setMetric] = useState<Metric>(defaultMetric);

    const {
      selectedChannels,
      deliveryAndPerformanceChannels,
      channelColors,
      kpi,
      dates,
      otherDates,
      platformAsSourceOfData,
    } = useContext(CrossChannelContext);

    const totalsByChannel = useMemo(
      () => getTotalsByChannel(data, otherData, selectedChannels, deliveryAndPerformanceChannels),
      [data, otherData, selectedChannels, deliveryAndPerformanceChannels]
    );

    const processedData = useMemo(
      () =>
        processSnapshotData(
          totalsByChannel,
          selectedChannels,
          metric,
          channelColors,
          deliveryAndPerformanceChannels
        ),
      [totalsByChannel, selectedChannels, metric, channelColors, deliveryAndPerformanceChannels]
    );

    const fileNameIdentifiers = useMemo(() => {
      const prettyMetric =
        PERFORMANCE_METRIC_OPTIONS.find(item => item.value === metric)?.label || metric;

      return [
        "CrossChannel",
        `${dates.start}–${dates.end}`,
        prettyMetric,
        `vs_${otherDates.start}–${otherDates.end}`,
        prettyMetric,
      ];
    }, [metric, dates, otherDates]);

    const excelDownload = useCallback(() => {
      const exportData = Object.entries(totalsByChannel).map(([channel, item]) => {
        const { totals, otherTotals } = item;
        const otherItem = R.keys(otherTotals || {}).reduce((acc, key) => {
          acc[`${key}_other`] = (otherTotals || {})[key];
          return acc;
        }, {});
        return {
          channel,
          ...totals,
          ...otherItem,
        };
      });
      exportToExcel(exportData, `${fileNameIdentifiers.join("_")}`);
    }, [totalsByChannel, fileNameIdentifiers]);

    const pngDownload = useCallback(async () => {
      const label = `KPI: ${kpi}`;

      await downloadPNG(".performanceSnapshotContainer", fileNameIdentifiers, label, [
        ".rightSide",
      ]);
    }, [kpi, fileNameIdentifiers]);

    const valueFormatter = useMemo(() => metricFormatter(metric), [metric]);

    return (
      <div className="performanceSnapshotContainer">
        <ChartContainer
          enableHoverDesign
          title={
            <>
              <div className="primaryDateRange">Primary's</div>
              <Dropdown
                type={DropdownToggleType.WIDGET_TITLE}
                value={metric}
                options={PERFORMANCE_METRIC_OPTIONS}
                onChange={option => setMetric(option)}
              />
            </>
          }
          leftActions={<div className="otherDateRange">vs Comparison</div>}
          rightActions={
            <DownloadDropdown
              size="sm"
              onClickOptions={[excelDownload, pngDownload]}
            ></DownloadDropdown>
          }
          footerContent={[
            `KPI = ${kpi}`,
            `Source = ${platformAsSourceOfData ? "Platform" : "Source of Truth"}`,
          ]}
        >
          <div className="performanceSnapshotGraphContainer">
            <div id="performanceSnapshotGraphContents" className="performanceSnapshotGraphContents">
              {R.isEmpty(processedData) ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "26px",
                  }}
                >
                  No data to show
                </div>
              ) : (
                <SnapshotChart
                  data={processedData}
                  doubleBarChart={true}
                  valueFormatter={valueFormatter}
                />
              )}
            </div>
          </div>
        </ChartContainer>
      </div>
    );
  }
);

export default PerformanceSnapshot;
