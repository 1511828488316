import React, { useState, useEffect } from "react";
import { MdLock, MdSmsFailed, MdSave } from "react-icons/md";
import * as R from "ramda";
import { RouteComponentProps } from "@reach/router";
import { useSelector } from "react-redux";
import { ClientInfo } from "@blisspointmedia/bpm-types/dist/Onboarding";
import * as UserRedux from "../redux/user";
import { useSetError } from "../redux/modals";
import useLocation from "../utils/hooks/useLocation";
import { SubBrand1 } from "../utils/colors";
import {
  Page,
  Button,
  ButtonType,
  ButtonFrameworkVariant,
  Spinner,
  CircularProgressBar,
} from "../Components";
import AlertDialog, { AlertDialogVariants } from "../Components/AlertDialog/AlertDialog";
import { useTabbedNav } from "../utils/hooks/useNav";
import PlatformChecklist from "./PlatformChecklist/PlatformChecklist";
import PlatformAccess from "./PlatformAccess/PlatformAccess";
import CoreReporting from "./CoreReporting/CoreReporting";
import BudgetsBilling from "./BudgetsBilling/BudgetsBilling";
import PlatformBudgets from "./PlatformBudgets/PlatformBudgets";
import Data from "./Data/Data";
import {
  saveClientInfo,
  getClientInfo,
  getCompletedPlatformPercentage,
  getCompletedPercentage,
} from "./OnboardingUtils";
import { budgetDetails, budgetStrategy, budgets } from "./BudgetsBilling/BudgetsBillingQuestions";
import { primaryContacts, calendar, metrics } from "./CoreReporting/CoreReportingQuestions";
import { questions } from "./Data/DataQuestions";
import { platformAccessOptions } from "./PlatformAccess/PlatformAccessOptions";
import { platformBudgetsOptions } from "./PlatformBudgets/PlatformBudgetsOptions";
import { PLATFORM_OPTIONS } from "./PlatformChecklist/PlatformChecklistOptions";
import "./ClientInfo.scss";

const enum TabKey {
  PLATFORM_CHECKLIST = "platform-checklist",
  PLATFORM_ACCESS = "platform-access",
  CORE_REPORTING = "core-reporting",
  BUDGETS_BILLING = "budgets-billing",
  PLATFORM_BUDGETS = "platform-budgets",
  DATA = "data",
}

export interface ClientInfoComponentProps {
  clientInfo: ClientInfo;
  onPercentageChange: (percentage: number) => void;
  onClientInfoChange: (clientInfo: ClientInfo) => void;
}

const ClientInfoForm: React.FC = ({ navigate }: RouteComponentProps) => {
  const { company, location } = useLocation();
  const user = useSelector(UserRedux.fullNameSelector);
  const [clientInfo, setClientInfo] = useState<ClientInfo | null>(null);
  const [dataPercentage, setDataPercentage] = useState<number>(0);
  const [budgetPercentage, setBudgetPercentage] = useState<number>(0);
  const [coreReportingPercentage, setCoreReportingPercentage] = useState<number>(0);
  const [platformChecklistPercentage, setPlatformChecklistPercentage] = useState<number>(0);
  const [platformAccessPercentage, setPlatformAccessPercentage] = useState<number>(0);
  const [platformBudgetsPercentage, setPlatformBudgetsPercentage] = useState<number>(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pendingNavKey, setPendingNavKey] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const setError = useSetError();

  useEffect(() => {
    const fetchClientInfo = async () => {
      const info = await getClientInfo(company);
      const updatedInfo = R.isEmpty(info) ? { company, responses: [], changeLog: [] } : info;
      setClientInfo(updatedInfo);

      const corePrimaryCount =
        !updatedInfo.responses || updatedInfo.responses.length === 0
          ? primaryContacts.rows.length
          : updatedInfo.responses.filter(response => response.questionNumber.startsWith("3A"))
              .length;

      setPlatformChecklistPercentage(
        getCompletedPlatformPercentage(updatedInfo.responses, PLATFORM_OPTIONS)
      );
      setPlatformAccessPercentage(
        getCompletedPlatformPercentage(
          updatedInfo.responses,
          platformAccessOptions(updatedInfo.responses)
        )
      );
      setCoreReportingPercentage(
        getCompletedPercentage(
          updatedInfo.responses,
          corePrimaryCount + calendar.length + metrics.length,
          "3"
        )
      );
      setBudgetPercentage(
        getCompletedPercentage(
          updatedInfo.responses,
          budgetStrategy.length + budgetDetails.length + budgets.length,
          "4"
        )
      );
      setPlatformBudgetsPercentage(
        getCompletedPlatformPercentage(
          updatedInfo.responses,
          platformBudgetsOptions(updatedInfo.responses)
        )
      );
      setDataPercentage(getCompletedPercentage(updatedInfo.responses, questions.length, "6"));
    };

    fetchClientInfo();
  }, [company]);

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "client-info",
    defaultKey: TabKey.PLATFORM_CHECKLIST,
  });

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);

  const handleNavClick = (navKey: string) => {
    navigateToTab(navKey);
  };

  const navigateToTab = (navKey: string) => {
    const selectedNav = NAVS.find(nav => nav.key === navKey);
    if (selectedNav?.isLocked) {
      console.warn(`Cannot navigate to ${selectedNav.label}. The tab is locked.`);
      return;
    }

    setHasUnsavedChanges(false);
    setShowModal(false);
    setPendingNavKey(null);
    goToTab(navKey);
  };

  const handleModalLeave = () => {
    if (pendingNavKey) {
      navigateToTab(pendingNavKey);
    }
  };

  const handleModalStay = () => {
    setShowModal(false);
    setPendingNavKey(null);
  };

  const handleClientInfoChange = (updatedClientInfo: ClientInfo) => {
    setClientInfo(updatedClientInfo);
    setHasUnsavedChanges(true);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await saveClientInfo({ company, responses: clientInfo?.responses ?? [], user });
      setHasUnsavedChanges(false);
    } catch (error: any) {
      setError({ message: `There was an problem saving your changes. Location: ${location.pathname}, Error: ${error.message}` });
    } finally {
      setLoading(false);
    }
  };

  const saveButton = (
    <Button
      type={ButtonType.FILLED}
      variant={ButtonFrameworkVariant.LEADING_ICON}
      icon={loading ? <Spinner /> : <MdSave />}
      onClick={handleSave}
      disabled={!hasUnsavedChanges || loading}
    >
      {loading ? "Saving..." : "Save"}
    </Button>
  );

  if (!clientInfo) {
    return <Spinner size={100} />;
  }

  const NAVS = [
    {
      label: "1. Platform Checklist",
      key: TabKey.PLATFORM_CHECKLIST,
      percentage: platformChecklistPercentage,
    },
    {
      label: "2. Platform Access",
      key: TabKey.PLATFORM_ACCESS,
      percentage: platformAccessPercentage,
      isLocked: platformChecklistPercentage === 0,
    },
    { label: "3. Core Reporting", key: TabKey.CORE_REPORTING, percentage: coreReportingPercentage },
    { label: "4. Budgets/Billing", key: TabKey.BUDGETS_BILLING, percentage: budgetPercentage },
    {
      label: "5. Platform Budgets",
      key: TabKey.PLATFORM_BUDGETS,
      percentage: platformBudgetsPercentage,
      isLocked: platformChecklistPercentage === 0,
    },
    { label: "6. Data", key: TabKey.DATA, percentage: dataPercentage },
  ];

  return (
    <span className="onboardingPageContainer">
      <Page
        title="Client Info"
        pageType="Client Info"
        app2Redesign
        navs={NAVS}
        onNav={handleNavClick}
        actions={saveButton}
        selectedNav={tab}
        navRenderer={label => {
          const navItem = NAVS.find(nav => nav.label === label);
          return (
            <span key={navItem?.key} className="onboardingProgress">
              <CircularProgressBar
                className="onboardingProgressBar"
                percentage={navItem?.percentage ?? 0}
                size={60}
                icon={navItem?.isLocked ? <MdLock size={30} /> : undefined}
              />
              {label}
            </span>
          );
        }}
      >
        <div>
          {tab === TabKey.PLATFORM_CHECKLIST && (
            <PlatformChecklist
              clientInfo={clientInfo}
              onClientInfoChange={handleClientInfoChange}
              onPercentageChange={setPlatformChecklistPercentage}
            />
          )}
          {tab === TabKey.PLATFORM_ACCESS && (
            <PlatformAccess
              clientInfo={clientInfo}
              onClientInfoChange={handleClientInfoChange}
              onPercentageChange={setPlatformAccessPercentage}
            />
          )}
          {tab === TabKey.CORE_REPORTING && (
            <CoreReporting
              clientInfo={clientInfo}
              onClientInfoChange={handleClientInfoChange}
              onPercentageChange={setCoreReportingPercentage}
            />
          )}
          {tab === TabKey.BUDGETS_BILLING && (
            <BudgetsBilling
              clientInfo={clientInfo}
              onClientInfoChange={handleClientInfoChange}
              onPercentageChange={setBudgetPercentage}
            />
          )}
          {tab === TabKey.PLATFORM_BUDGETS && (
            <PlatformBudgets
              clientInfo={clientInfo}
              onClientInfoChange={handleClientInfoChange}
              onPercentageChange={setPlatformBudgetsPercentage}
            />
          )}
          {tab === TabKey.DATA && (
            <Data
              clientInfo={clientInfo}
              onClientInfoChange={handleClientInfoChange}
              onPercentageChange={setDataPercentage}
            />
          )}
        </div>
      </Page>

      <AlertDialog
        title="Are you sure you want to leave?"
        body="If you leave now, your changes will not be saved."
        icon={<MdSmsFailed color={SubBrand1} size={75} />}
        variant={AlertDialogVariants.INFO}
        show={showModal}
        primaryButtonLabel="Stay"
        secondaryButtonLabel="Leave"
        onHide={handleModalStay}
        secondaryAction={handleModalLeave}
      />
    </span>
  );
};

export default ClientInfoForm;
