import "./RevenueImpact.scss";
import React, { useCallback } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import AreaChart from "../../Components/Charts/AreaChart";
import * as R from "ramda";
import { exportToExcel, downloadPNG, downloadJSONToCSV } from "../../utils/download-utils";
import * as Dfns from "date-fns/fp";
import { DownloadDropdown, InfoTooltip } from "../../Components";
import MoreInfo from "../../MMM/MoreInfo";
import { XAxis, YAxis, ResponsiveContainer, BarChart, Bar } from "recharts";
import { Brand80, Brand50, Brand40, Neutral300 } from "../../utils/colors";
import { currencyFormatter } from "../../MMM/MMMUtils";
import { percFormat } from "../BrandImpact/BrandImpactUtils";
import { MdArrowForward } from "react-icons/md";

interface TotalRevenueDecomposition {
  name: string;
  MediaDrivenBrandEquity: number;
  NonMediaDrivenBrandEquity: number;
  OtherFactors: number;
  MediaDrivenBrandEquityPercent: number;
  NonMediaDrivenBrandEquityPercent: number;
  OtherFactorsPercent: number;
}

interface WeeklyRevenueDecomposition {
  date: string;
  OtherFactors: number;
  total: number;
  MediaDrivenBrandEquity: number;
  NonMediaDrivenBrandEquity: number;
}

interface RevenueImpactTakeaways {
  name: string;
  MediaDrivenBrandEquity: number;
  BrandEquityRevenue: number;
  Total: number;
  BrandEquityRevenuePercent: number;
}

interface RevenueImpactProps {
  company: string;
  totalRevenueDecomposition: TotalRevenueDecomposition[];
  weeklyRevenueDecomposition: WeeklyRevenueDecomposition[];
  revenueImpactTakeaways: RevenueImpactTakeaways;
}

export const RevenueImpact: React.FC<RevenueImpactProps> = ({
  company,
  totalRevenueDecomposition,
  weeklyRevenueDecomposition,
  revenueImpactTakeaways,
}) => {
  const excelDownloadWeeklyBrandHealth = useCallback(() => {
    exportToExcel(totalRevenueDecomposition, "weekly_brand_health");
  }, [totalRevenueDecomposition]);

  const excelDownloadWeeklySpend = useCallback(() => {
    exportToExcel(weeklyRevenueDecomposition, "weekly_spend");
  }, [weeklyRevenueDecomposition]);

  const excelDownloadModelOverview = useCallback(() => {
    exportToExcel([revenueImpactTakeaways], "model_overview");
  }, [revenueImpactTakeaways]);

  const pngDownloadWeeklyBrandHealth = useCallback(async () => {
    await downloadPNG(".brandEquityleft .chartContainer .contents", "weekly_brand_health");
  }, []);

  const pngDownloadWeeklySpend = useCallback(async () => {
    await downloadPNG(".brandEquityleft .chartContainer .contents .areaChart", "weekly_spend");
  }, []);

  const csvDownloadModelOverview = useCallback(() => {
    downloadJSONToCSV([revenueImpactTakeaways], `${company}_model_overview`);
  }, [revenueImpactTakeaways, company]);

  return (
    <WidgetContainer
      collapsible
      header={
        <div>
          {" "}
          How does our brand equity
          <MoreInfo size="reg">
            Brand equity is a combination of multiple underlying brand health metrics. We use brand
            equity to evaluate the combined impact of the brand health metrics on [KPI].
          </MoreInfo>
          impact Revenue?
        </div>
      }
      subHeader={
        <>
          Attribution of revenue to brand equity based on model parameter estimates.
          <MoreInfo rightLabel="More info" size="sm">
            Calculated for each point in time as (level of [KPI] at that point in time) - (level of
            media investments at that point in time) x (model parameter estimates of media impact on
            brand equity) x (model parameter estimates of brand equity impact on [KPI])
          </MoreInfo>
        </>
      }
    >
      {!R.isEmpty(revenueImpactTakeaways) &&
        !R.isEmpty(totalRevenueDecomposition) &&
        !R.isEmpty(weeklyRevenueDecomposition) && (
          <div className="revenueImpact">
            <div className="revenueImpactLeft">
              <ChartContainer
                enableHoverDesign
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadWeeklyBrandHealth, pngDownloadWeeklyBrandHealth]}
                  />
                }
                title="Total Revenue Decomposition"
                titleAfterDashText="Trailing Twelve Months (TTM)"
              >
                <div className="barChartHoldings">
                  <div className="titleContainer">
                    <div className="titleText">
                      Total:
                      {`$${(
                        (totalRevenueDecomposition[0].MediaDrivenBrandEquity +
                          totalRevenueDecomposition[0].NonMediaDrivenBrandEquity +
                          totalRevenueDecomposition[0].OtherFactors) /
                        1000000
                      ).toFixed(0)}`}
                    </div>
                    <div className="mText">M</div>
                  </div>
                  <div className="topSegment"></div>
                  <div className="topOfBarchartLine"></div>
                  <ResponsiveContainer width="100%" height={65}>
                    <BarChart
                      data={totalRevenueDecomposition}
                      margin={{
                        top: 4.4,
                        right: 10,
                      }}
                      layout="vertical"
                    >
                      <XAxis type="number" hide={true} />
                      <YAxis type="category" dataKey="name" hide={true} />
                      <Bar dataKey="MediaDrivenBrandEquity" stackId="a" fill={Brand80} />
                      <Bar dataKey="NonMediaDrivenBrandEquity" stackId="a" fill={Brand50} />
                      <Bar dataKey="OtherFactors" stackId="a" fill={Neutral300} />
                    </BarChart>
                  </ResponsiveContainer>
                  <div className="barChartLegend">
                    <div className="legend">
                      <div className="topRow">
                        <div className="barOne"></div>
                        <div className="titleText">
                          {`$${(
                            totalRevenueDecomposition[0].MediaDrivenBrandEquity / 1000000
                          ).toFixed(0)}`}
                        </div>
                        <div className="mText">M</div>
                        <div>
                          (
                          {percFormat(
                            totalRevenueDecomposition[0].MediaDrivenBrandEquityPercent * 100,
                            0
                          )}
                          )
                        </div>
                      </div>
                      <div className="bottomRow">Media-Driven Brand Equity</div>
                    </div>
                    <div className="legend">
                      <div className="topRow">
                        <div className="barTwo"></div>
                        <div className="titleText">
                          {`$${(
                            totalRevenueDecomposition[0].NonMediaDrivenBrandEquity / 1000000
                          ).toFixed(0)}`}
                        </div>
                        <div className="mText">M</div>
                        <div>
                          (
                          {percFormat(
                            totalRevenueDecomposition[0].NonMediaDrivenBrandEquityPercent * 100,
                            0
                          )}
                          )
                        </div>
                      </div>
                      <div className="bottomRow">Non-Media-Driven Brand Equity</div>
                    </div>
                    <div className="legend">
                      <div className="topRow">
                        <div className="barThree"></div>
                        <div className="titleText">
                          {`$${(totalRevenueDecomposition[0].OtherFactors / 1000000).toFixed(0)}`}
                        </div>
                        <div className="mText">M</div>
                        <div>
                          ( {percFormat(totalRevenueDecomposition[0].OtherFactorsPercent * 100, 0)})
                        </div>
                      </div>
                      <div className="bottomRow">
                        Other Factors <InfoTooltip color="#5F6C84">Hello</InfoTooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </ChartContainer>
              <ChartContainer
                enableHoverDesign
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadWeeklySpend, pngDownloadWeeklySpend]}
                  />
                }
                title="Weekly Revenue Decomposition"
                titleAfterDashText={`${Dfns.format(
                  "M/dd/yy",
                  new Date(weeklyRevenueDecomposition[0].date)
                )} – ${Dfns.format(
                  "M/dd/yy",
                  new Date(weeklyRevenueDecomposition[weeklyRevenueDecomposition.length - 1].date)
                )}`}
              >
                <AreaChart
                  data={weeklyRevenueDecomposition}
                  xAxisDataKey="date"
                  xAxisTickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}`))}
                  dateGrouping="Week"
                  yAxisWidth={150}
                  yAxisTickFormatter={val => `${currencyFormatter.format(val, 0)}`}
                  tooltipFormatter={val => {
                    if (!val) {
                      return val;
                    }
                    return val < 1000
                      ? currencyFormatter.format(val, 0)
                      : currencyFormatter.format(val, 1);
                  }}
                  tooltipShape="line"
                  colorOverrides={{
                    "Media-Driven Brand Equity": Brand80,
                    "Non-Media-Driven Brand Equity": Brand40,
                    "Other Factors": "#CBD2E1",
                  }}
                  reverseLegend={true}
                  reverseToolTipItems={true}
                  areas={[
                    {
                      name: "Other Factors",
                      dataKey: "OtherFactors",
                      toolTip: "Other Factors",
                      toolTipColor: "#5F6C84",
                    },
                    {
                      name: "Non-Media-Driven Brand Equity",
                      dataKey: "NonMediaDrivenBrandEquity",
                    },
                    {
                      name: "Media-Driven Brand Equity",
                      dataKey: "MediaDrivenBrandEquity",
                    },
                  ]}
                  legendTitle="Revenue From:"
                ></AreaChart>
              </ChartContainer>
            </div>
            <div className="revenueImpactRight">
              <ChartContainer
                enableHoverDesign
                title="Takeaways"
                titleAfterDashText="TTM"
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadModelOverview, csvDownloadModelOverview]}
                    menuOptions={["XLSX", "CSV"]}
                  />
                }
              >
                <div className="takeAwaysContainer">
                  <div className="sectionOne">
                    <div className="sectionOneTextContainer">
                      <div className="textLeft">
                        <div>Brand</div>
                        <div>Equity</div>
                      </div>
                      <div className="textMiddle">
                        <MdArrowForward className="icon"></MdArrowForward>
                      </div>
                      <div className="textRight">
                        <div>Incremental</div>
                        <div className="titleTopText">Revenue</div>
                        <div className="dollarImpact">
                          <div className="titleTopText">
                            ~$
                            {Math.round(revenueImpactTakeaways.BrandEquityRevenue / 1000000)}
                          </div>
                          <div className="mText">M</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sectionTwo">
                    <div className="sectionTwoTextContainer">
                      <div className="textLeft">
                        <div>Media-Driven</div>
                        <div>Brand Equity</div>
                      </div>
                      <div className="textMiddle">
                        <MdArrowForward className="icon"></MdArrowForward>
                      </div>
                      <div className="textRight">
                        <div>Incremental</div>
                        <div className="titleText">Revenue</div>
                        <div className="dollarImpactBottom">
                          <div className="titleText">
                            ~$
                            {Math.round(revenueImpactTakeaways.MediaDrivenBrandEquity / 1000000)}
                          </div>
                          <div className="mText">M</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sectionThree">
                    <div className="sectionThreeText">
                      {`Brand equity accounts for about ${revenueImpactTakeaways.BrandEquityRevenuePercent.toLocaleString(
                        "en-US",
                        {
                          maximumFractionDigits: 0,
                        }
                      )}% or $${Math.round(
                        revenueImpactTakeaways.BrandEquityRevenue / 1000000
                      )}M of the total trailing twelve
                      months revenue after controlling for other factors.`}
                    </div>
                  </div>
                </div>
              </ChartContainer>
            </div>
          </div>
        )}
    </WidgetContainer>
  );
};

export default RevenueImpact;
