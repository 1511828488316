import React, { useEffect, useState } from "react";
import { ClientInfo, Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import OnboardingFormPage from "../OnboardingFormPage";
import QuestionOnlyForm from "../QuestionOnlyForm";
import { budgetDetails, budgetStrategy, budgets } from "./BudgetsBillingQuestions";
import { getCompletedPercentage } from "../OnboardingUtils";
import { ClientInfoComponentProps } from "../ClientInfo";

enum BudgetsBillingTabs {
  BUDGET_STRATEGY = "budget-strategy",
  BUDGET_DETAILS = "budget-details",
  BUDGET_FILES = "budget-files",
}

const BudgetsBilling: React.FC<ClientInfoComponentProps> = ({
  clientInfo,
  onPercentageChange,
  onClientInfoChange,
}) => {
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);

  const [budgetFilesCompleted, setBudgetFilesCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, budgets.length, "4A")
  );
  const [budgetStrategyCompleted, setBudgetStrategyCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, budgetStrategy.length, "4B")
  );
  const [budgetDetailsCompleted, setBudgetDetailsCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, budgetDetails.length, "4C")
  );

  const handleBudgetChange = (updatedResponses: Response[]) => {
    const updatedClientInfo = {
      ...allResponses,
      responses: updatedResponses,
    };

    setAllResponses(updatedClientInfo);
    onClientInfoChange(updatedClientInfo);
  };

  useEffect(() => {
    const totalPercentage = getCompletedPercentage(
      allResponses.responses,
      budgetStrategy.length + budgetDetails.length + budgets.length,
      "4"
    );
    setBudgetFilesCompleted(getCompletedPercentage(allResponses.responses, budgets.length, "4A"));
    setBudgetStrategyCompleted(
      getCompletedPercentage(allResponses.responses, budgetStrategy.length, "4B")
    );
    setBudgetDetailsCompleted(
      getCompletedPercentage(allResponses.responses, budgetDetails.length, "4C")
    );
    onPercentageChange(totalPercentage);
  }, [allResponses, onPercentageChange]);

  const renderTabs = [
    {
      label: "Budget Files",
      headerLabel: "A. Budget Files",
      key: BudgetsBillingTabs.BUDGET_FILES,
      percentage: budgetFilesCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={budgets}
          onChange={handleBudgetChange}
        />
      ),
    },
    {
      label: "Budget Strategy",
      headerLabel: "B. Budget Strategy",
      key: BudgetsBillingTabs.BUDGET_STRATEGY,
      percentage: budgetStrategyCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={budgetStrategy}
          onChange={handleBudgetChange}
        />
      ),
    },
    {
      label: "Budget Details",
      headerLabel: "C. Budget Details",
      key: BudgetsBillingTabs.BUDGET_DETAILS,
      percentage: budgetDetailsCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={budgetDetails}
          onChange={handleBudgetChange}
        />
      ),
    },
  ];

  return (
    <OnboardingFormPage
      renderTabs={renderTabs}
      defaultActiveTab={BudgetsBillingTabs.BUDGET_STRATEGY}
      tabs={Object.values(BudgetsBillingTabs)}
    />
  );
};

export default BudgetsBilling;
