import React, { useEffect, useState, useCallback, useMemo } from "react";
import { ClientInfo, Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import OnboardingFormPage from "../OnboardingFormPage";
import OnboardingFreeForm from "../OnboardingFreeForm";
import QuestionOnlyForm from "../QuestionOnlyForm";
import { primaryContacts, calendar, metrics } from "./CoreReportingQuestions";
import { getCompletedPercentage } from "../OnboardingUtils";
import { ClientInfoComponentProps } from "../ClientInfo";

enum CoreReportingTabs {
  CONTACT_INFO = "contact-info",
  CALENDAR = "calendar",
  METRICS = "metrics",
}

const CoreReporting: React.FC<ClientInfoComponentProps> = ({
  clientInfo,
  onPercentageChange,
  onClientInfoChange,
}) => {
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);

  const contactInfoQuestionsAnswered = useCallback((): Response[] => {
    return allResponses.responses.filter(response =>
      response.selections.every(
        selection => typeof selection.openText === "string" && selection.openText.trim() !== ""
      )
    );
  }, [allResponses.responses]);

  const contactInfoRowsLength = useMemo((): number => {
    if (!allResponses.responses || allResponses.responses.length === 0) {
      return primaryContacts.rows.length;
    }
    return allResponses.responses.filter(response => response.questionNumber.startsWith("3A"))
      .length;
  }, [allResponses.responses]);

  const [contactInfoCompleted, setContactInfoCompleted] = useState<number>(
    getCompletedPercentage(contactInfoQuestionsAnswered(), contactInfoRowsLength, "3A")
  );
  const [calendarCompleted, setCalendarCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, calendar.length, "3B")
  );
  const [metricsCompleted, setMetricsCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, metrics.length, "3C")
  );

  const handleCoreReportingChange = (updatedResponses: Response[]) => {
    const updatedClientInfo = {
      ...allResponses,
      responses: updatedResponses,
    };

    setAllResponses(updatedClientInfo);
    onClientInfoChange(updatedClientInfo);
  };

  useEffect(() => {
    const totalPercentage = getCompletedPercentage(
      allResponses.responses,
      contactInfoRowsLength + calendar.length + metrics.length,
      "3"
    );
    setContactInfoCompleted(
      getCompletedPercentage(contactInfoQuestionsAnswered(), contactInfoRowsLength, "3A")
    );
    setCalendarCompleted(getCompletedPercentage(allResponses.responses, calendar.length, "3B"));
    setMetricsCompleted(getCompletedPercentage(allResponses.responses, metrics.length, "3C"));
    onPercentageChange(totalPercentage);
  }, [allResponses, onPercentageChange, contactInfoQuestionsAnswered, contactInfoRowsLength]);

  const renderTabs = [
    {
      label: "Primary Contacts for:",
      headerLabel: "A. Contact Info",
      key: CoreReportingTabs.CONTACT_INFO,
      percentage: contactInfoCompleted,
      children: (
        <OnboardingFreeForm
          responses={allResponses.responses}
          onDataChange={handleCoreReportingChange}
          questions={primaryContacts}
        />
      ),
    },
    {
      label: "Calendar",
      headerLabel: "B. Calendar",
      key: CoreReportingTabs.CALENDAR,
      percentage: calendarCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={calendar}
          onChange={handleCoreReportingChange}
        />
      ),
    },
    {
      label: "Metrics",
      headerLabel: "C. Metrics",
      key: CoreReportingTabs.METRICS,
      percentage: metricsCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={metrics}
          onChange={handleCoreReportingChange}
        />
      ),
    },
  ];

  return (
    <OnboardingFormPage
      renderTabs={renderTabs}
      defaultActiveTab={CoreReportingTabs.CONTACT_INFO}
      tabs={Object.values(CoreReportingTabs)}
    />
  );
};

export default CoreReporting;
