import "./GoogleBrandHealth.scss";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Img,
  DownloadDropdown,
  Dropdown,
  DropdownToggleType,
  TextToggleButton,
  DmaMap,
  BPMTable,
  CheckBox,
  FullPageSpinner,
  CellRenderer,
} from "../Components";
import LineChart from "../Components/Charts/LineChart";
import ChartContainer from "../Components/ChartContainer";
import { downloadPNG, exportToExcel } from "../utils/download-utils";
import {
  filterTableDataByLabel,
  filterGqvQueryDataByRegionAndDateRange,
  formatGqvQueryDataForTimeSeries,
  groupFormattedGqvLineChartDataByDate,
  groupGqvQueryDataByDmaCode,
  returnTitleForGqvChart,
} from "./BrandHealthMetricsUtils";
import * as Dfns from "date-fns/fp";
import {
  Brand20,
  Brand25,
  Brand40,
  Brand60,
  Brand80,
  Channel1,
  Channel2,
  Channel3,
  Channel4,
} from "../utils/colors";
import { numberFormatter } from "../MMM/MMMUtils";
import FiltersPanelGoogleBrandHealth from "./FiltersPanelGoogleBrandHealth";
import AutoSizer from "react-virtualized-auto-sizer";
import { useMap } from "../utils/hooks/useData";
import { DateRange } from "../utils/types";
import {
  BrandHealthGqvQueryInfo,
  BrandHealthTableInfo,
} from "@blisspointmedia/bpm-types/dist/BrandHealthMetrics";
import { DATE_GROUPING_OPTIONS, DateGroupingKey } from "../TVADCrossChannel/homePageConstants";
import { formatDateLabel } from "../TVADCrossChannel/homePageUtils";
import BinsLegends from "./BinsLegend";

export const gqvDataHeaders = [
  {
    name: "dma_full_name",
    label: "DMA Full Name",
    flex: 1,
  },
  {
    name: "dma_code",
    label: "DMA Code",
    flex: 1,
  },
  {
    name: "Absolute Index",
    label: "Absolute Index",
    flex: 1,
    renderer: (data: BrandHealthTableInfo): string =>
      data["Absolute Index"].toLocaleString("en-US", {
        maximumFractionDigits: 2,
      }),
  },
  {
    name: "Population",
    label: "Population",
    flex: 1,
    renderer: (data: BrandHealthTableInfo): string =>
      data.Population.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      }),
  },
];

export interface DateGroupingOption {
  value: DateGroupingKey;
  label: string;
}

interface ExtendedBrandHealthTableInfo extends BrandHealthTableInfo {
  adjustedIndexedVolume: number;
}

export const CHART_TYPE_OPTIONS = [
  { value: "graph", label: "Graph" },
  { value: "table", label: "Table" },
  { value: "map", label: "Map" },
];

interface GoogleBrandHealthProps {
  gqvQueryDataWithGeoNamesCodesAndPopulation: BrandHealthGqvQueryInfo[];
  dateRange: DateRange | undefined;
}

const colors = [Channel1, Channel3, Channel2, Channel4];
const mapColorsActual = [Brand20, Brand25, Brand40, Brand60, Brand80];
const mapColorsAdjusted = ["#CBD2E1", "#E1E6EF", "#CBEF99", "#B1E666", "#7ED600"];

const GoogleBrandHealth = ({
  gqvQueryDataWithGeoNamesCodesAndPopulation,
  dateRange,
}: GoogleBrandHealthProps & RouteComponentProps): JSX.Element => {
  const [chartDateGrouping, setChartDateGrouping] = useState("Day");
  const [chartType, setChartType] = useState("graph");
  const [selectedToggleOptionMap, setSelectedToggleOptionMap] = useState<string>("Actual");

  const [regionMapGoogle, setRegionMapGoogle] = useMap<string, boolean>({
    "Region-All": true,
    Northeast: false,
    South: false,
    Midwest: false,
    West: false,
  });

  const [labels, setLabels] = useMap<string, boolean>({
    "Label 01": true,
    "Label 02": true,
    "Label 03": true,
    "Label 04": true,
  });

  const activeLabels = Object.entries(labels)
    .filter(([key, value]) => value)
    .map(([key]) => key)
    .join(", ");

  const filteredGqvDataByRegionAndDateRange = useMemo(() => {
    if (gqvQueryDataWithGeoNamesCodesAndPopulation.length > 0 && regionMapGoogle && dateRange) {
      return filterGqvQueryDataByRegionAndDateRange(
        gqvQueryDataWithGeoNamesCodesAndPopulation,
        regionMapGoogle,
        dateRange
      );
    }
    return [];
  }, [gqvQueryDataWithGeoNamesCodesAndPopulation, regionMapGoogle, dateRange]);

  const { res: timeSeriesGoogleQueryVolumeDataSumByLabelAndByDate, uniqueLabels } = useMemo(() => {
    if (filteredGqvDataByRegionAndDateRange.length > 0) {
      const { res: formattedGqvLineChartData, uniqueLabels } = formatGqvQueryDataForTimeSeries(
        filteredGqvDataByRegionAndDateRange
      );
      const groupByDateGqvData = groupFormattedGqvLineChartDataByDate(
        formattedGqvLineChartData,
        chartDateGrouping
      );
      return { res: groupByDateGqvData, uniqueLabels };
    }
    return { res: [], uniqueLabels: [] };
  }, [chartDateGrouping, filteredGqvDataByRegionAndDateRange]);

  const filteredDataTable = useMemo(() => {
    if (filteredGqvDataByRegionAndDateRange.length > 0 && labels) {
      return filterTableDataByLabel(filteredGqvDataByRegionAndDateRange, labels);
    }
    return [];
  }, [filteredGqvDataByRegionAndDateRange, labels]);

  const { formattedTableData, absoluteIndexSum, populationSum } = useMemo(() => {
    if (filteredDataTable.length > 0) {
      const groupedGqvQueryDataByDmaCode = groupGqvQueryDataByDmaCode(filteredDataTable);
      const { absoluteIndexSum, populationSum } = groupedGqvQueryDataByDmaCode.reduce(
        (acc, item) => {
          acc.absoluteIndexSum += item["Absolute Index"];
          acc.populationSum += item.Population;
          return acc;
        },
        { absoluteIndexSum: 0, populationSum: 0 }
      );
      return {
        formattedTableData: groupedGqvQueryDataByDmaCode,
        absoluteIndexSum: absoluteIndexSum,
        populationSum: populationSum,
      };
    }
    return { formattedTableData: [], absoluteIndexSum: 0, populationSum: 0 };
  }, [filteredDataTable]);

  const timeSeriesDataGqvByRegionAndDate = timeSeriesGoogleQueryVolumeDataSumByLabelAndByDate;

  const {
    renderColor,
    renderAdjustedColor,
    renderToolTip,
    renderAdjustedTooltip,
    thresholds,
    adjustedThresholds,
  } = useMemo(() => {
    if (formattedTableData && formattedTableData.length > 0) {
      const absoluteIndices = formattedTableData.map(item => item["Absolute Index"]);
      const minIndex = Math.min(...absoluteIndices);
      const maxIndex = Math.max(...absoluteIndices);

      const binSize = (maxIndex - minIndex) / 5;
      const thresholds: number[] = [];
      for (let i = 1; i < 5; i++) {
        thresholds.push(minIndex + i * binSize);
      }

      const dmaIndexMap: { [key: string]: BrandHealthTableInfo } = {};
      formattedTableData.forEach(item => {
        dmaIndexMap[item.dma_code] = {
          dma_code: item.dma_code,
          dma_full_name: item.dma_full_name,
          "Absolute Index": item["Absolute Index"],
          Population: item.Population,
        };
      });

      const renderColor = (dma_code: string) => {
        if (dmaIndexMap[dma_code] && dmaIndexMap[dma_code]["Absolute Index"] !== undefined) {
          const { "Absolute Index": absoluteIndex } = dmaIndexMap[dma_code];
          if (absoluteIndex <= thresholds[0]) {
            return mapColorsActual[0];
          } else if (absoluteIndex <= thresholds[1]) {
            return mapColorsActual[1];
          } else if (absoluteIndex <= thresholds[2]) {
            return mapColorsActual[2];
          } else if (absoluteIndex <= thresholds[3]) {
            return mapColorsActual[3];
          } else {
            return mapColorsActual[4];
          }
        } else {
          return "transparent";
        }
      };

      const renderToolTip = (dma_code: string) => {
        if (!dmaIndexMap[dma_code]) {
          return null;
        }

        const { "Absolute Index": absoluteIndex, dma_full_name, Population } = dmaIndexMap[
          dma_code
        ];
        return (
          <div className="chartTooltipMap">
            <div className="headerLabelMap">{`${dma_full_name} (DMA Code: ${dma_code})`}</div>
            <div className="itemListMap">
              <div className="itemRowMap">
                <div className="nameMap">{"Actual Index "}</div>
                <div className="valueMap">{`${absoluteIndex.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}`}</div>
              </div>
              <div className="itemRowMap">
                <div className="nameMap">{"Population "}</div>
                <div className="valueMap">{`${Population.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}`}</div>
              </div>
            </div>
          </div>
        );
      };

      const adjustedVolumes = formattedTableData.map(item => ({
        dma_code: item.dma_code,
        adjustedVolume: item["Absolute Index"] / item.Population,
        dma_full_name: item.dma_full_name,
        "Absolute Index": item["Absolute Index"],
        Population: item.Population,
      }));

      const totalAdjustedVolume = adjustedVolumes.reduce(
        (sum, item) => sum + item.adjustedVolume,
        0
      );
      const averageAdjustedVolume = totalAdjustedVolume / adjustedVolumes.length;

      const adjustedIndexedVolumes = adjustedVolumes.map(item => ({
        dma_code: item.dma_code,
        adjustedIndexedVolume: item.adjustedVolume / averageAdjustedVolume,
        dma_full_name: item.dma_full_name,
        "Absolute Index": item["Absolute Index"],
        Population: item.Population,
      }));

      const adjustedIndexedValues = adjustedIndexedVolumes.map(item => item.adjustedIndexedVolume);

      const minAdjustedIndex = Math.min(...adjustedIndexedValues);
      const maxAdjustedIndex = Math.max(...adjustedIndexedValues);

      const adjustedBinSize = (maxAdjustedIndex - minAdjustedIndex) / 5;
      const adjustedThresholds: number[] = [];
      for (let i = 1; i < 5; i++) {
        adjustedThresholds.push(minAdjustedIndex + i * adjustedBinSize);
      }

      const dmaAdjustedIndexMap: { [key: string]: ExtendedBrandHealthTableInfo } = {};
      adjustedIndexedVolumes.forEach(item => {
        dmaAdjustedIndexMap[item.dma_code] = {
          dma_code: item.dma_code,
          dma_full_name: item.dma_full_name,
          "Absolute Index": item["Absolute Index"],
          Population: item.Population,
          adjustedIndexedVolume: item.adjustedIndexedVolume,
        };
      });

      const renderAdjustedColor = (dma_code: string) => {
        if (
          dmaAdjustedIndexMap[dma_code] &&
          dmaAdjustedIndexMap[dma_code].adjustedIndexedVolume !== undefined
        ) {
          const { adjustedIndexedVolume } = dmaAdjustedIndexMap[dma_code];
          if (adjustedIndexedVolume <= adjustedThresholds[0]) {
            return mapColorsAdjusted[0];
          } else if (adjustedIndexedVolume <= adjustedThresholds[1]) {
            return mapColorsAdjusted[1];
          } else if (adjustedIndexedVolume <= adjustedThresholds[2]) {
            return mapColorsAdjusted[2];
          } else if (adjustedIndexedVolume <= adjustedThresholds[3]) {
            return mapColorsAdjusted[3];
          } else {
            return mapColorsAdjusted[4];
          }
        } else {
          return "transparent";
        }
      };

      const renderAdjustedTooltip = (dma_code: string) => {
        if (!dmaAdjustedIndexMap[dma_code]) {
          return null;
        }

        const { "Absolute Index": absoluteIndex, dma_full_name, Population } = dmaAdjustedIndexMap[
          dma_code
        ];
        return (
          <div className="chartTooltipMap">
            <div className="headerLabelMap">{`${dma_full_name} (DMA Code: ${dma_code})`}</div>
            <div className="itemListMap">
              <div className="itemRowMap">
                <div className="nameMap">{"Actual Index "}</div>
                <div className="valueMap">{`${absoluteIndex.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}`}</div>
              </div>
              <div className="itemRowMap">
                <div className="nameMap">{"Population "}</div>
                <div className="valueMap">{`${Population.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}`}</div>
              </div>
              <div className="footerMap">
                <div className="footerLabelMap">{"Pop. Adjusted Index "}</div>
                <div className="footerValueMap">{`${absoluteIndex.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}`}</div>
              </div>
            </div>
          </div>
        );
      };

      return {
        renderColor,
        renderAdjustedColor,
        renderToolTip,
        renderAdjustedTooltip,
        thresholds,
        adjustedThresholds,
      };
    } else {
      return {
        renderColor: () => "transparent",
        renderAdjustedColor: () => "transparent",
        renderToolTip: () => "No info for this DMA",
        renderAdjustedTooltip: () => "No info for this DMA",
      };
    }
  }, [formattedTableData]);

  const mapSvgRef = useRef<SVGSVGElement | null>(null);

  const pngDownloadTimeSeries = useCallback(async () => {
    await downloadPNG(".chartContainer", `${chartDateGrouping}_Indexed_Google_Query_Volume`);
  }, [chartDateGrouping]);

  const excelDownloadTimeSeries = useCallback(() => {
    exportToExcel(
      timeSeriesDataGqvByRegionAndDate,
      `${chartDateGrouping}_Indexed_Google_Query_Volume`
    );
  }, [timeSeriesDataGqvByRegionAndDate, chartDateGrouping]);

  const pngDownloadTable = useCallback(async () => {
    await downloadPNG(".chartContainer", `${chartDateGrouping}_Indexed_Google_Query_Volume`);
  }, [chartDateGrouping]);

  const excelDownloadTable = useCallback(() => {
    exportToExcel(formattedTableData, "Total Google Query Volume");
  }, [formattedTableData]);

  const superHeaderGqvTabel: any[] = [{ span: 4, data: activeLabels }];

  const totals = {
    "Absolute Index": Number(absoluteIndexSum.toFixed(2)),
    Population: Number(populationSum.toFixed(0)),
  };

  const totalsRenderer: CellRenderer<String | Number | Element | undefined> = ({
    data,
    style,
    classes,
  }) => {
    if (data === undefined) {
      return <div style={style} className={classes.join(" ")}></div>;
    }

    return (
      <div style={style} className={classes.join(" ")}>
        <div className="cellContents">
          <div className="cellContentsHeader">TOTAL</div>
          <div className="cellContentsValue">
            {data.toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>
    );
  };

  return dateRange !== undefined ? (
    <div className="googleBrandHealth">
      <div className="googleBrandHealthleft">
        <ChartContainer
          enableHoverDesign
          rightActions={
            <div className="googleBrandHealthRightActions">
              {chartType === "map" && (
                <TextToggleButton
                  design="secondary-light"
                  options={["Actual", "Population Adjusted"]}
                  selectedOption={selectedToggleOptionMap}
                  onChange={setSelectedToggleOptionMap}
                ></TextToggleButton>
              )}
              <Dropdown
                type={DropdownToggleType.FILLED}
                value={chartType}
                options={CHART_TYPE_OPTIONS}
                onChange={option => setChartType(option)}
              />
              <DownloadDropdown
                size="sm"
                onClickOptions={[
                  chartType === "graph" ? excelDownloadTimeSeries : excelDownloadTable,
                  chartType === "graph" ? pngDownloadTimeSeries : pngDownloadTable,
                ]}
              />
            </div>
          }
          title={
            <>
              {chartType === "graph" && (
                <Dropdown
                  type={DropdownToggleType.WIDGET_TITLE}
                  value={chartDateGrouping}
                  options={DATE_GROUPING_OPTIONS}
                  onChange={option => setChartDateGrouping(option)}
                />
              )}
              <div>{returnTitleForGqvChart(chartType)}</div>
            </>
          }
          titleAfterDashText={`${Dfns.format(
            "M/dd/yy",
            Dfns.parseISO(dateRange.start)
          )} – ${Dfns.format("M/dd/yy", Dfns.parseISO(dateRange.end))}`}
        >
          {chartType === "graph" && (
            <LineChart
              customTooltipHeader={date => `${formatDateLabel(date, chartDateGrouping, true)}`}
              data={timeSeriesDataGqvByRegionAndDate}
              lineDataKeys={uniqueLabels.map(label => ({
                name: label,
                dataKey: label,
              }))}
              colorMappings={uniqueLabels.map((row, index) => ({
                name: row,
                color: colors[index],
              }))}
              tooltipFormatter={val => `${numberFormatter.format(val, 2)}`}
              tooltipShape="line"
              usePercentageYAxis={false}
              useLeftYAxis
              xAxisDataKey="ReportDate"
              xAxisTickFormatter={date =>
                `${
                  chartDateGrouping === "Month"
                    ? Dfns.format("M/yy", Dfns.parseISO(date))
                    : formatDateLabel(date, chartDateGrouping, false)
                }`
              }
            />
          )}
          {chartType === "map" && thresholds && adjustedThresholds && (
            <div className="geoMapContainer">
              <div className="geoMapLegendAndMap">
                <BinsLegends
                  bins={selectedToggleOptionMap === "Actual" ? thresholds : adjustedThresholds}
                  actual={selectedToggleOptionMap === "Actual"}
                ></BinsLegends>
                <AutoSizer>
                  {({ width, height }) => (
                    <DmaMap
                      width={width}
                      height={height}
                      renderColor={
                        selectedToggleOptionMap === "Actual" ? renderColor : renderAdjustedColor
                      }
                      renderTooltip={
                        selectedToggleOptionMap === "Actual" ? renderToolTip : renderAdjustedTooltip
                      }
                      ref={mapSvgRef}
                    />
                  )}
                </AutoSizer>
              </div>
              <div className="labelFilter">
                {uniqueLabels.map(label => (
                  <div key={label} className="labelItem">
                    <div className="item">
                      <CheckBox
                        className="checkBox"
                        checked={labels[label] || false}
                        label={label}
                        onCheck={() => {
                          setLabels(label, !labels[label]);
                        }}
                      ></CheckBox>
                      <div>{label}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {chartType === "table" && (
            <div className="gqvTableContainer">
              <div className="gqvTable">
                <BPMTable
                  data={formattedTableData}
                  headers={gqvDataHeaders}
                  filterBar={false}
                  superHeaders={superHeaderGqvTabel}
                  totals={totals}
                  totalsRenderer={totalsRenderer}
                ></BPMTable>
              </div>
              <div className="labelFilter">
                {uniqueLabels.map(label => (
                  <div key={label} className="labelItem">
                    <div className="item">
                      <CheckBox
                        className="checkBox"
                        checked={labels[label] || false}
                        label={label}
                        onCheck={() => {
                          setLabels(label, !labels[label]);
                        }}
                      ></CheckBox>
                      <div>{label}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </ChartContainer>
      </div>
      <div className="googleBrandHealthRight">
        <ChartContainer title="Filters" enableHoverDesign>
          <FiltersPanelGoogleBrandHealth
            regionMap={regionMapGoogle}
            setRegionMap={setRegionMapGoogle}
          ></FiltersPanelGoogleBrandHealth>
        </ChartContainer>
        <div className="signature">
          <div className="signatureLabel">Powered By:</div>
          <div className="signatureImage">
            <Img src="https://cdn.blisspointmedia.com/assets/img/Google.png" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <FullPageSpinner />
  );
};

export default GoogleBrandHealth;
