import {
  Metric,
  CrossChannelMetrics,
  BudgetsData,
} from "@blisspointmedia/bpm-types/dist/CrossChannel";
import DeliveryBreakdown from "../Components/DeliveryBreakdown";
import DeliverySnapshot from "../Components/DeliverySnapshot";
import PerformanceBreakdown from "../Components/PerformanceBreakdown";
import PerformanceSnapshot from "../Components/PerformanceSnapshot";
import { FullPageSpinner } from "../../Components";

interface DeliveryAndPerformanceProps {
  data: CrossChannelMetrics[];
  otherData: CrossChannelMetrics[];
  budgetsData: BudgetsData[];
  defaultBudgetSnapshotTop?: string;
  defaultBudgetSnapshotBottom?: string;
  defaultDeliveryIncludeOtherDates?: boolean;
  defaultDeliveryMetric?: Metric;
  defaultPerformanceBreakdownIncludeOtherDates?: boolean;
  defaultPerformanceBreakdownMetric?: Metric;
  defaultPerformanceSnapshotMetric?: Metric;
  metricsDataLoading: boolean;
}

const DeliveryAndPerformance: React.FC<DeliveryAndPerformanceProps> = ({
  data,
  otherData,
  budgetsData,
  defaultBudgetSnapshotTop,
  defaultBudgetSnapshotBottom,
  defaultDeliveryIncludeOtherDates,
  defaultDeliveryMetric,
  defaultPerformanceBreakdownIncludeOtherDates,
  defaultPerformanceBreakdownMetric,
  defaultPerformanceSnapshotMetric,
  metricsDataLoading,
}) => {
  return (
    <>
      {metricsDataLoading ? (
        <FullPageSpinner />
      ) : (
        <div className="ccDeliveryAndPerformance">
          <div className="ccRow">
            <div className="ccDeliverySnapshot">
              <DeliverySnapshot
                data={data}
                otherData={otherData}
                budgetsData={budgetsData}
                defaultTop={defaultBudgetSnapshotTop}
                defaultBottom={defaultBudgetSnapshotBottom}
              />
            </div>
            <div className="ccDeliveryBreakdown">
              <DeliveryBreakdown
                data={data}
                otherData={otherData}
                defaultMetric={defaultDeliveryMetric}
                defaultIncludeOtherDates={defaultDeliveryIncludeOtherDates}
              />
            </div>
          </div>
          <div className="ccRow">
            <div className="ccPerformanceSnapshot">
              <PerformanceSnapshot
                data={data}
                otherData={otherData}
                defaultMetric={defaultPerformanceSnapshotMetric}
              />
            </div>
            <div className="ccPerformanceBreakdown">
              <PerformanceBreakdown
                data={data}
                otherData={otherData}
                defaultMetric={defaultPerformanceBreakdownMetric}
                defaultIncludeOtherDates={defaultPerformanceBreakdownIncludeOtherDates}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeliveryAndPerformance;
