import React from "react";
import "./ChartContainerFooter.scss";

interface ChartContainerFooterProps {
  content: string | string[] | React.ReactNode;
}

export const ChartContainerFooter: React.FC<ChartContainerFooterProps> = ({ content }) => {
  let resolvedContent = content;
  if (Array.isArray(content)) {
    resolvedContent = content.map(str => <span>{`${str}`}</span>);
  }
  return (
    <div className="chartContainerFooterContainer">
      <div className="chartContainerFooter">
        <>{resolvedContent}</>
      </div>
    </div>
  );
};
