import React from "react";
import { RouteComponentProps } from "@reach/router";
import { selectionsToArray } from "./MetaBuyingUtils";
import { Button, ButtonType } from "../Components";

interface MetaBuyingTabsProps {
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  creationTabs: {
    Campaign: boolean;
    "Ad Set": boolean;
    Ad: boolean;
  };
  validate: (index: number) => void;
}

export const MetaBuyingTabs = ({
  tabIndex,
  setTabIndex,
  creationTabs,
  validate,
}: MetaBuyingTabsProps & RouteComponentProps): JSX.Element => {
  return (
    <div className="metaBuyingTabs">
      {selectionsToArray(creationTabs).map((tab, index) => (
        <Button
          key={tab}
          //   disabled={!valid}
          type={ButtonType.EMPTY}
          className={tabIndex === index ? "selected" : ""}
          onClick={() => validate(index)}
          //   onClick={() => setTabIndex(index)}
        >
          {index + 1}. {tab}
        </Button>
      ))}
    </div>
  );
};
