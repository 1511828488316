import "./BinsLegend.scss";
import React from "react";

import { Brand20, Brand25, Brand40, Brand60, Brand80 } from "../utils/colors";

interface BinsLegendsProps {
  bins: number[];
  actual: boolean;
}

const actualSquareColors = [Brand20, Brand25, Brand40, Brand60, Brand80];
const adjustedSquareColors = ["#CBD2E1", "#E1E6EF", "#CBEF99", "#B1E666", "#7ED600"];

export const BinsLegends: React.FC<BinsLegendsProps> = ({ bins, actual }) => {
  return (
    <div className="legendMap">
      <div className="legendTitle">Legend:</div>
      {bins.map((item, i) => {
        if (i === 0) {
          return (
            <div className="legendItem" key={i}>
              <div
                className="legendShape"
                style={{
                  backgroundColor: actual ? actualSquareColors[i] : adjustedSquareColors[i],
                }}
              ></div>
              <div className="legendText">
                {`0-${item.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}`}
              </div>
            </div>
          );
        } else if (i === bins.length - 1) {
          return (
            <div className="legendItem" key={i}>
              <div
                className="legendShape"
                style={{
                  backgroundColor: actual ? actualSquareColors[i] : adjustedSquareColors[i],
                }}
              ></div>
              <div className="legendText">
                {`${item.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}+`}
              </div>
            </div>
          );
        } else {
          return (
            <div className="legendItem" key={i}>
              <div
                className="legendShape"
                style={{
                  backgroundColor: actual ? actualSquareColors[i] : adjustedSquareColors[i],
                }}
              ></div>
              <div className="legendText">
                {`${bins[i].toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}-${bins[i - 1].toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}`}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default BinsLegends;
