// Experiment to allow access to the YouTube Planning Tool.

const youTubePlanningUsers: string[] = [
  "carling.sugarman@tinuiti.com",
  "lauren.wolfen@tinuiti.com",
  "sean.odlum@tinuiti.com",
  "brian.binder@tinuiti.com",
  "micah.kilimann@tinuiti.com",
  // Add users here!
];

export const shouldEnableYouTubePlanning = (userEmail: string): boolean => {
  return youTubePlanningUsers.includes(userEmail);
};
