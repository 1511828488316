import { DateRange } from "../utils/types";
import {
  BrandHealthGqvQueryInfo,
  BrandHealthTableInfo,
  BrandHealthGqvTimeSeries,
  BrandHealthGqvTimeSeriesObject,
} from "@blisspointmedia/bpm-types/dist/BrandHealthMetrics";

export const formatGqvQueryDataForTimeSeries = (
  filteredGqvDataByRegionAndDateRange: BrandHealthGqvQueryInfo[]
): BrandHealthGqvTimeSeriesObject => {
  const sumsByDate: { [date: string]: { [label: string]: number } } = {};

  const uniqueLabelsSet = new Set<string>();

  for (let i = 0; i < filteredGqvDataByRegionAndDateRange.length; i++) {
    const row = filteredGqvDataByRegionAndDateRange[i];
    const date = row.ReportDate;
    const label = row.QueryLabel;
    const volume = row.IndexedQueryVolume;

    uniqueLabelsSet.add(label);

    if (!sumsByDate[date]) {
      sumsByDate[date] = {};
    }
    if (!sumsByDate[date][label]) {
      sumsByDate[date][label] = 0;
    }
    sumsByDate[date][label] += volume;
  }

  const uniqueLabels = Array.from(uniqueLabelsSet).sort();

  const allDates = Object.keys(sumsByDate).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  const result: BrandHealthGqvTimeSeries[] = [];

  for (let i = 0; i < allDates.length; i++) {
    const date = allDates[i];
    const dateSums = sumsByDate[date];
    const obj: any = {
      ReportDate: date,
    };

    for (let j = 0; j < uniqueLabels.length; j++) {
      const label = uniqueLabels[j];
      obj[label] = dateSums[label] || 0;
    }

    result.push(obj);
  }
  return { res: result, uniqueLabels: uniqueLabels };
};

export const filterGqvQueryDataByRegionAndDateRange = (
  gqvQueryDataWithGeoNamesCodesAndPopulation: BrandHealthGqvQueryInfo[],
  filters: Partial<Record<string, boolean>>,
  dateRange: DateRange
): BrandHealthGqvQueryInfo[] => {
  const filteredData = gqvQueryDataWithGeoNamesCodesAndPopulation.filter(item => {
    const reportDateStr = item.ReportDate;
    return reportDateStr >= dateRange.start && reportDateStr <= dateRange.end;
  });

  const regions = Object.keys(filters).filter(region => filters[region]);
  const filteredTimeSeriesData = filteredData.filter(row => regions.includes(row.Region));
  return regions.includes("Region-All") ? filteredData : filteredTimeSeriesData;
};

const capitalizeDmaFullName = (input: string): string => {
  return input
    .split(", ")
    .map((segment, index) => {
      if (index === 0) {
        return segment
          .split(" ")
          .map(word =>
            word
              .split("-")
              .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
              .join("-")
          )
          .join(" ");
      } else {
        return segment.toUpperCase();
      }
    })
    .join(", ");
};

export const groupGqvQueryDataByDmaCode = (
  data: BrandHealthGqvQueryInfo[]
): BrandHealthTableInfo[] => {
  const aggregation: { [key: string]: BrandHealthTableInfo } = {};

  data.forEach(item => {
    const { dma_code } = item;
    if (!dma_code) {
      return;
    }

    if (!aggregation[dma_code]) {
      aggregation[dma_code] = {
        dma_full_name: capitalizeDmaFullName(item.dma_full_name),
        dma_code: dma_code,
        "Absolute Index": 0,
        Population: parseFloat(item.population),
      };
    }

    const volume =
      typeof item.IndexedQueryVolume === "number"
        ? item.IndexedQueryVolume
        : parseFloat(item.IndexedQueryVolume);

    aggregation[dma_code]["Absolute Index"] += volume;
  });

  return Object.values(aggregation);
};

export const returnTitleForGqvChart = (typeOfChart: string): string => {
  let title = "";
  switch (typeOfChart) {
    case "graph":
      title = "Indexed Google Query Volume";
      break;
    case "table":
      title = "Total Google Query Volume";
      break;
    case "map":
      title = "Indexed Google Query Volume by DMA";
      break;
    default:
      title = "Indexed Google Query Volume";
      break;
  }

  return title;
};

export const filterTableDataByLabel = (
  filteredGqvDataByRegionAndDateRange: BrandHealthGqvQueryInfo[],
  labels: Partial<Record<string, boolean>>
): BrandHealthGqvQueryInfo[] => {
  const labelArray = Object.keys(labels).filter(label => labels[label]);
  const filteredTableData = filteredGqvDataByRegionAndDateRange.filter(row =>
    labelArray.includes(row.QueryLabel)
  );
  return filteredTableData;
};

const getStartOfWeek = (date: Date): Date => {
  const day = date.getUTCDay() || 7;
  if (day !== 1) {
    date.setUTCDate(date.getUTCDate() - day + 1);
  }
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
};

const getStartOfMonth = (date: Date): Date => {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
};

const getStartOfQuarter = (date: Date): Date => {
  const month = date.getUTCMonth();
  const quarterStartMonth = month - (month % 3);
  return new Date(Date.UTC(date.getUTCFullYear(), quarterStartMonth, 1));
};

const getStartOfYear = (date: Date): Date => {
  return new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
};

export const groupFormattedGqvLineChartDataByDate = (
  formattedGqvLineChartData: BrandHealthGqvTimeSeries[],
  groupingOption: string
): BrandHealthGqvTimeSeries[] => {
  const groups: { [groupKey: string]: BrandHealthGqvTimeSeries } = {};

  formattedGqvLineChartData.forEach((item: BrandHealthGqvTimeSeries) => {
    const date = new Date(item.ReportDate);
    let groupKey: string;
    let groupDate: Date;

    switch (groupingOption) {
      case "Day":
        groupDate = new Date(
          Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
        );
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Week":
        groupDate = getStartOfWeek(new Date(date));
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Month":
        groupDate = getStartOfMonth(date);
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Quarter":
        groupDate = getStartOfQuarter(date);
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      case "Year":
        groupDate = getStartOfYear(date);
        groupKey = groupDate.toISOString().split("T")[0];
        break;
      default:
        throw new Error("Invalid grouping option");
    }

    if (!groups[groupKey]) {
      groups[groupKey] = { ReportDate: groupKey };
    }

    for (const [key, value] of Object.entries(item)) {
      if (key !== "ReportDate" && typeof value === "number") {
        groups[groupKey][key] = ((groups[groupKey][key] as number) || 0) + value;
      }
    }
  });

  return Object.values(groups);
};
