import React, { useEffect, useMemo } from "react";
import { ListGroup, CloseButton as ClearButton } from "react-bootstrap";
import { Dropdown, DropdownToggleType, Img } from "../Components";
import {
  CreativeInsightsIsciData,
  Importance,
  CreativeInfoItem,
} from "@blisspointmedia/bpm-types/dist/Creative";
import "./CreativeShelf.scss";
import CreativeListPlaceHolder from "./CreativeListPlaceHolder";
import CreativeView from "./CreativeView";
import { BsPlayBtn } from "react-icons/bs";
import { useCompanyInfo } from "../redux/company";
import { getCreativeThumbnail } from "../SingleChannel/MetricsTable/metricsTableUtils";

interface CreativeShelfProps {
  selectedAttribute: string;
  attributeTypeBinomial: { [attribute: string]: boolean };
  selectedTag: string;
  setSelectedTag: React.Dispatch<React.SetStateAction<string>>;
  creativeInsightsIsciData: CreativeInsightsIsciData;
  tagImportanceData: { [concept: string]: Importance[] };
  selectedCreative: CreativeInfoItem | undefined;
  setSelectedCreative: React.Dispatch<React.SetStateAction<CreativeInfoItem | undefined>>;
  creativeInfoList: CreativeInfoItem[];
}

const CreativeShelf = ({
  selectedAttribute,
  attributeTypeBinomial,
  selectedTag,
  setSelectedTag,
  tagImportanceData,
  selectedCreative,
  setSelectedCreative,
  creativeInfoList,
}: CreativeShelfProps): JSX.Element => {
  const { cid } = useCompanyInfo();

  const filteredCreativeList: CreativeInfoItem[] = useMemo(() => {
    let output: CreativeInfoItem[] = [];
    if (selectedAttribute === "Concept") {
      // This is the simplest case where we just check if the selectedTag is the same as the creative's concept
      output = creativeInfoList.filter(creative => selectedTag === creative.concept);
      // Another simple case where we just return creatives with selectedTag as the creative's language
    } else if (selectedAttribute === "Language") {
      output = creativeInfoList.filter(creative => creative.language === selectedTag);
    } else if (attributeTypeBinomial[selectedAttribute]) {
      // In this isBinary case we just want to check if the selectedAttribute is or isn't in the creative map
      output = creativeInfoList.filter(creative => {
        if (selectedTag === selectedAttribute) {
          return creative.creativeTags[selectedAttribute];
        } else if (selectedTag === `No ${selectedAttribute}`) {
          return !creative.creativeTags[selectedAttribute];
        } else {
          return false;
        }
      });
    } else {
      // Multinomial case
      output = creativeInfoList.filter(creative => {
        // If BLANK then show all creatives where selectedAttribute does not appear in creative map
        if (selectedTag === "Not Present") {
          return !creative.creativeTags[selectedAttribute];
          // Else we want to show creatives where both the selectedAttribute and selectedTag appear in the creative map
        } else {
          return creative.creativeTags[selectedAttribute]?.includes(selectedTag);
        }
      });
    }

    return output.sort((creativeA, creativeB) => {
      let nameA = creativeA.name;
      let nameB = creativeB.name;
      return nameA.localeCompare(nameB);
    });
  }, [creativeInfoList, selectedAttribute, selectedTag, attributeTypeBinomial]);

  const tagDropDownValue: string = useMemo(() => {
    return selectedTag;
  }, [selectedTag]);

  const tagDropDownOptions: { value: string }[] = useMemo(() => {
    return tagImportanceData && tagImportanceData[selectedAttribute]
      ? tagImportanceData[selectedAttribute].map(tag => {
          return { value: tag.name };
        })
      : [];
  }, [tagImportanceData, selectedAttribute]);

  useEffect(() => {
    if (!selectedAttribute || !selectedTag) {
      setSelectedCreative(undefined);
    }
  }, [selectedAttribute, selectedTag, setSelectedCreative]);

  const CreativeList: JSX.Element[] = useMemo(() => {
    // Need to check which items are in the last row so we can remove the bottom border
    let length = filteredCreativeList?.length;
    let remainder = length % 4;
    return filteredCreativeList?.map((creative, index) => {
      let lastRowItem = "";
      if (remainder === 0) {
        lastRowItem = index + 4 >= length - 1 && length > 4 ? " lastRowItem" : "";
      } else {
        lastRowItem = index + (remainder - 1) >= length - 1 && length > 4 ? " lastRowItem" : "";
      }
      return (
        <ListGroup.Item
          title={creative.name}
          key={creative.isciList.toString()}
          className={`creativeRowItem${lastRowItem}`}
          onClick={() => setSelectedCreative(creative)}
          active={selectedCreative?.isciList.toString() === creative.isciList.toString()}
          variant={"Light"}
          action
        >
          <div className="thumbnailContainer">
            <Img
              className="videoThumbnail"
              src={getCreativeThumbnail(cid, creative.file)}
              unloader={
                <div className="videoThumbnail">
                  <BsPlayBtn size={100} />
                </div>
              }
            />
          </div>
          <div className="descriptions">
            <span className="title">
              {creative.name.concat(" (", creative.length.toString(), ")")}
            </span>
          </div>
        </ListGroup.Item>
      );
    });
  }, [cid, filteredCreativeList, selectedCreative, setSelectedCreative]);

  const creativeListHeight: "noSetHeight" | "setHeight" = useMemo(() => {
    return CreativeList && CreativeList.length <= 4 ? "noSetHeight" : "setHeight";
  }, [CreativeList]);

  return (
    <div className="creativesContainer">
      <div className="creativesHeader">
        <div className="title">Creatives</div>
        <div className="selectedAttribute">
          <span className="label">Attribute:&nbsp;</span>
          <span className="value">
            {selectedAttribute ? selectedAttribute : "Select Attribute"}
          </span>
        </div>
        {selectedAttribute ? (
          <div className="tagPicker">
            <Dropdown
              type={DropdownToggleType.FILLED}
              design="secondary"
              size="sm"
              value={tagDropDownValue}
              options={tagDropDownOptions}
              onChange={option => {
                setSelectedTag(option);
                setSelectedCreative(undefined);
              }}
              label="Tactic"
              placeholder={"Select Tactic"}
            />
          </div>
        ) : (
          <div className="selectTactic">
            <span className="label">Tactic:&nbsp;</span>
            <span className="value">Select Tactic</span>
          </div>
        )}
        {selectedTag && (
          <div className="clearButton">
            <ClearButton
              onClick={() => {
                setSelectedTag("");
              }}
            />
          </div>
        )}
      </div>
      <div className="creativesContent">
        {CreativeList && CreativeList.length > 0 && (
          <div className="content">
            <CreativeView cid={cid} selectedCreative={selectedCreative}></CreativeView>
            <ListGroup className="creativesList" id={creativeListHeight}>
              {CreativeList}
            </ListGroup>
          </div>
        )}
        {(!CreativeList || (CreativeList && CreativeList.length === 0)) && (
          <CreativeListPlaceHolder
            selectedAttribute={selectedAttribute}
            selectedTag={selectedTag}
          />
        )}
      </div>
    </div>
  );
};

export default CreativeShelf;
