export enum TabKey {
  CUSTOMER_BASE = "customer-base",
  INDEX = "index",
  PENETRATION = "penetration",
}

export const NAVS: { label: string; key: TabKey }[] = [
  { label: "Customer Base", key: TabKey.CUSTOMER_BASE },
  { label: "Index", key: TabKey.INDEX },
  { label: "Penetration", key: TabKey.PENETRATION },
];

export type ViewOptions = "showGraph" | "showTable";
export type SortOptions = "alphabetical" | "high to low" | "low to high";
export const SORT_DROPDOWN_OPTIONS: {
  value: SortOptions;
  label: string;
}[] = [
  { value: "alphabetical", label: "Sort by: Abc" },
  { value: "high to low", label: "Sort by: Highest to lowest" },
  { value: "low to high", label: "Sort by: Lowest to highest" },
];

export enum CustomerInsightsFields {
  AGE = "age_in_years",
  DWELLING = "dwelling_type",
  INCOME = "estimated_household_income_v2",
  ETHNICITY = "ethnicity_race_code_v1",
  GENDER = "gender_code",
  EDUCATION = "highest_education_achieved_code",
  HOME_OWNERSHIP = "homeowner_renter",
  MARITAL_STATUS = "marital_status_code",
  MSA = "msa_code",
  HOUSEHOLD_SIZE = "number_of_people_in_household_code",
  OCCUPATION = "occupation_group",
  PERSON_TYPE = "person_type",
  CHILDREN = "presence_of_children_in_household_all_ages",
  URBANICITY = "urbanicity",
  MATCH_RATE = "match_rate",
}

export const CUSTOMER_INSIGHTS_ORDERED_GROUPS = {
  household: [
    CustomerInsightsFields.INCOME,
    CustomerInsightsFields.HOUSEHOLD_SIZE,
    CustomerInsightsFields.CHILDREN,
    CustomerInsightsFields.DWELLING,
    CustomerInsightsFields.URBANICITY,
  ],
  individual: [
    CustomerInsightsFields.AGE,
    CustomerInsightsFields.ETHNICITY,
    CustomerInsightsFields.GENDER,
    CustomerInsightsFields.EDUCATION,
    CustomerInsightsFields.MARITAL_STATUS,
    CustomerInsightsFields.HOME_OWNERSHIP,
    CustomerInsightsFields.OCCUPATION,
    CustomerInsightsFields.PERSON_TYPE,
  ],
};

export const CUSTOMER_INSIGHTS_PRETTY_NAMES: Record<CustomerInsightsFields, string> = {
  [CustomerInsightsFields.AGE]: "Age",
  [CustomerInsightsFields.DWELLING]: "Dwelling Type",
  [CustomerInsightsFields.INCOME]: "Household Income",
  [CustomerInsightsFields.ETHNICITY]: "Ethnicity",
  [CustomerInsightsFields.GENDER]: "Gender",
  [CustomerInsightsFields.EDUCATION]: "Highest Level Of Education",
  [CustomerInsightsFields.HOME_OWNERSHIP]: "Homeowner / Renter",
  [CustomerInsightsFields.MARITAL_STATUS]: "Marital Status",
  [CustomerInsightsFields.MSA]: "Number of Customers per MSA",
  [CustomerInsightsFields.HOUSEHOLD_SIZE]: "Household Size",
  [CustomerInsightsFields.OCCUPATION]: "Occupation",
  [CustomerInsightsFields.PERSON_TYPE]: "Person Type",
  [CustomerInsightsFields.CHILDREN]: "Children Present",
  [CustomerInsightsFields.URBANICITY]: "Urbanicity",
  [CustomerInsightsFields.MATCH_RATE]: "",
};

export const CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES: Record<
  CustomerInsightsFields,
  Record<string, string>
> = {
  [CustomerInsightsFields.AGE]: {},
  [CustomerInsightsFields.DWELLING]: {
    "Marginal Multi-Family": "Marginal Multi-Fam",
  },
  [CustomerInsightsFields.INCOME]: {
    "$200,000 +": "$200,000+",
  },
  [CustomerInsightsFields.ETHNICITY]: {},
  [CustomerInsightsFields.GENDER]: {},
  [CustomerInsightsFields.EDUCATION]: {},
  [CustomerInsightsFields.HOME_OWNERSHIP]: {},
  [CustomerInsightsFields.MARITAL_STATUS]: {},
  [CustomerInsightsFields.MSA]: {},
  [CustomerInsightsFields.HOUSEHOLD_SIZE]: {},
  [CustomerInsightsFields.OCCUPATION]: {
    "Office Administration": "Office Admin",
  },
  [CustomerInsightsFields.PERSON_TYPE]: {},
  [CustomerInsightsFields.CHILDREN]: {
    0: "No",
    1: "Yes",
  },
  [CustomerInsightsFields.URBANICITY]: {},
  [CustomerInsightsFields.MATCH_RATE]: {},
};

export const CUSTOMER_INSIGHTS_ORDERED_LEVELS: Record<CustomerInsightsFields, string[]> = {
  [CustomerInsightsFields.AGE]: [
    "18 - 24",
    "25 - 34",
    "35 - 44",
    "45 - 54",
    "55 - 64",
    "65+",
    "Unknown",
  ],
  [CustomerInsightsFields.DWELLING]: [
    "Marginal Multi-Family",
    "Multi-Family",
    "PO Box",
    "Single Family",
    "Unknown",
  ],
  [CustomerInsightsFields.INCOME]: [
    "< $15,000",
    "$15,000 - $24,999",
    "$25,000 - $34,999",
    "$35,000 - $49,999",
    "$50,000 - $74,999",
    "$75,000 - $99,999",
    "$100,000 - $149,999",
    "$150,000 - $199,999",
    "$200,000 +",
    "Unknown",
  ],
  [CustomerInsightsFields.ETHNICITY]: [
    "African American",
    "Asian",
    "Caucasian",
    "Hispanic",
    "Middle Eastern",
    "Native American",
    "Unknown",
  ],
  [CustomerInsightsFields.GENDER]: ["Female", "Male", "Unknown"],
  [CustomerInsightsFields.EDUCATION]: [
    "Completed College",
    "Doctorate",
    "Graduate School",
    "High School",
    "Some College",
    "Unknown",
  ],
  [CustomerInsightsFields.HOME_OWNERSHIP]: [
    "Family Member",
    "Homeowner",
    "Likely Homeowner",
    "Renter",
  ],
  [CustomerInsightsFields.MARITAL_STATUS]: [
    "Divorced",
    "Likely Divorced",
    "Likely Married",
    "Married",
    "Single",
    "Unknown",
    "Widow",
  ],
  [CustomerInsightsFields.MSA]: [],
  [CustomerInsightsFields.HOUSEHOLD_SIZE]: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6 " /* TODO: Clean up data on backend */,
    "6+",
  ],
  [CustomerInsightsFields.OCCUPATION]: [
    "Blue Collar",
    "Farmer",
    "Management",
    "Office Administration",
    "Other",
    "Professional",
    "Retired",
    "Sales",
    "Technical",
    "Unknown",
  ],
  [CustomerInsightsFields.PERSON_TYPE]: [
    "Elderly Parent",
    "Other",
    "Primary Decision Maker",
    "Young Adult",
  ],
  [CustomerInsightsFields.CHILDREN]: ["0", "1"], // TODO: Conert these to "true" and "false"
  [CustomerInsightsFields.URBANICITY]: ["Rural", "Suburban", "Unknown", "Urban"],
  [CustomerInsightsFields.MATCH_RATE]: [],
};
