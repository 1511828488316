import React, { useEffect, useMemo, useState } from "react";
import "./FiltersPanelGoogleBrandHealth.scss";
import CheckBox from "./CheckBox";

interface FiltersPanelGoogleBrandHealthProps {
  regionMap: Partial<Record<string, boolean>>;
  setRegionMap: (key: string, value: boolean) => void;
}

const FiltersPanelGoogleBrandHealth: React.FC<FiltersPanelGoogleBrandHealthProps> = React.memo(
  ({ regionMap, setRegionMap }: FiltersPanelGoogleBrandHealthProps): JSX.Element => {
    const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
    useEffect(() => {
      window.addEventListener("resize", () => setInnerHeight(window.innerHeight));

      return () => {
        window.removeEventListener("resize", () => setInnerHeight(window.innerHeight));
      };
    }, [innerHeight]);

    const stylingSuffix = useMemo(() => {
      if (innerHeight < 972 && innerHeight >= 888) {
        return " reduce-spacing-1";
      } else if (innerHeight < 888 && innerHeight >= 804) {
        return " reduce-spacing-2";
      } else if (innerHeight < 804 && innerHeight >= 720) {
        return " reduce-spacing-3";
      } else if (innerHeight < 720) {
        return " wrap";
      } else {
        return "";
      }
    }, [innerHeight]);

    return (
      <div className="filtersPanelGoogleBrandHealth">
        <div className={`filters${stylingSuffix}`}>
          <div className={`filterSection${stylingSuffix}`}>
            <div className="filterName">Region</div>
            <div className={"filterOptions"}>
              <CheckBox
                className="checkBox"
                checked={regionMap["Region-All"] || false}
                label="All"
                onCheck={() => {
                  setRegionMap("Region-All", !regionMap["Region-All"]);
                  setRegionMap("Northeast", false);
                  setRegionMap("South", false);
                  setRegionMap("Midwest", false);
                  setRegionMap("West", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={regionMap.Northeast || false}
                label="Northeast"
                onCheck={() => {
                  setRegionMap("Northeast", !regionMap.Northeast);
                  setRegionMap("Region-All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={regionMap.South || false}
                label="South"
                onCheck={() => {
                  setRegionMap("South", !regionMap.South);
                  setRegionMap("Region-All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={regionMap.Midwest || false}
                label="Midwest"
                onCheck={() => {
                  setRegionMap("Midwest", !regionMap.Midwest);
                  setRegionMap("Region-All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={regionMap.West || false}
                label="West"
                onCheck={() => {
                  setRegionMap("West", !regionMap.West);
                  setRegionMap("Region-All", false);
                }}
              ></CheckBox>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FiltersPanelGoogleBrandHealth;
