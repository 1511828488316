import React from "react";
import { MdCheck } from "react-icons/md";
import "./CircularProgressBar.scss";
import { Brand30, Neutral600, appleGreen } from "../../utils/colors";

interface CircularProgressBarProps {
  percentage: number;
  icon?: JSX.Element;
  barColor?: string;
  remainingBarColor?: string;
  fontStyles?: React.CSSProperties;
  barHeight?: number;
  size?: number;
  fontSize?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  percentage,
  icon,
  barColor = appleGreen,
  remainingBarColor = Neutral600,
  fontStyles = {
    color: Brand30,
    fontSize: "16px",
    fontWeight: 600,
  },
  barHeight = 2.5,
  size = 100,
  className = "",
  style = {},
}) => {
    return (
    <div
      className={`progressCircle ${className}`}
      style={{ width: size, height: size, ...style }}
    >
      <svg className="progressCircleSvg" viewBox="0 0 36 36">
        <path
          className="progressCircleBg"
          style={{
            stroke: percentage === 100 ? barColor : remainingBarColor,
            strokeWidth: barHeight,
          }}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="progressCircleBar"
          style={{
            stroke: barColor,
            strokeWidth: barHeight,
            strokeLinecap: percentage > 0 ? "round" : "butt",
          }}
          strokeDasharray={`${percentage}, 100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      <div className="progressCircleText" style={fontStyles}>
        {percentage === 100 ? <MdCheck size={30} /> : icon ? icon : `${percentage}%`}
      </div>
    </div>
  );
};
