import "./KpiFunnels.scss";
import React, { useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import * as uuid from "uuid";
import {
  MdAdd,
  MdSave,
  MdDelete,
  MdOutlineArrowForward,
  MdDeleteForever,
  MdEdit,
} from "react-icons/md";
import {
  Button,
  ButtonType,
  Dropdown,
  DropdownToggleType,
  FullPageSpinner,
  Spinner,
} from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { CrossChannelLambdaFetch } from "../utils/fetch-utils";
import { StateSetter } from "../utils/types";

enum DrodownSection {
  KPIs = "KPIs",
  Metrics = "Metrics",
}

interface Funnel {
  id: number | string;
  name: string;
  kpis: string[];
  isDefault: boolean;
  isNew?: boolean;
}

interface KpiFunnelsProps {
  funnels: Funnel[] | undefined;
  crossChannelKpis: string[];
  company: string;
  setKpiFunnels: StateSetter<Funnel[] | undefined>;
}

const KpiFunnels: React.FC<KpiFunnelsProps> = ({
  funnels,
  crossChannelKpis,
  company,
  setKpiFunnels,
}) => {
  const [newFunnels, setNewFunnels] = useState<Record<string, Funnel>>({});
  const [funnelEdits, setFunnelEdits] = useState({});
  const [funnelDeletes, setFunnelDeletes] = useState({});
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);

  // existing + new funnels
  const combinedFunnels = useMemo(() => {
    const currentFunnels = R.reject(funnel => funnelDeletes[funnel.id], funnels || []);
    return [...(currentFunnels || []), ...Object.values(newFunnels)];
  }, [funnels, newFunnels, funnelDeletes]);

  const handleEdit = useCallback((funnelId, edit, isNew?: boolean) => {
    if (isNew) {
      setNewFunnels(current => {
        return {
          ...current,
          [funnelId]: { ...R.pathOr({}, [funnelId], current), ...edit },
        };
      });
    } else {
      setFunnelEdits(current => ({
        ...current,
        [funnelId]: { ...R.pathOr({}, [funnelId], current), ...edit },
      }));
    }
  }, []);

  const handleDelete = useCallback((id, isNew: boolean) => {
    if (isNew) {
      setNewFunnels(curr => ({ ...R.omit([id], curr) }));
    } else {
      setFunnelDeletes(curr => ({ ...curr, [id]: true }));
    }
  }, []);

  /**
   * If there's an edited value, use that. Otherwise, use the original value.
   */
  const valueToUse = useCallback(
    (funnel: Funnel, editType: string, kpiIndex?: number) => {
      if (editType === "kpis" && kpiIndex !== undefined) {
        const path = R.pathOr([], [funnel.id, editType], funnelEdits);
        if (path.length && path[kpiIndex] !== undefined) {
          return path[kpiIndex];
        } else {
          return R.path([editType, kpiIndex], funnel);
        }
      }

      if (R.hasPath([`${funnel.id}`, editType], funnelEdits)) {
        return R.path([funnel.id, editType], funnelEdits);
      } else {
        return funnel[editType];
      }
    },
    [funnelEdits]
  );

  const addNewFunnel = useCallback(() => {
    const id = uuid.v4();
    const init = {
      id,
      name: "",
      kpis: [],
      isDefault: false,
      isNew: true,
    };
    setNewFunnels({ ...newFunnels, [id]: init });
  }, [newFunnels]);

  const handleSave = useCallback(async () => {
    try {
      setSaving(true);
      // Merge edits with original funnel
      const mergedEditedFunnels: any[] = [];
      for (let funnel of funnels || []) {
        if (funnelEdits[funnel.id]) {
          funnel = { ...funnel, ...funnelEdits[funnel.id] };
          mergedEditedFunnels.push(funnel);
        }
      }

      await CrossChannelLambdaFetch("/setKpiFunnels", {
        method: "POST",
        body: {
          company,
          newFunnelPresets: R.values(newFunnels),
          updatedFunnelPresets: mergedEditedFunnels,
          funnelDeletes: R.keys(funnelDeletes),
        },
      });
      setSaving(false);
      setFunnelEdits({});
      setNewFunnels({});
      setKpiFunnels(undefined);
      setEditMode(false);
    } catch (e) {
      setSaving(false);
      console.error("Error saving KPI funnels", e);
    }
  }, [company, funnelEdits, funnels, newFunnels, funnelDeletes, setKpiFunnels]);

  const handleDiscardEdits = () => {
    setFunnelEdits({});
    setNewFunnels({});
    setFunnelDeletes({});
    setEditMode(false);
  };

  // Clicks and Impressions are allowed as funnel options
  const dropdownOptions = useMemo(() => {
    let options = crossChannelKpis.map(kpi => ({
      label: kpi,
      value: kpi,
      section: DrodownSection.KPIs,
    }));
    options.push({ label: "Clicks", value: "Clicks", section: DrodownSection.Metrics });
    options.push({ label: "Impressions", value: "Impressions", section: DrodownSection.Metrics });
    return options;
  }, [crossChannelKpis]);

  return R.isNil(funnels) ? (
    <FullPageSpinner />
  ) : (
    <div className="kpiFunnelConfig">
      <div className="kpiFunnelTopControls">
        <div className="title">
          Define funnels to be used for the KPI Funnel chart on the Cross Channel page
        </div>
        {editMode ? (
          <Button
            style={{ width: "200px" }}
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={<MdAdd />}
            onClick={addNewFunnel}
          >
            Add Funnel
          </Button>
        ) : (
          <Button
            style={{ width: "200px" }}
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={<MdEdit />}
            onClick={() => setEditMode(!editMode)}
          >
            Edit
          </Button>
        )}
      </div>
      <table className="kpiFunnelTable">
        <thead>
          <tr>
            <th></th>
            <th className="tableHeader">Funnel Name</th>
            <th className="tableHeader">KPI 1</th>
            <th className="tableHeader">KPI 2</th>
            <th className="tableHeader">KPI 3</th>
            <th className="tableHeader">KPI 4</th>
            <th className="tableHeader">KPI 5</th>
          </tr>
        </thead>
        <tbody>
          {combinedFunnels.map(funnel => {
            const { id, kpis, isNew } = funnel;
            return (
              <tr key={id} className="tableRow">
                <td>
                  {editMode && (
                    <Button
                      className="deleteNewKpiButton"
                      type={ButtonType.EMPTY}
                      variant={ButtonFrameworkVariant.ICON_ONLY}
                      icon={<MdDeleteForever style={{ color: "red" }} />}
                      size="sm"
                      onClick={() => handleDelete(id, !!isNew)}
                    />
                  )}
                </td>
                <td>
                  <input
                    value={valueToUse(funnel, "name")}
                    disabled={!editMode}
                    onChange={e => {
                      const edit = { name: e.target.value };
                      handleEdit(id, edit, isNew);
                    }}
                  />
                </td>
                {[0, 1, 2, 3, 4].map(index => (
                  <td key={index}>
                    <div className="kpiDropdownCell">
                      <Dropdown
                        className="kpiFunnelSelectDropdown"
                        type={DropdownToggleType.OUTLINED}
                        disabled={!editMode}
                        value={valueToUse(funnel, "kpis", index) || ""}
                        section={[DrodownSection.KPIs, DrodownSection.Metrics]}
                        options={dropdownOptions}
                        onChange={newVal => {
                          //@ts-ignore
                          const kpisCopy = [...(R.path([id, "kpis"], funnelEdits) || kpis)];
                          kpisCopy[index] = newVal;
                          const edit = {
                            kpis: kpisCopy,
                          };
                          handleEdit(id, edit, isNew);
                        }}
                        cancelOnClick={() => {
                          //@ts-ignore
                          const kpisCopy = [...(R.path([id, "kpis"], funnelEdits) || kpis)];
                          kpisCopy[index] = null;

                          const edit = {
                            kpis: kpisCopy,
                          };
                          handleEdit(id, edit, isNew);
                        }}
                        cancelContent="Remove KPI"
                      />
                      {index !== 4 && <MdOutlineArrowForward className="rightArrow" />}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="kpiFunnelBottomControls">
        <Button
          type={ButtonType.OUTLINED}
          variant={ButtonFrameworkVariant.LEADING_ICON}
          icon={<MdDelete style={{ color: "#8254FF" }} />}
          onClick={handleDiscardEdits}
        >
          {saving ? <Spinner /> : "Discard Edits"}
        </Button>
        <Button
          type={ButtonType.FILLED}
          variant={ButtonFrameworkVariant.LEADING_ICON}
          icon={<MdSave />}
          onClick={handleSave}
          disabled={R.isEmpty(funnelEdits) && R.isEmpty(newFunnels) && R.isEmpty(funnelDeletes)}
        >
          {saving ? <Spinner /> : "Commit"}
        </Button>
      </div>
    </div>
  );
};

export default KpiFunnels;
