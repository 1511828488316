import React from "react";

import * as R from "ramda";

import { Form } from "react-bootstrap";

import { parseInputToInt } from "../utils/data";

import { RelativeDatePicker, ToggleCheckboxes } from "../Components";

import { SlideType, SettingsComponentProps, SlideContext } from "./slidesTypes";
import { SlideState } from "./slideTemplateConstants";
import {
  computeResolvedDate,
  RelativeDatePickerState,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";

const DEFAULT_CREATIVES_PER_ROW = 4;
const DEFAULT_CREATIVE_ROWS = 4;

type MediaType = "tv" | "streaming" | "audio" | "display";

interface HousekeepingSlideData {
  mediaTypes: MediaType[];
  start: string;
  end: string;
  creativeRows: number;
  creativesPerRow: number;
  creativeDate: string;
}
interface HousekeepingSlideDates extends RelativeDateRange {
  creativeDate: RelativeDatePickerState;
}
export interface HousekeepingSlideState
  extends Omit<HousekeepingSlideData, "start" | "end" | "creativeDate"> {
  dates: HousekeepingSlideDates;
}

class HousekeepingSlide extends SlideType {
  static typeKey = "housekeeping";
  static displayKey = "Housekeeping";
  static defaultState: HousekeepingSlideState = {
    mediaTypes: ["tv", "streaming", "audio", "display"],
    dates: {
      start: {
        pivotDate: "monday",
        adjustment: 1,
        adjustmentType: "week",
      },
      end: {
        pivotDate: "monday",
        adjustment: 4,
        adjustmentType: "week",
        before: false,
      },
      creativeDate: {
        pivotDate: "sunday",
        adjustment: 1,
        adjustmentType: "week",
        before: false,
      },
    },
    creativeRows: DEFAULT_CREATIVE_ROWS,
    creativesPerRow: DEFAULT_CREATIVES_PER_ROW,
  };

  static SettingsComponent: React.FC<SettingsComponentProps<HousekeepingSlideState>> = React.memo(
    ({ id, state, setState }) => {
      const { mediaTypes, dates, creativeRows, creativesPerRow } = state;
      const { start, end, creativeDate } = dates;

      return (
        <div className="settingsBox">
          <div className="wrappingColumn">
            <Form.Group>
              <Form.Label>Plans Start</Form.Label>
              <RelativeDatePicker
                state={start}
                onChange={start => setState(R.mergeDeepLeft({ dates: { start } }))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Plans End</Form.Label>
              <RelativeDatePicker
                state={end}
                onChange={end => setState(R.mergeDeepLeft({ dates: { end } }))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Creative Live Date</Form.Label>
              <RelativeDatePicker
                state={creativeDate}
                onChange={creativeDate => setState(R.mergeDeepLeft({ dates: { creativeDate } }))}
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group>
              <Form.Label>Media Types</Form.Label>
              <ToggleCheckboxes
                block
                selectedOptions={mediaTypes}
                options={[
                  {
                    label: "Linear",
                    key: "tv",
                  },
                  { label: "Streaming", key: "streaming" },
                  { label: "Audio", key: "audio" },
                  { label: "Display", key: "display" },
                ]}
                onChange={mediaTypes => setState({ mediaTypes })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Creative Rows</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                value={`${creativeRows}`}
                onChange={e => setState({ creativeRows: parseInputToInt(e.currentTarget.value) })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Creatives Per Row</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                value={`${creativesPerRow}`}
                onChange={e =>
                  setState({ creativesPerRow: parseInputToInt(e.currentTarget.value) })
                }
              />
            </Form.Group>
          </div>
        </div>
      );
    }
  );

  generate = (context: SlideContext, state: SlideState): Promise<HousekeepingSlideData> => {
    const ourState = state as HousekeepingSlideState;
    return Promise.resolve({
      ...R.omit(["dates"], ourState),
      ...R.mapObjIndexed(date => computeResolvedDate(date), ourState.dates),
    });
  };
}

export default HousekeepingSlide;
