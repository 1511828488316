import { CompanyInfo } from "../redux/company";
import { CreativeMap } from "../redux/creative";
import { DerivedNetworkMap } from "../redux/networks";
import { download } from "../utils/download-utils";
import { RowWithTabularData as LinearRowWithTabularData } from "./LinearPerformance/linearPerformanceUtils";
import { RowWithTabularData as StreamingRowWithTabularData } from "./StreamingPerformance/streamingPerformanceUtils";
import { WindowLocation } from "@reach/router";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";
import React from "react";

export const MAX_DECIMALS = 4;

export const ALL_GROUP_NAME = "All";
export const STANDARD_GROUP_NAME = "Standard";

export const encodePrettyUrl = (name: string): string =>
  encodeURIComponent(name).replace(/%20/g, "+").replace(/%7C/g, "|");
export const decodePrettyUrl = (name: string): string =>
  decodeURIComponent(name.replace(/\+/g, " "));

export const resolveDecimals = (decimals: number): number => Math.min(MAX_DECIMALS, decimals);

interface Formatter {
  (num: number, decimals?: number): string;
}

export const toPrettySpend: Formatter = (spend: number, decimals = 0) =>
  (spend || 0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: resolveDecimals(spend > 0 && spend < 1 && decimals === 0 ? 2 : decimals),
    maximumFractionDigits: resolveDecimals(spend > 0 && spend < 1 && decimals === 0 ? 2 : decimals),
  });
export const toPrettyNumber: Formatter = (num: number, decimals = 0) =>
  (num || 0).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: resolveDecimals(decimals),
  });
export const toPretty1000sInteger: Formatter = (num: number, decimals = 0) =>
  ((num || 0) / 1000).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: resolveDecimals(decimals),
  });

export type SingleChannelMediaTypes =
  | S.Prefix
  | L.Prefix
  | "linear"
  | "youtube"
  | "social"
  | "commerce"
  | "searchShopping";

export interface PerformanceContextType {
  globalKpi: string;
  kpiMetaData: P.GetKpiMetaDataResponse;
  prefix: SingleChannelMediaTypes;
  impsBetween: boolean;
  globalLag: S.LagOption;
  branchBuild?: string;
  globalBrand?: string;
  globalAudience?: string;
  isGraph?: boolean;
}

export const PerformanceContext = React.createContext<PerformanceContextType>({
  kpiMetaData: {},
  globalKpi: "",
  globalLag: "7d",
  prefix: "streaming",
  branchBuild: "",
  impsBetween: true,
  globalAudience: "HH",
  isGraph: false,
});

export enum PercentageDisplayMode {
  SHOW = "SHOW",
  HIDE = "HIDE",
  // Disable is used for columns in which percentages don't make sense, since they show the
  // percentage in relation to an average of all rows and so would show values >100%.
  DISABLE = "DISABLE",
}

type PercentageDisplayModeType = keyof typeof PercentageDisplayMode;

export interface ColumnMetaData {
  formatter: Formatter;
  minWidth: number;
  prettyName: string;
  decimals?: number;
  percentageDisplayMode: PercentageDisplayModeType;
  defaultLabel?: string;
  minIsBest?: boolean;
  contentReplacement?: {
    replacementString: string;
    threshold: number;
    overlayText?: string;
  };
  zerosSortHigh?: boolean;
  category?: P.StreamingColumnCategory;
}
export interface DimensionColumnMetaData {
  minWidth: number;
  dimension: S.PerformanceDimension | L.Dimension | Y.Dimension;
  label?: string;
  defaultAdmin?: boolean;
  centerHeader?: boolean;
}

export const ROW_HEIGHT = 50;

export const adjustSupersAfterRemoval = (headers: P.ColumnHeader[], i: number): P.ColumnHeader[] =>
  headers.reduce((headers, header) => {
    // If it's a one-column-wide header and that column is the one we're deleting, delete
    // the header
    if (header.start === header.end && header.start === i) {
      return headers;
    }
    // If the header contains the element, then the end position shifts down, but the
    // start position remains the same.
    if (header.start <= i && header.end >= i) {
      return [...headers, { ...header, end: header.end - 1 }];
    }
    // If the header is before this column, add it in and continue
    if (header.end < i) {
      return [...headers, header];
    }
    // At this point we know the header doesn't contain the column and isn't before the
    // column. Thus, it must be after. That means the start and end need to be shifted
    // down because a column before it was removed.
    return [...headers, { ...header, start: header.start - 1, end: header.end - 1 }];
  }, [] as P.ColumnHeader[]);

export const resolveColumn = (
  column: P.Column,
  kpiMetaData: P.GetKpiMetaDataResponse,
  kpi: string,
  columnMetaDataMap: Record<string, ColumnMetaData | undefined>,
  isLinear?: boolean,
  isYoutube?: boolean
): P.Column => {
  let metadata = { ...columnMetaDataMap[column.type] };

  let basicColumnTypes = isYoutube
    ? Y.BASIC_COLUMN_TYPES
    : isLinear
    ? L.BASIC_COLUMN_TYPES
    : S.BASIC_COLUMN_TYPES;

  // https://github.com/microsoft/TypeScript/issues/26255
  const isSpecial = !(basicColumnTypes as readonly string[]).includes(column.type);
  if (isSpecial && !column.type.startsWith("roas") && !column.type.startsWith("volume")) {
    const kpiInfo = kpiMetaData[column.kpi || kpi];
    if (kpiInfo) {
      metadata.decimals = kpiInfo.decimals;
      metadata.defaultLabel = (metadata.defaultLabel || metadata.prettyName || "").replace(
        "CPX",
        kpiInfo.cpxName
      );
    }
  }

  let newCol: P.Column = {
    decimals: R.isNil(metadata.decimals) ? 0 : metadata.decimals,
    pct: !!(metadata.percentageDisplayMode === PercentageDisplayMode.SHOW),
    minWidth: metadata.minWidth,
    ...column,
    label: column.label || metadata.defaultLabel || metadata.prettyName,
  };

  return newCol;
};

export interface OverviewConfigItem {
  displayName: string;
  defaultOff?: boolean;
}

export const getGlobalBrand = (companyInfo: CompanyInfo, kpi: string): string | undefined => {
  if (companyInfo?.sub_companies?.length) {
    let res = kpi.match(/^([^_]+)_/);
    if (res && res.length >= 2) {
      return res[1];
    }
  }
};

export interface GlobalKpiPickerLabelArgs {
  kpiMetaData: P.GetKpiMetaDataResponse | undefined;
  kpi: string;
  companyInfo: CompanyInfo;
  globalBrand: string | undefined;
  lag?: S.LagOption | "";
  audience?: L.Audience;
}

export const getGlobalKpiPickerLabel = ({
  kpiMetaData,
  kpi,
  companyInfo,
  globalBrand,
  lag,
  audience,
}: GlobalKpiPickerLabelArgs): string => {
  if (!kpiMetaData) {
    return "";
  }
  let parts: string[] = [];
  if (globalBrand) {
    let brand: string | undefined;
    for (let item of companyInfo.sub_companies || []) {
      if (item.cid === globalBrand) {
        brand = item.name;
      }
    }
    if (brand) {
      parts.push(`${brand}`);
    }
  }
  parts.push(`${kpiMetaData[kpi]?.name || "KPI"}`);
  if (lag) {
    parts.push(`${lag} lag`);
  }
  if (audience) {
    parts.push(`${audience}`);
  }
  return parts.join(", ");
};

export type RowWithTabularData = StreamingRowWithTabularData & LinearRowWithTabularData;

export const getGroupOptions = (presets: P.PresetIDGroups[] | undefined): string[] => {
  return presets
    ? presets
        .reduce((list, preset) => {
          if (!preset.temporary && preset.groups) {
            preset.groups.forEach(groupName => {
              if (!list.includes(groupName)) {
                list.push(groupName);
              }
            });
          }
          return list;
        }, [] as string[])
        .sort()
    : [];
};

export const getPresetOptions = (
  presets: P.PresetIDGroups[] | undefined,
  groupName: string
): string[] => {
  return presets
    ? presets
        .reduce((list, preset) => {
          if (!preset.temporary) {
            let { groups } = preset;
            if (groupName === ALL_GROUP_NAME) {
              list.push(preset.name);
            } else if (groups && groups.includes(groupName)) {
              list.push(preset.name);
            }
          }
          return list;
        }, [] as string[])
        .sort()
    : [];
};

export const getBaseURL = (
  urlPresetName: string,
  location: WindowLocation<unknown> | undefined
): string => {
  let parts = (location?.pathname || "").split("/");

  if (urlPresetName || parts[parts.length - 1] === "") {
    return parts.slice(0, -1).join("/");
  }
  return location?.pathname || "";
};

const getResolvedDimensionColumnLabel = <DCT extends string>(
  column: P.PerformanceDimensionColumn,
  DIMENSION_COLUMN_METADATA_MAP: Record<DCT, DimensionColumnMetaData>
) => {
  // Some dimensions have the label "\u00a0" because we don't want to display text for that
  // column on the page. e.g. we don't need a header above network logos to say "Network Logo".
  // However, in the CSV we want headers for those dimensions, so we'll use the dimension name.
  let label =
    DIMENSION_COLUMN_METADATA_MAP[column.type].label === "\u00a0"
      ? DIMENSION_COLUMN_METADATA_MAP[column.type].dimension
      : DIMENSION_COLUMN_METADATA_MAP[column.type].label;

  return label;
};

interface ExportCSVProps<P extends P.Preset, DCT extends string, CT extends string> {
  preset: P | undefined;
  tabularData: RowWithTabularData[] | undefined;
  kpiMetaData: P.GetKpiMetaDataResponse | undefined;
  creativeMap: CreativeMap | undefined;
  isInternal: boolean;
  kpi: string;
  DIMENSION_COLUMN_METADATA_MAP: Record<DCT, DimensionColumnMetaData>;
  COLUMN_METADATA_MAP: Partial<Record<CT, ColumnMetaData>>;
  company: string;
  presetID: S.PresetIDGroups | undefined;
  derivedNetworkMap?: DerivedNetworkMap;
  isLinear?: boolean;
}

export const exportCSV = <P extends P.Preset, DCT extends string, CT extends string>({
  preset,
  tabularData,
  kpiMetaData,
  creativeMap,
  isInternal,
  kpi,
  DIMENSION_COLUMN_METADATA_MAP,
  COLUMN_METADATA_MAP,
  company,
  presetID,
  derivedNetworkMap,
  isLinear,
}: ExportCSVProps<P, DCT, CT>): void => {
  if (!(preset && tabularData && kpiMetaData && (isLinear || derivedNetworkMap) && creativeMap)) {
    return;
  }
  // +Fractional makes Excel die
  const sanitizeHeader = (str: string): string => `"${str.slice(str.startsWith("+") ? 1 : 0)}"`;
  let headers: string[] = [];
  for (let column of preset.performanceDimensionColumns) {
    if (isInternal || !column.adminOnly) {
      let dimensionColumnLabel = getResolvedDimensionColumnLabel<DCT>(
        column,
        DIMENSION_COLUMN_METADATA_MAP
      );
      headers.push(sanitizeHeader(column.label || dimensionColumnLabel || column.type));
    }
  }
  for (let column of preset.columns) {
    if (isInternal || !column.adminOnly) {
      headers.push(
        sanitizeHeader(
          resolveColumn(
            column,
            kpiMetaData,
            kpi,
            COLUMN_METADATA_MAP as Record<string, ColumnMetaData>
          ).label || ""
        )
      );
    }
  }
  let rows: (string | number)[][] = [headers];
  for (let row of tabularData) {
    let ourRow: (string | number)[] = [];
    for (let column of preset.performanceDimensionColumns) {
      if (!isInternal && column.adminOnly) {
        continue;
      }
      if (column.dimension === "Network") {
        const derivedNetwork = (derivedNetworkMap || {})[row.dimensions.Network || ""];
        if (!isLinear && derivedNetwork) {
          if (column.type === "Network Logo") {
            ourRow.push(derivedNetwork.network);
          } else if (column.type === "Derived ID") {
            ourRow.push(row.dimensions.Network || "");
          } else {
            ourRow.push(sanitizeHeader(derivedNetwork.description));
          }
        } else {
          ourRow.push(row.dimensions.Network || "");
        }
      } else if (column.dimension === "Network Group") {
        const derivedNetwork = (derivedNetworkMap || {})[row.dimensions["Network Group"] || ""];
        if (!isLinear && derivedNetwork) {
          if (column.type === "Network Group Logo") {
            ourRow.push(derivedNetwork.network);
          } else {
            ourRow.push(sanitizeHeader(derivedNetwork.description));
          }
        } else {
          ourRow.push(row.dimensions.Network || "");
        }
      } else if (column.dimension === "Creative") {
        let creative = creativeMap[row.dimensions.Creative || ""];
        if (creative) {
          ourRow.push(creative.name);
        } else {
          ourRow.push(row.dimensions.Creative || "");
        }
      } else if (column.dimension === "Length") {
        ourRow.push(row.dimensions.Length ? `${row.dimensions.Length}s` : "");
      } else if (column.dimension === "DeviceOS") {
        let [device, os] = (row.dimensions.DeviceOS || "").toUpperCase().split("_");
        ourRow.push(
          column.type === "Device" || column.type === "Device Logo" ? device || "" : os || ""
        );
      } else if (column.dimension === "Daypart") {
        ourRow.push(row.dimensions.Daypart || "");
      } else if (column.dimension === "Avail") {
        ourRow.push(row.dimensions.Avail || "");
      } else if (column.dimension === "Campaign") {
        ourRow.push(row.dimensions.Campaign || "");
      } else {
        ourRow.push("");
      }
    }
    ourRow = [...ourRow, ...row.tabularRow];
    rows.push(ourRow);
  }
  if (preset.headers.length) {
    let superHeaders: string[] = [];
    for (let column of preset.performanceDimensionColumns) {
      if (isInternal || !column.adminOnly) {
        superHeaders.push("");
      }
    }
    let remainingSuperHeaders = [...preset.headers];
    for (let i = 0; i < preset.columns.length; ++i) {
      let column = preset.columns[i];
      if (!isInternal && column.adminOnly) {
        continue;
      }
      while (remainingSuperHeaders.length && remainingSuperHeaders[0].end < i) {
        remainingSuperHeaders.shift();
      }
      if (
        remainingSuperHeaders.length &&
        remainingSuperHeaders[0].start <= i &&
        remainingSuperHeaders[0].end >= i
      ) {
        superHeaders.push(sanitizeHeader(remainingSuperHeaders[0].text));
      } else {
        superHeaders.push("");
      }
    }
    rows.unshift(superHeaders);
  }
  let csv = rows.map(R.join(",")).join("\n");
  download(csv, `${company}_${presetID?.name || ""}.csv`, "text/csv");
};
