import "./CustomerInsightsChart.scss";
import { useCallback, useMemo, useState } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import cn from "classnames";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import { formatNumber, formatPercent } from "../utils/format-utils";
import { getChannelSeriesColor } from "../utils/colors";
import { SnapshotChart } from "../Components";
import { DataItem } from "../Components/SnapshotChart";
import ChartContainer from "../Components/ChartContainer";
import { getChartType } from "./customerInsightsUtils";
import {
  CUSTOMER_INSIGHTS_PRETTY_NAMES,
  CustomerInsightsFields,
} from "./customerInsightsConstants";
import { CustomerInsightsLegend } from "./CustomerInsightsGroup";

interface DefaultBarProps {
  data: KnowYourCustomerData[];
  totalPopulation: number;
  fieldPrettyName: string;
}

const DefaultBar: React.FC<DefaultBarProps> = ({ data, totalPopulation, fieldPrettyName }) => {
  const barFormattedData: DataItem[] = useMemo(() => {
    return data.map(({ clientPopulation, levels }) => {
      return {
        label: "",
        name: levels,
        value: clientPopulation,
        computedValue: val => formatPercent(val / totalPopulation, 1),
      };
    });
  }, [data, totalPopulation]);

  return (
    <div className="customerInsightsBarChart customerBaseBarChart">
      <SnapshotChart
        data={barFormattedData}
        title={fieldPrettyName}
        valueFormatter={value => formatNumber(Math.round(value))}
        showDownload={false}
        enableHoverDesign={false}
      />
    </div>
  );
};

interface DefaultPieProps {
  data: KnowYourCustomerData[];
  fieldPrettyName: string;
}

const DefaultPie: React.FC<DefaultPieProps> = ({ data, fieldPrettyName }) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>();

  const getFillFromName = useCallback(
    (name: string): string => {
      const index = data.findIndex(entry => entry.levels === name);
      return getChannelSeriesColor(index, true);
    },
    [data]
  );

  const pieFormattedData = useMemo(() => {
    return data.map(({ levels, clientPopulation }) => {
      return {
        name: levels,
        value: Math.round(clientPopulation),
      };
    });
  }, [data]);

  const legendFormattedData = useMemo(() => {
    return data.map(({ levels, clientPopulation, clientPopulationFraction }) => {
      return {
        name: levels,
        val1: formatNumber(clientPopulation),
        computedValue: formatPercent(clientPopulationFraction, 1),
        color: getFillFromName(levels),
      };
    });
  }, [data, getFillFromName]);

  return (
    <div className="customerInsightsPieChart customerBasePieChart">
      <ChartContainer title={fieldPrettyName}>
        <ResponsiveContainer width="100%">
          <PieChart>
            <Legend
              content={() => (
                <CustomerInsightsLegend
                  data={legendFormattedData}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  selectBehavior="hover"
                />
              )}
              layout="vertical"
              align="left"
              verticalAlign="top"
            />
            <Pie
              data={pieFormattedData}
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              innerRadius="25%"
              dataKey="value"
              onMouseEnter={slice => {
                setSelectedValue(slice?.name);
              }}
              onMouseLeave={() => {
                setSelectedValue(undefined);
              }}
            >
              {pieFormattedData.map(({ name }) => (
                <Cell
                  className={cn("pieCell", {
                    selectedValue: name === selectedValue,
                    unselectedValue: Boolean(selectedValue) && name !== selectedValue,
                  })}
                  key={`cell-${name}`}
                  fill={getFillFromName(name)}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );
};

interface CustomerBaseChartProps {
  fieldName: CustomerInsightsFields;
  data: KnowYourCustomerData[];
  totalPopulation: number;
}

const CustomerBaseChart: React.FC<CustomerBaseChartProps> = ({
  fieldName,
  data,
  totalPopulation,
}) => {
  const fieldPrettyName = CUSTOMER_INSIGHTS_PRETTY_NAMES[fieldName];

  if (getChartType(fieldName) === "bar") {
    return (
      <DefaultBar data={data} totalPopulation={totalPopulation} fieldPrettyName={fieldPrettyName} />
    );
  }

  if (getChartType(fieldName) === "pie") {
    return <DefaultPie data={data} fieldPrettyName={fieldPrettyName} />;
  }

  return null;
};

export default CustomerBaseChart;
