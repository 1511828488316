import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as R from "ramda";

import { ButtonGroup, Dropdown, Tooltip, Form, DropdownButton } from "react-bootstrap";
import { MdFilterList, MdSave, MdWarning } from "react-icons/md";

import { useSetAreYouSure, useSetError } from "../redux/modals";
import { useCompanyInfo } from "../redux/company";
import { useCreativeMap } from "../redux/creative";
import * as UserRedux from "../redux/user";
import { useSelector } from "react-redux";
import {
  LinearBuyingLambdaFetch,
  LinearLambdaFetch,
  LinearOptimizationsLambdaFetch,
  awaitJSON,
} from "../utils/fetch-utils";
import { useStateFunction, useMap } from "../utils/hooks/useData";
import { convert24hrTo12hr } from "../utils/time-utils";
import { downloadJSONToCSV } from "../utils/download-utils";

import { PlanRow, TYPES_TO_NAMES } from "@blisspointmedia/bpm-types/dist/LinearBuying";

import EditTableView from "./EditTableView";
import SummaryView from "./SummaryView";
import PendingChanges from "./PendingChanges/PendingChanges";

import AddNewNetworkModal from "./Modals/AddNewNetworkModal";
import NoRateChangesModal from "./Modals/NoRateChangesModal";
import ExtendEditModal from "./Modals/ExtendEditModal";
import ExtendSelectedModal from "./Modals/ExtendSelectedModal";
import CreativeAllocationModal from "./Modals/CreativeAllocationModal";
import CreativeAllocationModalV2 from "./Modals/CreativeAllocationModalV2";
import ImportModal from "./Modals/ImportModal";
import AudiencePickerModal from "./Modals/AudiencePickerModal";
import * as Dfns from "date-fns/fp";

import {
  Page,
  FullPageSpinner,
  Spinner,
  BPMButton,
  OldFilterBar,
  ThreeDotsButton,
  OverlayTrigger,
  BPMDateRange,
} from "../Components";

import {
  RotationsAndPricing,
  EditsMap,
  makeNewRow,
  checkUpdatedRates,
  RowsByWeek,
  ExtendEditModalData,
  convertDaysOfWeekStringToArray,
  checkForDuplicateRows,
  checkForConstraints,
  Constraints,
  processExtendSelected,
  NielsenEstimates,
  makeKeyWithUUID,
  flattenedRowsByWeek,
  compareDates,
} from "./linearBuyingUtils";

import {
  processBulkImport,
  processImportChanges,
  exportToExcel,
  exportSummaryView,
  processImportChangesWithID,
  processXMLImport,
} from "./excelUtils";

import {
  CURRENT_WEEK_START,
  FILTER_BAR_OPTIONS,
  DAY_OF_WEEK_MAP,
  DEMO_GROUPINGS,
  PRETTY_DEMO_NAMES,
} from "./linearBuyingConstants";

import { useExperimentFlag } from "../utils/experiments/experiment-utils";

import "./LinearBuying.scss";

export const DATE_FORMAT = "yyyy-MM-dd";
interface Campaign {
  id: number;
  campaign: string;
}

interface GetPlansRes {
  rows: PlanRow[];
  campaigns: Campaign[];
  assumedClearanceRate: number;
}

export interface CampaignMetadata {
  options: {
    label: string;
    value: number;
  }[];
  idToName: Record<string, string>;
  nameToId: Record<string, number>;
}

type SelectedView = "table" | "summary";

interface LinearBuyingProps {
  channel?: string;
}

const LinearBuying: React.FC<LinearBuyingProps> = ({ channel = "linear" }) => {
  const companyInfo = useCompanyInfo();
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);
  const isAdmin = useSelector(UserRedux.isAdminSelector);
  const company = companyInfo.cid;
  const { creativeMap, colorMap } = useCreativeMap({
    // For company-specific scratchpads (i.e. instacart_scratchpad), we want to use the actual company's creative map so we can modify traffic.
    company: company.includes("_scratchpad") ? company.split("_")[0] : company,
    streaming: false,
    mediaTypes: channel === "radio" ? ["audio", "linear"] : ["linear"],
  });

  const defaultDates = () => {
    const start = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());
    const end = start;
    return { start, end };
  };

  const [rowsByWeek, setRowsByWeek] = useState<RowsByWeek>({});
  const [editsMap, setEditsMap] = useState<EditsMap>({});
  const [newRows, setNewRows] = useState<Record<string, PlanRow>>({});
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const [rotationsAndPricing, setRotationsAndPricing] = useState<RotationsAndPricing>({});
  const [nielsenEstimates, setNielsenEstimates] = useState<NielsenEstimates>({});
  const [universeEstimate, setUniverseEstimate] = useState<number>();
  const [assumedClearanceRate, setAssumedClearanceRate] = useState<number>();
  const [selectedDemos, setSelectedDemos] = useState<Record<string, boolean>>({ hh: true });
  const [selectedMeasurement, setSelectedMeasurement] = useState<string>("Live+ Same Day AA");
  const [constraints, setConstraints] = useState<Constraints>();
  const [warning, setWarning] = useState<Record<string, boolean>>({});

  const [selectedView, setSelectedView] = useState<SelectedView>("table");
  const [selectedNetworks, setSelectedNetwork, setSelectedNetworks] = useMap<string, boolean>({});
  const [saving, setSaving] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);

  const [showPendingChanges, setShowPendingChanges] = useState(false);
  const [showBulkImportModal, setShowBulkImportModal] = useState(false);
  const [showImportChangesModal, setShowImportChangesModal] = useState(false);
  const [showImportChangesWithIdModal, setShowImportChangesWithIdModal] = useState(false);
  const [showNoRateChangesModal, setShowNoRateChangesModal] = useState(false);
  const [showNewNetworkModal, setShowNewNetworkModal] = useState(false);
  const [showXMLImportModal, setShowXMLImportModal] = useState(false);
  const [showExtendSelectedModal, setShowExtendSelectedModal] = useState(false);
  const [showCreativeAllocationModal, setShowCreativeAllocationModal] = useState(false);
  const [showAudiencePickerModal, setShowAudiencePickerModal] = useState(false);
  const [dates, setDates] = useState(defaultDates);
  const [extendEditModalData, setExtendEditModalData] = useState<ExtendEditModalData | undefined>();
  const [extendEditRowOptions, setExtendEditRowOptions] = useState<PlanRow[] | undefined>();
  const [selectedRows, setSelectedRows] = useState<Record<string, PlanRow>>({});
  const [mondayDates, setMondayDates] = useState<string[]>([]);
  const [creativeAllocationModalData, setCreativeAllocationModalData] = useState<PlanRow>();
  const [creativeAllocationModalDataV2, setCreativeAllocationModalDataV2] = useState<PlanRow[]>();
  const enableAllLinearBuyingChanges = useExperimentFlag("enableAllLinearBuyingChanges");
  //update date array based on user input
  useEffect(() => {
    const mondayArray: string[] = [];
    let newDate = Dfns.parseISO(dates.start);
    let endDate = Dfns.parseISO(dates.end);
    setMondayDates([]);
    while (newDate <= endDate) {
      mondayArray.push(Dfns.format(DATE_FORMAT, newDate));
      newDate = Dfns.addDays(7, newDate);
    }
    setMondayDates(prevState => [...prevState, ...mondayArray]);
  }, [dates]);

  //get our plan rows
  const ourPlanRows: PlanRow[] = useMemo(() => {
    if (!R.isEmpty(rowsByWeek) && compareDates(rowsByWeek, mondayDates)) {
      const newRowsForCurrentWeek = R.filter(
        row => R.includes(row.week, mondayDates),
        R.values(newRows)
      );
      let combinedRows: PlanRow[] = [];
      mondayDates.forEach(week => {
        combinedRows.push(...rowsByWeek[week]);
      });
      combinedRows = [...newRowsForCurrentWeek, ...combinedRows];
      return combinedRows;
    } else {
      return [];
    }
  }, [rowsByWeek, newRows, mondayDates]);

  const updateRowsByWeek = useCallback(
    (rows: PlanRow[]) => {
      setRowsByWeek(current => {
        const newState = { ...current };
        mondayDates.forEach(week => {
          const matchingDateRows = R.filter(row => row.week === week, rows);
          newState[week] = matchingDateRows;
        });
        return newState;
      });
    },
    [mondayDates]
  );

  const filterBarLines = useMemo(() => {
    const campaignMap = campaigns.reduce(
      (acc, campaign) => ({ ...acc, [campaign.id]: campaign.campaign }),
      {}
    );
    return (ourPlanRows || []).map((row: PlanRow) => {
      const typeName = TYPES_TO_NAMES[row.type];
      let campaignName;
      if (row.campaign_id) {
        campaignName = campaignMap[row.campaign_id];
      }
      return { ...row, typeName, campaignName };
    });
  }, [ourPlanRows, campaigns]);

  const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);

  const filteredRowData = useMemo(() => {
    if (filterBarLines) {
      const filtered = filterBarLines.filter(filter);
      return filtered;
    } else {
      return [];
    }
  }, [filterBarLines, filter]);

  const getPlanRows = useCallback(
    async (company: string, start: string, end: string) => {
      try {
        const plansRes = await LinearBuyingLambdaFetch("/get_linear_plans", {
          params: {
            company,
            weekStart: dates.start,
            weekEnd: dates.end,
            channel,
          },
        });
        const plansData = await awaitJSON<GetPlansRes>(plansRes);
        return plansData;
      } catch (e) {
        setError({
          message: `Failed to get linear plans for w/o ${start} - ${{ end }}. Error: ${e.message}`,
          reportError: e,
        });
        return {} as GetPlansRes;
      }
    },
    [channel, setError, dates]
  );

  // Get plan rows Do we need to check if empty?
  useEffect(() => {
    if (compareDates(rowsByWeek, mondayDates)) {
      return;
    }
    (async () => {
      try {
        setFetching(true);
        const plansData = await getPlanRows(company, dates.start, dates.end);
        const { rows, campaigns, assumedClearanceRate } = plansData;
        updateRowsByWeek(rows);
        setCampaigns(campaigns || []);
        setAssumedClearanceRate(assumedClearanceRate);
        setFetching(false);
      } catch (e) {
        setError({ message: `Failed to get linear plans. Error: ${e.message}`, reportError: e });
      }
    })();
  }, [company, updateRowsByWeek, dates, mondayDates, rowsByWeek, getPlanRows, setError]);

  // Get Nielsen estimates
  useEffect(() => {
    if (!R.isEmpty(nielsenEstimates)) {
      return;
    }
    (async () => {
      try {
        setFetching(true);
        const joinedDemos = R.keys(selectedDemos).join(",");
        const res = await LinearBuyingLambdaFetch("/getNielsenEstimates", {
          params: {
            demos: joinedDemos,
            measurement: selectedMeasurement,
            // use line below for local testing
            // measurement: encodeURIComponent(selectedMeasurement),
          },
        });
        const nielsenData = await awaitJSON(res);
        const { estimatesByHH, universeEstimate } = nielsenData;
        setNielsenEstimates(estimatesByHH);
        setUniverseEstimate(universeEstimate);
        setFetching(false);
      } catch (e) {
        setError({
          message: `Failed to get Nielsen estimates. Error: ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [nielsenEstimates, selectedDemos, selectedMeasurement, setError]);

  const [networks, setNetworks] = useState<string[]>();
  useEffect(() => {
    if (!networks) {
      (async () => {
        try {
          const res = await LinearBuyingLambdaFetch("/networks", {
            params: {
              channel,
            },
          });
          const networksMap = await awaitJSON(res);
          setNetworks(Object.keys(networksMap));
        } catch (e) {
          setError({
            message: `Failed to get networks. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [channel, networks, setError]);

  // Get network rotations and pricing
  useEffect(() => {
    if (R.isEmpty(rotationsAndPricing) && networks) {
      (async () => {
        try {
          const res = await LinearLambdaFetch(`/get_rates?company=${company}`);
          const ratesData = await awaitJSON(res);

          let ratesMap: RotationsAndPricing = {};
          for (let row of ratesData) {
            // Ignore rows that aren't buyable, secured, or general
            if (["buyable", "secured", "general"].includes(row.status)) {
              const {
                network,
                avail,
                cost_30s,
                rotation_name,
                day_set,
                daypart_begin,
                daypart_end,
                status,
                market,
              } = row;

              if (!networks.includes(network)) {
                continue;
              }

              const daypart = `${convert24hrTo12hr(daypart_begin)}-${convert24hrTo12hr(
                daypart_end
              )}`;
              const rotationLabel = `${rotation_name} (${day_set} ${daypart})`;

              const dowString = DAY_OF_WEEK_MAP[day_set] || "MTuWThFSaSu";
              const daysOfWeek = convertDaysOfWeekStringToArray(dowString);

              ratesMap = R.mergeDeepRight(ratesMap, {
                [network]: {
                  [avail]: [
                    ...((ratesMap[network] || {})[avail] || []),
                    {
                      network,
                      avail,
                      cost30s: cost_30s,
                      rotationName: rotation_name,
                      daypartBegin: daypart_begin,
                      daypartEnd: daypart_end,
                      daysOfWeek,
                      rotationLabel,
                      status,
                      market,
                    },
                  ],
                },
              }) as RotationsAndPricing;
            }
          }
          setRotationsAndPricing(ratesMap);
        } catch (e) {
          setError({ message: `Failed to get linear rates. Error: ${e.message}`, reportError: e });
        }
      })();
    }
  }, [setError, rotationsAndPricing, company, networks]);

  // Get constraints
  useEffect(() => {
    if (!constraints) {
      (async () => {
        let res = await LinearOptimizationsLambdaFetch("/constraints?company=global&name=global");
        let data = await awaitJSON(res);
        let constraints = { maximum: {}, avoid: {} };
        for (let category of ["maximum", "avoid"]) {
          for (let row of data.constraints[category]) {
            const { network } = row;
            constraints[category][network] = [...(constraints[category][network] || []), row];
          }
        }
        setConstraints(constraints);
      })();
    }
  }, [constraints]);

  useEffect(() => {
    if (
      !(dates.start && dates.end && rowsByWeek && compareDates(rowsByWeek, mondayDates),
      mondayDates)
    ) {
      return;
    }

    let networksWithoutTraffic: Record<string, boolean> = {};

    const creativeRows = flattenedRowsByWeek(rowsByWeek);
    for (let row of creativeRows) {
      if (!row.creatives) {
        networksWithoutTraffic[row.network] = true;
      }
    }
    setWarning(networksWithoutTraffic);
  }, [dates, mondayDates, rowsByWeek]);

  // If there is an edit that was selected to extend, fetch the future week options.
  useEffect(() => {
    if (extendEditModalData) {
      const {
        week,
        network,
        daypart_start,
        daypart_end,
        dow,
        avail,
        length,
        type,
        cost,
        notes,
        campaign_id,
        market,
      } = extendEditModalData.row;
      // Need to convert before it's used as a param. This matches the format for querying the DB.
      let stringifiedDow = JSON.stringify(dow)
        .replace("[", "{")
        .replace("]", "}")
        .replaceAll('"', "");
      (async () => {
        try {
          const res = await LinearBuyingLambdaFetch("/get_future_matching_plans", {
            params: {
              company,
              weekStart: week,
              network,
              daypart_start,
              daypart_end,
              dow: stringifiedDow,
              avail,
              length,
              type,
              cost,
              notes,
              campaign_id,
              market,
            },
          });
          const rows = await awaitJSON<PlanRow[]>(res);
          setExtendEditRowOptions(rows);
        } catch (e) {
          setError({
            message: `Failed to get future plans for plan ID ${extendEditModalData.row.plan_id}. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [setError, extendEditModalData, company]);

  const campaignMetadata: CampaignMetadata = useMemo(() => {
    const options = R.map(
      campaign => ({ label: campaign.campaign, value: campaign.id }),
      campaigns
    );
    const idToName = campaigns.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.campaign }), {});
    const nameToId = campaigns.reduce((prev, curr) => ({ ...prev, [curr.campaign]: curr.id }), {});
    return { options, idToName, nameToId };
  }, [campaigns]);

  const addNewRow = (network: string, week: string, isNewNetwork = false) => {
    let newRow = makeNewRow(rotationsAndPricing, network, week, company, isNewNetwork);

    setNewRows(current => ({ ...current, [newRow.key]: newRow }));
  };

  const addNewNetwork = (network: string) => {
    mondayDates.forEach(week => {
      addNewRow(network, week, true);
    });
    setShowNewNetworkModal(false);
    setSelectedNetwork(network, !selectedNetworks[network] ?? true);
  };

  const bulkImport = useCallback(
    async (file, week) => {
      try {
        if (!rowsByWeek[week]) {
          const { rows } = await getPlanRows(company, dates.start, dates.end);
          updateRowsByWeek(rows);
          const importedNewRows = processBulkImport(
            file,
            mondayDates,
            company,
            rows,
            creativeMap,
            rotationsAndPricing,
            campaignMetadata.nameToId,
            enableAllLinearBuyingChanges
          );
          setNewRows(current => ({ ...current, ...importedNewRows }));
        } else {
          const importedNewRows = processBulkImport(
            file,
            mondayDates,
            company,
            filteredRowData,
            creativeMap,
            rotationsAndPricing,
            campaignMetadata.nameToId,
            enableAllLinearBuyingChanges
          );
          setNewRows(current => ({ ...current, ...importedNewRows }));
        }
      } catch (e) {
        setError({
          message: `Failed to import buy for the w/o ${week}: ${e.message}`,
          reportError: e,
        });
      }
    },
    [
      updateRowsByWeek,
      company,
      creativeMap,
      rowsByWeek,
      setError,
      getPlanRows,
      rotationsAndPricing,
      campaignMetadata.nameToId,
      dates,
      mondayDates,
      filteredRowData,
      enableAllLinearBuyingChanges,
    ]
  );

  const importXML = useCallback(
    async (file, week) => {
      try {
        if (!rowsByWeek[week]) {
          const { rows } = await getPlanRows(company, dates.start, dates.end);
          updateRowsByWeek(rows);
          const importedNewRows = processXMLImport(
            file,
            mondayDates,
            company,
            rows,
            creativeMap,
            rotationsAndPricing,
            campaignMetadata.nameToId,
            enableAllLinearBuyingChanges
          );
          setNewRows(current => ({ ...current, ...importedNewRows }));
        } else {
          const importedNewRows = processXMLImport(
            file,
            mondayDates,
            company,
            filteredRowData,
            creativeMap,
            rotationsAndPricing,
            campaignMetadata.nameToId,
            enableAllLinearBuyingChanges
          );
          setNewRows(current => ({ ...current, ...importedNewRows }));
        }
      } catch (e) {
        setError({
          message: `Failed to import buy for the w/o ${week}: ${e.message}`,
          reportError: e,
        });
      }
    },
    [
      updateRowsByWeek,
      company,
      creativeMap,
      rowsByWeek,
      setError,
      getPlanRows,
      rotationsAndPricing,
      campaignMetadata.nameToId,
      dates,
      mondayDates,
      filteredRowData,
      enableAllLinearBuyingChanges,
    ]
  );

  const importChanges = useCallback(
    async (file, week) => {
      try {
        if (!rowsByWeek[week]) {
          const { rows } = await getPlanRows(company, dates.start, dates.end);
          updateRowsByWeek(rows);
          const importedChanges = processImportChanges(
            file,
            rows,
            creativeMap,
            campaignMetadata.idToName,
            company,
            mondayDates,
            enableAllLinearBuyingChanges
          );
          setEditsMap(current => ({ ...current, ...importedChanges }));
        } else {
          const importedChanges = processImportChanges(
            file,
            filteredRowData,
            creativeMap,
            campaignMetadata.idToName,
            company,
            mondayDates,
            enableAllLinearBuyingChanges
          );
          setEditsMap(current => ({ ...current, ...importedChanges }));
        }
      } catch (e) {
        setError({
          message: `Failed to import changes for the w/o ${week}: ${e.message}`,
          reportError: e,
        });
      }
    },
    [
      updateRowsByWeek,
      rowsByWeek,
      getPlanRows,
      company,
      creativeMap,
      campaignMetadata.idToName,
      setError,
      mondayDates,
      dates,
      filteredRowData,
      enableAllLinearBuyingChanges,
    ]
  );

  const importChangesWithId = useCallback(
    async (file, week) => {
      try {
        if (!rowsByWeek[week]) {
          const { rows } = await getPlanRows(company, week, week);
          updateRowsByWeek(rows);
          const importedChanges = processImportChangesWithID(
            file,
            rows,
            creativeMap,
            company,
            mondayDates,
            enableAllLinearBuyingChanges
          );
          setEditsMap(current => ({ ...current, ...importedChanges }));
        } else {
          const importedChanges = processImportChangesWithID(
            file,
            filteredRowData,
            creativeMap,
            company,
            mondayDates,
            enableAllLinearBuyingChanges
          );
          setEditsMap(current => ({ ...current, ...importedChanges }));
        }
      } catch (e) {
        setError({
          message: `Failed to import changes for the w/o ${week}: ${e.message}`,
          reportError: e,
        });
      }
    },
    [
      updateRowsByWeek,
      rowsByWeek,
      getPlanRows,
      company,
      creativeMap,
      setError,
      mondayDates,
      filteredRowData,
      enableAllLinearBuyingChanges,
    ]
  );
  //checking if rows by week has the correct data and then processing extend selected
  const extendSelected = useCallback(
    async (rowsToExtend, extendedWeek) => {
      try {
        if (!rowsByWeek[extendedWeek]) {
          const { rows } = await getPlanRows(company, extendedWeek, extendedWeek);
          updateRowsByWeek(rows);
          const extendedRows = processExtendSelected(rowsToExtend, extendedWeek, rows);
          setNewRows(current => ({ ...current, ...extendedRows }));
        } else {
          const extendedRows = processExtendSelected(
            rowsToExtend,
            extendedWeek,
            rowsByWeek[extendedWeek]
          );

          setNewRows(current => ({ ...current, ...extendedRows }));
        }
      } catch (e) {
        setError({
          message: `Failed to extend selected rows for the w/o ${extendedWeek}: ${e.message}`,
          reportError: e,
        });
      }
    },
    [updateRowsByWeek, company, getPlanRows, rowsByWeek, setError]
  );

  const refreshRatesForSelected = useCallback(() => {
    let rateChanges = checkUpdatedRates(rotationsAndPricing, Object.values(selectedRows));

    if (R.isEmpty(rateChanges)) {
      setShowNoRateChangesModal(true);
      return;
    }
    setEditsMap(current => {
      return R.mergeDeepRight(current, rateChanges) as EditsMap;
    });
    setSelectedRows({});
    setShowPendingChanges(true);
  }, [rotationsAndPricing, selectedRows]);

  /*
   * Copy selected plan rows and add them as pending new rows.
   */
  const copyRows = useCallback(() => {
    const copiedRows = Object.fromEntries(
      Object.entries(selectedRows).map(([key, value]) => {
        const newKey = makeKeyWithUUID(value);
        return [
          newKey,
          {
            ...value,
            key: newKey,
            plan_id: null,
            order_id: null,
            traffic_id: null,
            isNewNetwork: true,
            isNewRow: true,
            is_plan_pending: true,
          },
        ];
      })
    );
    if (!R.isEmpty(copiedRows)) {
      setNewRows(current => ({ ...current, ...copiedRows }));
    }
    setSelectedRows({});
  }, [selectedRows]);

  const hasPendingChanges = useMemo(() => {
    if (!R.isEmpty(editsMap) || !R.isEmpty(newRows)) {
      return true;
    }
    return false;
  }, [editsMap, newRows]);

  const clearAllChanges = () => {
    setNewRows({});
    setEditsMap({});
    setShowPendingChanges(false);
  };

  const saveChanges = async () => {
    setSaving(true);
    const { hasDuplicates, lines } = checkForDuplicateRows(rowsByWeek, editsMap, newRows);
    const { isViolatingConstraints, rows } = checkForConstraints(editsMap, newRows, constraints);
    if (hasDuplicates) {
      setError({ title: "The following rows are duplicated", message: lines });
      setSaving(false);
      return;
    }
    if (isViolatingConstraints) {
      try {
        await setAreYouSure({
          title: "You are violating some constraints.",
          message: rows,
          okayText: "Save anyway",
        });
      } catch (e) {
        setSaving(false);
        return;
      }
    }

    try {
      await LinearBuyingLambdaFetch("/update_linear_plans", {
        method: "POST",
        body: {
          editedRows: R.values(editsMap),
          newRows: R.values(newRows),
          company,
          useRadioExperiment: channel === "radio",
          useLinearBuyingUpdatesExperiment: enableAllLinearBuyingChanges,
        },
      });
      setRowsByWeek({});
      setEditsMap({});
      setNewRows({});
      setSelectedNetworks({});
      setSelectedRows({});
      setSelectedView("table");
      setSaving(false);
      setShowPendingChanges(false);
    } catch (e) {
      setSaving(false);
      setError({ message: e.message, reportError: e });
    }
  };

  const MEASUREMENTS = ["Live+ Same Day AA", "Live+ 3 Days AA", "Live+ 7 Days AA"];

  let renderItem: JSX.Element;

  switch (selectedView) {
    case "table":
      renderItem = (
        <div className="tableViewContainer">
          <div className="filterBarContainer">
            <OldFilterBar
              options={FILTER_BAR_OPTIONS}
              lines={filterBarLines}
              onFilter={setFilter}
            />
          </div>
          <EditTableView
            rows={filteredRowData}
            editsMap={editsMap}
            rotationsAndPricing={rotationsAndPricing}
            nielsenEstimates={nielsenEstimates}
            selectedDemos={selectedDemos}
            selectedMeasurement={selectedMeasurement}
            universeEstimate={universeEstimate}
            campaignMetadata={campaignMetadata}
            weeks={mondayDates}
            selectedRows={selectedRows}
            setNewRows={setNewRows}
            setEditsMap={setEditsMap}
            setSelectedRows={setSelectedRows}
            setShowCreativeAllocationModal={setShowCreativeAllocationModal}
            setCreativeAllocationModalData={setCreativeAllocationModalData}
            setCreativeAllocationModalDataV2={setCreativeAllocationModalDataV2}
          />
        </div>
      );
      break;
    case "summary":
      renderItem = (
        <SummaryView
          rows={filteredRowData}
          editsMap={editsMap}
          newRows={newRows}
          creativeMap={creativeMap}
          weeks={mondayDates}
          nielsenEstimates={nielsenEstimates}
        />
      );
      break;
  }

  const getNMIExport = useCallback(async () => {
    try {
      setGeneralLoading(true);
      const joinedDemos = R.keys(selectedDemos).join(",");
      const nmiData = await LinearBuyingLambdaFetch("/convertToNMI", {
        params: {
          company,
          week: dates.start,
          demos: joinedDemos,
          measurement: selectedMeasurement,
          assumedClearanceRate,
        },
      });
      const response = await awaitJSON(nmiData);
      downloadJSONToCSV(
        response,
        `NMI_${company}_${dates.start}_${joinedDemos}_${selectedMeasurement}.csv`
      );
      setGeneralLoading(false);
    } catch (e) {
      setGeneralLoading(false);
      setError({ message: `Failed to get NMI Export. Error: ${e.message}`, reportError: e });
    }
  }, [company, dates.start, selectedDemos, selectedMeasurement, assumedClearanceRate, setError]);

  return (
    <Page
      title={
        <div className="linearBuyingTitle">
          <div>{channel === "radio" ? "Radio Buying" : "Linear Buying"}</div>
          <Dropdown className="optionsDropdown">
            <Dropdown.Toggle as={ThreeDotsButton} />
            <Dropdown.Menu>
              {(dates.start >= CURRENT_WEEK_START || isAdmin) && (
                <>
                  <Dropdown.Item onClick={() => setShowBulkImportModal(true)}>
                    Bulk Import
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowImportChangesModal(true)}>
                    Import Changes
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowImportChangesWithIdModal(true)}>
                    Import Changes with ID
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowExtendSelectedModal(true)}>
                    Extend Selected
                  </Dropdown.Item>
                  <Dropdown.Item onClick={refreshRatesForSelected}>Refresh Rates</Dropdown.Item>
                  <Dropdown.Item onClick={copyRows}>Copy Rows</Dropdown.Item>
                </>
              )}
              <Dropdown.Item
                onClick={() =>
                  exportToExcel(
                    ourPlanRows,
                    creativeMap,
                    nielsenEstimates,
                    universeEstimate,
                    campaignMetadata.idToName,
                    company,
                    dates.start,
                    enableAllLinearBuyingChanges
                  )
                }
              >
                Export
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  exportSummaryView(
                    rowsByWeek[dates.start],
                    editsMap,
                    newRows,
                    creativeMap,
                    company,
                    dates.start,
                    nielsenEstimates,
                    assumedClearanceRate
                  )
                }
              >
                Export Summary
              </Dropdown.Item>
              <Dropdown.Item onClick={getNMIExport}>NMI Export</Dropdown.Item>
              {showXMLImportModal && ( //TODO remove the flag so this can go live
                <Dropdown.Item onClick={() => setShowXMLImportModal(false)}>
                  XML Import
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          {generalLoading && <Spinner />}
          {dates.start >= CURRENT_WEEK_START && selectedView === "table" && (
            <BPMButton
              size="sm"
              variant="outline-primary"
              onClick={() => setShowNewNetworkModal(true)}
            >
              + Add Row
            </BPMButton>
          )}
          <BPMButton
            size="sm"
            variant="outline-primary"
            onClick={() => setShowAudiencePickerModal(true)}
          >
            Demos:{" "}
            {R.keys(selectedDemos).length === 1 &&
            R.values(DEMO_GROUPINGS.Meta).includes(R.keys(selectedDemos)[0])
              ? PRETTY_DEMO_NAMES[R.keys(selectedDemos)[0]]
              : "Custom"}
          </BPMButton>
          <Form.Group className="measurement">
            <DropdownButton
              className="measurementDropdown"
              title={`Measurement: ${selectedMeasurement}`}
              onSelect={value => {
                if (value) {
                  setSelectedMeasurement(value);
                  setNielsenEstimates({});
                }
              }}
            >
              {MEASUREMENTS.map(measurementSelection => (
                <Dropdown.Item key={measurementSelection} eventKey={measurementSelection}>
                  {measurementSelection}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Form.Group>
          {!R.isEmpty(warning) && (
            <div>
              <OverlayTrigger
                placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                overlay={
                  <Tooltip id={dates.start}>
                    <>
                      Networks that have missing traffic ({R.keys(warning).length}):{" "}
                      {R.keys(warning).join(", ")} <br />
                    </>
                  </Tooltip>
                }
              >
                <BPMButton variant="warning" size="sm">
                  <MdWarning color="white" />
                </BPMButton>
              </OverlayTrigger>
            </div>
          )}
        </div>
      }
      pageType={channel === "radio" ? "Radio Buying" : "Linear Buying"}
      actions={
        <div className="linearBuyingActions">
          {hasPendingChanges && (
            <>
              <BPMButton
                size="sm"
                variant="outline-primary"
                icon={<MdFilterList />}
                onClick={() => {
                  setShowPendingChanges(!showPendingChanges);
                }}
              >
                Pending Changes
              </BPMButton>
              <BPMButton size="sm" variant="danger" onClick={() => clearAllChanges()}>
                Clear All Changes
              </BPMButton>
              <BPMButton
                size="sm"
                variant="success"
                onClick={() => saveChanges()}
                disabled={saving}
              >
                {saving ? <Spinner /> : <MdSave />}
              </BPMButton>
            </>
          )}
          <ButtonGroup>
            {/* <BPMButton
              size="sm"
              variant={selectedView === "list" ? "primary" : "outline-primary"}
              onClick={() => setSelectedView("list")}
            >
              List View
            </BPMButton>
            <BPMButton
              size="sm"
              variant={selectedView === "table" ? "primary" : "outline-primary"}
              onClick={() => {
                setSelectedView("table");
                setShowPendingChanges(false);
              }}
            >
              Table View
            </BPMButton> */}
            <BPMButton
              size="sm"
              variant={selectedView === "table" ? "primary" : "outline-primary"}
              onClick={() => {
                setSelectedView("table");
                setShowPendingChanges(false);
              }}
            >
              Table View
            </BPMButton>
            <BPMButton
              size="sm"
              variant={selectedView === "summary" ? "primary" : "outline-primary"}
              onClick={() => {
                setSelectedView("summary");
                setShowPendingChanges(false);
              }}
            >
              Summary View
            </BPMButton>
          </ButtonGroup>
          {
            <BPMDateRange
              bordered
              range={dates}
              fullWeeksOnly
              isDayBlocked={date => !R.pipe(Dfns.parseISO, Dfns.isMonday)(date)}
              onChange={dates => {
                setDates(dates);
                setSelectedNetworks({});
                setSelectedRows({});
              }}
            />
          }
        </div>
      }
    >
      <div className="linearBuying">
        <div className="leftSideContainer">
          {!fetching && !R.isEmpty(rotationsAndPricing) ? renderItem : <FullPageSpinner />}
        </div>
        {showPendingChanges && hasPendingChanges && (
          <PendingChanges
            editsMap={editsMap}
            newRows={newRows}
            setEditsMap={setEditsMap}
            setNewRows={setNewRows}
            setExtendEditModalData={setExtendEditModalData}
            setShowPendingChanges={setShowPendingChanges}
          />
        )}
      </div>
      {showBulkImportModal && (
        <ImportModal
          importType="bulk"
          importFunction={bulkImport}
          week={dates.start}
          onClose={() => setShowBulkImportModal(false)}
        />
      )}
      {showXMLImportModal && (
        <ImportModal
          importType="xml"
          importFunction={importXML}
          week={dates.start}
          onClose={() => setShowXMLImportModal(false)}
        />
      )}
      {showImportChangesModal && (
        <ImportModal
          importType="changes"
          importFunction={importChanges}
          week={dates.start}
          onClose={() => setShowImportChangesModal(false)}
        />
      )}
      {showImportChangesWithIdModal && (
        <ImportModal
          importType="changesWithId"
          importFunction={importChangesWithId}
          week={dates.start}
          onClose={() => setShowImportChangesWithIdModal(false)}
        />
      )}
      {showNoRateChangesModal && (
        <NoRateChangesModal onClose={() => setShowNoRateChangesModal(false)} />
      )}
      {showNewNetworkModal && (
        <AddNewNetworkModal
          rotationsAndPricing={rotationsAndPricing}
          addNewNetwork={addNewNetwork}
          onClose={() => setShowNewNetworkModal(false)}
        />
      )}
      {extendEditModalData && extendEditRowOptions && (
        <ExtendEditModal
          onClose={() => {
            setExtendEditModalData(undefined);
            setExtendEditRowOptions(undefined);
          }}
          editData={extendEditModalData}
          rowOptions={extendEditRowOptions}
          setEditsMap={setEditsMap}
        />
      )}
      {showExtendSelectedModal && (
        <ExtendSelectedModal
          onClose={() => {
            setShowExtendSelectedModal(false);
            setSelectedRows({});
          }}
          rowsToExtend={selectedRows}
          week={dates.start}
          extendFunction={extendSelected}
        />
      )}
      {showCreativeAllocationModal && creativeAllocationModalData && creativeMap && (
        <CreativeAllocationModal
          planRow={creativeAllocationModalData}
          creativeMap={creativeMap}
          colorMap={colorMap}
          setEditsMap={setEditsMap}
          setNewRows={setNewRows}
          onClose={() => {
            setShowCreativeAllocationModal(false);
            setCreativeAllocationModalData(undefined);
          }}
        />
      )}
      {showCreativeAllocationModal && creativeAllocationModalDataV2 && creativeMap && (
        <CreativeAllocationModalV2
          planRows={creativeAllocationModalDataV2}
          creativeMap={creativeMap}
          colorMap={colorMap}
          setEditsMap={setEditsMap}
          setNewRows={setNewRows}
          onClose={() => {
            setShowCreativeAllocationModal(false);
            setCreativeAllocationModalDataV2(undefined);
          }}
        />
      )}
      {showAudiencePickerModal && (
        <AudiencePickerModal
          selectedDemos={selectedDemos}
          setSelectedDemos={setSelectedDemos}
          setNielsenEstimates={setNielsenEstimates}
          setUniverseEstimate={setUniverseEstimate}
          onClose={() => setShowAudiencePickerModal(false)}
        />
      )}
    </Page>
  );
};

export default LinearBuying;
