import { MiscLambdaFetch, awaitJSON } from "../utils/fetch-utils";

export interface AmcInstanceMappingParams {
  company: string;
  amcInstanceId: string;
}

export const createAmcInstanceIdMapping = async (
  clientInfoParams: AmcInstanceMappingParams
): Promise<{ statusCode: number; body: string }> => {
  let res = await MiscLambdaFetch("/createAmcInstanceIdMapping", {
    method: "POST",
    body: JSON.stringify(clientInfoParams),
  });
  return await awaitJSON(res);
};

export const getAmcInstanceIdMapping = async (
  company: string
): Promise<{ statusCode: number; body: AmcInstanceMappingParams[] }> => {
  const res = await MiscLambdaFetch(`/getAmcInstanceIdMapping?company=${company}`, {
    method: "GET",
  });
  return await awaitJSON(res);
};
