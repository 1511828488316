import React from "react";
import { RouteComponentProps } from "@reach/router";
import { BrandHealthEntry } from "@blisspointmedia/bpm-types/dist/BrandHealthMetrics";
import { DateRange } from "../utils/types";
import { Img, FullPageSpinner } from "../Components";
import BrandMetricsContent from "./BrandMetricsContent";
import FiltersPanel from "./FiltersPanel";

interface YouGovBrandHealthProps {
  focalAdvertiser: string;
  nonFocalAdvertisers: string[];
  data: BrandHealthEntry[];
  dateRange: DateRange | undefined;
  ageMap: Partial<Record<string, boolean>>;
  setAgeMap: (key: string, value: boolean) => void;
  genderMap: Partial<Record<string, boolean>>;
  setGenderMap: (key: string, value: boolean) => void;
  regionMap: Partial<Record<string, boolean>>;
  setRegionMap: (key: string, value: boolean) => void;
  incomeMap: Partial<Record<string, boolean>>;
  setIncomeMap: (key: string, value: boolean) => void;
  setFetchingData: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  appliedFilters: {
    [key: string]: boolean | undefined;
  };
  inLoadingState: boolean;
}

const YouGovBrandHealth = ({
  focalAdvertiser,
  nonFocalAdvertisers,
  data,
  dateRange,
  ageMap,
  setAgeMap,
  genderMap,
  setGenderMap,
  regionMap,
  setRegionMap,
  incomeMap,
  setIncomeMap,
  setFetchingData,
  setData,
  appliedFilters,
  inLoadingState,
}: YouGovBrandHealthProps & RouteComponentProps): JSX.Element => {
  return dateRange !== undefined ? (
    <div className="brandHealthMetrics">
      <>
        <div className="leftSection">
          <BrandMetricsContent
            focalAdvertiser={focalAdvertiser}
            nonFocalAdvertisers={nonFocalAdvertisers}
            type="Awareness"
            infoBarText="The percent of individuals that have ever heard of the brand."
            toolTipText="Respondents to YouGov's survey are asked to select from a list of online brands which they have ever heard of."
            data={data}
            dateRange={dateRange}
          />
          <BrandMetricsContent
            focalAdvertiser={focalAdvertiser}
            nonFocalAdvertisers={nonFocalAdvertisers}
            type="Consideration"
            infoBarText="The percent of individuals that would consider visiting the brand online."
            toolTipText="Respondents to YouGov's survey are asked to select from a list of online brands which they would consider visiting through desktop/laptop, tablet, or mobile phone."
            data={data}
            dateRange={dateRange}
          />
        </div>
        <div className="rightSection">
          <FiltersPanel
            ageMap={ageMap}
            setAgeMap={setAgeMap}
            genderMap={genderMap}
            setGenderMap={setGenderMap}
            regionMap={regionMap}
            setRegionMap={setRegionMap}
            incomeMap={incomeMap}
            setIncomeMap={setIncomeMap}
            setFetchingData={setFetchingData}
            setData={setData}
            appliedFilters={appliedFilters}
            inLoadingState={inLoadingState}
          />
          <div className="signature">
            <div className="signatureLabel">Powered By:</div>
            <div className="signatureImage">
              <Img src="https://cdn.blisspointmedia.com/assets/img/YouGov.png" />
            </div>
          </div>
        </div>
      </>
    </div>
  ) : (
    <FullPageSpinner />
  );
};

export default YouGovBrandHealth;
